import { Control, Controller, FieldValues, Path, UseFormRegister } from "react-hook-form";
import Checkbox from "ui/components/Checkbox";
import MultiSelectComboBox from "ui/components/MultiSelectComboBox";
import { allParticipantsAreNotTheHost, allParticipantsHaveValidEmails } from "ui/util/ParticipantEmailValidations";
import { FieldOptions } from "util/FieldOptions";

type GroupOrderParticipantInvitesFieldProps<T extends FieldValues> = {
  control: Control<T>;
  fieldOptions: FieldOptions<T>;
  groupOrderHostEmail?: string;
  register: UseFormRegister<T>;
  savedParticipants: string[];
};

const GroupOrderParticipantInvitesField = <T extends FieldValues>({
  control,
  fieldOptions,
  groupOrderHostEmail,
  register,
  savedParticipants,
}: GroupOrderParticipantInvitesFieldProps<T>) => {
  return (
    <>
      <Controller
        control={control}
        name={"participantEmails" as Path<T>}
        render={({ field, fieldState: { error } }) => (
          <MultiSelectComboBox
            errorText={error?.message}
            label="Invitees"
            options={savedParticipants}
            placeholder="Email address(es)"
            required
            {...field}
          />
        )}
        rules={{
          ...fieldOptions.participantEmails,
          validate: {
            allParticipantsHaveValidEmails: allParticipantsHaveValidEmails,
            allParticipantsAreNotTheHost: allParticipantsAreNotTheHost(groupOrderHostEmail),
          },
        }}
      />
      <Checkbox
        capitalizeText={false}
        label="Save these contacts for use in future group orders"
        variant="basic"
        {...register("saveParticipantEmails" as Path<T>)}
      />
    </>
  );
};

export default GroupOrderParticipantInvitesField;
