const Colors = {
  darkTomato: "#C20025",
  lightTomato: "#F7B3BF",
  freshTomato: "#E4002B",
  brightWhite: "#FFFFFF",
  gray100: "#F2F2F2",
  gray200: "#DEDEDE",
  gray300: "#BFBFBF",
  gray400: "#949494",
  gray500: "#6B6B6B",
  gray600: "#3B3B3B",
  superBlack: "#000000",
  overlay: "rgba(0, 0, 0, 0.35)",
} as const;

export default Colors;
