import MuiStyles from "ui/util/Styles";

const styles = {
  card: (theme) => ({
    width: theme.pxToRem(272),
    "& .MuiCardActions-root": { gap: "1.5rem" },
  }),
  primaryButton: {
    flex: 1,
  },
  productImage: {
    alignSelf: "center",
    flexShrink: 0,
    height: 52,
    objectFit: "contain",
    width: 76,
  },
  productInfo: {
    overflow: "hidden",
  },
  productInfoMeta: {
    display: "flex",
    gap: "0.5rem",
    marginBottom: "0.75rem",
  },
  productName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    typography: "formFieldLabel",
  },
  slide: {
    width: "fit-content !important",
    willChange: "transform",
  },
} as const satisfies MuiStyles;

export default styles;
