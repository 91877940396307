import DropdownIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps, Chip } from "@mui/material";
import React, { Ref } from "react";
import Checkbox from "ui/components/Checkbox";
import styles from "ui/components/MultiSelectComboBox/MultiSelectComboBox.styles";
import TextField from "ui/components/TextField";

type AutocompleteProps = Omit<MuiAutocompleteProps<string, true, true, true>, "onChange" | "renderInput">;

export type MultiSelectComboBoxProps = AutocompleteProps & {
  onChange: (value: string[]) => void;
  errorText?: string;
  label?: React.ReactNode;
  placeholder?: string;
  ref?: Ref<HTMLInputElement>;
  required?: boolean;
};

const MultiSelectComboBox = React.forwardRef(function MultiSelectComboBox(
  { errorText, label, onChange, options, placeholder, required, ...muiAutocompleteProps }: MultiSelectComboBoxProps,
  ref?: Ref<HTMLInputElement>
) {
  return (
    <Autocomplete
      {...muiAutocompleteProps}
      className={options.length === 0 ? "no-options" : undefined}
      clearOnBlur
      disableClearable
      disableCloseOnSelect
      forcePopupIcon={!!options.length}
      freeSolo
      getOptionLabel={(option) => option}
      handleHomeEndKeys
      multiple
      onChange={(_, value) => onChange(value)}
      openOnFocus={false}
      options={options}
      popupIcon={<DropdownIcon />}
      ref={ref}
      renderInput={(params) => (
        <TextField {...params} errorText={errorText} label={label} placeholder={placeholder} required={required} />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox capitalizeText={false} checked={selected} label={option} variant="basic" />
        </li>
      )}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip label={option} sx={styles.tag} {...getTagProps({ index })} />
        ))
      }
      selectOnFocus
      sx={styles.root}
    />
  );
});

export default MultiSelectComboBox;
