import isString from "lodash/isString";
import MetadataKey from "models/MetadataKey";

export type FaveProduct = {
  id: string;
  recipient?: string;
  name: string;
  options: FaveOption[];
};

export type Fave = {
  id: string;
  storeId: string;
  name: string;
  products: FaveProduct[];
};

export type FaveOption = {
  id: string;
  name: string;
  metadata?: { key: MetadataKey; value: string }[];
};

export type FavesResponse = Fave[];

export type FavesRequestPayload = Pick<Fave, "storeId" | "name"> & {
  products: (Omit<FaveProduct, "name" | "options"> & { optionIds?: string[] })[];
};

export const isFaveOption = (object: unknown): object is FaveOption => {
  const faveOption = object as FaveOption;
  return !!faveOption && isString(faveOption.id) && isString(faveOption.name);
};

export const isFaveProduct = (object: unknown): object is FaveProduct => {
  const faveProduct = object as FaveProduct;
  return (
    !!faveProduct &&
    isString(faveProduct.id) &&
    isString(faveProduct.name) &&
    (!faveProduct.options ||
      (Array.isArray(faveProduct.options) && faveProduct.options.every((option) => isFaveOption(option)))) &&
    (!faveProduct.recipient || isString(faveProduct.recipient))
  );
};

export const isFave = (object: unknown): object is Fave => {
  const fave = object as Fave;
  return (
    !!fave &&
    isString(fave.id) &&
    isString(fave.name) &&
    Array.isArray(fave.products) &&
    fave.products.every((product) => isFaveProduct(product))
  );
};

export const isFaveResponse = (object: unknown): object is FavesResponse => {
  const faves = object as FavesResponse;
  return !!faves && Array.isArray(faves) && faves.every((fave) => isFave(fave));
};
