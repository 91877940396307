import { ZIndex } from "ui/Theme";
import MuiStyles from "ui/util/Styles";

const styles = {
  appBar: (theme) => ({
    background: theme.palette.secondary.main,
    display: "flex",
    position: "relative",
    zIndex: ZIndex.high,
    paddingTop: "0.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      paddingTop: "0.75rem",
      paddingLeft: "2rem",
      paddingRight: "1rem",
    },

    [theme.breakpoints.up("xl")]: {
      height: "6rem",
      paddingTop: "1rem",
      paddingLeft: "2.75rem",
      paddingRight: "2rem",
    },
  }),
  toolBar: (theme) => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    gap: "0.5rem",
    height: "4.5rem",
    justifyContent: "space-between",
    paddingBottom: "0.75rem",

    [theme.breakpoints.up("xl")]: {
      alignItems: "flex-start",
      gap: "0.75rem",
    },
  }),
  openDrawerButton: (theme) => ({
    marginRight: "0.125rem",

    "> svg": {
      color: theme.palette.secondary.contrastText,
    },

    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  }),
  logo: (theme) => ({
    position: "relative",
    width: "3.75rem",
    height: "3.75rem",

    [theme.breakpoints.up("md")]: {
      width: "4rem",
      height: "4rem",
    },

    [theme.breakpoints.up("xl")]: {
      width: "8rem",
      height: "8rem",
    },

    "&:focus-visible": {
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },

    "& img": {
      width: "400%",
      height: "400%",
      transform: "scale(0.25)",
      transformOrigin: "0 0",
      verticalAlign: "middle",
    },
  }),
  navItems: (theme) => ({
    display: "none",
    alignItems: "center",
    margin: 0,
    padding: 0,

    [theme.breakpoints.up("lg")]: {
      height: "4rem",
      display: "flex",
    },
  }),
  navItem: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
  },
  navControls: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },

  // Divider

  divider: (theme) => ({
    borderColor: theme.palette.grey[600],
    display: "none",
    paddingLeft: "0.5rem",

    [theme.breakpoints.up("xl")]: {
      display: "block",
    },
  }),

  // Cart
  cart: (theme) => ({
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    padding: "0.625rem",

    [theme.breakpoints.up("lg")]: {
      padding: "1rem",
    },

    "&:focus-visible": {
      background: theme.palette.secondary.main,
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        background: theme.palette.secondary.main,
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },
  }),
  badge: (theme) => ({
    typography: "body1Bold",

    background: theme.palette.primary.main,
    border: `0.125rem solid ${theme.palette.secondary.main}`,
    borderRadius: "0.75rem",
    color: theme.palette.primary.contrastText,
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    height: "1.5rem",
    left: "1.75rem",
    lineHeight: `${theme.typography.pxToRem(20)} !important`,
    minWidth: "1.5rem",
    paddingLeft: "0.25rem",
    paddingRight: "0.25rem",
    position: "absolute",
    textAlign: "center",
    top: "-0.125rem",

    [theme.breakpoints.up("lg")]: {
      left: "2.125rem",
      top: "0.25rem",
    },
  }),
} as const satisfies MuiStyles;

export default styles;
