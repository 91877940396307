import ExpandIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary,
} from "@mui/material";
import { ReactNode } from "react";
import styles from "ui/components/Accordion/Accordion.styles";

type AccordionProps = Omit<MuiAccordionProps, "elevation" | "disableGutters" | "square" | "title" | "variant"> & {
  children: ReactNode;
  id: string;
  title: ReactNode;
};

const Accordion = ({ children, id, title, ...muiAccordionProps }: AccordionProps) => (
  <MuiAccordion
    sx={styles.accordion}
    defaultExpanded={false}
    {...muiAccordionProps}
    elevation={0}
    disableGutters
    square
  >
    <AccordionSummary
      expandIcon={<ExpandIcon sx={styles.expandIcon} />}
      aria-controls={`${id}-content`}
      id={`${id}-summary`}
      sx={styles.accordionSummary}
    >
      {title}
    </AccordionSummary>
    <AccordionDetails sx={styles.accordionDetails}>{children}</AccordionDetails>
  </MuiAccordion>
);

export default Accordion;
