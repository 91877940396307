import { GroupOrderDetails } from "models/groupOrder";
import { isOrder, Order } from "models/order/Order";
import { isString, isUndefined } from "util/JavascriptUtility";

/**
 * An OrderConfirmation object comes back when submitting an order
 * See the "Order Confirmation" object under Schemas at https://test-swagger.octocart.com/
 */
export type OrderConfirmation = {
  confirmationId: string;
  order: Order;
  email?: string;
  groupOrderDetails?: GroupOrderDetails;
  timePlacedFormatted: string;
  readyTimeFormatted: string;
  vendorExtRef: string;
};

export const isOrderConfirmation = (object: unknown): object is OrderConfirmation => {
  const confirmation = object as OrderConfirmation;

  return (
    confirmation != null &&
    isString(confirmation.confirmationId) &&
    isOrder(confirmation.order) &&
    (isUndefined(confirmation.email) || isString(confirmation.email))
  );
};
