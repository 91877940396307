// Util functions that simplify browser cookie use.

/** Parse all browser cookies into a Map. */
const loadCookies = () => {
  const cookies = new Map<string, string>();
  const matches = document.cookie.matchAll(/([^\s;]+)=([^\s;]+)/g);

  for (const match of matches) {
    const [, key, value] = match;
    cookies.set(key, value);
  }

  return cookies;
};

/**
 * Read value of a cookie with a given name.
 * @param name cookie name/key string
 * @returns cookie value or undefined if not found
 */
const readCookie = (name: string) => {
  const cookies = loadCookies();
  return cookies.get(name);
};

export { readCookie };
