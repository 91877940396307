import React, { Ref } from "react";
import styles from "ui/components/PickupTypeSelector/PickupTypeSelector.styles";
import useViewModel, {
  PickupTypeSelectorViewModelProps,
} from "ui/components/PickupTypeSelector/PickupTypeSelectorViewModel";
import RadioGroup, { RadioButton } from "ui/components/RadioGroup";
import { RadioGroupProps } from "ui/components/RadioGroup";

export type PickupTypeSelectorProps = RadioGroupProps & PickupTypeSelectorViewModelProps;

const PickupTypeSelector = React.forwardRef(function PickupTypeSelector(
  { store, timeWanted, ...radioGroupProps }: PickupTypeSelectorProps,
  ref?: Ref<unknown>
) {
  const { options } = useViewModel({ store, timeWanted });

  return (
    <RadioGroup aria-label="Pickup Type" ref={ref} sx={[options.length === 3, styles.tripleGroup]} {...radioGroupProps}>
      {options.map(({ hours, label, value }) => (
        <RadioButton
          key={label}
          title={label}
          description={hours ? `Hours: ${hours}` : ""}
          value={value}
          sx={[options.length === 3, styles.tripleBtn]}
        />
      ))}
    </RadioGroup>
  );
});

export default PickupTypeSelector;
