import { Box } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "ui/app/MinimalLayout/LogoBox/LogoBox.styles";

const LogoBox = ({ children }: { children: ReactNode }) => {
  return (
    <Box sx={styles.container}>
      <Link to="/">
        <Box sx={styles.img}>
          <Logo />
        </Box>
      </Link>
      <Box sx={styles.content}>{children}</Box>
    </Box>
  );
};

export default LogoBox;
