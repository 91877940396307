import { useMediaQuery, useTheme } from "@mui/material";
import useStoreManager from "stores/useStoreManager";

const useLayoutViewModel = () => {
  const { isLoading } = useStoreManager();
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  return {
    isLoading,
    isMediumOrLarger,
  };
};

export default useLayoutViewModel;
