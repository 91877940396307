/**
 * Defined in Swagger: Store -> StoreHours -> OpenCloseTimes
 * https://github.com/detroit-labs/octocart-backend/blob/main/docs/open-api/stores/models/open-close-time.yaml
 *
 * For opens and closes, this is a 24 time format with midnight shown as 00:00
 * Example: 08:00, pattern: [0-2][0-9]:[0-5][0-9]
 */
type OpenCloseTimes = {
  opens: string;
  closes: string;
};

export const isOpenCloseTimes = (object: unknown): object is OpenCloseTimes => {
  const openCloseTimes = object as OpenCloseTimes;
  return (
    !!openCloseTimes &&
    !!openCloseTimes.opens &&
    /^([01]\d|2[0123]):[012345]\d$/.test(openCloseTimes.opens) &&
    !!openCloseTimes.closes &&
    /^([01]\d|2[0123]):[012345]\d$/.test(openCloseTimes.closes)
  );
};

export default OpenCloseTimes;
