import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CartItem } from "models/order/CartItem";
import styles from "ui/components/CartItemView/CartItemView.styles";
import useViewModel from "ui/components/CartItemView/CartItemViewModel";
import Image from "ui/components/Image";
import QuantityStepper from "ui/components/QuantityStepper/QuantityStepper";
import { Size } from "ui/Size";

export type CartItemViewProps = {
  cartItem: CartItem;
  modifiable?: boolean;
  onQuantityChange?: (item: CartItem, quantity: number) => Promise<void>;
  onRemove?: (item: CartItem) => Promise<void>;
  showRecipient?: boolean;
};

const CartItemView = ({
  cartItem,
  modifiable = false,
  onQuantityChange,
  onRemove,
  showRecipient = true,
}: CartItemViewProps) => {
  const {
    name,
    images,
    price,
    recipient,
    options,
    hiddenOptionCount,
    onClickMoreOptions,
    isDisabled,
    isModifiable,
    onClickModify,
    quantityStepperViewModel,
    canRemove,
    onClickRemove,
  } = useViewModel(cartItem, onQuantityChange, onRemove);

  const groupNameInfo = "mobile-customize";

  return (
    <Box sx={styles.container} data-cy="cartItem">
      <Box sx={styles.main}>
        <Image
          images={images ?? []}
          groupNameInfo={groupNameInfo}
          imageUnavailableProps={{ size: Size.small }}
          productName={name}
          sx={styles.image}
        />
        <Box sx={styles.content}>
          <Box sx={styles.contentHeader}>
            <Typography variant="body1Bold" sx={styles.name}>
              {name}
            </Typography>
            <Typography variant="body2" sx={styles.price}>
              {price}
            </Typography>
          </Box>
          <Box component="ul" sx={styles.metadata}>
            {showRecipient && !!recipient && (
              <Box component="li" sx={styles.recipient}>
                For {recipient}
              </Box>
            )}
            {options.map((options) => (
              <Typography component="li" variant="body2" key={options.key}>
                {options.value}
              </Typography>
            ))}
            {hiddenOptionCount > 0 && (
              <Button variant="text" onClick={onClickMoreOptions} sx={styles.more}>
                +{hiddenOptionCount} More
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {modifiable && (
        <Box sx={styles.controls}>
          <Box sx={isModifiable ? [styles.control, styles.controlLeft] : styles.control}>
            {isModifiable ? (
              <Button variant="text" sx={styles.controlButton} onClick={onClickModify} disabled={isDisabled}>
                Modify
              </Button>
            ) : null}
          </Box>
          <Box sx={styles.stepper}>
            {quantityStepperViewModel && (
              <QuantityStepper
                onChange={quantityStepperViewModel.onQuantityChange}
                value={quantityStepperViewModel.value}
                disabled={isDisabled}
                label="sandwich quantity"
                hideBorder
              />
            )}
          </Box>
          {canRemove && (
            <Box sx={[styles.control, styles.controlRight]}>
              <Button variant="text" sx={styles.controlButton} onClick={onClickRemove} disabled={isDisabled}>
                Remove
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CartItemView;
