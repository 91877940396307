import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    borderTop: `0.0625rem solid ${theme.palette.grey[600]}`,

    [theme.breakpoints.up("sm")]: {
      borderTop: 0,
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },

    [theme.breakpoints.up("lg")]: {
      paddingLeft: "1.5rem",
    },
  }),
  orderButtonContainer: (theme) => ({
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  }),
  orderButton: (theme) => ({
    width: "100%",

    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  }),
} as const satisfies MuiStyles;

export default styles;
