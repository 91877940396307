import { createInstance, enums, ReactSDKClient, useDecision } from "@optimizely/react-sdk";
import { UserInfo } from "@optimizely/react-sdk/dist/utils";
import { readCookie } from "util/CookieUtils";
import LocalStorage from "util/LocalStorage";
import { logError } from "util/Logger";
import { OptimizelyTags, UserAttributes } from "util/Optimizely";
import FeatureFlags from "util/Optimizely/FeatureFlags";
import { OptimizelyEventName } from "util/Optimizely/OptimizelyTypes";
import { v4 as uuidv4 } from "uuid";

let optimizelyClientInstance: ReactSDKClient;

/**
 * Instantiate and/or return a pre-configured instance of the Optimizely React client SDK (singleton/global).
 * @see {@link https://docs.developers.optimizely.com/feature-experimentation/docs/initialize-sdk-react#instantiate-using-sdk-key | Optimizely React SDK Documentation}
 */
export const getOptimizelyClient = () => {
  if (optimizelyClientInstance) {
    return optimizelyClientInstance;
  }

  optimizelyClientInstance = createInstance({
    sdkKey: Env.OptimizelyKey,
    logLevel: enums.LOG_LEVEL.ERROR,
    logger: { log: (_, message: string) => logError(message) },
  });

  return optimizelyClientInstance;
};

/**
 * @returns an Optimizely UserInfo object.
 */
export const getOptimizelyUser = (): UserInfo => ({
  id: getOptimizelyUserId(),
  attributes: getOptimizelyUserAttributes(),
});

/**
 * Gets the user's Optimizely ID from local storage (if available);
 * otherwise, creates a new ID, stores it, and returns it.
 *
 * This ID enables Optimizely to bucket the user — or, more accurately,
 * the user's browser — so that they are consistently shown (or not shown)
 * features/experiments.
 *
 * @returns a persistent anonymous Optimizely user ID
 */
const getOptimizelyUserId = () => {
  const optimizelyUserIdStore = new LocalStorage<string>("OptimizelyUserId");
  const optimizelyUserId = optimizelyUserIdStore.get();

  if (optimizelyUserId) return optimizelyUserId;

  const newOptimizelyUserId = uuidv4();
  optimizelyUserIdStore.set(newOptimizelyUserId);

  return newOptimizelyUserId;
};

/**
 * Creates & returns a user attributes object.
 * @returns a user attributes object
 */
const getOptimizelyUserAttributes = () => {
  // use `testAudience` cookie value; used for canary testing unreleased features
  const testAudienceCookieValue = readCookie("testAudience");

  if (!testAudienceCookieValue) return;

  return {
    testAudience: testAudienceCookieValue,
  };
};

/**
 * Track an event for an Optimizely Web Experimentation project
 * @see {@link https://docs.developers.optimizely.com/web-experimentation/reference/event | Optimizely Web Experimentation Documentation}
 */
export const trackOptimizelyEvent = (eventName: OptimizelyEventName, tags?: OptimizelyTags) => {
  try {
    window.optimizely?.push({ type: "event", eventName, tags });
  } catch (error) {
    logError(error);
  }
};

/**
 * Update a user attribute for Optimizely Web Experimentation tracking
 * @see {@link https://docs.developers.optimizely.com/web-experimentation/reference/user | Optimizely Web Experimentation Documentation}
 */
export const updateOptimizelyUserAttributes = (attributes: Partial<UserAttributes>) => {
  try {
    window.optimizely?.push({ type: "user", attributes });
  } catch (error) {
    logError(error);
  }
};

/** Convenience function for checking if a given feature flag is enabled for the current user. */
export const useFeatureFlag = (flagKey: FeatureFlags) => {
  const [decision] = useDecision(flagKey);
  return decision.enabled;
};
