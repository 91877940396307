import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "ui/components/QuantityStepper/QuantityStepper.styles";

const DEFAULT_MAX_QUANTITY = 99;

export interface QuantityStepperProps {
  onChange: (value: number) => void;
  value: number;
  fullWidth?: boolean;
  hideBorder?: boolean;
  label?: string;
  max?: number;
  disabled?: boolean;
}

const QuantityStepper = observer(
  ({ max = DEFAULT_MAX_QUANTITY, value, onChange, label, disabled, hideBorder, fullWidth }: QuantityStepperProps) => {
    const upDisabled = max === value;
    const downDisabled = value === 1;

    const onClickIncrement = (): void => {
      if (value === max) return;
      if (onChange) {
        onChange(value + 1);
      }
    };

    const onClickDecrement = (): void => {
      if (value === 1) return;
      if (onChange) {
        onChange(value - 1);
      }
    };

    const onKeyboardInput = (quantity: number): void => {
      if (quantity > max || quantity < 1) return;

      if (!quantity) {
        onChange(0);
        return;
      }

      if (onChange) {
        onChange(quantity);
      }
    };

    const clickOut = (quantity: number): void => {
      if (!quantity) {
        onChange(1);
      }
    };

    const keyPress = (event: React.KeyboardEvent): void => {
      if (event.code === "ArrowUp") {
        onClickIncrement();
      }

      if (event.code === "ArrowDown") {
        onClickDecrement();
      }

      if (event.code === "Home") {
        onChange(1);
      }

      if (event.code === "End") {
        onChange(max);
      }
    };

    const className = (() => {
      const classes: string[] = [];
      if (fullWidth) classes.push("full-width");
      if (hideBorder) classes.push("no-border");
      return classes.join(" ");
    })();

    return (
      <Box sx={styles.container} className={className} aria-label="update quantity">
        <IconButton
          sx={styles.stepperBtn}
          disabled={disabled || downDisabled}
          onClick={onClickDecrement}
          tabIndex={-1}
          color="primary"
          disableRipple
          data-testid={"decrement"}
        >
          <Remove />
        </IconButton>
        <Box
          role="spinbutton"
          component="input"
          sx={[styles.input, styles.focus]}
          inputMode="numeric"
          value={value}
          onChange={(e) => onKeyboardInput(parseInt(e.target.value, 10))}
          aria-label={`${label ? label : "quantity"}`}
          aria-valuemax={max}
          aria-valuemin={1}
          aria-valuenow={value}
          onKeyDown={(e) => keyPress(e)}
          onBlur={(e) => clickOut(parseInt(e.target.value))}
          disabled={disabled}
        />
        <IconButton
          color="primary"
          sx={styles.stepperBtn}
          disabled={disabled || upDisabled}
          onClick={onClickIncrement}
          tabIndex={-1}
          disableRipple
          data-testid={"increment"}
        >
          <Add />
        </IconButton>
      </Box>
    );
  }
);

export default QuantityStepper;
