import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  addressContainer: {
    alignItems: "center",
    padding: (theme) => theme.spacing(3, 2),
  },
  button: (theme) => ({
    textDecoration: "none",

    "&:focus-visible": {
      borderColor: "transparent",
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "@supports not selector(:focus-visible)": {
      "&:focus": {
        borderColor: "transparent",
        outline: `0.25rem solid ${theme.palette.primary.light}`,
      },
    },

    ".MuiSvgIcon-root": {
      color: (theme) => theme.palette.grey[400],
    },
  }),
  changeLocationText: {
    fontSize: (theme) => `${theme.pxToRem(17)} !important`,
  },
  chevron: {
    marginLeft: "0.25rem",
    verticalAlign: "bottom",
  },
  cityStateZipLine: (theme) => ({
    color: Colors.superBlack,
    fontSize: `${theme.pxToRem(17)} !important`,
    lineHeight: `${theme.pxToRem(24)} !important`,
  }),
  container: (theme) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "normal",
      marginTop: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
  }),
  crossStreet: (theme) => ({
    color: Colors.superBlack,
    fontSize: `${theme.pxToRem(17)} !important`,
    fontWeight: 370,
    lineHeight: `${theme.pxToRem(24)} !important`,
  }),
  divider: {
    my: "0.5rem",
  },
  popoverContainer: {
    minWidth: (theme) => theme.spacing(45),
  },
  popoverFooter: {
    alignItems: "center",
    background: Colors.gray100,
    flexDirection: "row",
    justifyContent: "center",
    gap: 1.5,
    padding: (theme) => theme.spacing(3, 0),
  },
  startOverButton: {
    fontSize: (theme) => `${theme.pxToRem(18)} !important`,
  },
  storeInfoButton: (theme) => ({
    "&:visited": {
      color: Colors.superBlack,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  streetAddress: (theme) => ({
    fontSize: `${theme.pxToRem(20)} !important`,
    lineHeight: `${theme.pxToRem(24)} !important`,
  }),
} as const satisfies MuiStyles;

export default styles;
