import { OrderError } from "errors/Errors";
import { observer } from "mobx-react-lite";
import { isHostPayGroupOrder } from "models/groupOrder/GroupOrder";
import { lazy, useEffect, useRef, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import Spinner from "ui/components/Spinner";
import { CheckoutPagePath, MainPagePath } from "ui/navigation/Pages";
import { isInvalidRewardError } from "util/Reward.utils";

const CheckoutScreen = lazy(() => import("ui/screens/checkout/CheckoutScreen"));
const OrderConfirmationView = lazy(() => import("ui/screens/checkout/OrderConfirmation"));

const CheckoutOrderValidationRoute = observer(function CheckoutOrderValidationRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const [isOrderValid, setIsOrderValid] = useState<boolean>();
  const { addErrorAlert } = useAlert();
  const { validateOrder, order, orderItemsCount, isLoadingOrder, unlockGroupOrder, lockGroupOrder } = useOrder();

  const { isLoadingSavedPayments, isGroupOrderHost } = useUser();

  const orderItemsCountRef = useRef<number>();

  const orderIsLocked = isHostPayGroupOrder(order) && order.groupOrderDetails.status === "locked";
  const orderIsLockable = isHostPayGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);

  useEffect(() => {
    if (isOrderValid && orderIsLockable && !orderIsLocked) {
      lockGroupOrder();
    }

    return () => {
      if (isOrderValid && orderIsLockable && orderIsLocked) {
        unlockGroupOrder();
      }
    };
  }, [isOrderValid, lockGroupOrder, orderIsLockable, orderIsLocked, unlockGroupOrder]);

  useEffect(() => {
    if (isLoadingOrder) {
      setIsOrderValid(undefined);
      return;
    }

    if (!order) {
      setIsOrderValid(false);
      return;
    }

    async function runValidateOrder() {
      try {
        setIsOrderValid(undefined);
        await validateOrder();
        setIsOrderValid(true);
      } catch (e) {
        setIsOrderValid(false);

        // suppress invalid reward errors; they will be specially handled on checkout, when the order is submitted.
        if (isInvalidRewardError(e)) return;

        addErrorAlert(OrderError.ValidateOrder, e);
      }
    }

    // validate the cart on first load, and whenever item quantity changes
    if (orderItemsCountRef.current !== orderItemsCount) {
      orderItemsCountRef.current = orderItemsCount;
      runValidateOrder();
    }
  }, [addErrorAlert, validateOrder, isLoadingOrder, order, orderItemsCount]);

  // if no order or no items in order, go home
  if (!isLoadingOrder && !order && !orderItemsCount && isOrderValid === false) {
    return <Navigate to={MainPagePath.home} replace={true} />;
  }

  // if loading order or order not validated, show spinner
  if (isLoadingOrder || isLoadingSavedPayments || isOrderValid === undefined) {
    return <Spinner wrap />;
  }

  return children;
});

const CheckoutRoutes = (
  <Route path={CheckoutPagePath.checkout}>
    <Route
      index
      element={
        <CheckoutOrderValidationRoute>
          <CheckoutScreen />
        </CheckoutOrderValidationRoute>
      }
    />
    <Route path={CheckoutPagePath.orderConfirmation} element={<OrderConfirmationView />} />
    <Route
      path={CheckoutPagePath.hostPayParticipantOrderConfirmation}
      element={<OrderConfirmationView isHostPayParticipant />}
    />
  </Route>
);

export default CheckoutRoutes;
