import { BaseDataTransformer } from "dataTransformers/BaseDataTransformer";
import { ProductDataTransformable } from "dataTransformers/ProductDataTransformable";
import TokenManageable from "dataTransformers/TokenManageable";
import { DynamicData, isDynamicData } from "models/DynamicData";
import { Filter, FilterOption, isFilterArray, SelectedFilter } from "models/Filter";
import MenuType from "models/MenuType";
import { isModifierCategoryArray, ModifierCategory } from "models/ModifierCategory";
import { ProductItem } from "models/ProductItem";
import { isProducts, Products } from "models/Products";
import { ProductsNetworking } from "networking/ProductsNetworking";
import TokenNetworking from "networking/TokenNetworking";
import { OrderStorable } from "stores/order";
import { ProductModifier, ProductModifierManager } from "ui/screens/Product/ProductModifiers/ProductModifierManager";
import { first } from "util/JavascriptUtility";

/** Keys of all filter types for the backend.
 * See https://test-swagger.octocart.com/#/Products/getProductFilters */
enum FilterKey {
  purchaseCategory = "purchaseCategory",
  category = "category",
  type = "type",
  brand = "brand",
  speciesName = "speciesName",
  nutrient = "nutrient",
}

export class ProductDataTransformer extends BaseDataTransformer implements ProductDataTransformable {
  private productNetworker: ProductsNetworking;

  private readonly orderStore: OrderStorable;

  constructor(
    networker: ProductsNetworking & TokenNetworking,
    tokenManager: TokenManageable,
    orderStore: OrderStorable
  ) {
    super(networker, tokenManager);
    this.productNetworker = networker;
    this.orderStore = orderStore;
  }

  get orderType() {
    return this.orderStore.order?.orderType;
  }

  fetchDynamicData(product: ProductItem, storeId: string, modifiers?: ProductModifier[]): Promise<DynamicData> {
    const optionIds = ProductModifierManager.flattenSelectedOptionIds(modifiers ?? []);
    return this.makeNetworkCall(
      () => this.productNetworker.fetchDynamicData(product.id, storeId, optionIds),
      isDynamicData
    );
  }

  getFilters(storeId: string, menuType?: MenuType): Promise<{ filters: Filter[]; categories: FilterOption[] }> {
    return this.makeNetworkCall(() => this.productNetworker.getFilters(storeId, menuType), isFilterArray).then(
      (filters) => {
        const categoryOptions: FilterOption[] =
          first(filters.filter((filter) => filter.key === FilterKey.category))?.options ?? [];
        return Promise.resolve({
          filters: filters.filter((f) => f.key !== FilterKey.category),
          categories: categoryOptions,
        });
      }
    );
  }

  getModifiers(productId: string, storeId: string): Promise<ModifierCategory[]> {
    return this.makeNetworkCall(
      () => this.productNetworker.getModifiers(productId, storeId, this.orderType),
      isModifierCategoryArray
    );
  }

  getProducts(storeId: string, categoryId?: string, menuType?: MenuType): Promise<Products> {
    return this.makeNetworkCall(
      () => this.productNetworker.getProducts(storeId, categoryId, menuType, this.orderType),
      isProducts
    );
  }

  // Maps selected filters to what the ProductNetworker will use
  private mapSelectedFilters = (selectedFilters: SelectedFilter[]): { [key: string]: string[] } => {
    const filters: { [key: string]: string[] } = {};

    selectedFilters.forEach((selectedFilter) => {
      filters[selectedFilter.key] = selectedFilter.values;
    });

    return filters;
  };
}
