import LocalStorage from "util/LocalStorage";

const NAME_KEY = "GroupOrderParticipantName";

export const getStoredGroupOrderParticipantName = () => {
  const storage = new LocalStorage<string>(NAME_KEY);
  return storage.get();
};

export const setStoredGroupOrderParticipantName = (name: string) => {
  const storage = new LocalStorage<string>(NAME_KEY);
  storage.set(name);
};
