import { fontFamilyBlankenhorn } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: {
    marginLeft: "1rem",
    padding: 0,

    "& .splide .splide__track": {
      width: "100%",
      paddingLeft: "2px !important",
    },
  },
  heading: {
    marginBottom: "1rem",
  },
  headingText: (theme) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(20),
    },
  }),
  headingTextCursive: (theme) => ({
    color: theme.palette.primary.main,
    fontFamily: fontFamilyBlankenhorn,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "lowercase",
  }),
} as const satisfies MuiStyles;

export default styles;
