import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Stack, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import styles from "ui/components/AchievementBadgeModal/AchievementBadgeModal.styles";

const BadgeTerms = ({ children }: PropsWithChildren) => {
  const [shouldShowTerms, setShouldShowTerms] = useState(false);

  const toggleTerms = () => {
    setShouldShowTerms((currentState) => !currentState);
  };

  return (
    <Stack sx={styles.terms}>
      <Button
        endIcon={shouldShowTerms ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleTerms}
        variant="text"
        sx={styles.termsToggle}
        aria-description="show terms and conditions"
      >
        <Typography color="primary" component="span" variant="body2Bold">
          Terms and Conditions
        </Typography>
      </Button>
      {shouldShowTerms && (
        <Typography variant="body2" sx={styles.termsText}>
          {children}
        </Typography>
      )}
    </Stack>
  );
};

export default BadgeTerms;
