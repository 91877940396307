import { createGlobalStyle } from "styled-components";
import Colors from "ui/theme/Colors";

const GlobalStyle = createGlobalStyle`  
  :root {
    box-sizing: border-box;
    overflow-wrap: break-word;
  }
  
  *,
  ::before,
  ::after {
    // Resets any libraries to use their own box-sizing
    box-sizing: inherit;
  }

  a {
    color: ${Colors.superBlack};
  }
`;

export default GlobalStyle;
