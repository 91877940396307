import { DAYS_OF_WEEK, HOURS_OF_DAY, PromoModalData } from "models/cms/DataTypes/CmsPromoModalData";
import LocalStorage from "util/LocalStorage";

const promoModalStorage = new LocalStorage<Record<string, string>>("promo-modals");

export const markPromoModalAsSeen = (promoModalId: string, now = new Date()) => {
  const lastSeenDates = promoModalStorage.get();

  const newLastSeenDates = {
    ...lastSeenDates,
  };

  newLastSeenDates[promoModalId] = now.toISOString();

  promoModalStorage.set(newLastSeenDates);
};

export const shouldDisplayPromoModal = (promoModal: PromoModalData, now = new Date()) => {
  return (
    isIntendedForWeb(promoModal) &&
    isWithinCampaignDates(promoModal, now) &&
    isWithinDaypartDisplayWindow(promoModal, now) &&
    isOffCooldown(promoModal, now)
  );
};

const isIntendedForWeb = (promoModal: PromoModalData) => {
  return promoModal.platforms.includes("Web");
};

// verify that the promo modal isn't on cooldown (aka hasn't been displayed < frequencyInDays ago)
const isOffCooldown = (promoModal: PromoModalData, now: Date) => {
  const promoModalsSeen = promoModalStorage.get();

  if (promoModalsSeen && promoModalsSeen[promoModal.id]) {
    const lastSeenDateTimeStr = promoModalsSeen[promoModal.id];
    const coolDownExpiry = new Date(lastSeenDateTimeStr);
    coolDownExpiry.setDate(coolDownExpiry.getDate() + promoModal.frequencyInDays);
    coolDownExpiry.setHours(0, 0, 0);

    if (now <= coolDownExpiry) return false;
  }

  return true;
};

const isWithinCampaignDates = (promoModal: PromoModalData, now: Date) => {
  // verify that the campaign start date/time in the past
  if (promoModal.campaignStart > now) return false;

  // verify that the campaign end date/time in the future
  if (promoModal.campaignEnd && promoModal.campaignEnd < now) return false;

  return true;
};

const isWithinDaypartDisplayWindow = (promoModal: PromoModalData, now: Date) => {
  const today = new Date(now);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const startTimeHour = HOURS_OF_DAY.indexOf(promoModal.startTime);

  for (const day of promoModal.days) {
    const dayOfWeek = DAYS_OF_WEEK.indexOf(day);
    let startTimeForDayOfWeek = new Date();

    switch (dayOfWeek) {
      case today.getDay():
        startTimeForDayOfWeek = new Date(today);
        startTimeForDayOfWeek.setHours(startTimeHour);
        break;
      case yesterday.getDay():
        startTimeForDayOfWeek = new Date(yesterday);
        startTimeForDayOfWeek.setHours(startTimeHour);
        break;
      default:
        continue;
    }

    const durationInMilliseconds = promoModal.duration * 60 * 60 * 1000;

    const endTimeForDayOfWeek = new Date(startTimeForDayOfWeek);
    endTimeForDayOfWeek.setTime(startTimeForDayOfWeek.getTime() + durationInMilliseconds);

    if (now >= startTimeForDayOfWeek && now <= endTimeForDayOfWeek) return true;
  }

  return false;
};
