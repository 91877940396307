import backgroundSrc from "assets/auth-background.jpg";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    minHeight: "100vh",
    backgroundAttachment: "fixed",
    backgroundImage: `url(${backgroundSrc})`,
    backgroundSize: "cover",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 2, 6, 2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2, 10, 2),
    },
  }),
} satisfies MuiStyles;

export default styles;
