import { ErrorWithOrderItem } from "errors/ErrorWithOrderItem";
import { Order } from "models/order/Order";

export type FaveOrderResponse = {
  order: Order;
  errors?: ErrorWithOrderItem[];
};

export const isFaveOrderResponse = (object: unknown): object is FaveOrderResponse => {
  const faveOrderResponse = object as FaveOrderResponse;
  return faveOrderResponse != null && faveOrderResponse.order != null;
};
