import { BaseDataTransformer, isVoidResponse, UnknownError } from "dataTransformers/BaseDataTransformer";
import { OrderDataTransformable } from "dataTransformers/OrderDataTransformable";
import TokenManageable from "dataTransformers/TokenManageable";
import { LocalErrorCodes } from "errors/LocalErrorCodes";
import { ValidationErrors } from "errors/LocalErrors";
import { ContactInfo } from "models/ContactInfo";
import {
  AcceptGroupOrderInviteResponse,
  GroupOrder,
  GroupOrderDetails,
  GroupOrderParticipant,
  isAcceptGroupOrderInviteResponse,
  isGroupOrder,
  isGroupOrderDetails,
  isGroupOrderParticipantArray,
} from "models/groupOrder";
import { AddItem } from "models/order/AddItem";
import { CartItem } from "models/order/CartItem";
import { DeliveryInfo } from "models/order/DeliveryInfo";
import { FaveOrderResponse, isFaveOrderResponse } from "models/order/FaveOrderResponse";
import { isOrder, Order } from "models/order/Order";
import { OrderType } from "models/order/OrderType";
import { isOrderUpsellResponse, OrderUpsellResponse } from "models/order/OrderUpsell";
import Tip from "models/order/Tip";
import UpdateAndSubmitPayload from "models/order/UpdateAndSubmitOrderPayload";
import { isOrderConfirmation, OrderConfirmation } from "models/OrderConfirmation";
import { Store } from "models/store/Store";
import { NetworkError, NetworkResponse } from "networking/NetworkPromise";
import { isOctocartNetworkError } from "networking/OctocartNetworkError";
import OctocartNetworkErrorCodes from "networking/OctocartNetworkErrorCodes";
import { OrderNetworking } from "networking/OrderNetworking";
import CreateGroupOrderPayload from "networking/requestPayloads/CreateGroupOrderPayload";
import SendGroupOrderInvitesPayload from "networking/requestPayloads/SendGroupOrderInvitesPayload";
import UpdateGroupOrderPayload from "networking/requestPayloads/UpdateGroupOrderPayload";
import TokenNetworking from "networking/TokenNetworking";
import { ProductModifier, ProductModifierManager } from "ui/screens/Product/ProductModifiers/ProductModifierManager";

/**
 * Validates and transforms order related data from the stores to the networker.
 * It is important to always unpack the alerts from the API calls and send them back in the success and error responses
 */
export class OrderDataTransformer extends BaseDataTransformer implements OrderDataTransformable {
  private orderNetworker: OrderNetworking;

  constructor(networker: OrderNetworking & TokenNetworking, tokenManager: TokenManageable) {
    super(networker, tokenManager);
    this.orderNetworker = networker;
  }

  applyTip(payload: Tip): Promise<Order> {
    if (!payload.amount && !payload.percent) {
      return this.makeNetworkCall(() => this.orderNetworker.removeTip(), isOrder);
    }

    return this.makeNetworkCall(() => this.orderNetworker.applyTip(payload), isOrder);
  }

  addToOrder(
    itemId: string,
    quantity: number,
    modifiers: ProductModifier[],
    specialInstructions?: string,
    recipient?: string,
    isUpsell?: boolean
  ): Promise<Order> {
    const modifierIds = ProductModifierManager.flattenSelectedOptionIds(modifiers);
    return this.makeNetworkCall(
      () =>
        this.orderNetworker.addToOrder(
          itemId,
          quantity,
          modifierIds,
          specialInstructions?.trim(),
          recipient?.trim(),
          isUpsell
        ),
      isOrder
    );
  }

  addMultipleItemsToOrder(items: AddItem[]): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.addMultipleItemsToOrder(items), isOrder);
  }

  repeatOrder(orderId: string): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.repeatOrder(orderId), isOrder);
  }

  applyCoupon(coupon: string): Promise<Order> {
    const sanitizedCoupon = coupon.trim();
    return this.makeNetworkCall(() => this.orderNetworker.applyCoupon(sanitizedCoupon), isOrder);
  }

  cancelOrder(): Promise<void> {
    return this.makeNetworkCall(() => this.orderNetworker.cancelOrder(), isVoidResponse);
  }

  checkinOrder(orderId: string): Promise<void> {
    return this.makeNetworkCall(() => this.orderNetworker.checkinOrder(orderId), isVoidResponse);
  }

  createOrder(store: Store): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.createOrder(store.id), isOrder);
  }

  deleteFromOrder(orderItem: CartItem): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.deleteFromOrder(orderItem.id), isOrder);
  }

  editOrderItem(
    orderItem: CartItem,
    quantity: number,
    modifiers: ProductModifier[],
    specialInstructions?: string,
    recipient?: string
  ): Promise<Order> {
    const modifierIds = ProductModifierManager.flattenSelectedOptionIds(modifiers);
    return this.makeNetworkCall(
      () => this.orderNetworker.editOrderItem(orderItem.id, quantity, modifierIds, specialInstructions, recipient),
      isOrder
    );
  }

  editOrderItemQuantity(orderItem: CartItem, quantity: number): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.editOrderItemQuantity(orderItem.id, quantity), isOrder);
  }

  getOrder(): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.getOrder(), isOrder);
  }

  getOrderUpsell(): Promise<OrderUpsellResponse> {
    return this.makeNetworkCall(() => this.orderNetworker.getOrderUpsell(), isOrderUpsellResponse);
  }

  addFaveToOrder(faveId: string): Promise<FaveOrderResponse> {
    return this.makeNetworkCall(() => this.orderNetworker.addFaveToOrder(faveId), isFaveOrderResponse);
  }

  redeemReward(reference: string): Promise<Order> {
    if (!reference) return Promise.reject(ValidationErrors.IrredeemableReward);
    return this.makeNetworkCall(() => this.orderNetworker.redeemReward(reference), isOrder);
  }

  removeCoupon(): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.removeCoupon(), isOrder);
  }

  removeReward(rewardId: string): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.removeReward(rewardId), isOrder);
  }

  setOrderTimeWanted(timeWanted: string): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.setOrderTimeWanted(timeWanted), isOrder);
  }

  setOrderType(orderType: OrderType, deliveryInfo?: DeliveryInfo, specialInstructions?: string): Promise<Order> {
    const deliveryAddress = orderType === OrderType.delivery ? deliveryInfo : undefined;
    return this.makeNetworkCall(
      () => this.orderNetworker.setOrderType(orderType, deliveryAddress, specialInstructions),
      isOrder
    );
  }

  updateContactInfo(contactInfo: ContactInfo): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.updateContactInfo(contactInfo), isOrder);
  }

  validateOrder(): Promise<Order> {
    return this.makeNetworkCall(() => this.orderNetworker.validateOrder(), isOrder);
  }

  submitOrder(): Promise<OrderConfirmation> {
    return this.makeNetworkCall(() => this.orderNetworker.submitOrder(), isOrderConfirmation);
  }

  updateAndSubmitOrder(payload: UpdateAndSubmitPayload): Promise<OrderConfirmation> {
    return this.makeNetworkCall(() => this.orderNetworker.updateAndSubmitOrder(payload), isOrderConfirmation);
  }

  /******************
   * Group Ordering *
   ******************/

  acceptGroupOrderInvite(
    groupOrderId: string,
    participantId: string,
    participantName: string
  ): Promise<AcceptGroupOrderInviteResponse> {
    return this.makeNetworkCall(
      () => this.orderNetworker.acceptGroupOrderInvite(groupOrderId, participantId, participantName),
      isAcceptGroupOrderInviteResponse
    );
  }

  cancelGroupOrder(groupOrderId: string): Promise<void> {
    return this.makeNetworkCall(() => this.orderNetworker.cancelGroupOrder(groupOrderId), isVoidResponse);
  }

  createGroupOrder(payload: CreateGroupOrderPayload): Promise<GroupOrderDetails> {
    return this.makeNetworkCall(() => this.orderNetworker.createGroupOrder(payload), isGroupOrderDetails);
  }

  getCurrentGroupOrderParticipants(groupOrderId: string): Promise<GroupOrderParticipant[]> {
    return this.makeNetworkCall(
      () => this.orderNetworker.getCurrentGroupOrderParticipants(groupOrderId),
      isGroupOrderParticipantArray
    );
  }

  getGroupOrder(groupOrderId: string): Promise<GroupOrderDetails> {
    return this.makeNetworkCall(() => this.orderNetworker.getGroupOrder(groupOrderId), isGroupOrderDetails);
  }

  joinGroupOrder(groupOrderId: string, orderId?: string): Promise<GroupOrder> {
    return this.makeNetworkCall(() => this.orderNetworker.joinGroupOrder(groupOrderId, orderId), isGroupOrder);
  }

  sendGroupOrderInvites(groupOrderId: string, payload: SendGroupOrderInvitesPayload): Promise<void> {
    return this.makeNetworkCall(() => this.orderNetworker.sendGroupOrderInvites(groupOrderId, payload), isVoidResponse);
  }

  submitGroupOrder(groupOrderId: string): Promise<GroupOrderDetails> {
    return this.makeNetworkCall(() => this.orderNetworker.submitGroupOrder(groupOrderId), isGroupOrderDetails);
  }

  updateGroupOrder(groupOrderId: string, payload: UpdateGroupOrderPayload): Promise<GroupOrderDetails> {
    return this.makeNetworkCall(() => this.orderNetworker.updateGroupOrder(groupOrderId, payload), isGroupOrderDetails);
  }

  /**
   * Intercept the error handling to handle expired orders
   */
  handleError(error: NetworkResponse | NetworkError | Error | unknown): Promise<never> {
    return super.handleError(error).catch((processedError) => {
      if (
        isOctocartNetworkError(processedError) &&
        processedError.httpStatusCode === 404 &&
        (processedError.code === OctocartNetworkErrorCodes.OrderNotFound || processedError.code === UnknownError.code)
      ) {
        return Promise.reject({ message: processedError.message, code: LocalErrorCodes.OrderNotFound });
      }
      return Promise.reject(processedError);
    });
  }
}
