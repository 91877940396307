import { useLocalObservable } from "mobx-react-lite";
import { createContext, ReactNode } from "react";

export type ConfirmationModalState = {
  confirmButtonLabel: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
};

type ConfirmationModalStore = {
  confirmationModalState: ConfirmationModalState;
  confirm: (props: { confirmButtonLabel: string; message: string; title: string }) => Promise<boolean>;
};

export const ConfirmationModalContext = createContext<ConfirmationModalStore | null>(null);

type ProviderProps = {
  children: ReactNode;
};

const ConfirmationModalProvider = ({ children }: ProviderProps) => {
  const confirmationModalState = useLocalObservable<ConfirmationModalStore>(() => ({
    confirmationModalState: {
      confirmButtonLabel: "Confirm",
      message: "Are you sure?",
      onClose: () => {},
      onConfirm: () => {},
      open: false,
      title: "Confirm",
    },
    confirm: function (this: ConfirmationModalStore, { confirmButtonLabel, message, title }) {
      return new Promise<boolean>((resolve) => {
        this.confirmationModalState = {
          confirmButtonLabel,
          message,
          title,
          open: true,
          onClose: () => {
            this.confirmationModalState.open = false;
            resolve(false);
          },
          onConfirm: () => {
            this.confirmationModalState.open = false;
            resolve(true);
          },
        };
      });
    },
  }));

  return (
    <ConfirmationModalContext.Provider value={confirmationModalState}>{children}</ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalProvider;
