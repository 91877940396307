import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: {
    gap: "1rem",
    padding: "1rem",
  },
  groupHeading: {
    color: Colors.superBlack,
  },
} satisfies MuiStyles;

export default styles;
