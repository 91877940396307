import { Box, Divider } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps, To, useMatch } from "react-router-dom";
import styles from "ui/components/GlobalNavLink/GlobalNavLink.styles";
import { PagePath } from "ui/navigation/Pageable";

export type GlobalNavLinkProps = Omit<RouterLinkProps, "to" | "onClick"> & {
  children: ReactNode;
  selectOnMatch?: boolean;
  isSelected?: boolean;
  Icon?: JSX.Element;
  to?: PagePath;
  onClick?: () => void;
};

const GlobalNavLink = ({
  children,
  selectOnMatch = false,
  isSelected,
  Icon,
  to,
  onClick,
  ...linkProps
}: GlobalNavLinkProps) => {
  const isRouteMatch = useMatch(to ?? "") != null;
  const shouldShowSelected = isSelected || (selectOnMatch && to != null && isRouteMatch);
  const className = shouldShowSelected ? "global-nav-link selected" : "global-nav-link";

  return (
    <Box
      component={RouterLink}
      onClick={onClick}
      to={to as To}
      sx={styles.container}
      className={className}
      aria-current={shouldShowSelected}
      {...linkProps}
    >
      {Icon ? Icon : null}
      <Box>
        <Box sx={styles.label}>{children}</Box>
        <Divider sx={styles.bottomDivider} />
      </Box>
    </Box>
  );
};

export default GlobalNavLink;
