import MenuType, { isMenuType } from "models/MenuType";
import MetadataKey from "models/MetadataKey";
import { TypeChecker } from "util/JavascriptUtility";

type FilterKey = string;
export type FilterOptionValue = string;

export type FilterOption = {
  id: string;
  name: string;
  value: FilterOptionValue;
  description: string;
  menuType: MenuType;
  images?: ImagesType[];
  metadata?: { key: MetadataKey; value: string }[];
};

export type ImagesType = {
  groupName: string;
  url: string;
};

export enum FilterType {
  singleSelect = "single-select",
  multiSelect = "multi-select",
}

const isFilterType = (object: unknown): object is FilterType => {
  return object === FilterType.multiSelect || object === FilterType.singleSelect;
};

export type Filter = {
  id: string;
  name: string;
  key: FilterKey;
  type: FilterType;
  options: FilterOption[];
};

/** Helper object for user selected filters */
export type SelectedFilter = {
  key: FilterKey;
  values: FilterOptionValue[];
};

export const isFilterOption = (object: unknown): object is FilterOption => {
  const option = object as FilterOption;
  return (
    !!option &&
    TypeChecker.isString(option.name) &&
    TypeChecker.isString(option.value) &&
    isMenuType(option.menuType) &&
    TypeChecker.isUndefined(option.images)
  );
};

const isFilter = (object: unknown): object is Filter => {
  const filter = object as Filter;
  return (
    !!filter &&
    TypeChecker.isString(filter.name) &&
    TypeChecker.isString(filter.key) &&
    isFilterType(filter.type) &&
    TypeChecker.isArray(filter.options, isFilterOption)
  );
};

export const isFilterArray = (object: unknown): object is Filter[] => {
  const filters = object as Filter[];
  return Array.isArray(filters) && filters.map((filter) => isFilter(filter)).length === filters.length;
};
