import { useContext } from "react";
import { ConfirmationModalContext } from "ui/app/AccountLayout/ConfirmationModal";

const useConfirmationModal = () => {
  const confirmationModalState = useContext(ConfirmationModalContext);
  if (!confirmationModalState) {
    throw new Error("useConfirmationModalState must be used within a ConfirmationModalProvider.");
  }
  return confirmationModalState;
};

export default useConfirmationModal;
