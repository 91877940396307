import useRedirectToPreviousLocation from "hooks/useRedirectToPreviousLocation";
import { observer } from "mobx-react-lite";
import { lazy, useEffect } from "react";
import { Outlet, Route } from "react-router-dom";
import { useUser } from "stores/user";
import AuthenticationLayout from "ui/app/MinimalLayout";
import { AccountPagePath } from "ui/navigation/Pages";

const CreateAccountScreen = lazy(() => import("ui/screens/auth/CreateAccountScreen"));
const ForgotPasswordScreen = lazy(() => import("ui/screens/auth/ForgotPasswordScreen"));
const SignInScreen = lazy(() => import("ui/screens/auth/SignInScreen"));

const GuestOnlyRoute = observer(() => {
  const { isLoggedIn } = useUser();

  const { redirectToPreviousLocation } = useRedirectToPreviousLocation();

  useEffect(() => {
    if (isLoggedIn) {
      redirectToPreviousLocation();
    }
  }, [isLoggedIn, redirectToPreviousLocation]);

  return <Outlet />;
});

const AuthenticationRoutes = (
  <Route element={<GuestOnlyRoute />}>
    <Route path={AccountPagePath.base} element={<AuthenticationLayout />}>
      <Route path={AccountPagePath.createAccount} element={<CreateAccountScreen />} />
      <Route path={AccountPagePath.forgotPassword} element={<ForgotPasswordScreen />} />
      <Route path={AccountPagePath.signIn} element={<SignInScreen />} />
    </Route>
  </Route>
);

export default AuthenticationRoutes;
