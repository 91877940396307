import MuiStyles from "ui/util/Styles";

const styles = {
  tripleBtn: (theme) => ({
    [theme.breakpoints.up("md")]: { flexBasis: "33%" },
  }),
  tripleGroup: (theme) => ({
    [theme.breakpoints.up("md")]: { flexWrap: "nowrap" },
  }),
} satisfies MuiStyles;

export default styles;
