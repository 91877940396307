import { Theme } from "@mui/material";
import { Typography } from "@mui/material/styles/createTypography";
import Colors from "ui/theme/Colors";

export const fontFamilyBlankenhorn = ["Blankenhorn", "cursive"].join(",");
export const fontFamilyKnockout = [
  "Knockout",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
].join(",");

const createTypography = (theme: Theme): Typography => ({
  ...theme.typography,

  // global font-family stack
  fontFamily: fontFamilyKnockout,

  fontSize: 16,
  // variants
  body1: {
    color: theme.palette.text.primary,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(24),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(28),
    },
  },
  body1Bold: {
    color: theme.palette.text.primary,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(24),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(28),
    },
  },
  body2: {
    color: theme.palette.text.primary,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  body2Bold: {
    color: theme.palette.text.primary,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  bodySmall: {
    color: theme.palette.text.primary,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
  },
  bodySmallBold: {
    color: theme.palette.common.black,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
  },
  cardTitle: {
    color: Colors.superBlack,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  formFieldLabel: {
    color: Colors.superBlack,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
  formFieldLabelCaps: {
    color: Colors.superBlack,
    fontWeight: 370,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "uppercase",
  },
  formFieldText: {
    color: Colors.superBlack,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
  globalNav: {
    color: Colors.superBlack,
    fontWeight: 445,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: 1,
    textTransform: "uppercase",

    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  h1: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(56),
      lineHeight: 1,
    },
  },
  h2: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(32),
    textTransform: "uppercase",
    borderBottom: "none",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: 1,
    },
  },
  h3: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(28),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(40),
    },
  },
  h4: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(32),
    },
  },
  h5: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  modifierGroupHeading: {
    color: Colors.superBlack,
    fontWeight: 420,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(28),
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(32),
    },
  },
  overline: {
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    textTransform: "uppercase",

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(28),
    },
  },
  subtitle1: {
    color: Colors.superBlack,
    fontWeight: 375,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
  },
  button: {
    color: Colors.superBlack,
    fontWeight: 415,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: 0.2,
    textTransform: "uppercase",
  },
});

export default createTypography;
