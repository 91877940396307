import Colors from "ui/theme/Colors";
import { fontFamilyBlankenhorn } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: {
    counterReset: "step",
  },
  orderTimeInfoText: {
    marginTop: "0.5rem",
  },
  priceLimitField: (theme) => ({
    "& label": {
      marginBottom: "0.5rem",
    },
    "& input": {
      boxSizing: "border-box",
      border: `1px solid ${theme.palette.grey[400]}`,
      boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.25)",
      padding: theme.spacing(2),
      typography: "formFieldText",
      width: "100%",

      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },

      "&:focus-visible": {
        border: `2px solid ${Colors.superBlack}`,
        outline: "none",
      },
    },
  }),
  sectionHeading: (theme) => ({
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "step",
      content: `counter(step)". """`,
      fontFamily: fontFamilyBlankenhorn,
      fontSize: theme.typography.pxToRem(28),
      fontWeight: 400,
      lineHeight: theme.typography.pxToRem(24),

      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(40),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
  }),
} satisfies MuiStyles;

export default styles;
