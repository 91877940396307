import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import useStickyAlertsViewModel from "ui/app/StickyAlertsView/StickyAlertsViewModel";
import Alert from "ui/components/Alert";

const StickyAlertsView = observer(() => {
  const { alerts } = useStickyAlertsViewModel();

  return (
    <Box aria-live={"polite"} role={"alert"} sx={{ position: "absolute", width: "100%", zIndex: 1 }}>
      {alerts.map((alert) => (
        <Alert {...alert} key={alert.message ?? alert.title} />
      ))}
    </Box>
  );
});

export default StickyAlertsView;
