import useAnalytics from "hooks/useAnalytics";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "stores/user/useUser";
import { MainPagePath } from "ui/navigation/Pages";

const useNavUserViewModel = () => {
  const userStore = useUser();
  const navigate = useNavigate();
  const [shouldShowPopover, setShouldShowPopover] = useState<boolean>(false);

  const handleTogglePopover = () => {
    setShouldShowPopover((val) => !val);
  };

  const handleClosePopover = () => {
    setShouldShowPopover(false);
  };

  const handleLogOutClick = async () => {
    await userStore.logout();
    handleClosePopover();
    navigate(MainPagePath.home);
  };

  const { analytics } = useAnalytics();
  const signInAnalytics = () => analytics.signIn("Log In CTA");

  return {
    shouldShowPopover,
    handleTogglePopover,
    handleClosePopover,
    handleLogOutClick,
    signInAnalytics,
    user: userStore.user,
  };
};

export default useNavUserViewModel;
