import { LocalErrorCodes } from "errors/LocalErrorCodes";
import { OctocartError } from "errors/OctocartError";

/** These are not currently used explicitly in the app but are pulled out for consistency */
enum ValidationErrorCodes {
  DeliveryAddressRequired = "DELIVERY_ADDRESS_REQUIRED",
  InvalidBirthday = "INVALID_BIRTHDAY",
  InvalidCommunicationsPreferences = "INVALID_COMMUNICATION_PREFERENCES",
  InvalidCreditCard = "INVALID_CREDIT_CARD",
  InvalidDisplayName = "INVALID_DISPLAY_NAME",
  InvalidEmail = "INVALID_EMAIL",
  InvalidGiftCard = "INVALID_GIFT_CARD",
  InvalidName = "INVALID_NAME",
  InvalidPassword = "INVALID_PASSWORD",
  InvalidPhone = "INVALID_PHONE",
  InvalidSavedPaymentEdit = "INVALID_SAVED_PAYMENT_EDIT",
  IrredeemableReward = "IRREDEEMABLE_REWARD",
  OrderDetailsRequired = "ORDER_DETAILS_REQUIRED",
  PasswordMismatch = "PASSWORD_MISMATCH",
  Unauthenticated = "UNAUTHENTICATED",
}

export class ValidationErrors {
  static readonly InvalidSavedPaymentEdit: OctocartError = {
    name: "Invalid payment edit",
    message: "Neither display name nor default payment has been updated",
    code: ValidationErrorCodes.InvalidSavedPaymentEdit,
  };

  static readonly DeliveryAddressRequired: OctocartError = {
    name: "Delivery Address Required",
    message: "A delivery address is required for delivery",
    code: ValidationErrorCodes.DeliveryAddressRequired,
  };

  static readonly InvalidCreditCard: OctocartError = {
    name: "Invalid Credit Card",
    message: "The credit card details you have entered are invalid",
    code: ValidationErrorCodes.InvalidCreditCard,
  };

  static readonly InvalidEmail: OctocartError = {
    name: "Invalid Email",
    message: "The email you have entered is invalid",
    code: ValidationErrorCodes.InvalidEmail,
  };

  static readonly InvalidName: OctocartError = {
    name: "Invalid Name",
    message: "The name you have entered contains invalid characters",
    code: ValidationErrorCodes.InvalidName,
  };

  static readonly InvalidPassword: OctocartError = {
    name: "Invalid Password",
    message: "The password you have entered does not meet requirements",
    code: ValidationErrorCodes.InvalidPassword,
  };

  static readonly InvalidPhone: OctocartError = {
    name: "Invalid Phone Number",
    message: "The phone number you have entered is invalid",
    code: ValidationErrorCodes.InvalidPhone,
  };

  static readonly InvalidBirthday: OctocartError = {
    name: "Invalid Birthday",
    message: "The birthday you have entered is invalid",
    code: ValidationErrorCodes.InvalidBirthday,
  };

  static readonly InvalidCommunicationsPreferences: OctocartError = {
    name: "Invalid Communication Preferences",
    message: "The communication preferences you have entered are invalid",
    code: ValidationErrorCodes.InvalidCommunicationsPreferences,
  };

  static readonly NotLoggedIn: OctocartError = {
    name: "User not logged in",
    message: "You are not logged in, please login to continue",
    code: ValidationErrorCodes.Unauthenticated,
  };

  static readonly PasswordsDoNotMatch: OctocartError = {
    name: "Passwords don't match",
    message: "The passwords you have entered do not match",
    code: ValidationErrorCodes.PasswordMismatch,
  };

  static readonly OrderDetailsDataRequired: OctocartError = {
    name: "No information found",
    message: "You must include contact info, payment type, order type, or special instructions.",
    code: ValidationErrorCodes.OrderDetailsRequired,
  };

  static readonly InvalidGiftCard: OctocartError = {
    name: "Invalid gift card",
    message: "The gift card you have entered is invalid",
    code: ValidationErrorCodes.InvalidGiftCard,
  };

  static readonly InvalidDisplayName: OctocartError = {
    name: "Invalid display name",
    message: "Display name must be between 1 and 30 alphanumeric characters",
    code: ValidationErrorCodes.InvalidDisplayName,
  };

  static readonly IrredeemableReward: OctocartError = {
    name: "Irredeemable Reward",
    message: "Reward cannot be redeemed",
    code: ValidationErrorCodes.IrredeemableReward,
  };
}

export const MalformedData: OctocartError = {
  name: "Malformed data",
  message: "Data cannot be parsed",
  code: LocalErrorCodes.MalformedData,
};

export const CSRFTimeOut: OctocartError = {
  name: "Call timed out",
  message: "The network call has timed out",
  code: LocalErrorCodes.CSRFTimeOut,
};
