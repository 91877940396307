import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "ui/app/GlobalNav/GlobalNav.styles";
import Icon from "ui/components/Icon/Icon";

export type NavCartProps = {
  itemCount: number | undefined;
  onClick: () => void;
};

const NavUser = observer(({ itemCount, onClick }: NavCartProps) => {
  let ariaLabel = "Cart";
  if (itemCount) {
    ariaLabel = `Cart with ${itemCount} items`;
  }

  return (
    <Box component="button" onClick={onClick} sx={styles.cart} aria-label={ariaLabel}>
      <Icon icon="bag" />
      {itemCount ? (
        <Box sx={styles.badge} aria-hidden="true">
          {itemCount}
        </Box>
      ) : null}
    </Box>
  );
});

export default NavUser;
