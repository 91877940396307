import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    margin: "0 auto",
    maxWidth: theme.pxToRem(theme.breakpoints.values.xxl),
    paddingX: 2,
    paddingY: 4,

    [theme.breakpoints.up("md")]: {
      paddingX: 6,
      paddingY: 8,
    },
  }),
  layout: (theme) => ({
    gap: 2,
    margin: "0 auto",
    maxWidth: "45rem",

    [theme.breakpoints.up("md")]: {
      gap: 3,
    },
  }),
  flyingSandwich: (theme) => ({
    height: "6.625rem",
    width: "unset",

    [theme.breakpoints.up("md")]: {
      height: "12.4375rem",
    },
  }),
} as const satisfies MuiStyles;

export default styles;
