import {
  Box,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import ErrorIcon from "assets/icons/error.svg";
import React from "react";
import { Ref } from "react";
import styles from "ui/components/Select/Select.styles";

export type SelectProps = MuiSelectProps & {
  id: string;
  errorText?: string;
  helperText?: string;
  label?: string;
  optional?: boolean;
  placeholder?: string;
  value?: string;
};

const PLACEHOLDER = "placeholder";

const Select = React.forwardRef(function Select(
  {
    children,
    disabled,
    errorText,
    helperText,
    id,
    label,
    labelId = `${id}-label`,
    optional = false,
    placeholder,
    value = PLACEHOLDER,
    ...muiSelectProps
  }: SelectProps,
  ref?: Ref<unknown>
) {
  const isSelected = value.length > 0 && value !== PLACEHOLDER;

  const labelClassName = (() => {
    const classes: string[] = [];
    if (disabled) classes.push("disabled");
    if (optional) classes.push("optional");
    if (classes.length === 0) return undefined;
    return classes.join(" ");
  })();

  const selectClassName = isSelected ? "selected" : undefined;

  return (
    <FormControl fullWidth>
      {label && (
        <Box sx={styles.labelContainer}>
          <Stack direction="row" gap={0.25}>
            {label && (
              <Typography
                className={labelClassName}
                id={labelId}
                variant="formFieldLabelCaps"
                role="label"
                sx={styles.label}
              >
                {label}
              </Typography>
            )}

            {optional && (
              <Typography variant="formFieldText" sx={styles.optionalText}>
                (optional)
              </Typography>
            )}
          </Stack>
          {helperText && (
            <Typography variant="formFieldText" sx={styles.helperText}>
              {helperText}
            </Typography>
          )}
        </Box>
      )}
      <MuiSelect
        disabled={disabled}
        IconComponent={ChevronDownIcon}
        inputRef={ref}
        labelId={labelId}
        value={value}
        className={selectClassName}
        sx={styles.select}
        {...muiSelectProps}
      >
        {placeholder && value === PLACEHOLDER && (
          <MenuItem value={PLACEHOLDER} disabled>
            {placeholder}
          </MenuItem>
        )}
        {children}
      </MuiSelect>
      {errorText && (
        <Stack direction="row" gap={1} sx={styles.errorText}>
          <img src={ErrorIcon} aria-hidden="true" alt="" />
          {errorText}
        </Stack>
      )}
    </FormControl>
  );
});

export default Select;
