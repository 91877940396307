import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps,
  Typography,
  useRadioGroup,
} from "@mui/material";
import React, { Ref } from "react";
import Icon from "ui/components/Icon";
import styles from "ui/components/RadioGroup/RadioGroup.styles";

export type RadioGroupProps = MuiRadioGroupProps & {
  id: string;
  error?: boolean;
  errorText?: string;
  helperText?: string;
};

/**
 * @link https://mui.com/material-ui/react-radio-button/#controlled
 */
const RadioGroup = React.forwardRef(function RadioGroup(
  { children, error, errorText, helperText, sx, ...muiRadioGroupProps }: RadioGroupProps,
  ref?: Ref<unknown>
) {
  const errorTextId = errorText ? muiRadioGroupProps.id + "-error-text" : undefined;
  const helperTextId = helperText ? muiRadioGroupProps.id + "-helper-text" : undefined;
  const additionalStyles = Array.isArray(sx) ? sx : typeof sx === "object" || typeof sx === "function" ? [sx] : [];

  return (
    <FormControl error={error}>
      <MuiRadioGroup
        aria-describedby={helperTextId}
        aria-errormessage={errorTextId}
        aria-invalid={error}
        ref={ref}
        sx={[styles.radioGroup, ...additionalStyles]}
        {...muiRadioGroupProps}
      >
        {children}
      </MuiRadioGroup>
      {helperText ? <FormHelperText id={helperTextId}>{helperText}</FormHelperText> : null}
      {errorText ? (
        <Box sx={styles.errorBox}>
          <Icon icon="error" />
          <FormHelperText id={errorTextId} sx={styles.errorText}>
            {errorText}
          </FormHelperText>
        </Box>
      ) : null}
    </FormControl>
  );
});

type RadioButtonProps = Omit<FormControlLabelProps, "control" | "label"> & {
  title: string;
  description?: string;
  image?: string;
  imageFit?: "cover" | "fill";
  fullWidth?: boolean;
  variant?: string;
};

export const RadioButton = React.forwardRef(function RadioButton(
  {
    title,
    description,
    image,
    imageFit,
    fullWidth,
    variant = "outlined",
    sx,
    ...formControlLabelProps
  }: RadioButtonProps,
  ref?: Ref<HTMLButtonElement>
) {
  const radioGroup = useRadioGroup();
  const isSelected = radioGroup && formControlLabelProps.value === radioGroup.value;
  const additionalStyles = Array.isArray(sx) ? sx : typeof sx === "object" || typeof sx === "function" ? [sx] : [];

  return (
    <FormControlLabel
      control={<CustomRadioButton ref={ref} />}
      label={
        <Box sx={styles.content}>
          {image && (
            <Box sx={styles.image}>
              <img className={imageFit == "fill" ? "image-fill" : undefined} src={image} alt="" />
            </Box>
          )}
          <Box sx={styles.text}>
            <Typography variant="formFieldLabel">{title}</Typography>
            {description ? <Typography variant="bodySmall">{description}</Typography> : null}
          </Box>
        </Box>
      }
      sx={[
        styles.radioButton,
        variant === "outlined" ? styles.outlinedButton : styles.basicRadioButton,
        ...additionalStyles,
      ]}
      className={[
        isSelected ? "selected" : undefined,
        fullWidth ? "full-width" : undefined,
        imageFit == "fill" ? undefined : "image-cover",
      ].join(" ")}
      {...formControlLabelProps}
    />
  );
});

const CustomRadioButton = React.forwardRef(function RadioButton(props: RadioProps, ref?: Ref<HTMLButtonElement>) {
  return (
    <MuiRadio
      disableRipple
      checkedIcon={<Box sx={styles.checkedIconStyles} />}
      icon={<Box sx={styles.iconStyles} />}
      ref={ref}
      {...props}
    />
  );
});

export default RadioGroup;
