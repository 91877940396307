import { ContentfulClientApi, createClient, EntriesQueries, EntrySkeletonType } from "contentful";
import { HomepageSkeleton, PolicyPageData } from "models/cms";
import { DocumentSkeleton } from "models/cms/ContentTypes/Document";
import { PolicyPageSkeleton } from "models/cms/ContentTypes/PolicyPage";
import { PromoModalSkeleton } from "models/cms/ContentTypes/PromoModal";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { convertPolicyPageToData, convertPromoModalToData } from "util/CmsEntryToPropsUtils";
import { logError } from "util/Logger";

type ContentfulCient = ContentfulClientApi<"WITHOUT_UNRESOLVABLE_LINKS">;

const PREVIEW_VERIFICATION_TOKEN = "80EE7B3B-6591-4A63-9D8D-917C3E190F9D";

const useCms = () => {
  const [searchParams] = useSearchParams();
  const previewToken = searchParams.get("preview-token");
  const shouldUsePreview = previewToken === PREVIEW_VERIFICATION_TOKEN;

  /**
   * Instantiate contentfulClient to always use `withoutUnresolvableLinks` to keep
   * link resolution enabled and to discard any links that are unresolvable.
   * @see https://www.contentful.com/developers/docs/javascript/tutorials/typescript-in-javascript-client-library/
   */
  const contentfulClient = useMemo(() => {
    try {
      if (shouldUsePreview) {
        // create and return a Contentful _preview_ API client
        return createClient({
          accessToken: Env.ContentfulPreviewToken ?? "",
          environment: Env.ContentfulEnvironment,
          host: "preview.contentful.com",
          space: Env.ContentfulSpaceId ?? "",
        }).withoutUnresolvableLinks;
      }

      // create and return a Contentful API client
      return createClient({
        accessToken: Env.ContentfulAccessToken ?? "",
        environment: Env.ContentfulEnvironment,
        space: Env.ContentfulSpaceId ?? "",
      }).withoutUnresolvableLinks;
    } catch {
      return undefined;
    }
  }, [shouldUsePreview]);

  if (!contentfulClient) {
    logError("contentfulClient is undefined");
    throw new Error("contentfulClient is undefined");
  }

  const getDocument = async (identifier: string) => {
    try {
      const entry = await getEntryOfType<DocumentSkeleton>(contentfulClient, "document", {
        "fields.identifier[match]": identifier,
        include: 4,
        limit: 1,
      });

      return entry;
    } catch (e) {
      logError(e);
    }
  };

  const getHomepage = () => getEntryOfType<HomepageSkeleton>(contentfulClient, "homepage", { include: 4 });

  const getPolicyPages = useCallback(async () => {
    try {
      const allPolicyPages = await contentfulClient.getEntries<PolicyPageSkeleton>({
        content_type: "policyPage",
        limit: 1000,
        include: 4,
      });

      const policyPagesData: PolicyPageData[] = allPolicyPages.items.map((policyPage) => {
        const { id, title, policyGroups } = convertPolicyPageToData(policyPage);

        return {
          id,
          title,
          policyGroups,
        };
      });

      return policyPagesData;
    } catch (e) {
      logError(e);
      throw e;
    }
  }, [contentfulClient]);

  const getPromoModals = useCallback(async () => {
    try {
      const promoModals = await contentfulClient.getEntries<PromoModalSkeleton>({
        content_type: "promoModal",
        limit: 1000,
        include: 2,
      });

      return promoModals.items
        .filter(({ fields: { title, image, description, cta } }) => {
          // validate that at least one optional content field must be populated
          return title || image || description || cta;
        })
        .map(convertPromoModalToData);
    } catch (e) {
      logError(e);
      throw e;
    }
  }, [contentfulClient]);

  return {
    getDocument,
    getHomepage,
    getPolicyPages,
    getPromoModals,
  };
};

/** Convenience function that allows for fetching a single entry of a given content type */
const getEntryOfType = async <T extends EntrySkeletonType>(
  client: ContentfulCient,
  content_type: string,
  query?: Omit<EntriesQueries<T, "WITHOUT_UNRESOLVABLE_LINKS">, "content_type">
) => {
  try {
    const entries = await client.getEntries<T>({ content_type, ...query, limit: 1 });
    return entries.items[0];
  } catch (e) {
    logError(e);
  }
};

export default useCms;
