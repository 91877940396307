import React from "react";
import { Stylable } from "ui/Stylable";
import { Theme } from "ui/Theme";

export enum IconType {
  arrowDown12 = 1,
  arrowLeft20,
  arrowUp12,
  car24,
  cartEmpty,
  check12,
  check20,
  checkCircle20,
  chevronDown12,
  chevronDown16,
  chevronRight12,
  chevronRight16,
  chevronRight20,
  close12,
  close20,
  close24,
  closeCircle24,
  delete20,
  delivery24,
  Facebook,
  filter24,
  info16,
  Instagram,
  loading16,
  loading20,
  loading24,
  location24,
  menu24,
  minus12,
  plus12,
  placeholder20,
  phone20,
  rewardCircle,
  rewardStamp,
  shoppingCart24,
  store24,
  Twitter,
  visibility20,
  visibilityOff20,
  yourLocation20,
  YouTube,
}

const ArrowDown12 = (color: string, className?: string) => (
  <svg className={className} width="8" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16795 4.75192
              C3.56377 5.34566 4.43623 5.34566 4.83205 4.75192
              L6.96353 1.5547
              C7.40657 0.890145 6.93018 0 6.13148 0H1.86852
              C1.06982 0 0.59343 0.890145 1.03647 1.5547
              L3.16795 4.75192Z"
      fill={color}
    />
  </svg>
);

const ArrowLeft20 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.24863 8.04853C9.71725 7.5799 9.71725 6.8201 9.24863 6.35147C8.78 5.88284 8.0202 5.88284 7.55157
      6.35147L2.75157 11.1515C2.28294 11.6201 2.28294 12.3799 2.75157 12.8485L7.55157 17.6485C8.0202 18.1172 8.78
      18.1172 9.24863 17.6485C9.71725 17.1799 9.71725 16.4201 9.24863 15.9515L5.29715 12L9.24863 8.04853Z"
      fill={color}
    />
    <rect x="21.6001" y="10.8" width="2.4" height="19.2" rx="1" transform="rotate(90 21.6001 10.8)" fill={color} />
  </svg>
);

const ArrowUp12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16795 4.24808C5.56377 3.65434 6.43623 3.65434 6.83205
    4.24808L8.96353 7.4453C9.40657 8.10986 8.93018 9 8.13148 9H3.86852C3.06982 9 2.59343 8.10985 3.03647 7.4453L5.16795
    4.24808Z"
      fill={color}
    />
  </svg>
);

const Car24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.3375 9.75L6.5385 9.678L8.241 6.486C8.56076 5.88614 9.03756 5.38447 9.62041 5.03466C10.2033 4.68484 10.8702 4.50004 11.55 4.5H15.8385C16.6255 4.50028 17.3924 4.74815 18.0307 5.2085C18.669 5.66885 19.1463 6.31834 19.395 7.065L20.2065 9.4995C20.8864 9.78606 21.4666 10.2668 21.8746 10.8815C22.2825 11.4963 22.5001 12.2177 22.5 12.9555V14.625C22.5 15.69 21.867 16.605 20.955 17.0175C20.8371 17.6931 20.4913 18.3078 19.9752 18.7594C19.4591 19.211 18.8038 19.4722 18.1186 19.4993C17.4334 19.5265 16.7595 19.3181 16.2092 18.9088C15.659 18.4996 15.2656 17.9141 15.0945 17.25H8.9055C8.73444 17.9141 8.34102 18.4996 7.79077 18.9088C7.24051 19.3181 6.56664 19.5265 5.8814 19.4993C5.19616 19.4722 4.54092 19.211 4.02482 18.7594C3.50871 18.3078 3.16291 17.6931 3.045 17.0175C2.58456 16.8097 2.19388 16.4734 1.91983 16.049C1.64578 15.6246 1.50001 15.1302 1.5 14.625V14.1165C1.5 12.531 2.499 11.1165 3.993 10.584L6.258 9.7785V9.75H6.3375ZM17.973 7.539C17.8238 7.09091 17.5373 6.70114 17.1542 6.42492C16.7711 6.14871 16.3108 6.00004 15.8385 6H14.25V9.75H18.7095L17.9715 7.539H17.973ZM8.2005 9.75H12.75V6H11.55C11.1421 5.99986 10.7418 6.1106 10.392 6.32038C10.0422 6.53017 9.75599 6.8311 9.564 7.191L8.199 9.75H8.2005ZM19.5 16.5C19.5 16.1022 19.342 15.7206 19.0607 15.4393C18.7794 15.158 18.3978 15 18 15C17.6022 15 17.2206 15.158 16.9393 15.4393C16.658 15.7206 16.5 16.1022 16.5 16.5C16.5 16.8978 16.658 17.2794 16.9393 17.5607C17.2206 17.842 17.6022 18 18 18C18.3978 18 18.7794 17.842 19.0607 17.5607C19.342 17.2794 19.5 16.8978 19.5 16.5ZM6 15C5.60218 15 5.22064 15.158 4.93934 15.4393C4.65804 15.7206 4.5 16.1022 4.5 16.5C4.5 16.8978 4.65804 17.2794 4.93934 17.5607C5.22064 17.842 5.60218 18 6 18C6.39782 18 6.77936 17.842 7.06066 17.5607C7.34196 17.2794 7.5 16.8978 7.5 16.5C7.5 16.1022 7.34196 15.7206 7.06066 15.4393C6.77936 15.158 6.39782 15 6 15Z"
      fill={color}
    />
  </svg>
);

const CartEmpty = (color: string, className?: string) => (
  <svg
    className={className}
    width="104"
    height="136"
    viewBox="0 0 104 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5 0C58.6046 0 59.5 0.89543 59.5 2V33C59.5 34.1046 58.6046 35 57.5 35C56.3954 35 55.5 34.1046 55.5 33V2C55.5 0.89543 56.3954 0 57.5 0ZM82.8944 14.2111C83.8824 14.7051 84.2828 15.9065 83.7889 16.8944L75.2889 33.8944C74.7949 34.8824 73.5935 35.2828 72.6056 34.7889C71.6176 34.2949 71.2172 33.0935 71.7112 32.1056L80.2112 15.1056C80.7051 14.1176 81.9065 13.7172 82.8944 14.2111ZM31.2112 16.8944C30.7172 15.9065 31.1176 14.7051 32.1056 14.2111C33.0935 13.7172 34.2949 14.1176 34.7889 15.1056L43.2889 32.1056C43.7828 33.0935 43.3824 34.2949 42.3944 34.7889C41.4065 35.2828 40.2051 34.8824 39.7112 33.8944L31.2112 16.8944Z"
      fill={color}
    />
    <path
      d="M13 43H21.6667L23.4 51.6667M30.3333 86.3333H73.6667L91 51.6667H23.4M30.3333 86.3333L23.4 51.6667M30.3333 86.3333L20.3975 96.2692C17.6676 98.9991 19.601 103.667 23.4616 103.667H73.6667M73.6667 103.667C68.8802 103.667 65 107.547 65 112.333C65 117.12 68.8802 121 73.6667 121C78.4531 121 82.3333 117.12 82.3333 112.333C82.3333 107.547 78.4531 103.667 73.6667 103.667ZM39 112.333C39 117.12 35.1198 121 30.3333 121C25.5469 121 21.6667 117.12 21.6667 112.333C21.6667 107.547 25.5469 103.667 30.3333 103.667C35.1198 103.667 39 107.547 39 112.333Z"
      stroke="#9CA3AF"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckCircle20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00025 14.0006C8.73625 14.0006 8.48125 13.8966 8.29325 13.7076L6.29325 11.7076C5.90225 11.3166 5.90225 10.6846 6.29325 10.2936C6.68425 9.90257 7.31625 9.90257 7.70725 10.2936L8.84525 11.4316L12.1683 6.44557C12.4742 5.98557 13.0943 5.86157 13.5553 6.16857C14.0142 6.47557 14.1383 7.09557 13.8323 7.55557L9.83225 13.5556C9.66625 13.8046 9.39625 13.9666 9.09925 13.9956C9.06525 13.9986 9.03325 14.0006 9.00025 14.0006Z"
      fill={color}
    />
    <path
      d="M2 10C2 5.60228 5.60228 2 10 2C14.3977 2 18 5.60228 18 10C18 14.3977 14.3977 18 10 18C5.60228 18 2 14.3977 2 10Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

const ChevronDown12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.13812 3.86209C2.87746 3.60143 2.45615 3.60143 2.19549 3.86209C1.93484 4.12275 1.93484 4.54406 2.19549 4.80472L5.52869 8.13791C5.78934 8.39857 6.21066 8.39857 6.47131 8.13791L9.80451 4.80472C10.0652 4.54406 10.0652 4.12275 9.80451 3.86209C9.54385 3.60143 9.12254 3.60143 8.86188 3.86209L6 6.72397L3.13812 3.86209Z"
      fill={color}
    />
  </svg>
);

const ChevronDown16 = (color: string, className?: string) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20724 4.79324C2.81624 4.40224 2.18424 4.40224 1.79324 4.79324C1.40224 5.18424 1.40224 5.81624 1.79324 6.20724L6.79324 11.2072C7.18424 11.5982 7.81624 11.5982 8.20724 11.2072L13.2072 6.20724C13.5982 5.81624 13.5982 5.18424 13.2072 4.79324C12.8162 4.40224 12.1842 4.40224 11.7932 4.79324L7.50024 9.08624L3.20724 4.79324Z"
      fill={color}
    />
  </svg>
);

const ChevronRight12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5287 8.86188C4.26805 9.12254 4.26805 9.54385 4.5287 9.80451C4.78936 10.0652 5.21067 10.0652 5.47133 9.80451L8.80452 6.47131C9.06518 6.21066 9.06518 5.78934 8.80452 5.52869L5.47133 2.19549C5.21067 1.93484 4.78936 1.93484 4.5287 2.19549C4.26805 2.45615 4.26805 2.87746 4.5287 3.13812L7.39058 6L4.5287 8.86188Z"
      fill={color}
    />
  </svg>
);

const ChevronRight16 = (color: string, className?: string) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29324 12.2933C3.90224 12.6842 3.90224 13.3162 4.29324 13.7072C4.68424 14.0982 5.31624 14.0982 5.70724 13.7072L10.7072 8.70725C11.0982 8.31625 11.0982 7.68425 10.7072 7.29325L5.70724 2.29325C5.31624 1.90225 4.68424 1.90225 4.29324 2.29325C3.90224 2.68425 3.90224 3.31625 4.29324 3.70725L8.58624 8.00025L4.29324 12.2933Z"
      fill={color}
    />
  </svg>
);

const ChevronRight20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4817 9.99982L5.34172 16.2468C4.92572 16.6108 4.88372 17.2428 5.24772 17.6578C5.61172 18.0738 6.24372 18.1158 6.65872 17.7528L14.6587 10.7518C15.1137 10.3538 15.1137 9.64582 14.6587 9.24782L6.65872 2.24782C6.24372 1.88382 5.61172 1.92582 5.24772 2.34082C4.88372 2.75682 4.92572 3.38882 5.34172 3.75182L12.4817 9.99982Z"
      fill={color}
    />
  </svg>
);

const Close12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4141 6.00001L9.7071 3.70701C10.0981 3.31601 10.0981 2.68401 9.7071 2.29301C9.3161 1.90201 8.6841 1.90201 8.2931 2.29301L6.0001 4.58601L3.7071 2.29301C3.3161 1.90201 2.6841 1.90201 2.2931 2.29301C1.9021 2.68401 1.9021 3.31601 2.2931 3.70701L4.5861 6.00001L2.2931 8.29301C1.9021 8.68401 1.9021 9.31601 2.2931 9.70701C2.4881 9.90201 2.7441 10 3.0001 10C3.2561 10 3.5121 9.90201 3.7071 9.70701L6.0001 7.41401L8.2931 9.70701C8.4881 9.90201 8.7441 10 9.0001 10C9.2561 10 9.5121 9.90201 9.7071 9.70701C10.0981 9.31601 10.0981 8.68401 9.7071 8.29301L7.4141 6.00001Z"
      fill={color}
    />
  </svg>
);

const Close20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4141 10L15.7071 5.70701C16.0981 5.31601 16.0981 4.68401 15.7071 4.29301C15.3161 3.90201 14.6841 3.90201 14.2931 4.29301L10.0001 8.58601L5.7071 4.29301C5.3161 3.90201 4.6841 3.90201 4.2931 4.29301C3.9021 4.68401 3.9021 5.31601 4.2931 5.70701L8.5861 10L4.2931 14.293C3.9021 14.684 3.9021 15.316 4.2931 15.707C4.4881 15.902 4.7441 16 5.0001 16C5.2561 16 5.5121 15.902 5.7071 15.707L10.0001 11.414L14.2931 15.707C14.4881 15.902 14.7441 16 15.0001 16C15.2561 16 15.5121 15.902 15.7071 15.707C16.0981 15.316 16.0981 14.684 15.7071 14.293L11.4141 10Z"
      fill={color}
    />
  </svg>
);

const Close24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4143 12.0002L18.7072 6.70725C19.0982 6.31625 19.0982 5.68425 18.7072 5.29325C18.3162 4.90225 17.6842 4.90225 17.2933 5.29325L12.0002 10.5862L6.70725 5.29325C6.31625 4.90225 5.68425 4.90225 5.29325 5.29325C4.90225 5.68425 4.90225 6.31625 5.29325 6.70725L10.5862 12.0002L5.29325 17.2933C4.90225 17.6842 4.90225 18.3162 5.29325 18.7072C5.48825 18.9022 5.74425 19.0002 6.00025 19.0002C6.25625 19.0002 6.51225 18.9022 6.70725 18.7072L12.0002 13.4143L17.2933 18.7072C17.4882 18.9022 17.7443 19.0002 18.0002 19.0002C18.2562 19.0002 18.5122 18.9022 18.7072 18.7072C19.0982 18.3162 19.0982 17.6842 18.7072 17.2933L13.4143 12.0002Z"
      fill={color}
    />
  </svg>
);

const CloseCircle24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      fill={color}
    />
    <path
      d="M14.1924 15.5355C14.5829 15.9261 15.2161 15.9261 15.6066 15.5355C15.9971 15.145 15.9971 14.5118 15.6066 14.1213L13.4853 12L15.6066 9.87868C15.9971 9.48816 15.9971 8.85499 15.6066 8.46447C15.2161 8.07394 14.5829 8.07394 14.1924 8.46447L12.0711 10.5858L9.94975 8.46447C9.55922 8.07394 8.92606 8.07394 8.53553 8.46447C8.14501 8.85499 8.14501 9.48816 8.53553 9.87868L10.6569 12L8.53553 14.1213C8.14501 14.5118 8.14501 15.145 8.53553 15.5355C8.92606 15.9261 9.55922 15.9261 9.94975 15.5355L12.0711 13.4142L14.1924 15.5355Z"
      fill={color}
    />
  </svg>
);

const Check12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00025 10.0006C4.73625 10.0006 4.48125 9.89657 4.29325 9.70757L2.29325 7.70757C1.90225 7.31657 1.90225 6.68457 2.29325 6.29357C2.68425 5.90257 3.31625 5.90257 3.70725 6.29357L4.84525 7.43157L8.16825 2.44557C8.47425 1.98557 9.09425 1.86157 9.55525 2.16857C10.0142 2.47557 10.1383 3.09557 9.83225 3.55557L5.83225 9.55557C5.66625 9.80457 5.39625 9.96657 5.09925 9.99557C5.06525 9.99857 5.03325 10.0006 5.00025 10.0006Z"
      fill={color}
    />
  </svg>
);

const Check20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33362 10L8.33362 15L16.667 5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Delete20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8C15.5523 8 16 8.44772 16 9V15C16 16.6569 14.6569 18 13 18H7C5.34315 18 4 16.6569 4 15V9C4 8.44772 4.44772 8 5 8H15ZM7 3C7 2.44772 7.44772 2 8 2H12C12.5523 2 13 2.44772 13 3V4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5C3 4.44772 3.44772 4 4 4H7V3Z"
      fill={color}
    />
  </svg>
);

const Delivery24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.993 7.94998C21.9914 7.87778 21.9817 7.80599 21.964 7.73598C21.957 7.71098 21.943 7.68698 21.934 7.66198C21.913 7.60498 21.894 7.54898 21.864 7.49698C21.848 7.46998 21.826 7.44798 21.807 7.42198C21.775 7.37698 21.744 7.33098 21.705 7.29198C21.682 7.26998 21.652 7.25198 21.627 7.23098C21.588 7.19898 21.552 7.16398 21.507 7.13698C21.503 7.13398 21.498 7.13398 21.493 7.13098L21.485 7.12498L12.506 2.13498C12.3576 2.0527 12.1908 2.00945 12.0211 2.00928C11.8515 2.0091 11.6845 2.05201 11.536 2.13398L2.515 7.12398C2.512 7.12698 2.509 7.13098 2.504 7.13398L2.494 7.13798C2.459 7.15798 2.433 7.18698 2.4 7.21098C2.364 7.23798 2.326 7.26198 2.294 7.29298C2.264 7.32398 2.241 7.35998 2.215 7.39498C2.188 7.42998 2.158 7.46098 2.136 7.49898C2.11 7.54198 2.096 7.59098 2.077 7.63798C2.063 7.67098 2.045 7.70198 2.036 7.73798C2.01873 7.80672 2.00901 7.87713 2.007 7.94798C2.006 7.96498 2 7.97998 2 7.99798V16C2 16.363 2.197 16.698 2.515 16.874L11.493 21.861L11.494 21.862L11.496 21.863L11.516 21.874C11.559 21.898 11.606 21.911 11.651 21.928C11.683 21.941 11.714 21.958 11.748 21.967C11.914 22.0098 12.088 22.0098 12.254 21.967C12.287 21.958 12.318 21.941 12.351 21.928C12.396 21.911 12.443 21.899 12.486 21.874L12.506 21.863L12.508 21.862L12.509 21.861L21.487 16.874C21.803 16.698 22 16.363 22 16V7.99798C22 7.98098 21.994 7.96698 21.993 7.94998ZM11.972 11.872L5.058 8.00498L7.82 6.47698L14.654 10.382L11.972 11.872ZM12.02 4.15298L18.941 7.99998L16.697 9.24698L9.867 5.34398L12.02 4.15298ZM13 19.301L13.002 13.622L16 11.944V15L18 14V10.825L20 9.70598V15.411L13 19.301Z"
      fill={color}
    />
  </svg>
);

const Facebook = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1742 5.32031H17V2.14062C16.6856 2.09766 15.6023 2 14.3409 2C11.7083 2 9.9053 3.65625 9.9053 
      6.69922V9.5H7V13.0547H9.9053V22H13.4659V13.0547H16.2538L16.697 9.5H13.4659V7.05078C13.4659 6.02344 
      13.7424 5.32031 15.1742 5.32031Z"
      fill={color}
    />
  </svg>
);

const Filter24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.14285 19.7143V13.7143" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.14285 10.2857V4.28571" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19.7143V12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 8.57142V4.28571" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.8571 19.7143V15.4286" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.8571 12V4.28571" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.57143 13.7143H7.71428" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.42857 8.57141H14.5714" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.2857 15.4286H21.4286" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Info16 = (color: string, className?: string) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM8 7C7.48716 7 7.06449 7.38604 7.00673 7.88338L7 8V11C7 11.5523 7.44772 12 8 12C8.51284 12 8.93551 11.614 8.99327 11.1166L9 11V8C9 7.44772 8.55228 7 8 7ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4Z"
      fill={color}
    />
  </svg>
);

const Instagram = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8251 2H13.1739C14.8492 2.00379 15.2338 2.01855 16.1227 2.0591C17.1871 2.10765 17.9141 2.27672 18.5502
      2.52396C19.2079 2.77949 19.7656 3.12144 20.3216 3.6774C20.8775 4.2334 21.2195 4.79111 21.475 5.44873C21.7222
      6.08484 21.8913 6.81182 21.9399 7.87626C21.9869 8.90737 21.9992 9.25998 22 11.7357V12.2633C21.9992 14.739 21.9869
      15.0916 21.9399 16.1227C21.8913 17.1871 21.7222 17.9141 21.475 18.5502C21.2195 19.2079 20.8775 19.7656 20.3216
      20.3216C19.7656 20.8775 19.2079 21.2195 18.5502 21.475C17.9141 21.7222 17.1871 21.8913 16.1227 21.9399C15.0916
      21.9869 14.739 21.9992 12.2633 22H11.7357C9.25998 21.9992 8.90737 21.9869 7.87626 21.9399C6.81182 21.8913 6.08484
      21.7222 5.44873 21.475C4.79111 21.2195 4.2334 20.8775 3.6774 20.3216C3.12144 19.7656 2.77949 19.2079 2.52396
      18.5502C2.27672 17.9141 2.10765 17.1871 2.0591 16.1227C2.01855 15.2338 2.00379 14.8492 2 13.1739V10.8251C2.00379
      9.1498 2.01855 8.76515 2.0591 7.87626C2.10765 6.81182 2.27672 6.08484 2.52396 5.44873C2.77949 4.79111 3.12144
      4.2334 3.6774 3.6774C4.2334 3.12144 4.79111 2.77949 5.44873 2.52396C6.08484 2.27672 6.81182 2.10765 7.87626
      2.0591C8.76515 2.01855 9.1498 2.00379 10.8251 2H13.1739H10.8251ZM12.733 3.80115H11.2659C9.25858 3.80324 8.90746
      3.81583 7.9584 3.85914C6.98335 3.9036 6.45383 4.06651 6.10144 4.20347C5.63464 4.38489 5.3015 4.60159 4.95157
      4.95157C4.60159 5.3015 4.38489 5.63464 4.20347 6.10144C4.06651 6.45383 3.9036 6.98335 3.85914 7.9584C3.81583
      8.90746 3.80324 9.25858 3.80115 11.2659V12.733C3.80324 14.7404 3.81583 15.0915 3.85914 16.0406C3.9036 17.0156
      4.06651 17.5451 4.20347 17.8975C4.38489 18.3643 4.60163 18.6975 4.95157 19.0474C5.3015 19.3974 5.63464 19.6141
      6.10144 19.7955C6.45383 19.9324 6.98335 20.0954 7.9584 20.1398C9.0128 20.1879 9.32902 20.1981 11.9995
      20.1981C14.6699 20.1981 14.9862 20.1879 16.0406 20.1398C17.0156 20.0954 17.5451 19.9324 17.8975 19.7955C18.3643
      19.6141 18.6975 19.3974 19.0474 19.0474C19.3974 18.6975 19.6141 18.3643 19.7955 17.8975C19.9324 17.5451 20.0954
      17.0156 20.1398 16.0406C20.1879 14.986 20.1981 14.6697 20.1981 11.9995C20.1981 9.32922 20.1879 9.01292 20.1398
      7.9584C20.0954 6.98335 19.9324 6.45383 19.7955 6.10144C19.6141 5.63464 19.3974 5.3015 19.0474 4.95157C18.6975
      4.60159 18.3643 4.38489 17.8975 4.20347C17.5451 4.06651 17.0156 3.9036 16.0406 3.85914C15.0915 3.81583 14.7404
      3.80324 12.733 3.80115ZM11.9995 6.9992C14.7611 6.9992 16.9998 9.23788 16.9998 11.9995C16.9998 14.7611 14.7611
      16.9998 11.9995 16.9998C9.23788 16.9998 6.9992 14.7611 6.9992 11.9995C6.9992 9.23788 9.23788 6.9992 11.9995
      6.9992ZM11.9995 8.75368C10.2069 8.75368 8.75368 10.2069 8.75368 11.9995C8.75368 13.7921 10.2069 15.2453 11.9995
      15.2453C13.7921 15.2453 15.2453 13.7921 15.2453 11.9995C15.2453 10.2069 13.7921 8.75368 11.9995 8.75368ZM17.4164
      5.33244C18.1068 5.33244 18.6665 5.89213 18.6665 6.58253C18.6665 7.27293 18.1068 7.83258 17.4164 7.83258C16.7261
      7.83258 16.1664 7.27293 16.1664 6.58253C16.1664 5.89213 16.7261 5.33244 17.4164 5.33244Z"
      fill={color}
    />
  </svg>
);

const Loading16 = (color: string, className?: string) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 8C2 8.55228 2.44772 9 3 9C3.55228 9 4 8.55228 4 8H2ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14
      8 14V12ZM8 2C4.69772 2 2 4.69772 2 8H4C4 5.80228 5.80228 4 8 4V2ZM8 14C11.3023 14 14 11.3023 14 8H12C12 10.1977
      10.1977 12 8 12V14ZM14 8C14 4.69772 11.3023 2 8 2V4C10.1977 4 12 5.80228 12 8H14Z"
      fill={color}
    />
  </svg>
);

const Loading20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 10C1 10.5523 1.44772 11 2 11C2.55228 11 3 10.5523 3 10H1ZM10 17C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772
       19 10 19V17ZM10 1C5.04772 1 1 5.04772 1 10H3C3 6.15229 6.15229 3 10 3V1ZM10 19C14.9523 19 19 14.9523 19 10H17C17
       13.8477 13.8477 17 10 17V19ZM19 10C19 5.04772 14.9523 1 10 1V3C13.8477 3 17 6.15229 17 10H19Z"
      fill={color}
    />
  </svg>
);

const Loading24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.71429C7.71288 3.71429 4.18623 6.97023 3.75794 11.1442C3.70962 11.6151 3.33053 12 2.85714 12C2.38376 12
      1.99617 11.6149 2.03619 11.1432C2.47079 6.02145 6.76576 2 12 2C17.5228 2 22 6.47715 22 12C22 17.2342 17.9786
      21.5292 12.8568 21.9638C12.3851 22.0038 12 21.6162 12 21.1429C12 20.6695 12.3849 20.2904 12.8558 20.2421C17.0298
      19.8138 20.2857 16.2871 20.2857 12C20.2857 7.42393 16.5761 3.71429 12 3.71429Z"
      fill={color}
    />
  </svg>
);

const Location24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.96657 5.53798C8.22574 4.27881 9.93355 3.57141 11.7143 3.57141C13.495 3.57141 15.2028 4.27881 16.462 5.53798C17.7212 6.79715 18.4286 8.50496 18.4286 10.2857C18.4286 12.8838 16.7343 15.4257 14.8455 17.4194C13.9215 18.3947 12.9942 19.1936 12.2967 19.749C12.0763 19.9245 11.8796 20.075 11.7143 20.1985C11.5489 20.075 11.3523 19.9245 11.1319 19.749C10.4344 19.1936 9.50704 18.3947 8.5831 17.4194C6.69431 15.4257 5 12.8838 5 10.2857C5 8.50496 5.7074 6.79715 6.96657 5.53798ZM11.1591 22.2603C11.1593 22.2604 11.1596 22.2606 11.7143 21.4286L12.269 22.2606C11.9331 22.4845 11.495 22.4842 11.1591 22.2603ZM11.1591 22.2603L11.7143 21.4286C12.269 22.2606 12.2699 22.26 12.2702 22.2598L12.2722 22.2585L12.2781 22.2545L12.2983 22.2408C12.3153 22.2293 12.3395 22.2128 12.3703 22.1914C12.4319 22.1487 12.5202 22.0868 12.6313 22.0065C12.8533 21.8461 13.1673 21.6123 13.5426 21.3135C14.2915 20.7171 15.2928 19.8553 16.2974 18.7949C18.2657 16.7172 20.4286 13.6876 20.4286 10.2857C20.4286 7.97452 19.5105 5.75801 17.8762 4.12377C16.242 2.48952 14.0255 1.57141 11.7143 1.57141C9.40311 1.57141 7.1866 2.48952 5.55236 4.12377C3.91811 5.75801 3 7.97452 3 10.2857C3 13.6876 5.16283 16.7172 7.13119 18.7949C8.13582 19.8553 9.13705 20.7171 9.886 21.3135C10.2613 21.6123 10.5752 21.8461 10.7973 22.0065C10.9084 22.0868 10.9967 22.1487 11.0583 22.1914C11.0891 22.2128 11.1133 22.2293 11.1303 22.2408L11.1505 22.2545L11.1564 22.2585L11.1591 22.2603ZM10.1429 10.2857C10.1429 9.41785 10.8464 8.71429 11.7143 8.71429C12.5822 8.71429 13.2857 9.41785 13.2857 10.2857C13.2857 11.1536 12.5822 11.8572 11.7143 11.8572C10.8464 11.8572 10.1429 11.1536 10.1429 10.2857ZM11.7143 6.71429C9.74184 6.71429 8.14286 8.31328 8.14286 10.2857C8.14286 12.2582 9.74184 13.8572 11.7143 13.8572C13.6867 13.8572 15.2857 12.2582 15.2857 10.2857C15.2857 8.31328 13.6867 6.71429 11.7143 6.71429Z"
      fill={color}
    />
  </svg>
);

const Menu24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 17C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H21ZM21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H21ZM21 5C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5H21Z"
      fill={color}
    />
  </svg>
);

const Minus12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 6C2 5.44772 2.44772 5 3 5H9C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7H3C2.44772 7 2 6.55228 2 6Z"
      fill={color}
    />
  </svg>
);

const Phone20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9678 13.9791V16.3877C17.9687 16.6113 17.9229 16.8327 17.8333 17.0375C17.7438 17.2424 17.6124 17.4263 17.4476 17.5775C17.2828 17.7286 17.0883 17.8437 16.8765 17.9154C16.6647 17.987 16.4402 18.0136 16.2175 17.9935C13.7469 17.725 11.3737 16.8808 9.28865 15.5286C7.34875 14.2959 5.70406 12.6512 4.47136 10.7113C3.11448 8.61679 2.27006 6.23206 2.00652 3.75036C1.98645 3.52834 2.01284 3.30457 2.084 3.0933C2.15515 2.88203 2.26952 2.6879 2.41981 2.52325C2.57011 2.35861 2.75304 2.22706 2.95696 2.13699C3.16088 2.04691 3.38132 2.00029 3.60425 2.00008H6.0129C6.40254 1.99624 6.78028 2.13422 7.07572 2.3883C7.37116 2.64237 7.56413 2.99521 7.61866 3.38103C7.72032 4.15185 7.90886 4.9087 8.18068 5.63713C8.2887 5.9245 8.31208 6.23682 8.24804 6.53707C8.18401 6.83732 8.03525 7.11292 7.81938 7.33121L6.79972 8.35087C7.94267 10.3609 9.60696 12.0252 11.617 13.1682L12.6367 12.1485C12.855 11.9326 13.1306 11.7839 13.4308 11.7198C13.7311 11.6558 14.0434 11.6792 14.3308 11.7872C15.0592 12.059 15.816 12.2476 16.5869 12.3492C16.9769 12.4042 17.333 12.6007 17.5877 12.9012C17.8423 13.2017 17.9776 13.5853 17.9678 13.9791Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Placeholder20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z"
      fill={color}
    />
  </svg>
);

const Plus12 = (color: string, className?: string) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H5V9C5 9.55228 5.44772 10 6 10C6.55228 10 7 9.55228 7 9V7H9C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5H7V3C7 2.44772 6.55228 2 6 2Z"
      fill={color}
    />
  </svg>
);
const RewardCircle = (color: string, className?: string) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 61C48.0163 61 61 48.0163 61 32C61 15.9837 48.0163 3 32 3C15.9837 3 3 15.9837 3 32C3 48.0163 15.9837 61 32 61Z"
      stroke={color}
      strokeWidth="6"
    />
  </svg>
);

const RewardStamp = (color: string, className?: string) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 61C48.0163 61 61 48.0163 61 32C61 15.9837 48.0163 3 32 3C15.9837 3 3 15.9837 3 32C3 48.0163 15.9837 61 32 61Z"
      stroke={color}
      strokeWidth="6"
    />
    <path
      d="M31.5248 14.7967C31.6744 14.336 32.3261 14.336 32.4758 14.7967L36.0791 25.886C36.146 26.092 36.3379 26.2315 36.5545 26.2315H48.2145C48.6989 26.2315 48.9003 26.8513 48.5084 27.136L39.0753 33.9896C38.9 34.1169 38.8267 34.3426 38.8936 34.5486L42.4968 45.638C42.6464 46.0986 42.1192 46.4817 41.7274 46.197L32.2942 39.3434C32.1189 39.2161 31.8816 39.2161 31.7064 39.3434L22.2733 46.197C21.8814 46.4817 21.3542 46.0986 21.5038 45.638L25.107 34.5486C25.1739 34.3426 25.1006 34.1169 24.9253 33.9896L15.4922 27.136C15.1003 26.8513 15.3017 26.2315 15.7861 26.2315H27.4461C27.6627 26.2315 27.8547 26.092 27.9216 25.886L31.5248 14.7967Z"
      fill={color}
    />
  </svg>
);

const ShoppingCart24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.27274 21.8182C9.77482 21.8182 10.1818 21.4112 10.1818 20.9091C10.1818 20.407 9.77482 20 9.27274 20C8.77066 20 8.36365 20.407 8.36365 20.9091C8.36365 21.4112 8.77066 21.8182 9.27274 21.8182Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2727 21.8182C19.7748 21.8182 20.1818 21.4112 20.1818 20.9091C20.1818 20.407 19.7748 20 19.2727 20C18.7707 20 18.3636 20.407 18.3636 20.9091C18.3636 21.4112 18.7707 21.8182 19.2727 21.8182Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 3H5.63636L8.07273 15.1727C8.15586 15.5913 8.38355 15.9672 8.71595 16.2348C9.04835 16.5024 9.46427 16.6445 9.89091 16.6364H18.7273C19.1539 16.6445 19.5698 16.5024 19.9022 16.2348C20.2346 15.9672 20.4623 15.5913 20.5455 15.1727L22 7.54545H6.54545"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Store24 = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.999 8C21.9991 7.81852 21.9497 7.64045 21.856 7.485L19.147 2.97C18.9686 2.67484 18.7171 2.43057 18.417 2.26069C18.1168 2.09081 17.7779 2.00104 17.433 2H6.56502C5.86702 2 5.21002 2.372 4.85102 2.971L2.14202 7.485C2.04837 7.64045 1.99893 7.81852 1.99902 8C1.99902 9.005 2.38502 9.914 2.99902 10.618V20C2.99902 20.2652 3.10438 20.5196 3.29192 20.7071C3.47945 20.8946 3.73381 21 3.99902 21H11.999C12.2642 21 12.5186 20.8946 12.7061 20.7071C12.8937 20.5196 12.999 20.2652 12.999 20V15H16.999V20C16.999 20.2652 17.1044 20.5196 17.2919 20.7071C17.4795 20.8946 17.7338 21 17.999 21H19.999C20.2642 21 20.5186 20.8946 20.7061 20.7071C20.8937 20.5196 20.999 20.2652 20.999 20V10.618C21.613 9.914 21.999 9.005 21.999 8ZM19.983 8.251C19.9215 8.73391 19.6861 9.17785 19.321 9.49977C18.9558 9.82169 18.4858 9.99953 17.999 10C16.896 10 15.999 9.103 15.999 8C15.999 7.932 15.974 7.872 15.96 7.808L15.98 7.804L15.219 4H17.433L19.983 8.251ZM10.006 8.065L10.818 4H13.179L13.992 8.065C13.957 9.138 13.079 10 11.999 10C10.919 10 10.041 9.138 10.006 8.065ZM6.56502 4H8.77902L8.01902 7.804L8.03902 7.808C8.02402 7.872 7.99902 7.932 7.99902 8C7.99902 9.103 7.10202 10 5.99902 10C5.51221 9.99953 5.04226 9.82169 4.67708 9.49977C4.31191 9.17785 4.07654 8.73391 4.01502 8.251L6.56502 4ZM9.99902 16H5.99902V13H9.99902V16Z"
      fill={color}
    />
  </svg>
);

const Twitter = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9442 7.98749C19.9569 8.16248 19.9569 8.33751 19.9569 8.5125C19.9569 13.85 15.8326 20 8.29444 20C5.97209
      20 3.81473 19.3375 2 18.1875C2.32996 18.225 2.64719 18.2375 2.98985 18.2375C4.90607 18.2375 6.67006 17.6 8.0787
      16.5125C6.27666 16.475 4.76649 15.3125 4.24618 13.7125C4.5 13.75 4.7538 13.775 5.02032 13.775C5.38833 13.775
      5.75638 13.725 6.099 13.6375C4.22083 13.2625 2.81215 11.6375 2.81215 9.67499V9.62501C3.35782 9.92501 3.99239
      10.1125 4.66493 10.1375C3.56087 9.41247 2.83754 8.17498 2.83754 6.77497C2.83754 6.02499 3.04055 5.33749 3.3959
      4.73748C5.41369 7.18748 8.4467 8.78745 11.8477 8.96248C11.7843 8.66248 11.7462 8.35001 11.7462 8.03751C11.7462
      5.81248 13.5736 4 15.8452 4C17.0254 4 18.0914 4.4875 18.8401 5.275C19.7665 5.10001 20.6548 4.76249 21.4416
      4.3C21.137 5.23752 20.4898 6.02502 19.6396 6.52499C20.4645 6.43753 21.264 6.21248 22 5.90001C21.4417 6.69998
      20.7437 7.41245 19.9442 7.98749Z"
      fill={color}
    />
  </svg>
);

const Visibility20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C13.1993 4 15.7551 5.78386 17.5962 9.21474L17.7668 9.54121L18 10L17.7668 10.4588C15.9142 14.1029 13.2993 16 10 16C6.80071 16 4.24492 14.2161 2.40375 10.7853L2.23324 10.4588L2 10L2.23324 9.54121C4.08581 5.89709 6.70074 4 10 4ZM10 6C7.73671 6 5.89057 7.1877 4.4104 9.72318L4.25321 10L4.4104 10.2768C5.89057 12.8123 7.73671 14 10 14C12.3441 14 14.2408 12.7259 15.7468 10C14.2408 7.27405 12.3441 6 10 6ZM10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8Z"
      fill={color}
    />
  </svg>
);
const VisibilityOff20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.63603 4.56524L8.62204 8.55043C8.98075 8.20934 9.46593 8 10 8C11.1046 8 12 8.89543 12 10C12 10.5341 11.7907 11.0193 11.4496 11.378L15.364 15.2929C15.7244 15.6534 15.7522 16.2206 15.4472 16.6129L15.364 16.7071C14.9734 17.0976 14.3403 17.0976 13.9498 16.7071L12.7459 15.506C11.8918 15.834 10.9758 16 10 16C6.80071 16 4.24492 14.2161 2.40375 10.7853L2.23324 10.4588L2 10L2.23324 9.54121C2.77328 8.47891 3.37811 7.56507 4.04579 6.80341L3.22183 5.97944C2.83131 5.58893 2.8313 4.95578 3.22181 4.56526C3.61234 4.17473 4.2455 4.17472 4.63603 4.56524ZM4.43739 9.67717L4.25321 10L4.4104 10.2768C5.89057 12.8123 7.73671 14 10 14C10.3905 14 10.7685 13.9647 11.1344 13.8931L5.46177 8.2192C5.10253 8.64221 4.76124 9.12764 4.43739 9.67717ZM10 4C13.1993 4 15.7551 5.78386 17.5962 9.21474L17.7668 9.54121L18 10L17.7668 10.4588C17.2446 11.4859 16.6619 12.3742 16.0203 13.1204L14.6027 11.704C15.0067 11.2169 15.3879 10.6497 15.7468 10C14.2408 7.27405 12.3441 6 10 6C9.65186 6 9.31358 6.0281 8.98499 6.08489L7.35624 4.45566C8.1811 4.15303 9.06295 4 10 4Z"
      fill={color}
    />
  </svg>
);

const YourLocation20 = (color: string, className?: string) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8 2V3.6552C12.2079 3.83468 13.5163 4.47659 14.5198 5.48016C15.5234 6.48373 16.1653 7.79214 16.3448 9.2H18V10.8H16.3448C16.1654 12.2079 15.5236 13.5164 14.52 14.52C13.5164 15.5236 12.2079 16.1654 10.8 16.3448V18H9.2V16.3448C7.79214 16.1653 6.48373 15.5234 5.48016 14.5198C4.47659 13.5163 3.83468 12.2079 3.6552 10.8H2V9.2H3.6552C3.83495 7.79224 4.47694 6.48397 5.48046 5.48046C6.48397 4.47694 7.79224 3.83495 9.2 3.6552V2H10.8ZM5.2 10C5.2 12.6472 7.3528 14.8 10 14.8C12.6472 14.8 14.8 12.6472 14.8 10C14.8 7.3528 12.6472 5.2 10 5.2C7.3528 5.2 5.2 7.3528 5.2 10ZM13.2 10C13.2 11.7673 11.7673 13.2 10 13.2C8.23269 13.2 6.8 11.7673 6.8 10C6.8 8.23269 8.23269 6.8 10 6.8C11.7673 6.8 13.2 8.23269 13.2 10Z"
      fill={color}
    />
  </svg>
);

const YouTube = (color: string, className?: string) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5057 3.22746C23.2289 2.19946 22.4184 1.38892 21.3904 1.11215C19.5123 0.598145 11.9999 0.598145 11.9999 0.598145C11.9999 0.598145 4.48763 0.598145 2.60954 1.09238C1.60131 1.36915 0.771002 2.19946 0.494232 3.22746C0 5.10554 0 9.00009 0 9.00009C0 9.00009 0 12.9144 0.494232 14.7727C0.771002 15.8007 1.58154 16.6113 2.60954 16.888C4.50739 17.402 11.9999 17.402 11.9999 17.402C11.9999 17.402 19.5123 17.402 21.3904 16.9078C22.4184 16.631 23.2289 15.8205 23.5057 14.7925C23.9999 12.9144 23.9999 9.01986 23.9999 9.01986C23.9999 9.01986 24.0197 5.10554 23.5057 3.22746Z"
      fill={color}
    />
    <path d="M9.60791 5.4021V12.5981L15.855 9.00011L9.60791 5.4021Z" fill="black" />
  </svg>
);

interface Props extends Stylable {
  icon: IconType;
  color?: string;
}

/// Maps the enum Icon to its matching component
export const Icon = ({ icon, color, className }: Props) => {
  const iconColor = color ? color : Theme.colors.primary100;

  switch (icon) {
    case IconType.arrowDown12:
      return ArrowDown12(iconColor, className);
    case IconType.arrowLeft20:
      return ArrowLeft20(iconColor, className);
    case IconType.arrowUp12:
      return ArrowUp12(iconColor, className);
    case IconType.car24:
      return Car24(iconColor, className);
    case IconType.cartEmpty:
      return CartEmpty(color ?? Theme.colors.secondary100, className);
    case IconType.check12:
      return Check12(iconColor, className);
    case IconType.check20:
      return Check20(iconColor, className);
    case IconType.checkCircle20:
      return CheckCircle20(iconColor, className);
    case IconType.chevronDown12:
      return ChevronDown12(iconColor, className);
    case IconType.chevronDown16:
      return ChevronDown16(iconColor, className);
    case IconType.chevronRight16:
      return ChevronRight16(iconColor, className);
    case IconType.chevronRight12:
      return ChevronRight12(iconColor, className);
    case IconType.chevronRight20:
      return ChevronRight20(iconColor, className);
    case IconType.close12:
      return Close12(iconColor, className);
    case IconType.close20:
      return Close20(iconColor, className);
    case IconType.close24:
      return Close24(iconColor, className);
    case IconType.closeCircle24:
      return CloseCircle24(iconColor, className);
    case IconType.delete20:
      return Delete20(iconColor, className);
    case IconType.delivery24:
      return Delivery24(iconColor, className);
    case IconType.Facebook:
      return Facebook(iconColor, className);
    case IconType.filter24:
      return Filter24(iconColor, className);
    case IconType.info16:
      return Info16(iconColor, className);
    case IconType.Instagram:
      return Instagram(iconColor, className);
    case IconType.loading16:
      return Loading16(iconColor, className);
    case IconType.loading20:
      return Loading20(iconColor, className);
    case IconType.loading24:
      return Loading24(iconColor, className);
    case IconType.location24:
      return Location24(iconColor, className);
    case IconType.menu24:
      return Menu24(iconColor, className);
    case IconType.minus12:
      return Minus12(iconColor, className);
    case IconType.placeholder20:
      return Placeholder20(iconColor, className);
    case IconType.plus12:
      return Plus12(iconColor, className);
    case IconType.phone20:
      return Phone20(iconColor, className);
    case IconType.rewardCircle:
      return RewardCircle(iconColor, className);
    case IconType.rewardStamp:
      return RewardStamp(iconColor, className);
    case IconType.shoppingCart24:
      return ShoppingCart24(iconColor, className);
    case IconType.store24:
      return Store24(iconColor, className);
    case IconType.Twitter:
      return Twitter(iconColor, className);
    case IconType.visibility20:
      return Visibility20(iconColor, className);
    case IconType.visibilityOff20:
      return VisibilityOff20(iconColor, className);
    case IconType.yourLocation20:
      return YourLocation20(iconColor, className);
    case IconType.YouTube:
      return YouTube(iconColor, className);
  }
};
