import { displayNameForOrderType, PickupType } from "models/order/OrderType";
import { Store } from "models/store/Store";
import { useMemo } from "react";
import {
  getEnabledPickupTypes,
  getHoursForPickupType,
} from "ui/components/PickupTypeSelector/PickupTypeSelector.helpers";

export type PickupTypeSelectorViewModelProps = {
  store: Store;
  timeWanted: string;
};

type PickupTypeOption = {
  label: string;
  value: PickupType;
  hours?: string;
};

const usePickupTypeSelectorViewModel = ({ store, timeWanted }: PickupTypeSelectorViewModelProps) => {
  const options: PickupTypeOption[] = useMemo(() => {
    const enabledPickupTypes = getEnabledPickupTypes(store);

    return enabledPickupTypes
      .map((pickupType) => ({
        hours: getHoursForPickupType(pickupType, store.specialtyHours, timeWanted),
        label: displayNameForOrderType(pickupType),
        value: pickupType,
      }))
      .filter(({ hours }) => !!hours); // filter out pickup type options that don't have hours for the given order day
  }, [store, timeWanted]);

  return { options };
};

export default usePickupTypeSelectorViewModel;
