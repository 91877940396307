import { OrderDataTransformer } from "dataTransformers/OrderDataTransformer";
import { observer } from "mobx-react-lite";
import { createContext, ReactNode } from "react";
import { OrderStorable } from "stores/order/OrderStorable";
import OrderStore from "stores/order/OrderStore";
import RootStore from "stores/RootStore";

export const OrderContext = createContext<OrderStorable | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

const OrderProvider = observer(({ children }: StoreProviderProps) => {
  const store: OrderStorable = new OrderStore(new OrderDataTransformer(RootStore.networker, RootStore.tokenManager));

  return <OrderContext.Provider value={store}>{children}</OrderContext.Provider>;
});

export default OrderProvider;
