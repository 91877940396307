import MuiStyles from "ui/util/Styles";

const styles = {
  wrap: {
    alignSelf: "center",
    display: "flex",
    height: "60vh",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    margin: "auto",
    background: "transparent",
    display: "block",
    shapeRendering: "auto",
  },
  circle: {},
} as const satisfies MuiStyles;

export default styles;
