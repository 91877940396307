import _isBoolean from "lodash/isBoolean";
import _isNumber from "lodash/isNumber";
import { isStore, Store } from "models/store/Store";

export type SimpleSavedLocation = {
  id: number;
  isDefault: boolean;
};

export const isSimpleSavedLocation = (object: unknown): object is SimpleSavedLocation => {
  const savedLocation = object as SimpleSavedLocation;
  return !!savedLocation && _isNumber(savedLocation.id) && _isBoolean(savedLocation.isDefault);
};

type SavedLocation = {
  id: number;
  isDefault: boolean;
  store: Store;
};

export const isSavedLocation = (object: unknown): object is SavedLocation => {
  const savedLocation = object as SavedLocation;
  return (
    !!savedLocation &&
    _isNumber(savedLocation.id) &&
    _isBoolean(savedLocation.isDefault) &&
    isStore(savedLocation.store)
  );
};

export const isSavedLocationArray = (object: unknown): object is SavedLocation[] => {
  const savedLocations = object as SavedLocation[];
  return (
    Array.isArray(savedLocations) &&
    savedLocations.length === savedLocations.filter((location) => isSavedLocation(location)).length
  );
};

export default SavedLocation;
