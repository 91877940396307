import styled, { css } from "styled-components";
import { Sizable, Size } from "ui/Size";
import { Theme } from "ui/Theme";

/** Default color is set to gray100 but nothing else should be overridden */

export const Display = styled.span<Sizable>`
  color: ${Theme.colors.primary150};

  ${(props) =>
    props.size === Size.small &&
    css`
      font-size: 2rem;
      line-height: 2.5rem;
    `}

  ${(props) =>
    props.size === Size.medium &&
    css`
      font-size: 2.5rem;
      line-height: 3rem;
    `}

  ${(props) =>
    props.size === Size.large &&
    css`
      font-size: 3rem;
      line-height: 3rem;
    `}
`;

export const BodySmallCSS = css`
  color: ${Theme.colors.gray100};
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
`;

export const BodySmallBoldCSS = css`
  ${BodySmallCSS};
`;

export const BodyLargeCSS = css`
  color: ${Theme.colors.gray100};
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const BodyLargeBoldCSS = css`
  ${BodyLargeCSS};
  font-weight: bold;
`;

export const BodyMediumCSS = css`
  color: ${Theme.colors.gray100};
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const BodyMediumBoldCSS = css`
  ${BodyMediumCSS};
`;

export type BodyCopyProps = { id?: string; size?: Size; bold?: boolean };

export const BodyCopyCSS = css<BodyCopyProps>`
  ${(props) => {
    if (props.size === Size.large && props.bold) return BodyLargeBoldCSS;
    if (props.size === Size.large && !props.bold) return BodyLargeCSS;
    if (props.size === Size.small && props.bold) return BodySmallBoldCSS;
    if (props.size === Size.small && !props.bold) return BodySmallCSS;
    if (props.bold) return BodyMediumBoldCSS;
    return BodyMediumCSS;
  }};
`;

/** Defaults to medium and not bold */
export const BodyCopy = styled.span<BodyCopyProps>`
  ${BodyCopyCSS};
`;

export const Subtitle = styled.span`
  color: ${Theme.colors.gray100};
  font-size: 1.25rem;
  line-height: 2rem;
`;

export const Overline = styled.span`
  color: ${Theme.colors.gray100};
  font-size: 0.75rem;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
`;

/** Specific to button text, not defined in Typography */
export const ButtonTextCSS = css<{ size?: Size }>`
  color: ${Theme.colors.gray100};
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${(props) =>
    props.size === Size.small &&
    css`
      font-size: 0.75rem;
      line-height: 1rem;
    `}

  ${(props) =>
    props.size === Size.large &&
    css`
      font-size: 1rem;
      line-height: 1.5rem;
    `}
`;
