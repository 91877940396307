import { ErrorType, MenuError, OrderError, StoreError, UserAccountError } from "errors/Errors";
import { DateTime } from "luxon";
import { GroupOrderPaymentType } from "models/groupOrder";
import { OrderType } from "models/order/OrderType";
import { isProductItem, ProductItem } from "models/ProductItem";
import AchievementBadge from "models/rewards/AchievementBadge";
import {
  AccountEngagement,
  AnalyticsError,
  AnalyticsErrorCategory,
  Authentication,
  AuthenticationSteps,
  BannerBaseline,
  BannerKey,
  BannerType,
  EventItemParams,
  EventParams,
  Location,
  Modal,
  ModalType,
  Modifier,
  UserIdEventParams,
} from "util/GoogleAnalytics";
import { createBannerAnalytics } from "util/GoogleAnalytics/GoogleAnalytics.helpers";
import { v4 as uuidv4 } from "uuid";

const errorTypeToAnalyticsErrorCategory = (errorType: ErrorType): AnalyticsErrorCategory => {
  switch (errorType) {
    case OrderError.AddFave:
    case OrderError.AddItem:
    case OrderError.AddMultipleItems:
    case OrderError.AddUpsell:
    case OrderError.CreateOrder:
    case OrderError.FetchOrder:
    case OrderError.ModifyItem:
    case OrderError.ModifyOrderType:
    case OrderError.RemoveItem:
    case OrderError.RepeatOrder:
    case OrderError.ValidateOrder:
      return "Bag Error";

    case OrderError.CancelOrder:
      return "Cancel Order Error";

    case OrderError.CheckIn:
      return "Check In Error";

    case OrderError.ApplyCoupon:
    case OrderError.RedeemReward:
    case OrderError.RemoveCoupon:
    case OrderError.RemoveReward:
      return "Checkout Error - Discount";

    case OrderError.SubmitOrder:
      return "Checkout Error - Payment";

    case UserAccountError.ChangePassword:
    case UserAccountError.ChangeEmail:
    case UserAccountError.CreateFave:
    case UserAccountError.DeleteAccount:
    case UserAccountError.FetchFaves:
    case UserAccountError.FetchRecentOrders:
    case UserAccountError.FetchRewards:
    case UserAccountError.FetchSavedDeliveryAddresses:
    case UserAccountError.FetchSavedLocations:
    case UserAccountError.FetchSavedPayments:
    case UserAccountError.FetchUser:
    case UserAccountError.RemoveFave:
    case UserAccountError.RemoveSavedPayment:
    case UserAccountError.SavePaymentMethod:
    case UserAccountError.SetDefaultDeliveryAddress:
    case UserAccountError.SetDefaultLocation:
      return "Profile Error";

    case UserAccountError.LogIn:
      return "Sign Up Error";

    case UserAccountError.CreateAccount:
      return "Sign Up Error";

    case StoreError.FetchOrderAheadTimes:
    case StoreError.SearchStores:
      return "Store Error";

    case MenuError.General:
    case OrderError.ApplyTip:
    case UserAccountError.LogOut:
    default:
      return "General Error";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dataLayer: any[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mockDataLayer: any[] = [];

const getDataLayer = () => (typeof dataLayer === "undefined" ? mockDataLayer : dataLayer);

/**
 * Google Analytics is set up in the public/index.html with the key being injected
 * from the theming server
 *
 * Be sure to update the README when new events are added
 */
class GoogleAnalytics {
  protected readonly dataLayer;

  constructor(dataLayer = getDataLayer()) {
    this.dataLayer = dataLayer;
  }

  linkClick(linkText: string, linkType: "Cart" | "Global Footer" | "Global Header" | "PDP") {
    const eventParams = {
      link_text: linkText,
      link_type: linkType,
    };

    this.dataLayer.push({ event: "link_click", ...eventParams });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
   */
  viewItemList(listName: string, index: number, productItems: (ProductItem | EventItemParams)[]) {
    const items = productItems.map((item, index) => {
      const isProduct = isProductItem(item);

      const price = isProduct && item.dynamicData.price ? Number(item.dynamicData.price.replace(/[^0-9.-]+/g, "")) : 0;

      const isCatering =
        (item as EventItemParams).is_catering === "Yes"
          ? "Yes"
          : isProduct && "categories" in item && item.categories?.[0].menuType === "catering"
          ? "Yes"
          : "No";

      const itemObject: EventItemParams = {
        item_id: isProduct ? item.id : item.item_id,
        item_name: isProduct ? item.name : item.item_name,
        item_category: isProduct && "categories" in item ? item.categories?.[0].name : listName,
        index: index + 1,
        item_list_name: listName,
        price: price ? price.toString() : "0",
        is_catering: isCatering,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      item_list_name: listName,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "view_item_list", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
   */
  selectItem(listName: string, productItem: ProductItem | EventItemParams, productItemIndex: number) {
    const items = [productItem].map((item) => {
      const isProduct = isProductItem(item);

      const price = isProduct && item.dynamicData.price ? Number(item.dynamicData.price.replace(/[^0-9.-]+/g, "")) : 0;

      const isCatering =
        (productItem as EventItemParams).is_catering === "Yes"
          ? "Yes"
          : isProduct && "categories" in productItem && productItem.categories?.[0].menuType === "catering"
          ? "Yes"
          : "No";

      const itemObject: EventItemParams = {
        item_id: isProduct ? item.id : item.item_id,
        item_name: isProduct ? item.name : item.item_name,
        index: productItemIndex ? productItemIndex + 1 : 1,
        item_category: isProduct && "categories" in productItem ? productItem.categories?.[0].name : listName,
        item_list_name: listName,
        is_catering: isCatering,
        price: price?.toString(),
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      item_list_name: listName,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "select_item", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
   */
  viewItem(productItems: EventItemParams[], storeName?: string, orderType?: OrderType) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      if (price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        discount: 0,
        index: item.index ? item.index + 1 : undefined,
        item_category2: item.item_category2,
        item_category: item.item_category,
        item_list_name: item.item_list_name,
        is_catering: item.is_catering,
        item_variant: item.item_variant,
        price: price?.toString(),
      };

      const filteredValues = this.filterEmptyValues(itemObject);

      return filteredValues;
    });

    const gaEventParams = {
      item_list_name: productItems[0].item_list_name,
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      handoff_type: orderType,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "view_item", ecommerce: { ...gaEventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart
   */
  addToCart(
    productItems: EventItemParams[],
    quantity: number,
    storeName: string,
    orderType: OrderType,
    listName?: string
  ) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      if (price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        currency: price != null ? "USD" : undefined,
        discount: 0,
        index: item.index ? item.index + 1 : undefined,
        item_category2: item.item_category2,
        item_category: item.item_category,
        item_list_name: item.item_list_name,
        item_variant: item.item_variant,
        price: price?.toString(),
        is_catering: item.is_catering,
        quantity,
      };

      const filteredValues = this.filterEmptyValues(itemObject);

      return filteredValues;
    });

    const gaEventParams = {
      item_list_name: listName ?? productItems[0].item_list_name,
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      handoff_type: orderType,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "add_to_cart", ecommerce: { ...gaEventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart
   */
  viewCart(productItems: EventItemParams[], storeName: string, orderType: OrderType) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : 0;

      if (item.price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        price: price?.toString(),
        currency: item.price ? "USD" : undefined,
        discount: 0,
        quantity: item.quantity,
        is_catering: item.is_catering,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      handoff_type: orderType,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "view_cart", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#remove_from_cart
   */
  removeFromCart(productItems: EventItemParams[], storeName: string, orderType: OrderType) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : 0;

      if (item.price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        currency: item.price ? "USD" : undefined,
        discount: 0,
        index: item.index ? item.index + 1 : undefined,
        item_category2: item.item_category2,
        item_category: item.item_category,
        item_list_name: item.item_list_name,
        item_variant: item.item_variant,
        location_id: storeName,
        is_catering: item.is_catering,
        handoff_type: item.handoff_type,
        price: price ? price.toString() : "0",
        quantity: item.quantity,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      item_list_name: productItems[0].item_list_name,
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      handoff_type: orderType,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "remove_from_cart", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
   */
  beginCheckout(productItems: EventItemParams[], storeName: string, orderType: OrderType) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      if (price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        currency: price != null ? "USD" : undefined,
        discount: 0,
        index: item.index ? item.index + 1 : undefined,
        item_category2: item.item_category2,
        item_category: item.item_category,
        item_list_name: item.item_list_name,
        item_variant: item.item_variant,
        location_id: storeName,
        price: price?.toString(),
        is_catering: item.is_catering,
        quantity: item.quantity,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      handoff_type: orderType,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "begin_checkout", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_shipping_info
   */
  addShippingInfo(shippingTier: string, productItems: EventItemParams[], storeName: string) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      if (price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      shipping_tier: shippingTier,
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "add_shipping_info", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
   */
  addPaymentInfo(paymentMethods: string[], paymentTypes: string[], productItems: EventItemParams[], storeName: string) {
    let totalPrice = 0;

    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      if (price != null) {
        totalPrice += price;
      }

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      value: totalPrice ? totalPrice.toString() : "0",
      currency: "USD",
      location_id: storeName,
      payment_method: paymentMethods.join(),
      payment_type: paymentTypes.join(),
      items: items,
    };

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "add_payment_info", ecommerce: { ...eventParams } });
  }

  /**
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
   */
  purchase(
    total: string,
    tax: string,
    productItems: EventItemParams[],
    storeName: string,
    orderType: OrderType,
    orderId: string,
    isGroupOrder: boolean,
    orderTip?: string,
    orderCoupon?: string,
    orderDeliveryFee?: string
  ) {
    const items = productItems.map((item) => {
      const price = item.price ? Number(item.price.replace(/[^0-9.-]+/g, "")) : undefined;

      const itemObject: EventItemParams = {
        item_id: item.item_id,
        item_name: item.item_name,
        currency: price != null ? "USD" : undefined,
        discount: 0,
        index: item.index ? item.index + 1 : undefined,
        item_category2: item.item_category2,
        item_category: item.item_category,
        item_list_name: item.item_list_name,
        item_variant: item.item_variant,
        location_id: storeName,
        price: price?.toString(),
        is_catering: item.is_catering,
        quantity: item.quantity,
      };

      return this.filterEmptyValues(itemObject);
    });

    const eventParams: EventParams = {
      value: total ? total.replace(/[^0-9.-]+/g, "") : "0",
      tax: tax ? tax.replace(/[^0-9.-]+/g, "") : "0",
      currency: "USD",
      location_id: storeName,
      tip: orderTip ? orderTip.replace(/[^0-9.-]+/g, "") : "0",
      coupon: orderCoupon ?? "0",
      handoff_type: orderType,
      transaction_id: orderId,
      items: items,
      group_order: isGroupOrder ? true : undefined,
    };

    if (orderType === OrderType.delivery) {
      eventParams.shipping = orderDeliveryFee ? orderDeliveryFee.replace(/[^0-9.-]+/g, "") : "0";
    }

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({ event: "purchase", ecommerce: { ...eventParams } });
  }

  filterEmptyValues = (obj: { [key: string]: unknown }): { [key: string]: unknown } => {
    return Object.fromEntries(Object.entries(obj).filter(([, value]) => value != null && value !== ""));
  };

  /**
   * JJ Modify Requirements
   *
   */

  modifier(
    modifierSectionTitle: string,
    modifierText: string | string[],
    modifierItemName: string,
    modifierUpcharge?: string
  ) {
    const eventParams: Modifier = {
      modify_engagement: modifierSectionTitle,
      modify_value: modifierText,
      modify_item_name: modifierItemName,
      price: modifierUpcharge || "0",
    };

    this.dataLayer.push({ event: "modify", ...eventParams });
  }

  /**
   * JJ Authentication Requirements
   * https://docs.google.com/document/d/1e44tz4Ede0dQueGCqq_dhFJmmVCHfYjA/edit?usp=sharing&ouid=104213064482839101040&rtpof=true&sd=true
   */

  signIn(signInStep: AuthenticationSteps) {
    const eventParams: Authentication = {
      account_engagement: "Sign In",
      account_sign_in_step: signInStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  createAccount(creationStep: AuthenticationSteps) {
    const eventParams: Authentication = {
      account_engagement: "Create Account",
      account_creation_step: creationStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  /**
   * JJ User Account Requirements
   * https://docs.google.com/document/d/1e44tz4Ede0dQueGCqq_dhFJmmVCHfYjA/edit?usp=sharing&ouid=104213064482839101040&rtpof=true&sd=true
   */

  updatePassword(passwordUpdateStep: AccountEngagement) {
    const eventParams: Authentication = {
      account_engagement: passwordUpdateStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  updateProfile(UpdateStep: AccountEngagement) {
    const eventParams: Authentication = {
      account_engagement: UpdateStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  deleteAccount(deleteStep: AccountEngagement) {
    const eventParams: Authentication = {
      account_engagement: deleteStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  repeatOrder(recentOrderId: string, page: "account" | "home") {
    this.dataLayer.push({
      event: "order",
      order_interaction: "Reorder",
      order_freeform_text: `Reorder From ${page}`,
      order_id: recentOrderId,
    });
  }

  orderCheckIn(checkInStep: AccountEngagement) {
    const eventParams: Authentication = {
      account_engagement: checkInStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  userFavs(userFavStep: AccountEngagement) {
    const eventParams: Authentication = {
      account_engagement: userFavStep,
    };

    this.dataLayer.push({ event: "account", ...eventParams });
  }

  /**
   * Rewards
   */

  clickReward({
    buttonText,
    offerName,
    placement,
  }: {
    buttonText: string;
    offerName: string;
    placement: "Rewards Page" | "Checkout Page";
  }) {
    const eventParams = {
      rewards_engagement: buttonText,
      reward_placement: placement,
      offer_name: offerName,
    };

    this.dataLayer.push({ event: "rewards", ...eventParams });
  }

  upsellImpression(productName: string) {
    const eventParams = {
      cross_sell_engagement: "Impression",
      cross_sell_item_name: productName,
    };

    this.dataLayer.push({ event: "cross_sell", ...eventParams });
  }

  upsellScroll() {
    this.dataLayer.push({ event: "cross_sell", ...{ cross_sell_engagement: "Scroll" } });
  }

  upsellAdd(productName: string, placement = "bag") {
    const eventParams = {
      cross_sell_engagement: "Add To Bag",
      cross_sell_placement: placement,
      cross_sell_item_name: productName,
    };

    this.dataLayer.push({ event: "cross_sell", ...eventParams });
  }

  readFaq(group: string, title: string) {
    const eventParams = {
      faq_engagement: "Read",
      faq_group: group,
      faq_title: title,
    };

    this.dataLayer.push({ event: "faqs", ...eventParams });
  }

  /*
   * JJ Modal Tracking Requirements
   * https://docs.google.com/document/d/1jmzy-M5OET2u2M96gnOWTmDa-YQMXpQn/edit?usp=sharing&ouid=104213064482839101040&rtpof=true&sd=true
   */

  modalImpression(modalType: ModalType, modalName: string) {
    const eventParams: Modal = {
      modal_interaction: "Impression",
      modal_name: modalName,
      modal_type: modalType, //usually either "Information" or "Promotion"
    };

    this.dataLayer.push({ event: "modal", ...eventParams });
  }

  modalCTA(linkText: string, modalName: string) {
    const eventParams: Modal = {
      modal_interaction: "CTA Click",
      modal_name: modalName,
      link_text: linkText, //in the case of clicking on the "X", use "Close"
    };

    this.dataLayer.push({ event: "modal", ...eventParams });
  }

  /**
   * JJ Location Tracking Requirements
   * https://docs.google.com/document/d/1vtPOiLPAqUrRTIZGZ3Gnj37E2BVAfGswX_TFBZu_R1w/edit?usp=sharing
   */

  locationCTA(locationCTA: string) {
    const eventParams: Location = {
      location_engagement: "Location CTA",
      location_cta: locationCTA,
    };

    this.dataLayer.push({ event: "location", ...eventParams });
  }

  locationSearch(locationSearchTerm: string, locationSearchFilter: string[]) {
    const eventParams: Location = {
      location_engagement: "Location Search",
      location_search_term: locationSearchTerm,
      location_search_filter: locationSearchFilter,
    };

    this.dataLayer.push({ event: "location", ...eventParams });
  }

  locationSelection(storeName: string, locationStoreAddress: string) {
    const eventParams: Location = {
      location_engagement: "Location Selection",
      location_id: storeName,
      location_store_address: locationStoreAddress,
    };

    this.dataLayer.push({ event: "location", ...eventParams });
  }

  /**
   * JJ Banner Tracking Requirements
   * https://docs.google.com/document/d/1KJyzczWWw1eUJwATyyPgY2rndRO8j2TC/edit?usp=sharing&ouid=104213064482839101040&rtpof=true&sd=true
   */

  bannerImpression(bannerName: string, bannerType: BannerType, bannerKey: BannerKey = "banner") {
    const baselineBannerEventParams: BannerBaseline = {
      banner_type: bannerType,
    };
    const eventParams = {
      ...createBannerAnalytics(bannerKey, "Impression", bannerName),
      ...baselineBannerEventParams,
    };

    this.dataLayer.push({ event: bannerKey, ...eventParams });
  }

  bannerCTA(bannerName: string, bannerLinkText: string, bannerKey: BannerKey = "banner") {
    const baselineBannerEventParams: BannerBaseline = {
      link_text: bannerLinkText,
    };
    const eventParams = {
      ...createBannerAnalytics(bannerKey, "CTA Click", bannerName),
      ...baselineBannerEventParams,
    };

    this.dataLayer.push({ event: bannerKey, ...eventParams });
  }

  /**
   * JJs Error Tracking Requirements
   * https://docs.google.com/document/d/19EPupxDuiLHj4fJ3-AgUEPG37cXUXYOc11r2sdul5sc/edit?usp=sharing
   */

  error(errorType: ErrorType, user_error_description = "", vendor_error_description = "") {
    const eventParams: AnalyticsError = {
      error_category: errorTypeToAnalyticsErrorCategory(errorType),
      error_description: user_error_description,
      error_description_vendor: vendor_error_description,
    };

    this.dataLayer.push({ event: "errors", ...eventParams });
  }

  /** Group Ordering */

  startGroupOrder(groupOrderPaymentType: GroupOrderPaymentType, participantCount: number, timeWanted: string) {
    const group_order_pay = groupOrderPaymentType === "host" ? "Host" : "Invitee";

    // "Today" or number of days ahead as string
    const group_order_pickup_time = (() => {
      const now = DateTime.now();
      const today = now.toLocaleString(DateTime.DATE_SHORT);
      const dateTimeWanted = DateTime.fromISO(timeWanted);
      const dateWanted = dateTimeWanted.toLocaleString(DateTime.DATE_SHORT);

      if (dateWanted === today) return "Today";

      const numberOfDaysAhead = now.until(dateTimeWanted).count("days").toString();

      return numberOfDaysAhead;
    })();

    this.dataLayer.push({
      event: "group_order",
      group_order_pickup_time,
      group_order_pay,
      group_order_invitees: participantCount, // Number of invitees as integer
    });
  }

  /**
   * Sets the user ID/state for the session.
   * @param userId The logged-in user's ID or undefined. If undefined, a random UUID will be set as the user ID.
   */
  setUser(userId?: string) {
    const eventParams: UserIdEventParams = {
      userID: userId ?? uuidv4(),
      isLoggedIn: !!userId,
      userAgent: window.navigator.userAgent,
    };

    this.dataLayer.push({ event: "userId", ...eventParams });
  }

  /** Achievement Badge Modal */

  achievementBadgeClick(badge: AchievementBadge) {
    this.dataLayer.push({
      event: "badge",
      badge_placement: "Rewards",
      badge_interaction: "Badge Selection",
      badge_name: badge.name,
      badge_type: badge.status === "ACHIEVED" ? "Achieved" : "Available",
    });
  }

  achievementBadgeModalCTA(badge: AchievementBadge, link_text: string) {
    this.dataLayer.push({
      event: "badge",
      badge_placement: "Modal",
      badge_interaction: "Impression CTA",
      badge_name: badge.name,
      badge_type: badge.status === "ACHIEVED" ? "Achieved" : "Available",
      link_text,
    });
  }

  achievementBadgeModalImpression(badge: AchievementBadge) {
    this.dataLayer.push({
      event: "badge",
      badge_placement: "Modal",
      badge_interaction: "Impression",
      badge_name: badge.name,
      badge_type: badge.status === "ACHIEVED" ? "Achieved" : "Available",
    });
  }

  viewRecentOrder(recentOrderId: string) {
    this.dataLayer.push({
      event: "order",
      order_interaction: "View Previous Order",
      order_id: recentOrderId,
    });
  }

  cancelOrder(orderId: string) {
    dataLayer.push({
      event: "order",
      order_interaction: "Cancel Order",
      order_id: orderId,
    });
  }
}

export default GoogleAnalytics;
