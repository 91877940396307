import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { lazy } from "react";
import styles from "ui/components/Error/FullScreenError.styles";
import { MainPagePath } from "ui/navigation/Pages";

type FullScreenErrorProps = {
  title: string;
  subtitle: string;
  description: string;
  buttonText?: string;
  loading?: boolean;
  onClick?: () => void;
};

const returnToHome = () => {
  /** A pure JS way of returning to home and replacing the erroneous page URL */
  window.location.replace(MainPagePath.home);
};

const FlyingSandwich = lazy(async () => ({
  default: (await import("assets/flying-sandwich.svg")).ReactComponent,
}));

const FullScreenError = ({
  title,
  subtitle,
  description,
  buttonText,
  loading = false,
  onClick = returnToHome,
}: FullScreenErrorProps) => (
  <Box sx={styles.container}>
    <Stack sx={styles.layout}>
      <Box component={FlyingSandwich} sx={styles.flyingSandwich} />
      <div aria-label={`${title} ${subtitle}`} role="heading">
        <Typography color="primary.main" variant="h4" component="p">
          {title}
        </Typography>
        <Typography variant="h1">{subtitle}</Typography>
      </div>
      <Divider />
      <Typography color="secondary.main" variant="body1">
        {description}
      </Typography>
      <div>
        <LoadingButton loading={loading} onClick={onClick}>
          {buttonText ?? "Back to Home"}
        </LoadingButton>
      </div>
    </Stack>
  </Box>
);

export default FullScreenError;
