import { BaseDataTransformer } from "dataTransformers/BaseDataTransformer";
import {
  CanDeliverParameters,
  StoreDataTransformable,
  StoreSearchParameters,
} from "dataTransformers/StoreDataTransformable";
import TokenManageable from "dataTransformers/TokenManageable";
import { OrderType } from "models/order/OrderType";
import { CanDeliverResponse } from "models/store/CanDeliver";
import OrderAheadDateTimes, { isOrderAheadDateTimesArray } from "models/store/OrderAheadDateTimes";
import { isCanDeliverResponse, isStoresArray, Store } from "models/store/Store";
import { isStoreDisclaimersResponse, StoreDisclaimerResponse } from "models/store/StoreDisclaimer";
import { StoreNetworking } from "networking/StoreNetworking";
import TokenNetworking from "networking/TokenNetworking";

export class StoreDataTransformer extends BaseDataTransformer implements StoreDataTransformable {
  private storeNetworker: StoreNetworking;

  constructor(networker: StoreNetworking & TokenNetworking, tokenManager: TokenManageable) {
    super(networker, tokenManager);
    this.storeNetworker = networker;
  }

  getOrderAheadTimes(storeId: string, orderType: OrderType): Promise<OrderAheadDateTimes[]> {
    return this.makeNetworkCall(
      () => this.storeNetworker.getOrderAheadTimes(storeId, orderType),
      isOrderAheadDateTimesArray
    );
  }

  getStores(parameters?: StoreSearchParameters): Promise<Store[]> {
    const { searchText, coordinates, radius, filters, canDeliver, deliveryAddress, numberOfResults } = parameters ?? {};

    return this.makeNetworkCall(
      () =>
        this.storeNetworker.getStores(
          searchText,
          coordinates?.latitude,
          coordinates?.longitude,
          radius,
          filters,
          canDeliver,
          deliveryAddress?.addressLineOne,
          deliveryAddress?.city,
          deliveryAddress?.zipcode,
          numberOfResults
        ),
      isStoresArray
    );
  }

  checkCanDeliver({ storeId, address }: CanDeliverParameters): Promise<CanDeliverResponse> {
    return this.makeNetworkCall(() => this.storeNetworker.checkCanDeliver(storeId, address), isCanDeliverResponse);
  }

  getStoreDisclaimers(storeId: string): Promise<StoreDisclaimerResponse> {
    return this.makeNetworkCall(() => this.storeNetworker.getStoreDisclaimers(storeId), isStoreDisclaimersResponse);
  }
}
