import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import useStoreManager from "stores/useStoreManager";
import LogoBox from "ui/app/MinimalLayout/LogoBox";
import styles from "ui/app/MinimalLayout/MinimalLayout.styles";
import StickyAlertsView from "ui/app/StickyAlertsView";

const MinimalLayout = () => {
  const { isLoading } = useStoreManager();

  return (
    <>
      {!isLoading ? (
        <Box sx={styles.container}>
          <StickyAlertsView />
          <LogoBox>
            <Outlet />
          </LogoBox>
        </Box>
      ) : null}
    </>
  );
};

export default MinimalLayout;
