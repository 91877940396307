import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Asset } from "contentful";
import {
  CmsButtonData,
  CmsIconData,
  CmsModalData,
  CmsModalTextLinkData,
  CtaEntry,
  HeroEntry,
  HighlightEntry,
  isCmsButtonData,
  isCmsIconData,
} from "models/cms";
import { PolicyGroupData, PolicyPageData, PolicySectionData, PolicySectionEntry } from "models/cms";
import { FeatureBlockEntry } from "models/cms/ContentTypes/FeatureBlock";
import { MenuItemEntry } from "models/cms/ContentTypes/MenuItem";
import { MenuItemGroupEntry } from "models/cms/ContentTypes/MenuItemGroup";
import { ModalEntry } from "models/cms/ContentTypes/Modal";
import { ModalTextLinkEntry } from "models/cms/ContentTypes/ModalTextLink";
import { PolicyGroupEntry } from "models/cms/ContentTypes/PolicyGroup";
import { PolicyPageEntry } from "models/cms/ContentTypes/PolicyPage";
import { PromoModalEntry } from "models/cms/ContentTypes/PromoModal";
import { isDayOfWeekArray, isHourOfDay, isPlatformArray, PromoModalData } from "models/cms/DataTypes/CmsPromoModalData";
import MetadataKey from "models/MetadataKey";
import { ProductItem } from "models/ProductItem";
import { getProductByVanityMetadata } from "stores/menu";
import { FeatureBlockProps } from "ui/components/FeatureBlock";
import { HighlightProps } from "ui/components/Highlight";
import { TopPicksCardProps } from "ui/components/TopPicksCard/TopPicksCard";
import { HeroContent } from "ui/screens/HomeScreen/Hero";
import { Formatter } from "util/Formatter";

// General

const cmsCtaToButtonData = (cta: CtaEntry | undefined): CmsButtonData | undefined => {
  if (!cta || !cta.fields.href || !cta.fields.labelText) return;
  return {
    href: cta.fields.href,
    id: cta.sys.id,
    labelText: cta.fields.labelText,
  };
};

export const getImageUrlFromAsset = (imageAsset: Asset | undefined) => {
  if (!imageAsset?.fields.file) return;
  return "https:" + imageAsset.fields.file.url;
};

//Modal

const cmsModalTextLinkData = (modalTextLink: ModalTextLinkEntry | undefined): CmsModalTextLinkData | undefined => {
  if (!modalTextLink || !modalTextLink.fields.textLinkLabel || !modalTextLink.fields.modal) return;

  const modalContent = getModalContent(modalTextLink.fields.modal);

  if (!modalContent) return;
  return {
    textLinkLabel: modalTextLink.fields.textLinkLabel,
    modal: modalContent,
  };
};

const getModalContent = (modal: ModalEntry | undefined): CmsModalData | undefined => {
  if (!modal || !modal.fields.modalTitle || !modal.fields.modalContent) return;
  return {
    modalTitle: modal.fields.modalTitle,
    modalContent: modal.fields.modalContent,
  };
};

//Highlights

const highlightEntryToProps = (entry: HighlightEntry): HighlightProps | undefined => {
  const {
    buttonPrimary: cmsButtonPrimary,
    buttonSecondary: cmsButtonSecondary,
    icons: cmsIcons,
    image: imageAsset,
    ...rest
  } = entry.fields;

  const buttonPrimary = cmsCtaToButtonData(cmsButtonPrimary);
  const buttonSecondary = cmsCtaToButtonData(cmsButtonSecondary);
  const image = getImageUrlFromAsset(imageAsset);
  const icons = cmsIcons
    .map((icon) => {
      return {
        image: getImageUrlFromAsset(icon),
        labelText: icon?.fields.title ?? "",
      } as CmsIconData;
    })
    .filter((icon) => isCmsIconData(icon));

  // validate required/expected content is present
  if (!rest.backgroundColor || !rest.heading || !buttonPrimary || !image) return;

  return {
    buttonPrimary,
    buttonSecondary,
    icons,
    image,
    ...rest,
  };
};

//Top Picks

export type TopPicksProps = {
  heading: string;
  topPicks: TopPicksCardProps[];
};

const topPicksToProps = (entry: MenuItemGroupEntry, menuProducts: ProductItem[]): TopPicksProps | undefined => {
  const { groupName: heading, menuItems } = entry.fields;
  const topPickMenuItems = menuItems.filter(Boolean) as MenuItemEntry[];

  if (!heading || topPickMenuItems.length === 0) return;

  const topPickProducts = topPickMenuItems.map((topPickMenuItem) => {
    if (!topPickMenuItem) return;

    const topPickUrlPath = topPickMenuItem.fields.urlPath;
    const matches = topPickUrlPath.match(/\/menu\/([a-z0-9-]+)\/([a-z0-9-]+)/i);

    if (!matches) return;

    const [, categorySlug, productSlug] = matches;
    const product = getProductByVanityMetadata(menuProducts, categorySlug, productSlug);

    if (!product) return;

    return {
      ...product,
      urlPath: topPickUrlPath,
    };
  });

  const topPicks = topPickProducts
    .map((product, index) => {
      if (!product) return;

      const { calorieInfo, dynamicData, id: itemId, images, name, urlPath } = product;
      const calorieMetaData = dynamicData?.metaData?.find(({ name }) => name === MetadataKey.SecondCalRange)?.value;
      const calories = calorieInfo ? Formatter.formatCalories(calorieInfo, calorieMetaData) : null;
      const price = dynamicData?.price !== "$0.00" ? dynamicData?.price : null;

      return {
        calories,
        images,
        index,
        itemId,
        name,
        price,
        product,
        urlPath,
      };
    })
    .filter(Boolean) as TopPicksCardProps[];

  if (topPicks.length === 0) return;

  return {
    heading,
    topPicks,
  };
};
//Feature Blocks

const featureBlockEntryToProps = (entry: FeatureBlockEntry): FeatureBlockProps | undefined => {
  const { button: cmsButton, description, heading, image: imageAsset } = entry.fields;

  const button = cmsCtaToButtonData(cmsButton);
  const image = getImageUrlFromAsset(imageAsset);

  // validate required/expected content is present
  if (!button || !description || !heading || !image) return;

  return { button, description, heading, image };
};

const heroEntryToProps = (entry: HeroEntry): HeroContent | undefined => {
  const {
    buttons: cmsButtons,
    headingLine1,
    headingLine2,
    headingLine3,
    image: imageAsset,
    modalTextLink: textLink,
    ...rest
  } = entry.fields;

  const headingLines: string[] = [];
  if (headingLine1) headingLines.push(headingLine1);
  if (headingLine2) headingLines.push(headingLine2);
  if (headingLine3) headingLines.push(headingLine3);

  const buttons = cmsButtons.map(cmsCtaToButtonData).filter(isCmsButtonData);
  const image = getImageUrlFromAsset(imageAsset);
  const modalTextLink = cmsModalTextLinkData(textLink);

  // validate required/expected content is present
  if (!headingLine1 || buttons.length === 0 || !image) return;

  return {
    ...rest,
    buttons,
    headingLines,
    image,
    modalTextLink,
  };
};

//Policy Pages
const convertPolicyGroupToData = (policyGroup: PolicyGroupEntry): PolicyGroupData => {
  const policySectionsData: PolicySectionData[] =
    policyGroup.fields.policySections?.map((policySection) =>
      policySection ? convertPolicySectionToData(policySection) : { id: "", title: "", content: "" }
    ) || [];
  return {
    id: policyGroup.sys.id,
    title: policyGroup.fields.policyGroupHeading || "",
    policySections: policySectionsData,
  };
};

const convertPolicySectionToData = (policySection: PolicySectionEntry): PolicySectionData => {
  const policySectionContent = documentToReactComponents(policySection.fields.content || []);

  return {
    title: policySection.fields.title || "",
    id: policySection.sys.id,
    content: policySectionContent,
  };
};

const convertPolicyPageToData = (policyPage: PolicyPageEntry): PolicyPageData => {
  const policyGroupsData: PolicyGroupData[] =
    policyPage.fields.policyGroups?.map((policyGroupEntry) =>
      policyGroupEntry ? convertPolicyGroupToData(policyGroupEntry) : { id: "", title: "", policySections: [] }
    ) || [];

  const filteredPolicyGroups = policyGroupsData.filter((group) => group.policySections.length > 0);

  return {
    id: policyPage.sys.id,
    title: policyPage.fields.title || "",
    policyGroups: filteredPolicyGroups,
  };
};

const convertPromoModalToData = (promoModal: PromoModalEntry): PromoModalData => {
  const { campaignEnd, campaignStart, cta, days, image: imageAsset, platforms, startTime, ...rest } = promoModal.fields;

  return {
    ...rest,
    id: promoModal.sys.id,
    image: getImageUrlFromAsset(imageAsset),
    cta: cmsCtaToButtonData(cta),
    campaignStart: new Date(campaignStart),
    campaignEnd: campaignEnd ? new Date(campaignEnd) : undefined,
    platforms: isPlatformArray(platforms) ? platforms : [],
    days: isDayOfWeekArray(days) ? days : [],
    startTime: isHourOfDay(startTime) ? startTime : "12AM",
  };
};

export {
  heroEntryToProps,
  highlightEntryToProps,
  topPicksToProps,
  cmsCtaToButtonData,
  featureBlockEntryToProps,
  cmsModalTextLinkData,
  convertPolicyGroupToData,
  convertPolicyPageToData,
  convertPolicySectionToData,
  convertPromoModalToData,
};
