import { Thumbnail, ThumbnailType } from "models/Thumbnail";
import { MouseEvent } from "react";
import { Link, WindowType } from "ui/app/Footer/FooterLinkColumns";
import { IconType } from "ui/commerceUI/foundations/icons/Icon";
import { MainPagePath } from "ui/navigation/Pages";

const mapIconForThumbnailType = (type: ThumbnailType): IconType =>
  ({
    [ThumbnailType.facebook]: IconType.Facebook,
    [ThumbnailType.instagram]: IconType.Instagram,
    [ThumbnailType.twitter]: IconType.Twitter,
    [ThumbnailType.youtube]: IconType.YouTube,
  }[type]);

const mapThumbnailsToFooterLink = (thumbnails: Thumbnail[]): Link[] =>
  (thumbnails ?? []).map(
    (t) =>
      ({
        url: t.url,
        icon: mapIconForThumbnailType(t.type),
        label: t.text,
      } as Link)
  );

const useFooterViewModel = (currentDate = new Date()) => ({
  copyright: `TM & © ${currentDate.getFullYear()} Jimmy John's Franchisor SPV, LLC All Rights Reserved.`,
  socialLinks: mapThumbnailsToFooterLink([
    { type: ThumbnailType.twitter, text: "Twitter", url: "https://twitter.com/jimmyjohns" },
    { type: ThumbnailType.facebook, text: "Facebook", url: "https://www.facebook.com/jimmyjohns/" },
    { type: ThumbnailType.instagram, text: "Instagram", url: "https://www.instagram.com/jimmyjohns/" },
    { type: ThumbnailType.youtube, text: "YouTube", url: "https://www.youtube.com/user/jimmyjohns" },
  ]),
  secondaryLinks: [
    { label: "Terms & Conditions", url: MainPagePath.termsAndConditions },
    { label: "Ad & Cookie Policy", url: MainPagePath.adsCookiePolicy },
    { label: "Privacy Policy", url: MainPagePath.privacyPolicy },
    {
      label: "Cookie Settings",
      onClick: (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        if (ketch) {
          ketch("showPreferences");
        }
      },
      type: WindowType.External,
      url: "#",
    },
    {
      label: "Your Privacy Choices",
      url: "https://bigidprivacy.cloud/consumer/#/EnSBtJyXmT/Form-NJkZtUEqYjcmwwr",
      type: WindowType.External,
    },
    { label: "Accessibility Statement", url: MainPagePath.accessibility },
    { label: "CA Supply Chains Act", url: MainPagePath.californiaSupplyChain },
    {
      label: "All JJ’s Locations",
      url: "https://locations.jimmyjohns.com/",
      type: WindowType.External,
    },
  ],
});

export default useFooterViewModel;
