import { Box, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import OrderInfoButton from "ui/app/GlobalNav/OrderNav/OrderInfoButton";
import styles from "ui/app/GlobalNav/OrderNav/OrderNav.styles";
import useViewModel from "ui/app/GlobalNav/OrderNav/OrderNavViewModel";
import { MainPagePath } from "ui/navigation/Pages";

const OrderNav = observer(() => {
  const { isOrderStarted, onClickOrderNow } = useViewModel();

  return (
    <Box sx={styles.container}>
      {!isOrderStarted ? (
        <Box sx={styles.orderButtonContainer}>
          <Button component={Link} to={MainPagePath.locations} sx={styles.orderButton} onClick={onClickOrderNow}>
            Order Now
          </Button>
        </Box>
      ) : (
        <OrderInfoButton />
      )}
    </Box>
  );
});

export default OrderNav;
