import _ from "lodash";
import { Address, isAddress } from "models/Address";
import { CardIssuer } from "models/payment/CardIssuer";
import { PaymentType } from "models/payment/PaymentType";
import { CanDeliverResponse } from "models/store/CanDeliver";
import ProductSpecialInstructions from "models/store/ProductSpecialInstructions";
import StoreHours, { isStoreHours } from "models/store/StoreHours";
import { TypeChecker } from "util/JavascriptUtility";

export enum StoreFeature {
  Coupons = "COUPONS",
  OnlineOrdering = "ONLINE_ORDERING",
  OrderAhead = "ORDER_AHEAD",
  PickupSpecialInstructions = "PICKUP_SPECIAL_INSTRUCTIONS",
  Rewards = "REWARDS",
}

export type StoreFilter = "curbside" | "delivery" | "drivethru" | "rewards";

export enum StatusState {
  Closed = "CLOSED",
  Open = "OPEN",
}

/**
 * This matches on the required store properties defined by the octocart-backend
 * See the Store object under Schemas at https://test-swagger.octocart.com/
 */
export type Store = {
  id: string;
  name: string;
  phone: string;
  address: Address;
  storeNumber?: string;
  status: {
    state: StatusState;
    message?: string;
  };
  productSpecialInstructions: ProductSpecialInstructions;
  distance?: number;
  businessHours: StoreHours;
  specialtyHours: StoreHours[];
  timeZoneOffset: string;
  timeZoneName: string;
  features: StoreFeature[];
  supportedCardIssuers?: CardIssuer[];
  delivery: {
    enabled: boolean;
    supportedPaymentOptions: PaymentType[];
  };
  pickup: {
    enabled: boolean;
    supportedPaymentOptions: PaymentType[];
  };
  curbside: {
    enabled: boolean;
    supportedPaymentOptions: PaymentType[];
  };
  drivethru: {
    enabled: boolean;
    supportedPaymentOptions: PaymentType[];
  };
  dinein: {
    enabled: boolean;
    supportedPaymentOptions: PaymentType[];
  };
  rewardsSupported: boolean;
  deliveryDelayAlert?: string;
};

export const isStore = (object: unknown): object is Store => {
  const store = object as Store;
  return (
    !!store &&
    _.isString(store.id) &&
    _.isString(store.name) &&
    _.isString(store.phone) &&
    isAddress(store.address) &&
    isStoreHours(store.businessHours)
  );
};

export const isStoresArray = (object: unknown): object is Array<Store> => {
  const stores = object as Store[];
  return TypeChecker.isArray(stores, isStore);
};

export const isCanDeliverResponse = (object: unknown): object is CanDeliverResponse => {
  const response = object as CanDeliverResponse;

  return TypeChecker.isBoolean(response.canDeliver);
};
