import _isString from "lodash/isString";

export type TokenResponse = {
  token: string;
};

export const isTokenResponse = (object: unknown): object is TokenResponse => {
  const user = object as TokenResponse;
  return !!user && !!user.token && _isString(user.token);
};
