import Coordinates, { isCoordinates } from "models/Coordinates";
import { TypeChecker } from "util/JavascriptUtility";

export type Address = {
  id?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  crossStreet?: string;
  city: string;
  state?: string;
  zipcode: string;
  coordinates?: Coordinates;
};

export const isAddress = (object: unknown): object is Address => {
  const address = object as Address;
  return (
    !!address &&
    TypeChecker.isOptional(address.id, TypeChecker.isString) &&
    TypeChecker.isString(address.addressLineOne) &&
    TypeChecker.isOptional(address.addressLineTwo, TypeChecker.isString) &&
    TypeChecker.isString(address.city) &&
    TypeChecker.isOptional(address.state, TypeChecker.isString) &&
    TypeChecker.isString(address.zipcode) &&
    TypeChecker.isOptional(address.coordinates, isCoordinates)
  );
};

export type DeliveryAddress = Pick<Address, "addressLineOne" | "addressLineTwo" | "city" | "zipcode">;

export const isDeliveryAddress = (object: unknown): object is Address => {
  const address = object as DeliveryAddress;
  return (
    !!address &&
    TypeChecker.isString(address.addressLineOne) &&
    TypeChecker.isOptional(address.addressLineTwo, TypeChecker.isString) &&
    TypeChecker.isString(address.city) &&
    TypeChecker.isString(address.zipcode)
  );
};
