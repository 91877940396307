import { isMetaData, MetaData } from "models/MetaData";
import { isNumber, isUndefined } from "util/JavascriptUtility";

/**
 * Response object of the getProductDynamicData call
 * https://test-swagger.octocart.com/#/Products/getProductDynamicData
 */
export type DynamicData = {
  metaData: MetaData[];
  price?: string;
  availableQuantity?: number;
};

export const isDynamicData = (object: unknown): object is DynamicData => {
  const dynamicData = object as DynamicData;
  if (!dynamicData || !dynamicData.metaData) return false;
  const metaData = dynamicData.metaData;
  return (
    !!dynamicData &&
    (isUndefined(dynamicData.availableQuantity) || isNumber(dynamicData.availableQuantity)) &&
    Array.isArray(metaData) &&
    metaData.filter((md) => isMetaData(md)).length === metaData.length
  );
};
