import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";
import styles from "ui/app/AccountLayout/AccountLayout.styles";
import useViewModel from "ui/app/AccountLayout/AccountLayoutViewModel";
import { ConfirmationModalProvider } from "ui/app/AccountLayout/ConfirmationModal";
import ConfirmationModal from "ui/app/AccountLayout/ConfirmationModal";
import Spinner from "ui/components/Spinner";
import { AccountPagePath } from "ui/navigation/Pages";

const AuthAccountLayout = observer(() => {
  const { isLoadingUser, onLogOutClick } = useViewModel();
  const orderClass = window.location.pathname.match(/order/) ? "active" : "";

  return (
    <ConfirmationModalProvider>
      <Box data-testid="account-header">
        <Suspense fallback={<Spinner />}>
          <Box sx={styles.header}>
            <Typography variant="h1" sx={styles.headerText}>
              Account
            </Typography>
          </Box>
          <Grid container sx={styles.content}>
            <Grid item xs={0} md={4} component="nav" sx={styles.nav} data-testid="account-nav">
              <Box component={NavLink} to={AccountPagePath.profile} end sx={styles.navControl}>
                Profile
              </Box>
              <Box component={NavLink} to={AccountPagePath.recentOrders} sx={styles.navControl} className={orderClass}>
                Orders
              </Box>
              <Box component={NavLink} to={AccountPagePath.myFaves} sx={styles.navControl}>
                My Faves
              </Box>
              <Box
                component="button"
                onClick={onLogOutClick}
                sx={[styles.navControl, styles.logOut]}
                data-testid="logout-button"
              >
                Log Out
              </Box>
            </Grid>
            <Grid item xs={0} md={8} sx={styles.outlet}>
              {!isLoadingUser ? <Outlet /> : <Spinner />}
            </Grid>
          </Grid>
        </Suspense>
      </Box>
      <ConfirmationModal />
    </ConfirmationModalProvider>
  );
});

export default AuthAccountLayout;
