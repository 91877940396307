/** Returns the last element of an array, undefined if there are no elements */
import _ from "lodash";

export function last<T>(object?: Array<T>): T | undefined {
  if (!object) return undefined;
  if (object.length > 0) {
    return object[object.length - 1];
  } else {
    return undefined;
  }
}

/** Returns the first element of an array, undefined if there are no elements */
export function first<T>(object?: Array<T>): T | undefined {
  if (!object) return undefined;
  if (object.length > 0) {
    return object[0];
  } else {
    return undefined;
  }
}

/** Returns true if the object is empy */
export function isEmpty(object: Array<unknown>): boolean {
  return object.length === 0;
}

/** Pluralizes the string if there's not 1 item */
export const formatPlural = (count: number, noun: string, suffix = "s"): string =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

/** Deep clones an object */
export function cloneObj<T>(obj: T): T {
  return _.cloneDeep(obj);
}

/**
 * Type validators
 * These exist to avoid typos when checking types
 */

export const TypeChecker = {
  isBoolean: (object: unknown): object is boolean => {
    return typeof object === "boolean";
  },
  isString: (object: unknown): object is string => {
    return typeof object === "string";
  },
  isUndefined: (object: unknown): boolean => {
    return typeof object === "undefined";
  },
  isNumber: (value: unknown): value is number => {
    const num = value as number;
    return !TypeChecker.isUndefined(num) && !isNaN(num);
  },
  isArray: <T>(value: unknown, typeGuard: (value: unknown) => value is T): value is T[] => {
    if (TypeChecker.isUndefined(value)) return false;
    if (!Array.isArray(value)) return false;
    return value.length === value.filter((item) => typeGuard(item)).length;
  },
  /** Allows you to pass an object type checker */
  isOptional: <T>(value: unknown, typeGuard: (value: unknown) => value is T): value is T => {
    if (TypeChecker.isUndefined(value)) return true;
    return typeGuard(value);
  },
  isFunction: (object: unknown): boolean => {
    if (TypeChecker.isUndefined(object)) return false;
    return typeof object === "function";
  },
};

export function isString(object: unknown): object is string {
  return typeof object === "string";
}

export function isBoolean(object: unknown): object is boolean {
  return typeof object === "boolean";
}

export function isNotUndefined(object: unknown): boolean {
  return typeof object !== "undefined";
}

export function isUndefined(object: unknown): object is undefined {
  return typeof object === "undefined";
}

export function isNumber(value: unknown): value is number {
  const num = value as number;
  return !isUndefined(num) && !isNaN(num);
}

/** Returns true if undefined or string */
export const isOptionalString = (value: unknown): value is string | undefined => {
  if (typeof value === "undefined") return true;
  return isString(value);
};

/** Returns true if undefined or passes type guard */
export function isOptional<T>(value: unknown, typeGuard: (value: unknown) => value is T): value is T | undefined {
  if (isUndefined(value)) return true;
  return typeGuard(value);
}

export const isOptionalBoolean = (value: unknown): boolean => {
  if (isUndefined(value)) return true;
  return typeof value === "boolean";
};

export const isStringUnion = <T extends string>(value: unknown, unionValues: Readonly<string[]>): value is T => {
  return !!value && isString(value) && unionValues.includes(value);
};
