import MuiStyles from "ui/util/Styles";

const styles = {
  dateTimeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginTop: "0.5rem",
  },
  dateTimePicker: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  errorBox: {
    display: "inline-flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  errorText: (theme) => ({
    typography: theme.typography.formFieldText,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    textTransform: "none",
    display: "inline-flex",
    flexDirection: "row",
    marginLeft: "0.5rem",
    marginTop: 0,
  }),
} satisfies MuiStyles;

export default styles;
