import useRedirectToPreviousLocation from "hooks/useRedirectToPreviousLocation";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "stores/user";
import { AccountPagePath } from "ui/navigation/Pages";

const ProtectedRoute = observer(() => {
  const { isLoggedIn, isLoadingUser } = useUser();
  const { redirectState } = useRedirectToPreviousLocation();

  if (!isLoggedIn && !isLoadingUser) {
    return <Navigate to={AccountPagePath.signIn} state={redirectState} replace />;
  }

  return <Outlet />;
});

export default ProtectedRoute;
