import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  StandardTextFieldProps as MuiStandardTextFieldProps,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React, { KeyboardEvent, ReactElement, ReactNode, RefObject } from "react";
import InputMask, { BeforeMaskedStateChangeStates } from "react-input-mask";
import Icon from "ui/components/Icon/Icon";
import styles from "ui/components/TextField/TextField.styles";

export type TextFieldProps = Omit<MuiStandardTextFieldProps, "error" | "helperText" | "ref" | "value"> & {
  id: string;
  allowedCharacters?: RegExp;
  description?: string;
  errorText?: string;
  leftIcon?: ReactElement;
  optional?: boolean;
  ref?: RefObject<HTMLInputElement>;
  rightIcon?: ReactElement;
  value?: string;
};

type AdornmentProps = { position: "start" | "end"; icon: ReactElement };

const adornment = ({ position, icon }: AdornmentProps) => {
  return <InputAdornment position={position}>{icon}</InputAdornment>;
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(inProps, forwardedRef) {
  const {
    allowedCharacters,
    description,
    disabled,
    errorText,
    fullWidth,
    id,
    inputProps,
    InputProps,
    label,
    leftIcon,
    onBlur,
    onChange,
    onFocus,
    onMouseDown,
    optional,
    placeholder,
    rightIcon,
    sx,
    value,
    ...muiTextFieldProps
  } = inProps;

  const errorTextId = errorText ? id + "-error-text" : undefined;
  const startAdornment = leftIcon ? adornment({ icon: leftIcon, position: "start" }) : null;
  const endAdornment = rightIcon ? adornment({ icon: rightIcon, position: "end" }) : null;
  const inputPropsCustomized = { "aria-errormessage": errorTextId, ...inputProps };
  const InputPropsCustomized = { startAdornment, endAdornment, ...InputProps };

  const getMask = (label: ReactNode) => {
    switch (label) {
      case "Phone Number":
        return "(999) 999-9999";
      case "Birthdate":
        return "99/99";
      case "Date Visited":
        return "99/99/9999";
      case "Expiration Date":
        return "99/9999";
      default:
        return null;
    }
  };
  const mask = getMask(label);

  function beforeMaskedStateChange({ nextState }: BeforeMaskedStateChangeStates, label: ReactNode) {
    let { value } = nextState;
    // Trim away starting parenthesis from phone number formatting if that is the only character
    if (label == "Phone Number" && value == "(") {
      value = "";
    }

    return {
      ...nextState,
      value,
    };
  }

  /** Validate each key press against the given allow characters regexp, if provided. */
  const validateKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (allowedCharacters && !allowedCharacters.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <FormControl variant="standard" error={!!errorText} disabled={disabled} sx={sx} fullWidth={fullWidth}>
      {label ? (
        <InputLabel htmlFor={id} disableAnimation sx={styles.label} shrink={false}>
          <Box sx={styles.labelText}>
            {label}
            {optional ? (
              <Typography sx={styles.optionalText} variant="formFieldText">
                {" "}
                (optional)
              </Typography>
            ) : null}
          </Box>
          {description ? (
            <Typography sx={styles.description} variant="formFieldText">
              {description}
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {!mask ? (
        <MuiTextField
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          error={!!errorText}
          sx={styles.textfield}
          value={value}
          inputProps={inputPropsCustomized}
          InputProps={InputPropsCustomized}
          inputRef={forwardedRef}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={allowedCharacters ? validateKeyPress : undefined}
          onMouseDown={onMouseDown}
          {...muiTextFieldProps}
        />
      ) : (
        <InputMask
          id={id}
          mask={mask}
          maskPlaceholder={null}
          disabled={disabled}
          inputRef={forwardedRef}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={allowedCharacters ? validateKeyPress : undefined}
          onMouseDown={onMouseDown}
          value={value}
          beforeMaskedStateChange={(changeStates) => beforeMaskedStateChange(changeStates, label)}
        >
          <MuiTextField
            error={!!errorText}
            inputProps={inputPropsCustomized}
            InputProps={InputPropsCustomized}
            sx={styles.textfield}
            {...muiTextFieldProps}
          />
        </InputMask>
      )}
      {errorText ? (
        <Box sx={styles.errorBox}>
          <Icon icon="error" />
          <FormHelperText id={errorTextId} sx={styles.errorText}>
            {errorText}
          </FormHelperText>
        </Box>
      ) : null}
    </FormControl>
  );
});

export default TextField;
