import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";

export const getSessionId = () => {
  let sessionId = window.sessionStorage.getItem("X-Session-Id");

  if (sessionId) return sessionId;

  sessionId = uuidv4();
  window.sessionStorage.setItem("X-Session-Id", sessionId);
  return sessionId;
};

export const setSessionEmail = (email: string | undefined) => {
  if (!email) {
    window.sessionStorage.removeItem("email");
    return;
  }

  const hashedEmail = sha256(email);
  window.sessionStorage.setItem("email", hashedEmail);
};
