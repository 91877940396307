import { useContext } from "react";
import { MenuContext } from "stores/menu/MenuProvider";

const useMenu = () => {
  const store = useContext(MenuContext);
  if (!store) {
    throw new Error("useMenu must be used within a MenuProvider.");
  }
  return store;
};

export default useMenu;
