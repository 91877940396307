import { AddItem } from "models/order/AddItem";
import { CartItem } from "models/order/CartItem";
import { BaseProductItem, ProductItem } from "models/ProductItem";
import { useCallback, useMemo } from "react";
import { useMenu } from "stores/menu";
import ProductStore from "stores/menu/ProductStore";
import { ProductModifier, ProductModifierManager } from "ui/screens/Product/ProductModifiers/ProductModifierManager";
import GoogleAnalytics, { EventItemParams } from "util/GoogleAnalytics";

async function getCategoryIndex(product: BaseProductItem, menuStore: ProductStore) {
  const productCategory = product?.categories[0];

  const productCategoryProducts = productCategory
    ? await menuStore.getProductsForCategory(productCategory.id)
    : undefined;
  const productCategoryIndex = productCategoryProducts?.findIndex(
    (categoryProducts) => categoryProducts.id === product?.id
  );

  return productCategoryIndex;
}

function getComboModifiers(productModifiers: ProductModifier[]) {
  return productModifiers.filter(
    (modifier) => modifier.enabled && (modifier.text === "Your Side" || modifier.text === "Your Drink")
  );
}

function getComboCategoryName(modifier: ProductModifier) {
  const selectedOptionId = modifier.selectedOptions.length ? modifier.selectedOptions[0] : undefined;
  const selectedOption = modifier.options.find((option) => option.id === selectedOptionId);
  const comboCategoryName = modifier.text === "Your Side" ? "Side" : modifier.text === "Your Drink" ? "Drink" : "";
  return [selectedOption, comboCategoryName] as const;
}

function getSelectedSizeOption(productModifiers: ProductModifier[]) {
  const sizeModifier = productModifiers.find(
    (modifier) =>
      modifier.enabled && (modifier.text === "Choose Your Size" || modifier.text === "Choose Your Combo Size")
  );

  const sizeSelectedOptionId =
    sizeModifier && sizeModifier.selectedOptions.length ? sizeModifier.selectedOptions[0] : undefined;
  const sizeSelectedOption = sizeModifier && sizeModifier.options.find((option) => option.id === sizeSelectedOptionId);

  return sizeSelectedOption;
}

const useAnalytics = () => {
  const menuStore = useMenu();

  const analytics = useMemo(() => new GoogleAnalytics(), []);

  const getAnalyticsItemFromProduct = useCallback(
    async (
      product: ProductItem,
      productPrice: string | undefined,
      productModifiers: ProductModifier[],
      sidesAndDrinks: AddItem[]
    ) => {
      const productCategory = product?.categories[0];
      const products: EventItemParams[] = [];

      const comboModifiers = getComboModifiers(productModifiers);
      const sizeSelectedOption = getSelectedSizeOption(productModifiers);

      if (product) {
        const productCategoryIndex = await getCategoryIndex(product, menuStore);

        products.push({
          item_id: product.id,
          item_name: product.name,
          item_list_name: productCategory?.name,
          index: productCategoryIndex && productCategoryIndex >= 0 ? productCategoryIndex : undefined,
          item_category: productCategory?.name ?? "",
          item_category2: comboModifiers.length ? "Combo" : "",
          is_catering: "categories" in product && product.categories?.[0].menuType === "catering" ? "Yes" : "No",
          item_variant: sizeSelectedOption ? sizeSelectedOption.text : undefined,
          price: productPrice ?? product?.dynamicData.price ?? "",
        });
      }

      if (sidesAndDrinks.length) {
        sidesAndDrinks.forEach((sideOrDrink) => {
          const sideOrDrinkProduct = menuStore.products?.find((product) => product.id === sideOrDrink.productId);

          if (sideOrDrinkProduct) {
            products.push({
              item_id: sideOrDrinkProduct.id,
              item_name: sideOrDrinkProduct.name,
              item_list_name: "ADD DRINKS AND SIDES",
              item_category: sideOrDrinkProduct.categories[0].name,
              price: sideOrDrinkProduct.dynamicData.price ?? "",
            });
          }
        });
      }

      return products;
    },
    [menuStore]
  );

  const getAnalyticsItemFromProductId = useCallback(
    (productId: string, chainId?: string) => {
      const product = menuStore.products?.find((product) =>
        chainId ? product.chainId === chainId : product.id === productId
      );
      if (!product) {
        return Promise.resolve([]);
      }

      return getAnalyticsItemFromProduct(product, undefined, [], []);
    },
    [menuStore, getAnalyticsItemFromProduct]
  );

  const getAnalyticsItemFromCartItem = useCallback(
    async (item: CartItem, options?: { skipCategoryIndex?: boolean }) => {
      const products: EventItemParams[] = [];

      const productCategory = item.product?.categories[0];

      const productCategoryIndex = !options?.skipCategoryIndex
        ? await getCategoryIndex(item.product, menuStore)
        : undefined;

      products.push({
        item_id: item.product.id,
        item_name: item.product.name,
        price: item.price,
        quantity: item.quantity,
        item_list_name: productCategory?.name,
        is_catering: productCategory.menuType === "catering" ? "Yes" : "No",
        index: productCategoryIndex && productCategoryIndex >= 0 ? productCategoryIndex : undefined,
        item_category: productCategory?.name ?? "",
      });

      if (item.options.length) {
        const modifierCategories = await menuStore.getModifierCategoriesForProduct(item.product.id);
        const optionIds = item.options.map((o) => o.id);
        const modifiers = modifierCategories.map((c) => c.modifiers).reduce((a, b) => a.concat(b), []);
        const appliedModifierOptions = ProductModifierManager.applyModifierOptions(modifiers, optionIds);

        const comboModifiers = getComboModifiers(appliedModifierOptions);
        products[0].item_category2 = comboModifiers.length ? "Combo" : "";

        const sizeSelectedOption = getSelectedSizeOption(appliedModifierOptions);
        products[0].item_variant = sizeSelectedOption ? sizeSelectedOption.text : undefined;

        comboModifiers.forEach((modifier) => {
          const [selectedOption, comboCategoryName] = getComboCategoryName(modifier);

          products.push({
            item_id: selectedOption?.id ?? "",
            item_name: selectedOption?.text ?? "",
            price: "0.00",
            quantity: item.quantity,
            item_category: "Side",
            item_category2: comboCategoryName,
          });
        });
      }

      return products;
    },
    [menuStore]
  );

  const getAnalyticsItemFromCartItems = useCallback(
    async (items: CartItem[], options?: { skipCategoryIndex?: boolean }) => {
      const products: EventItemParams[] = [];

      await Promise.all(
        items.map(async (item) => {
          const itemProducts = await getAnalyticsItemFromCartItem(item, options);
          products.push(...itemProducts);
        })
      );

      return products;
    },
    [getAnalyticsItemFromCartItem]
  );

  return {
    analytics,
    getAnalyticsItemFromCartItem,
    getAnalyticsItemFromCartItems,
    getAnalyticsItemFromProduct,
    getAnalyticsItemFromProductId,
  };
};

export default useAnalytics;
