import MuiStyles from "ui/util/Styles";

const styles = {
  text: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  image: {
    width: "4.5rem",
    height: "4.5rem",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    "&:not(.image-fill) img": {
      objectFit: "contain",
    },
  },
  content: {
    display: "flex",
    flex: 1,
    gap: 1.5,
  },
  radioButton: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexBasis: "100%",
    flexGrow: 1,
    gap: 1.5,
    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiFormControlLabel-label": {
      display: "flex",
      flex: 1,
    },

    "& .Mui-focusVisible div": {
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },

    "&.Mui-disabled": {
      "& .MuiTypography-root": {
        color: theme.palette.grey[500],
      },
      "& .MuiRadio-root.Mui-checked": {
        color: theme.palette.grey[100],
      },
    },

    "& .MuiFormControlLabel-asterisk": {
      display: "none",
    },

    [theme.breakpoints.up("md")]: {
      "&:not(.full-width)": {
        // 2 buttons, 1 gaps, one half of space minus one half of the sum of the gaps
        flexBasis: "calc(100% / 2 - 1rem / 2)",
        flexGrow: 0,
      },
    },
  }),
  radioGroup: (theme) => ({
    flexDirection: {
      xs: "column",
      md: "row",
    },
    flexWrap: {
      xs: "nowrap",
      md: "wrap",
    },
    gap: {
      xs: theme.spacing(1.5),
      md: theme.spacing(2),
    },

    "& .MuiFormControlLabel-root": { m: 0 },
  }),
  // custom radio button styles for empty botton
  iconStyles: (theme) => ({
    borderRadius: "50%",
    width: "1.5rem",
    height: "1.5rem",
    boxShadow: `inset 0 0 0 0.125rem ${theme.palette.grey[400]}`,
    border: theme.palette.grey[400],
    backgroundColor: theme.palette.primary.contrastText,
    "input:disabled ~ &": {
      backgroundColor: theme.palette.grey[100],
      border: theme.palette.grey[300],
    },
  }),
  // custom radio button styles for filled botton
  checkedIconStyles: (theme) => ({
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    // adding in the transparent circle in the center of the radio button
    "&:before": {
      display: "block",
      width: "1.5rem",
      height: "1.5rem",
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:disabled ~ &": {
      backgroundColor: theme.palette.grey[300],
    },
  }),
  basicRadioButton: (theme) => ({
    "&:hover .MuiRadio-root div": {
      backgroundColor: theme.palette.grey[100],
    },

    "&.Mui-checked": {
      backgroundColor: theme.palette.common.white,
    },
  }),
  outlinedButton: (theme) => ({
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.25)",
    padding: "0 1rem",

    "&.selected": {
      outline: `0.125rem solid ${theme.palette.secondary.main}`,
      background: theme.palette.common.white,
      padding: "0 1rem",
    },

    ":hover": {
      background: theme.palette.grey[100],
      boxShadow: "none",
      outline: `0.125rem solid ${theme.palette.grey[400]}`,
      padding: "0 1rem",

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },

    "&.Mui-disabled": {
      border: `0.0625rem solid ${theme.palette.grey[200]}`,
      boxShadow: "none",
      padding: "0 1rem",
    },
  }),
  errorBox: {
    display: "inline-flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  errorText: (theme) => ({
    typography: theme.typography.formFieldText,

    color: theme.palette.primary.main,
    display: "inline-flex",
    flexDirection: "row",
    fontFamily: theme.typography.fontFamily,
    marginLeft: "0.5rem",
    marginTop: 0,
    textTransform: "none",
  }),
} satisfies MuiStyles;

export default styles;
