import { useContext } from "react";
import { OrderContext } from "stores/order/OrderProvider";

const useOrder = () => {
  const store = useContext(OrderContext);
  if (!store) {
    throw new Error("useOrder must be used within an OrderProvider.");
  }
  return store;
};

export default useOrder;
