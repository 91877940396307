import MuiStyles from "ui/util/Styles";

const textPadding = {
  paddingX: {
    xs: "1rem",
    md: "2rem",
  },
};

const styles = {
  container: {
    alignItems: "center",
    gap: "1.5rem",
    paddingBottom: {
      xs: "2rem",
      md: "3rem",
    },
    paddingTop: {
      xs: "1rem",
      md: "0.5rem",
    },
    textAlign: "center",
  },
  cta: {
    width: {
      xs: "100%",
      md: "auto",
    },
  },
  description: {
    ...textPadding,
  },
  image: {
    height: {
      xs: 163,
      md: 244,
    },
    objectFit: "contain",
    maxWidth: "100%",

    "& > img": {
      height: "100%",
      objectFit: "contain",
      width: "100%",
    },
  },
  termsButton: {
    color: "grey.500",
  },
  termsText: {
    ...textPadding,
  },
  title: {
    ...textPadding,
  },
} satisfies MuiStyles;

export default styles;
