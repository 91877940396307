import MuiStyles from "ui/util/Styles";

const styles = {
  root: {
    "&.no-options .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      gap: "0.5rem",
      padding: "1rem !important",

      "&.Mui-focused": {
        padding: "1rem !important",
      },
      "& .MuiInput-input": {
        flexBasis: "100%",
        padding: "0 !important",
      },
    },

    "& .MuiAutocomplete-endAdornment": {
      padding: "0 0.875rem",
    },
  },
  tag: (theme) => ({
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "2.25rem",
    margin: "0 !important",
    paddingRight: "0.375rem",
    textTransform: "none",

    "& .MuiChip-deleteIcon": {
      color: theme.palette.grey[400],
      margin: 0,

      "&:hover": {
        color: theme.palette.grey[500],
      },
    },
    "& .MuiChip-label": {
      textTransform: "none",
    },
  }),
} satisfies MuiStyles;

export default styles;
