import { isGroupOrder } from "models/groupOrder";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";

const useGroupOrderHost = () => {
  const { order } = useOrder();
  const { isLoggedIn, user } = useUser();

  const groupOrderHostEmail = (() => {
    if (!isLoggedIn || !user) {
      return;
    }

    const groupOrder = isGroupOrder(order) ? order : undefined;
    const existingHost = groupOrder?.groupOrderDetails.participants.find((participant) => participant.id === "host");

    if (existingHost) {
      return existingHost.email;
    } else {
      return user.email;
    }
  })();

  return { groupOrderHostEmail };
};

export default useGroupOrderHost;
