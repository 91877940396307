import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  select: (theme) => ({
    border: `0.0625rem solid ${theme.palette.grey[400]}`,
    borderRadius: 0,
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.25)",
    padding: "0.0625rem",

    "& fieldset": {
      border: "0 !important",
    },

    "& .MuiSelect-icon": {
      color: theme.palette.grey[400],
      right: "1rem",
      top: "calc(50% - 0.75rem)",
      transition: "transform 0.2s linear",
    },

    "& .MuiSelect-select": {
      typography: "formFieldText",

      color: theme.palette.grey[500],
      minHeight: "auto !important",
      padding: "0.875rem 2.875rem 0.875rem 0.875rem !important",
    },

    // focus
    "&:focus-visible, &.Mui-focused": {
      border: `0.125rem solid ${Colors.superBlack}`,
      padding: 0,
    },

    "&:focus-visible .MuiSelect-icon, &.Mui-focused .MuiSelect-icon": {
      color: Colors.superBlack,
    },

    // hover
    "&:hover": {
      background: theme.palette.grey[100],
    },

    "&:hover .MuiSelect-icon": {
      color: Colors.superBlack,
    },

    // option selected
    "&.selected .MuiSelect-select": {
      color: Colors.superBlack,
    },

    // error
    "&.Mui-error": {
      borderWidth: "0.125rem",
      borderColor: "primary.main",
      padding: 0,
    },

    // disabled
    "&.Mui-disabled": {
      background: theme.palette.grey[200],
      borderColor: theme.palette.grey[200],
      boxShadow: "none",
    },
  }),
  errorText: {
    typography: "formFieldText",
    color: "primary.main",
    marginTop: 1,
  },
  helperText: {
    color: (theme) => theme.palette.grey[500],
  },
  label: {
    "&.disabled": {
      color: (theme) => theme.palette.grey[500],
    },
  },
  labelContainer: {
    marginBottom: 1,
  },
  optionalText: {
    color: (theme) => theme.palette.grey[500],
  },
} as const satisfies MuiStyles;

export default styles;
