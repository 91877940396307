import useAnalytics from "hooks/useAnalytics";
import useGroupOrderHost from "hooks/useGroupOrderHost";
import { isGroupOrder } from "models/groupOrder";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { AddParticipantsSchema, fieldOptions } from "ui/components/AddParticipantsModal/AddParticipantsModal.data";
import { logError } from "util/Logger";

const title = "Add More People";

export type AddParticipantsModalViewModelProps = {
  onClose: () => void;
};

const useAddParticipantsViewModel = ({ onClose }: AddParticipantsModalViewModelProps) => {
  const { analytics } = useAnalytics();
  const { getCurrentGroupOrderParticipants, order, sendGroupOrderInvites } = useOrder();
  const { getSavedGroupOrderParticipants } = useUser();
  const { groupOrderHostEmail } = useGroupOrderHost();

  const [currentGroupOrderParticipants, setCurrentGroupOrderParticipants] = useState<string[]>([]);
  const [savedParticipants, setSavedParticipants] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    formState: { errors: fieldErrors },
    handleSubmit,
    register,
    reset,
  } = useForm<AddParticipantsSchema>({
    defaultValues: {
      participantEmails: [],
    },
  });

  const closeModal = () => {
    onClose();
    analytics.modalCTA("Cancel", title);
  };

  useEffect(() => {
    if (savedParticipants.length) return;
    const loadSavedParticipants = async () => {
      try {
        const savedParticipants = await getSavedGroupOrderParticipants();
        setSavedParticipants(savedParticipants);
      } catch (e) {
        logError(e);
        setSavedParticipants([]);
      }
    };

    loadSavedParticipants();
  }, [getSavedGroupOrderParticipants, savedParticipants.length]);

  useEffect(() => {
    const loadCurrentGroupOrderParticipants = async () => {
      try {
        const currentParticipants = await getCurrentGroupOrderParticipants();
        const currentParticipantEmailsExcludingHost = currentParticipants
          .map((participant) => {
            if (participant.id !== "host") return participant.email;
          })
          .filter((email) => email !== undefined) as string[];

        currentParticipantEmailsExcludingHost.length &&
          setCurrentGroupOrderParticipants(currentParticipantEmailsExcludingHost);
      } catch (e) {
        logError(e);
        setCurrentGroupOrderParticipants([]);
      }
    };

    loadCurrentGroupOrderParticipants();
  }, [getCurrentGroupOrderParticipants]);

  const onSubmit = handleSubmit(async (fields) => {
    if (!isGroupOrder(order)) return;

    setIsSubmitting(true);

    try {
      await sendGroupOrderInvites(order.groupOrderDetails.id, fields);
      onClose();
    } catch (e) {
      logError(e);
    }

    setIsSubmitting(false);
  });

  useEffect(() => {
    return function tearDown() {
      reset();
    };
  }, [reset]);

  // Remove already invited emails from saved participants
  const filteredSavedParticipants = savedParticipants.filter((email) => !currentGroupOrderParticipants.includes(email));

  return {
    analytics,
    control,
    currentGroupOrderParticipants,
    fieldErrors,
    fieldOptions,
    filteredSavedParticipants,
    groupOrderHostEmail,
    isSubmitting,
    closeModal,
    onSubmit,
    register,
    title,
  };
};

export default useAddParticipantsViewModel;
