import { useState } from "react";
import { useCart } from "stores/cart";
import { useOrder } from "stores/order";

const useGlobalNavViewModel = () => {
  const { shouldShowCart, toggleCart } = useCart();
  const { order } = useOrder();
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);

  const showDrawer = () => setShouldShowDrawer(true);
  const hideDrawer = () => setShouldShowDrawer(false);

  const cartItemCount = order?.items.reduce((value, item) => value + item.quantity, 0);
  const hasOrder = order?.orderType;

  return {
    cartItemCount,
    shouldShowDrawer,
    showDrawer,
    hideDrawer,
    shouldShowCart,
    toggleCart,
    hasOrder,
  };
};

export default useGlobalNavViewModel;
