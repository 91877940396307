import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { MainPagePath } from "ui/navigation/Pages";

const useRedirectToPreviousLocation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const redirectState: RedirectState = {
    redirectTo: location.pathname + location.search,
  };

  const redirectToPreviousLocation = (options?: NavigateOptions) => {
    if (!isRedirectState(location.state)) {
      navigate(MainPagePath.home);
      return;
    }

    navigate(location.state.redirectTo, options);
  };

  return { redirectToPreviousLocation, redirectState };
};

export type RedirectState = {
  redirectTo: string;
};

export function isRedirectState(obj: unknown): obj is RedirectState {
  const redirectState = obj as RedirectState;
  return !!redirectState && !!redirectState.redirectTo && typeof redirectState.redirectTo === "string";
}

export default useRedirectToPreviousLocation;
