// Abstracts axios from the rest of the app
export interface NetworkResponse<T = unknown> {
  data: T;
  status: number;
  headers?: unknown;
}

export interface NetworkError<T = unknown> extends Error {
  response: NetworkResponse<T>;
}

export const isNetworkError = (object: unknown): object is NetworkError => {
  const error = object as NetworkError;
  return !!error && !!error.response && error.response.data !== undefined;
};

export type NetworkPromise<T> = Promise<NetworkResponse<T>>;
