import { Box } from "@mui/material";
import account from "assets/icons/account.svg";
import arrow from "assets/icons/arrow.svg";
import backArrow from "assets/icons/back-arrow.svg";
import bag from "assets/icons/bag.svg";
import close from "assets/icons/close.svg";
import closeDark from "assets/icons/close-dark.svg";
import curbside from "assets/icons/curbside.svg";
import delivery from "assets/icons/delivery.svg";
import drivethru from "assets/icons/drivethru.svg";
import error from "assets/icons/error.svg";
import eye from "assets/icons/eye.svg";
import filter from "assets/icons/filter.svg";
import geolocationOff from "assets/icons/geolocation-off.svg";
import geolocation from "assets/icons/geolocation-on.svg";
import greenCheckMark from "assets/icons/green-check-mark.svg";
import groupOrder from "assets/icons/group-order.svg";
import hamburger from "assets/icons/hamburger.svg";
import info from "assets/icons/Info.svg";
import instore from "assets/icons/instore.svg";
import location from "assets/icons/location.svg";
import more from "assets/icons/more.svg";
import peaceSign from "assets/icons/peace-sign.svg";
import perkBonusChallenges from "assets/icons/perk-bonus-challenges.svg";
import perkFasterCheckout from "assets/icons/perk-faster-checkout.svg";
import perkOther from "assets/icons/perk-other.svg";
import perkPayWithPhone from "assets/icons/perk-pay-with-phone.svg";
import perkSneakPeek from "assets/icons/perk-sneak-peek.svg";
import perkSpecialSurprises from "assets/icons/perk-special-surprises.svg";
import recentOrders from "assets/icons/recent-order.svg";
import rewardBox from "assets/icons/reward-box.svg";
import rewardBoxOpen from "assets/icons/reward-box-open.svg";
import rewards from "assets/icons/rewards.svg";
import search from "assets/icons/search.svg";
import star from "assets/icons/star.svg";
import starOutline from "assets/icons/star-outline.svg";
import stillWaiting from "assets/icons/still-waiting.svg";
import warning from "assets/icons/warning.svg";
import whiteCheckMark from "assets/icons/white-check-mark.svg";
import styles from "ui/components/Icon/Icon.styles";

export const Icons = {
  account,
  arrow,
  backArrow,
  bag,
  close,
  closeDark,
  curbside,
  delivery,
  drivethru,
  error,
  eye,
  filter,
  geolocation,
  geolocationOff,
  greenCheckMark,
  groupOrder,
  hamburger,
  info,
  instore,
  location,
  more,
  peaceSign,
  perkBonusChallenges,
  perkFasterCheckout,
  perkOther,
  perkPayWithPhone,
  perkSneakPeek,
  perkSpecialSurprises,
  recentOrders,
  rewardBox,
  rewardBoxOpen,
  rewards,
  search,
  star,
  starOutline,
  stillWaiting,
  warning,
  whiteCheckMark,
} as const;

export type IconSize = "xxsmall" | "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge" | "xxxlarge";

// default size is "medium"; only specify default size overrides here.
export const DefaultIconSize: Partial<{ [key in IconType]: IconSize }> = {
  backArrow: "small",
  bag: "large",
  close: "large",
  closeDark: "large",
  greenCheckMark: "small",
  location: "large",
  peaceSign: "xxlarge",
  perkBonusChallenges: "xlarge",
  perkFasterCheckout: "xlarge",
  perkOther: "xlarge",
  perkPayWithPhone: "xlarge",
  perkSneakPeek: "xlarge",
  rewardBox: "xlarge",
  rewardBoxOpen: "xxlarge",
  star: "small",
  starOutline: "small",
  stillWaiting: "small",
  warning: "xxxlarge",
};

export type IconType = keyof typeof Icons;

export type IconProps = {
  icon: IconType;
  size?: IconSize;
  inline?: boolean;
  responsive?: boolean;
};

const Icon = ({ icon, size, inline = false, responsive = false }: IconProps) => {
  const iconSize: IconSize = size ?? DefaultIconSize[icon] ?? "medium";
  const classNames = [`icon-size-${iconSize}`];

  if (inline) classNames.push("icon-inline");
  if (responsive) classNames.push("icon-responsive");

  return (
    <Box sx={styles.container} className={classNames.join(" ")}>
      <img src={Icons[icon]} aria-hidden="true" alt="" />
    </Box>
  );
};

export default Icon;
