import { CmsButtonData } from "models/cms/DataTypes/CmsButtonData";

export type PromoModalData = {
  id: string;
  name: string;
  title?: string;
  image?: string;
  description?: string;
  termsAndConditions?: string;
  cta?: CmsButtonData;
  campaignStart: Date;
  campaignEnd?: Date;
  platforms: Platform[];
  frequencyInDays: number;
  days: DayOfWeek[];
  startTime: HourOfDay;
  duration: number;
};

const PLATFORMS = ["Android", "iOS", "Web"] as const;
type Platform = (typeof PLATFORMS)[number];

export const isPlatform = (value: unknown): value is Platform => {
  const platform = value as Platform;
  return PLATFORMS.includes(platform);
};

export const isPlatformArray = (value: unknown): value is Platform[] => {
  return Array.isArray(value) && value.every(isPlatform);
};

export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as const;
type DayOfWeek = (typeof DAYS_OF_WEEK)[number];

export const isDayOfWeek = (value: unknown): value is DayOfWeek => {
  const dayOfWeek = value as DayOfWeek;
  return DAYS_OF_WEEK.includes(dayOfWeek);
};

export const isDayOfWeekArray = (value: unknown): value is DayOfWeek[] => {
  return Array.isArray(value) && value.every(isDayOfWeek);
};

export const HOURS_OF_DAY = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
] as const;
type HourOfDay = (typeof HOURS_OF_DAY)[number];

export const isHourOfDay = (value: unknown): value is HourOfDay => {
  const hourOfDay = value as HourOfDay;
  return HOURS_OF_DAY.includes(hourOfDay);
};
