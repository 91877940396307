import { isArray, isString } from "lodash";
import {
  GroupOrderInviteStatus,
  GroupOrderParticipantOrder,
  isGroupOrderInviteStatus,
  isGroupOrderParticipantOrder,
} from "models/groupOrder";

type GroupOrderParticipant = {
  id: string;
  email: string;
  inviteStatus?: GroupOrderInviteStatus;
  name?: string;
  orders?: GroupOrderParticipantOrder[];
};

export const isGroupOrderParticipant = (object: unknown): object is GroupOrderParticipant => {
  const participant = object as GroupOrderParticipant;
  return (
    participant !== undefined &&
    (participant.id === undefined || isString(participant.id)) &&
    (participant.inviteStatus === undefined || isGroupOrderInviteStatus(participant.inviteStatus)) &&
    isString(participant.email) &&
    (participant.name === undefined || isString(participant.name)) &&
    (participant.orders === undefined ||
      (isArray(participant.orders) &&
        participant.orders.filter(isGroupOrderParticipantOrder).length === participant.orders.length))
  );
};

export const isGroupOrderParticipantArray = (object: unknown): object is GroupOrderParticipant[] => {
  const array = object as GroupOrderParticipant[];
  return isArray(array) && array.every((element) => isGroupOrderParticipant(element));
};

export default GroupOrderParticipant;
