import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";

type ContentfulImageProps = {
  url: string;
  sx?: SxProps<Theme>;
  width?: number;
};

const ContentfulImage = ({ sx, url, width }: ContentfulImageProps) => {
  const widthParam = width ? `&w=${width}` : "";

  const imageUrl = {
    avif: `${url}?fm=avif${widthParam}`,
    png: `${url}?fm=png&fl=png8${widthParam}`,
    webp: `${url}?fm=webp${widthParam}`,
  };

  return (
    <Box component="picture" sx={sx}>
      <source srcSet={imageUrl.avif} type="image/avif" />
      <source srcSet={imageUrl.webp} type="image/webp" />
      <img src={imageUrl.png} alt="" />
    </Box>
  );
};

export default ContentfulImage;
