import _ from "lodash";

/** All possible credit card issuers */
export enum CardIssuer {
  americanExpress = "amex",
  dinersClub = "diners-club",
  discover = "discover",
  mastercard = "master-card",
  visa = "visa",
}

export const isCardIssuer = (object: unknown): object is CardIssuer => {
  const issuer = object as CardIssuer;
  return _.isString(issuer) && Object.values(CardIssuer).includes(issuer);
};
