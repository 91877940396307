import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * µ-component that will automatically scroll the window to the top, whenever the route changes.
 */
const ScrollToTop = () => {
  const { pathname, state: scrollState } = useLocation();

  // Override any scroll positions stored in history from previous visits to a given page.
  // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  useEffect(() => {
    const top = document.getElementById("top");

    top?.focus();

    const behavior = scrollState?.smoothScroll ? "smooth" : "auto";
    window.scrollTo({ top: 0, left: 0, behavior });
  }, [pathname, scrollState]);

  return null;
};

export default ScrollToTop;
