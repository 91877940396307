import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    backgroundColor: theme.palette.grey[100],
    padding: "1.5rem 1rem",
  }),
  ledger: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    margin: 0,
    padding: 0,
  },
  ledgerItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 0,
  },
  ledgerItemLabel: {
    color: Colors.superBlack,

    ".gift-card &": {
      color: "primary.main",
    },
  },
  ledgerItemValue: {
    color: Colors.superBlack,

    ".gift-card &": {
      color: "primary.main",
    },
  },
} satisfies MuiStyles;

export default styles;
