import { useNavigate } from "react-router-dom";
import useUser from "stores/user/useUser";
import { MainPagePath } from "ui/navigation/Pages";

const useAuthAccountLayoutViewModel = () => {
  const userStore = useUser();
  const navigate = useNavigate();

  const onLogOutClick = async () => {
    await userStore.logout();
    navigate(MainPagePath.home);
  };

  return {
    isLoadingUser: userStore.isLoadingUser,
    onLogOutClick,
  };
};

export default useAuthAccountLayoutViewModel;
