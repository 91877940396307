import _ from "lodash";
import { CommunicationPreferences } from "models/CommunicationPreferences";
/**
 * This matches on the required user properties defined by the octocart-backend
 * See the "User" object under Schemas at https://test-swagger.octocart.com/
 */

export type User = {
  birthday: string;
  communicationPreferences: CommunicationPreferences;
  email: string;
  enrolledInRewards: boolean;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  updateRequired: boolean;
  userGuid: string;
  userName: string;
};

export const isUser = (object: unknown): object is User => {
  const user = object as User;
  return (
    !!user &&
    !!user.email &&
    !!user.firstName &&
    !!user.id &&
    !!user.lastName &&
    !!user.userGuid &&
    !!user.userName &&
    _.isBoolean(user.updateRequired) &&
    _.isBoolean(user.enrolledInRewards)
  );
};

/**
 * Payload for creating a new user defined by the backend
 */
export type NewUserRequestPayload = Pick<
  User,
  "firstName" | "lastName" | "userName" | "phoneNumber" | "birthday" | "communicationPreferences"
> & {
  password: string;
};

/**
 * Payload for editing an existing user defined by the backend
 */
export type EditUserRequestPayload = Pick<User, "firstName" | "lastName" | "phoneNumber"> &
  Partial<Pick<User, "birthday">> & {
    communicationPreferences?: Partial<CommunicationPreferences>;
    email: string;
  };
