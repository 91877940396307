import { TypeChecker } from "util/JavascriptUtility";

type Coordinates = {
  latitude: number;
  longitude: number;
};

export const isCoordinates = (object: unknown): object is Coordinates => {
  const coordinates = object as Coordinates;
  return !!coordinates && TypeChecker.isNumber(coordinates.latitude) && TypeChecker.isNumber(coordinates.longitude);
};

export default Coordinates;
