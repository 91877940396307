/**
 * Error codes that can come from the backend
 */
enum OctocartNetworkErrorCodes {
  OrderNotFound = "2001",
  CouponCodeInvalid = "2011",
  RewardsInvalid = "2021",
  InvalidCSRFToken = "9001",
}

export default OctocartNetworkErrorCodes;
