import { OrderError, UnknownError } from "errors/Errors";
import useAnalytics from "hooks/useAnalytics";
import useOrderUpsell from "hooks/useOrderUpsell";
import CalorieInfo from "models/CalorieInfo";
import { ImagesType } from "models/Filter";
import { isGroupOrder, isHostPayGroupOrder, isSplitPayGroupOrder } from "models/groupOrder/GroupOrder";
import { CartItem } from "models/order/CartItem";
import { OrderUpsellGroupItem } from "models/order/OrderUpsell";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useMenu } from "stores/menu";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import {
  SortedGroupOrderParticipants,
  sortGroupOrderParticipants,
} from "ui/components/GroupOrderParticipantStatus/GroupOrderParticipantStatus.helpers";
import { CheckoutPagePath, MainPagePath } from "ui/navigation/Pages";
import { EventItemParams } from "util/GoogleAnalytics";
import { logError } from "util/Logger";
import MParticle from "util/MParticle";
import { trackOptimizelyEvent, useFeatureFlag } from "util/Optimizely";
import FeatureFlags from "util/Optimizely/FeatureFlags";
import { OptimizelyEventName, OptimizelyTagName } from "util/Optimizely/OptimizelyTypes";

export type CartUpsellProduct = OrderUpsellGroupItem & {
  addToOrder: () => Promise<void>;
  images?: ImagesType[];
  calorieInfo?: CalorieInfo;
};

const useCartViewModel = ({ isOpen, onClose: closeCart }: { isOpen: boolean; onClose: () => void }) => {
  // stores
  const { addErrorAlert } = useAlert();
  const menuStore = useMenu();
  const {
    cancelOrder,
    deleteFromOrder,
    editOrderItemQuantity,
    getCurrentGroupOrderParticipants,
    order,
    orderLedgerItems,
    orderTotal,
  } = useOrder();
  const { addUpsellToOrder, smartUpsellItems, staticUpsellItems } = useOrderUpsell();
  const { isGroupOrderHost, isLoggedIn } = useUser();
  // hooks
  const { analytics, getAnalyticsItemFromCartItem, getAnalyticsItemFromCartItems } = useAnalytics();
  const isGrouporderingEnabled = useFeatureFlag(FeatureFlags.groupOrdering);
  const navigate = useNavigate();

  // local state
  const [shouldShowParticipantStatusModal, setShouldShowParticipantStatusModal] = useState(false);
  const [shouldShowParticipantStatusConfirmationModal, setShouldShowParticipantStatusConfirmationModal] =
    useState(false);
  const [shouldShowAddParticipantsModal, setShouldShowAddParticipantsModal] = useState(false);
  const [shouldShowCheckoutLoading, setShouldShowCheckoutLoading] = useState(false);
  const [shouldShowStartGroupOrderModal, setShouldShowStartGroupOrderModal] = useState(false);
  const [shouldShowLockGroupOrderModal, setShouldShowLockGroupOrderModal] = useState(false);
  const [sortedGroupOrderParticipants, setSortedGroupOrderParticipants] = useState<SortedGroupOrderParticipants>();

  const isHostPayParticipant = isHostPayGroupOrder(order) && !isGroupOrderHost(order.groupOrderDetails);
  const isHostPayHost = isHostPayGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);
  const isSplitPayHost = isSplitPayGroupOrder(order) && isGroupOrderHost(order.groupOrderDetails);

  const cartBagTitle = isHostPayGroupOrder(order) ? "Group Bag" : "Your Bag";
  const cartItems = order?.items;
  const checkoutBtnLabel = isHostPayParticipant ? "Submit" : "Checkout";
  const isCartEmpty = !order || order.items.length === 0;
  const isOpenRef = useRef(false);
  const ledgerItems = orderLedgerItems;
  const shouldGroupCartItemsByRecipient = isHostPayGroupOrder(order);
  const shouldShowEmptyCheckout = isSplitPayHost;
  const shouldShowParticipantsBanner = isGroupOrder(order) ? isGroupOrderHost(order.groupOrderDetails) : false;
  const shouldShowParticipantStatusButton = shouldShowParticipantsBanner && isSplitPayHost;
  const shouldShowGroupOrderButton = isGrouporderingEnabled && order && !isGroupOrder(order) && isLoggedIn;
  const total = orderTotal;

  const hideStartGroupOrderModal = () => setShouldShowStartGroupOrderModal(false);
  const showStartGroupOrderModal = () => setShouldShowStartGroupOrderModal(true);
  const hideAddParticipantsModal = () => setShouldShowAddParticipantsModal(false);
  const showAddParticipantsModal = () => setShouldShowAddParticipantsModal(true);
  const hideParticipantStatusModal = () => setShouldShowParticipantStatusModal(false);
  const showParticipantStatusModal = () => setShouldShowParticipantStatusModal(true);
  const hideParticipantStatusConfirmationModal = () => setShouldShowParticipantStatusConfirmationModal(false);
  const showParticipantStatusConfirmationModal = () => setShouldShowParticipantStatusConfirmationModal(true);
  const hideLockGroupOrderModal = () => setShouldShowLockGroupOrderModal(false);
  const showLockGroupOrderModal = () => setShouldShowLockGroupOrderModal(true);

  const handleAddFoodClick = () => {
    analytics.linkClick("Add Food", "Cart");
    closeCart();
    navigate(MainPagePath.menu);
  };

  const handleCartItemQuantityChange = async (item: CartItem, quantity: number) => {
    try {
      // this returns a promise so the quantitystepper knows when loading is finished
      await editOrderItemQuantity(item, quantity);

      // analytics
      try {
        if (order) {
          const products: EventItemParams[] = [];

          if (item) {
            const itemProducts = await getAnalyticsItemFromCartItem(item);
            products.push(...itemProducts);
          }

          if (item.quantity > quantity) {
            const quantityDifference = item.quantity - quantity;
            products.forEach((product) => {
              product.quantity = quantityDifference;
            });

            analytics.removeFromCart(products, order.store.name, order.orderType);
          }

          if (item.quantity < quantity) {
            const quantityDifference = quantity - item.quantity;
            analytics.addToCart(products, quantityDifference, order.store.name, order.orderType);
            trackOptimizelyEvent(OptimizelyEventName.ATB_CLICK, { [OptimizelyTagName.ITEM_NAME]: item.product.name });

            // if adding more, log add to cart event with the net quantity that is being added
            if (quantityDifference > 0) {
              MParticle.logEventAddToCart({
                items: products.map((product) => ({ ...product, quantity: quantityDifference })),
                orderId: order.id,
                storeId: order.store.id,
                storeName: order.store.name,
              });
            }
          }
        }
      } catch (e) {
        logError(e);
      }
    } catch (e) {
      addErrorAlert(OrderError.ModifyItem, e);
    }
  };

  const handleCartItemRemove = async (item: CartItem) => {
    try {
      await deleteFromOrder(item);

      try {
        if (order) {
          const products: EventItemParams[] = [];

          if (item) {
            const itemProducts = await getAnalyticsItemFromCartItem(item);
            products.push(...itemProducts);
          }

          analytics.removeFromCart(products, order.store.name, order.orderType);
        }
      } catch (e) {
        logError(e);
      }
    } catch (e) {
      addErrorAlert(OrderError.RemoveItem, e);
    }
  };

  const handleCheckoutClick = async () => {
    // if participant of a host-pay group order, skip checkout and go to confirmation screen
    if (isHostPayParticipant) {
      setShouldShowCheckoutLoading(true);

      try {
        // clear the order session
        await cancelOrder();
        closeCart();
        navigate(CheckoutPagePath.hostPayParticipantOrderConfirmation);
      } catch (e) {
        addErrorAlert(UnknownError.Unknown, e);
      }

      setShouldShowCheckoutLoading(false);
      return;
    }

    if (isHostPayHost) {
      showLockGroupOrderModal();
      return;
    }

    if (isSplitPayHost) {
      try {
        setShouldShowCheckoutLoading(true);

        const groupOrderParticipants = await getCurrentGroupOrderParticipants();
        const sortedGroupOrderParticipants = sortGroupOrderParticipants(groupOrderParticipants);
        setSortedGroupOrderParticipants(sortedGroupOrderParticipants);

        if (sortedGroupOrderParticipants.orderNotSubmittedParticipants.length > 0) {
          showParticipantStatusConfirmationModal();
          return;
        }

        closeCart();
        navigate(CheckoutPagePath.checkout);
      } catch (e) {
        addErrorAlert(UnknownError.Unknown, e);
      } finally {
        setShouldShowCheckoutLoading(false);
      }

      return;
    }

    try {
      trackOptimizelyEvent(OptimizelyEventName.CHECKOUT_CLICK);

      if (order?.items) {
        const items = await getAnalyticsItemFromCartItems(order.items);
        analytics.beginCheckout(items, order.store.name, order.orderType);
        MParticle.logEventCheckout({
          items,
          orderId: order.id,
          storeId: order.store.id,
          storeName: order.store.name,
        });
      }
    } catch (e) {
      logError(e);
    }

    closeCart();
    navigate(CheckoutPagePath.checkout);
  };

  useEffect(() => {
    async function sendViewCartEvent() {
      try {
        if (order?.items && order?.store.name && order?.orderType) {
          const products = order.items
            ? await getAnalyticsItemFromCartItems(order.items, { skipCategoryIndex: true })
            : [];
          analytics.viewCart(products, order.store.name, order.orderType);
        }
      } catch (e) {
        logError(e);
      }
    }

    // only send analytics event if the cart went from closed to open
    if (isOpen === true && isOpenRef.current === false) {
      sendViewCartEvent();
    }

    isOpenRef.current = isOpen;
  }, [order?.items, order?.orderType, order?.store.name, menuStore, isOpen, analytics, getAnalyticsItemFromCartItems]);

  return {
    addUpsellToOrder,
    cartBagTitle,
    cartItems,
    checkoutBtnLabel,
    handleAddFoodClick,
    handleCartItemQuantityChange,
    handleCartItemRemove,
    handleCheckoutClick,
    hideAddParticipantsModal,
    hideParticipantStatusModal,
    hideParticipantStatusConfirmationModal,
    hideLockGroupOrderModal,
    hideStartGroupOrderModal,
    isCartEmpty,
    ledgerItems,
    shouldGroupCartItemsByRecipient,
    shouldShowEmptyCheckout,
    shouldShowParticipantsBanner,
    shouldShowParticipantStatusButton,
    shouldShowAddParticipantsModal,
    shouldShowParticipantStatusModal,
    shouldShowParticipantStatusConfirmationModal,
    shouldShowCheckoutLoading,
    shouldShowGroupOrderButton,
    shouldShowLockGroupOrderModal,
    shouldShowStartGroupOrderModal,
    showAddParticipantsModal,
    showParticipantStatusModal,
    showStartGroupOrderModal,
    sortedGroupOrderParticipants,
    smartUpsellItems,
    staticUpsellItems,
    total,
  };
};

export default useCartViewModel;
