import { OrderType, PickupType } from "models/order/OrderType";
import OpenCloseTimes from "models/store/OpenCloseTimes";
import { Store } from "models/store/Store";
import StoreHours from "models/store/StoreHours";
import { Formatter } from "util/Formatter";

const storeHoursDayKeys = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] satisfies (keyof Omit<StoreHours, "label">)[];

const getEnabledPickupTypes = (store: Store) => {
  const orderedPickupTypes: PickupType[] = [OrderType.pickup, OrderType.curbside, OrderType.drivethru];

  const enabledPickupTypes = orderedPickupTypes.filter(
    (pickupType: PickupType) => store[pickupType] && store[pickupType]["enabled"]
  );

  return enabledPickupTypes;
};

const getHoursForPickupType = (pickupType: PickupType, storeHours: StoreHours[], timeWanted?: string) => {
  const pickupTypeHours = storeHours.find((orderTypeHours) => {
    return orderTypeForStoreHoursLabel(orderTypeHours.label) === pickupType;
  });

  if (!pickupTypeHours) return;

  const dateOfOrder = timeWanted && timeWanted.toLowerCase() !== "asap" ? new Date(timeWanted) : new Date();
  const dayOfOrder = storeHoursDayKeys[dateOfOrder.getDay()];
  const hoursForDayOfOrder = pickupTypeHours[dayOfOrder];

  if (!hoursForDayOfOrder) return;

  const formattedHoursForDayOfOrder = hoursForDayOfOrder
    .map((time: OpenCloseTimes) => {
      const { opens, closes } = time;
      const fOpens = Formatter.formatMilitaryTime(opens);
      const fCloses = Formatter.formatMilitaryTime(closes);

      if (!fOpens || !fCloses) return;

      return { opens: fOpens, closes: fCloses };
    })
    .filter(Boolean) as OpenCloseTimes[];

  const displayHours = formattedHoursForDayOfOrder.map((time) => `${time.opens} - ${time.closes}`).join(", ");

  return displayHours;
};

const orderTypeForStoreHoursLabel = (label: string) => {
  switch (label) {
    case "Pickup":
      return OrderType.pickup;
    case "Delivery":
      return OrderType.delivery;
    case "Curbside Pickup":
      return OrderType.curbside;
    case "Drive Through":
      return OrderType.drivethru;
    default:
      return undefined;
  }
};

export { getEnabledPickupTypes, getHoursForPickupType };
