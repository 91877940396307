import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import MenuLayout from "ui/app/MenuLayout";
import { MainPagePath } from "ui/navigation/Pages";

const CategoryScreen = lazy(() => import("ui/screens/CategoryScreen"));
const CateringScreen = lazy(() => import("ui/screens/CateringScreen"));
const MenuScreen = lazy(() => import("ui/screens/MenuScreen"));
const ProductScreenViewProvider = lazy(() => import("ui/screens/Product/ProductScreenViewProvider"));

const MenuRoutes = (
  <>
    <Route path={MainPagePath.catering} element={<CateringScreen />} />
    <Route path={MainPagePath.product} element={<ProductScreenViewProvider />} />
    <Route path={MainPagePath.productVanityRegularMenu} element={<ProductScreenViewProvider />} />
    <Route path={MainPagePath.productVanityCateringMenu} element={<ProductScreenViewProvider />} />
    <Route element={<MenuLayout />}>
      <Route path={MainPagePath.menu} element={<MenuScreen />} />
      <Route path={MainPagePath.category} element={<CategoryScreen />} />
      <Route path={MainPagePath.categoryVanityRegularMenu} element={<CategoryScreen />} />
      <Route path={MainPagePath.categoryVanityCateringMenu} element={<CategoryScreen />} />
    </Route>

    {/* 
    Redirect regular/catering menu 404s to regular/catering menu home, respectively.
    These routes will probably never get hit, because invalid menu routes should be caught by the vanity routes, above.
    The vanity routes have their own 404 handling in their respective view models.
    */}
    <Route path={`${MainPagePath.catering}/*`} element={<Navigate to={MainPagePath.catering} replace />} />
    <Route path={`${MainPagePath.menu}/*`} element={<Navigate to={MainPagePath.menu} replace />} />
  </>
);

export default MenuRoutes;
