export type ErrorType = UnknownError | MenuError | OrderError | StoreError | UserAccountError;

export enum UnknownError {
  Unknown = "Unknown",
}

export enum MenuError {
  General = "GeneralMenuError",
}

export enum OrderError {
  AddFave = "AddFave",
  AddFaveItem = "AddFaveItem",
  AddItem = "AddItem",
  AddMultipleItems = "AddMultipleItems",
  AddUpsell = "AddUpsell",
  ApplePay = "ApplePay",
  ApplyCoupon = "ApplyCoupon",
  ApplyTip = "ApplyTip",
  CancelOrder = "CancelOrder",
  CheckIn = "CheckIn",
  CreateOrder = "CreateOrder",
  FetchOrder = "FetchOrder",
  FetchUpsell = "FetchUpsell",
  GroupOrderSubmission = "GroupOrderSubmission",
  JoinGroupOrder = "JoinGroupOrder",
  ModifyItem = "ModifyItem",
  ModifyOrderType = "ModifyOrderType",
  ModifyOrderTime = "ModifyOrderTime",
  RedeemReward = "RedeemReward",
  RemoveCoupon = "RemoveCoupon",
  RemoveItem = "RemoveItem",
  RemoveReward = "RemoveReward",
  RepeatOrder = "RepeatOrder",
  SubmitOrder = "SubmitOrder",
  ValidateOrder = "ValidateOrder",
}

export enum StoreError {
  FetchOrderAheadTimes = "FetchOrderAheadTimes",
  GeneralStoreError = "GeneralStoreError",
  SearchStores = "SearchStores",
}

export enum UserAccountError {
  ChangeEmail = "ChangeEmail",
  ChangePassword = "ChangePassword",
  ChangeUsername = "ChangeUsername",
  CreateAccount = "CreateAccount",
  CreateFave = "CreateFave",
  DeleteAccount = "DeleteAccount",
  FetchFaves = "FetchFaves",
  FetchRecentOrders = "FetchRecentOrders",
  FetchRewards = "FetchRewards",
  FetchSavedDeliveryAddresses = "FetchSavedDeliveryAddresses",
  FetchSavedLocations = "FetchSavedLocations",
  FetchSavedPayments = "FetchSavedPayments",
  FetchUser = "FetchUser",
  LogIn = "LogIn",
  LogOut = "LogOut",
  ModifySavedPayment = "ModifySavedPayment",
  ModifyUserProfile = "ModifyUserProfile",
  RemoveFave = "RemoveFave",
  RemoveSavedDeliveryAddress = "RemoveSavedDeliveryAddress",
  RemoveSavedLocation = "RemoveSavedLocation",
  RemoveSavedPayment = "RemoveSavedPayment",
  ResetPassword = "ResetPassword",
  SavePaymentMethod = "SavePaymentMethod",
  SetDefaultDeliveryAddress = "SetDefaultDeliveryAddress",
  SetDefaultLocation = "SetDefaultLocation",
  SetDefaultPaymentMethod = "SetDefaultPaymentMethod",
}
