import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  label: (theme) => ({
    display: "flex",
    flexDirection: "column",
    position: "static",
    transform: "none",
    whiteSpace: "normal",
    "&.MuiInputLabel-root": {
      typography: theme.typography.formFieldLabelCaps,
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[500],
    },
    "&.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "&.Mui-error": {
      color: theme.palette.secondary.main,
    },
  }),
  labelText: {
    display: "flex",
    gap: 0.5,
  },
  optionalText: {
    color: (theme) => theme.palette.grey[500],
    textTransform: "none",
  },
  description: {
    color: (theme) => theme.palette.grey[500],
    textTransform: "none",
  },
  textfield: (theme) => ({
    "label + &": {
      marginTop: theme.spacing(1),
    },
  }),
  errorBox: {
    display: "inline-flex",
    flexDirection: "row",
    marginTop: "0.5rem",
  },
  errorText: (theme) => ({
    typography: theme.typography.formFieldText,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    textTransform: "none",
    display: "inline-flex",
    flexDirection: "row",
    marginLeft: "0.5rem",
    marginTop: 0,
  }),
};

export default styles;
