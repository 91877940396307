/**
 * Comes back from the footer links call
 */
export type Thumbnail = {
  url: string;
  text: string;
  type: ThumbnailType;
};

export enum ThumbnailType {
  facebook = "FACEBOOK",
  twitter = "TWITTER",
  instagram = "INSTAGRAM",
  youtube = "YOUTUBE",
}

const isThumbnailType = (object: unknown): object is ThumbnailType => {
  const type = object as ThumbnailType;
  return Object.values(ThumbnailType).includes(type);
};

const isThumbnail = (object: unknown): object is Thumbnail => {
  const thumbnail = object as Thumbnail;
  return !!thumbnail && !!thumbnail.url && !!thumbnail.type && isThumbnailType(thumbnail.type) && !!thumbnail.text;
};

export const isThumbnailArray = (object: unknown): object is Thumbnail[] => {
  const array = object as Thumbnail[];
  return !!array && Array.isArray(array) && array.length === array.filter((o) => isThumbnail(o)).length;
};
