import React from "react";
import styled from "styled-components";
import noImage from "ui/commerceUI/components/placeholders/no-image-large.png";
import { BodyCopy } from "ui/commerceUI/foundations/typography/Typography";
import { Size } from "ui/Size";
import { Stylable } from "ui/Stylable";
import { Theme } from "ui/Theme";

/***
 * Set custom sizing on a placeholder by wrapping it in styled(). Example:
   const StyledImagePlaceholder = styled(ImagePlaceholder)`
       height: 152px;
       width: 152px;
       margin-bottom: 16px;
   `;
 <StyledImagePlaceholder size={PlaceholderType.medium}/>

 Or drop in a container where the image would go with the same attributes!
 */

interface Props extends Stylable {
  size?: Size;
}

export const ImageUnavailable = ({ size = Size.medium, className }: Props) => {
  switch (size) {
    case Size.small:
      return <SmallImagePlaceholder className={className} />;
    case Size.medium:
      return <MediumImagePlaceholder className={className} />;
    case Size.large:
      return <LargeImagePlaceholder className={className} />;
  }
};

const ImageContainer = styled.div`
  background-color: ${Theme.colors.gray10};
  border-radius: ${Theme.layout.borderRadius};
  color: ${Theme.colors.gray80};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

type PlaceholderProps = Stylable;

const SmallImagePlaceholder = ({ className }: PlaceholderProps) => (
  <ImageContainer role="presentation" className={className}>
    <BodyCopy size={Size.small}>No image</BodyCopy>
  </ImageContainer>
);

const MediumImagePlaceholder = ({ className }: PlaceholderProps) => {
  return (
    <ImageContainer role="presentation" className={className}>
      <BodyCopy size={Size.small}>No image available</BodyCopy>
    </ImageContainer>
  );
};

const LargeTextContainer = styled.div`
  margin-top: 16px;
`;

const LargeImagePlaceholder = ({ className }: PlaceholderProps) => (
  <ImageContainer className={className}>
    <img role="presentation" src={noImage} alt="" />
    <LargeTextContainer>
      <BodyCopy>No image available</BodyCopy>
    </LargeTextContainer>
  </ImageContainer>
);
