import { OrderError } from "errors/Errors";
import useAnalytics from "hooks/useAnalytics";
import { OrderUpsellGroupItem } from "models/order/OrderUpsell";
import { isProductItem, ProductItem } from "models/ProductItem";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "stores/alert";
import { useCart } from "stores/cart";
import { useMenu } from "stores/menu";
import { useOrder } from "stores/order";
import { MainPage } from "ui/navigation/Pages";
import { EventItemParams } from "util/GoogleAnalytics";
import { logError } from "util/Logger";
import MParticle from "util/MParticle";
import { trackOptimizelyEvent } from "util/Optimizely";
import { OptimizelyEventName, OptimizelyTagName, PlacementOptions } from "util/Optimizely/OptimizelyTypes";

export type CartUpsellViewModelProps = {
  addUpsellToOrder: (productId: string, quantity: number) => Promise<void>;
  smartUpsellItems?: OrderUpsellGroupItem[];
  staticUpsellItems?: OrderUpsellGroupItem[];
};

const useCartUpsellViewModel = ({
  addUpsellToOrder,
  smartUpsellItems,
  staticUpsellItems,
}: CartUpsellViewModelProps) => {
  const { addErrorAlert } = useAlert();
  const { analytics, getAnalyticsItemFromProductId } = useAnalytics();
  const { hideCart } = useCart();
  const { products } = useMenu();
  const { order } = useOrder();
  const navigate = useNavigate();
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const upsellProducts = useMemo(() => {
    if (!smartUpsellItems?.length && !staticUpsellItems?.length) return [];

    const orderItemNames = order?.items?.map(({ product }) => product.name.toLowerCase());

    // Filter out any "static" items that have a name (case ignored) already present in the order items
    const filteredStaticUpsellItems =
      staticUpsellItems?.filter(({ name }) => !orderItemNames?.includes(name.toLowerCase())) ?? [];

    const staticUpsellItemNames = filteredStaticUpsellItems?.map(({ name }) => name.toLowerCase());

    // Filter out any "smart" items that share a name (case ignored) with any "static" upsell items or any order items
    const filteredSmartUpsellItems =
      smartUpsellItems
        ?.filter(({ name }) => !staticUpsellItemNames.includes(name.toLowerCase()))
        ?.filter(({ name }) => !orderItemNames?.includes(name.toLowerCase())) ?? [];

    let upsellItems: OrderUpsellGroupItem[] = [];

    if (filteredSmartUpsellItems?.length) {
      // upsell items should consist of up to 2 static upsells followed by smart upsells, up to a total of 6 upsell items
      // e.g. [ static, static, smart, smart, smart, smart ]
      upsellItems = [...filteredStaticUpsellItems.slice(0, 2), ...filteredSmartUpsellItems].slice(0, 6);
    } else {
      // if there are no smart upsell items, just show all static items (legacy behavior, pre-smart upsells)
      upsellItems = filteredStaticUpsellItems;
    }

    return upsellItems
      .map((item) => {
        const product = products?.find((product) => product.id === item.id);

        if (!product) return;

        return {
          cost: item.cost,
          ...product,
        };
      })
      .filter(isUpsellProduct);
  }, [order?.items, products, smartUpsellItems, staticUpsellItems]);

  const onClickAddToCart = (product: ProductItem) => async () => {
    setIsAddingToCart(true);

    try {
      await addUpsellToOrder(product.id, 1);

      // analytics
      try {
        if (order?.store.name && order?.orderType) {
          const products: EventItemParams[] = [];

          const itemProducts = await getAnalyticsItemFromProductId(product.id);
          products.push(...itemProducts);

          analytics.addToCart(products, 1, order.store.name, order.orderType);

          MParticle.logEventAddToCart({
            items: products,
            orderId: order.id,
            storeId: order.store.id,
            storeName: order.store.name,
          });

          trackOptimizelyEvent(OptimizelyEventName.ATB_CLICK, { [OptimizelyTagName.ITEM_NAME]: product.name });
        }

        analytics.upsellAdd(product.name);

        trackOptimizelyEvent(OptimizelyEventName.CROSS_SELL_CLICK, {
          [OptimizelyTagName.ITEM_NAME]: product.name,
          [OptimizelyTagName.PLACEMENT]: PlacementOptions.BAG,
        });
      } catch (e) {
        logError(e);
      }
    } catch (e) {
      addErrorAlert(OrderError.AddUpsell, e);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const onClickModify = (product: ProductItem) => () => {
    navigate(MainPage.product(product).path, { state: { productId: product.id, isUpsell: true } });
    hideCart();
  };

  const onImpression = (productName?: string | null) => {
    if (!productName) return;
    analytics.upsellImpression(productName);
  };

  const onScroll = () => {
    analytics.upsellScroll();
  };

  return {
    isAddingToCart,
    onClickAddToCart,
    onClickModify,
    onImpression,
    onScroll,
    upsellProducts,
  };
};

export type UpsellProduct = ProductItem & {
  cost: string;
};

const isUpsellProduct = (object: unknown): object is UpsellProduct => {
  const upsellProduct = object as UpsellProduct;
  return isProductItem(upsellProduct) && !!upsellProduct.cost;
};

export default useCartUpsellViewModel;
