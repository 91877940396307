import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  closeButton: {
    marginRight: "-0.75rem",
    padding: "0.75rem",

    "&.close-button-large": {
      marginRight: "-0.5rem",
      padding: "0.5rem",
    },
  },
  content: {
    ".MuiDialogTitle-root + &": {
      paddingTop: "1.5rem",
    },
  },
  actions: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    width: "100%",
  },
};

export default styles;
