/**
 * Local error codes are for locally generated errors. They are used in the code property of the error
 */
export enum LocalErrorCodes {
  /** Response was successful but the data did not pass local validations. */
  MalformedData = "MalformedData",

  /** Unknown error */
  UnknownError = "UnknownError",

  /** An order that existed locally does not exist on the backend */
  OrderNotFound = "OrderNotFound",

  /** The user tried to do something that they needed to be authenticated for and were not */
  NotAuthenticated = "NotAuthenticated",

  /** Used when a user changes order type in checkout with an order time that is no longer available*/
  OrderAheadTimeUnavailable = "OrderAheadTimeUnavailable",

  /** Waiting for the CSRF token has timed out */
  CSRFTimeOut = "CSRFTimeOut",

  /** The order returned successfully, but there is an error with at least on order item */
  ErrorWithOrderItem = "ErrorWithOrderItem",
}
