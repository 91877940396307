import { Button, Typography } from "@mui/material";
import useViewModel, {
  LockGroupOrderModalViewModelProps as ViewModelProps,
} from "ui/components/Cart/LockGroupOrderModal/LockGroupOrderModalViewModel";
import Modal from "ui/components/Modal";

export type LockGroupOrderModalProps = ViewModelProps & {
  open: boolean;
};

const LockGroupOrderModal = ({ open, ...viewModelProps }: LockGroupOrderModalProps) => {
  const { onClose, checkout } = useViewModel(viewModelProps);

  const modalActions = [
    <Button variant="text" onClick={onClose} key="cancel">
      Cancel
    </Button>,
    <Button onClick={() => checkout()} key="save">
      Proceed to Checkout
    </Button>,
  ];

  return (
    <Modal actions={modalActions} onClose={onClose} open={open} title="Stop Collecting Orders">
      <Typography>
        Once you proceed to checkout, participants&apos; orders will be final, and they will not be able to edit or add
        to their orders. You will still be able to edit orders if needed.
      </Typography>
    </Modal>
  );
};

export default LockGroupOrderModal;
