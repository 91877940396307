// import { fontFamilyKnockout } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    backgroundColor: theme.palette.common.white,
    overflow: "hidden",
    border: `0.0625rem solid ${theme.palette.grey[200]}`,
    width: "9rem",
    height: "3rem",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    "&.full-width": {
      width: "100%",
    },

    "&.no-border": {
      border: "none",
    },
  }),
  stepperBtn: (theme) => ({
    borderRadius: 0,
    height: "3rem",
    width: "3rem",
    flexShrink: 0,
    "&:hover": {
      svg: { fill: theme.palette.primary.dark },
      backgroundColor: theme.palette.grey[100],
    },
    "&:active": {
      svg: {
        fill: theme.palette.common.black,
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  }),
  focus: (theme) => ({
    ":focus-visible": {
      outline: `0.25rem solid ${theme.palette.primary.light}`,
    },
  }),
  input: (theme) => ({
    background: "transparent",
    border: 0,
    textAlign: "center",
    typography: theme.typography.formFieldLabelCaps,
    width: "3rem",

    "&:disabled": {
      color: "grey.500",
    },
  }),
} satisfies MuiStyles;

export default styles;
