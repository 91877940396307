export enum ModifierType {
  SingleSelect = "single-select",
  MultiSelect = "multi-select",
  PreSelect = "pre-select",
  Stepper = "stepper",
  Checkbox = "checkbox",
}

export const isModifierType = (object: unknown): object is ModifierType => {
  return (
    object === ModifierType.SingleSelect ||
    object === ModifierType.MultiSelect ||
    object === ModifierType.Stepper ||
    object === ModifierType.Checkbox
  );
};
