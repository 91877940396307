import { action, makeObservable, observable } from "mobx";
import { Size } from "ui/Size";

/**
 * This class creates an observable for what the size of the screen should be based on the width of the window.
 * This is the *only* class that should be referenced directly in ScreenViews (as opposed to referencing
 * things from the RootStore).
 *
 * The purpose of this class and its size observable is to encapsulate sizing
 *
 * This is referenced directly in ScreenViews because if it was set in ViewProviders, the ViewModels created by the
 * ViewProvider would be recreated as well, losing any local state on resize.
 * Injection of size should still happen when possible (for example, only ScreenViews should need to observe this,
 * its child components can then have it injected from the ScreenView)
 */

export const SizeConstants = {
  largeMinimum: 1024,
  mediumMinimum: 768,
};

export interface AppSizable {
  size: Size;
}

class SizeManager implements AppSizable {
  @observable size: Size;

  private readonly document: Document;
  private readonly window: Window;

  constructor(window: Window, document: Document) {
    makeObservable(this);
    this.size = this.calculateSize(window, document);
    this.window = window;
    this.document = document;
    window.onresize = (): void => {
      this.handleResize();
    };
  }

  @action handleResize(): void {
    const newSize = this.calculateSize(this.window, this.document);
    if (this.size !== newSize) {
      this.size = newSize;
    }
  }

  calculateSize(window: Window, document: Document): Size {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width >= SizeConstants.largeMinimum
      ? Size.large
      : width >= SizeConstants.mediumMinimum
      ? Size.medium
      : Size.small;
  }
}

/** Singleton that can be used for injecting the app's implementation of AppSizable */
export class AppSize {
  static get sizer(): AppSizable {
    if (AppSize._sizer) return AppSize._sizer;
    const sizer = new SizeManager(window, document);
    this._sizer = sizer;
    return sizer;
  }

  static set sizer(sizer: AppSizable | undefined) {
    this._sizer = sizer;
  }
  private static _sizer?: AppSizable;

  /** Call at the start of the app to start running this so the rest of the app can react to size changes */
  static start(window: Window, document: Document): AppSizable {
    if (AppSize._sizer) return AppSize._sizer;
    const sizer = new SizeManager(window, document);
    this._sizer = sizer;
    return sizer;
  }
}
