import MuiStyles from "ui/util/Styles";

const styles = {
  divider: {
    marginY: "1rem",
  },
  pariticipantList: {
    gap: "0.5rem",

    "& ul": {
      listStyleType: "none",
      margin: 0,
      padding: 0,
    },
  },
  participantRow: {
    alignItems: "center",
    display: "flex",
    gap: "0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;
