import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  accordion: {
    borderBottom: "none",
    borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
    "&:before": { display: "none" },
  },
  accordionDetails: {
    padding: "0 0 1.5rem",

    "& a": {
      color: Colors.superBlack,
    },
  },
  accordionSummary: {
    alignItems: "flex-start",
    padding: 0,

    "& .MuiAccordionSummary-content": (theme) => ({
      margin: theme.spacing(2, 1, 2, 0),
    }),

    "& .MuiAccordionSummary-expandIconWrapper": (theme) => ({
      color: theme.palette.grey[300],
      margin: theme.spacing(2, 0),
    }),

    "&:hover .MuiAccordionSummary-expandIconWrapper": {
      color: Colors.superBlack,
    },
  },
  expandIcon: {
    transform: "scale(1.5)",
  },
};

export default styles;
