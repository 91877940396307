import _ from "lodash";
import OpenCloseTimes, { isOpenCloseTimes } from "models/store/OpenCloseTimes";

/**
 * Defined in Swagger: Store -> StoreHours
 * https://github.com/detroit-labs/octocart-backend/blob/main/docs/open-api/stores/models/store-hours.yaml
 */
type StoreHours = {
  label: string;
  sunday: OpenCloseTimes[];
  monday: OpenCloseTimes[];
  tuesday: OpenCloseTimes[];
  wednesday: OpenCloseTimes[];
  thursday: OpenCloseTimes[];
  friday: OpenCloseTimes[];
  saturday: OpenCloseTimes[];
};

export const isStoreHours = (object: unknown): object is StoreHours => {
  const storeHours = object as StoreHours;
  return (
    !!storeHours &&
    _.isString(storeHours.label) &&
    _.every(storeHours.sunday, isOpenCloseTimes) &&
    _.every(storeHours.monday, isOpenCloseTimes) &&
    _.every(storeHours.tuesday, isOpenCloseTimes) &&
    _.every(storeHours.wednesday, isOpenCloseTimes) &&
    _.every(storeHours.thursday, isOpenCloseTimes) &&
    _.every(storeHours.friday, isOpenCloseTimes) &&
    _.every(storeHours.saturday, isOpenCloseTimes)
  );
};

export default StoreHours;
