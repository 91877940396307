import Colors from "ui/theme/Colors";
import { fontFamilyBlankenhorn } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  alertHeadline: (theme) => ({
    color: theme.palette.primary.main,
    fontFamily: fontFamilyBlankenhorn,
    fontSize: theme.pxToRem(48),
    fontWeight: 400,
    gap: "0.5rem",
    letterSpacing: "0.06rem",
    lineHeight: theme.pxToRem(58),

    [theme.breakpoints.up("md")]: {
      fontSize: theme.pxToRem(64),
      letterSpacing: "0.08rem",
      lineHeight: theme.pxToRem(77),
    },
  }),
  carousel: (theme) => ({
    "& .splide__arrows": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    "& .splide__arrow": {
      background: theme.palette.primary.main,
      height: "4rem",
      opacity: 1,
      width: "4rem",

      "&:disabled": {
        display: "none",
      },

      "& svg": {
        fill: "#FFF",
      },
    },

    "& .splide__pagination": {
      background: Colors.brightWhite,
      bottom: 0,
      paddingY: "1rem",
      position: "sticky",

      "& > *": {
        display: "flex",
      },
    },

    "& .splide__pagination__page": {
      background: theme.palette.grey[400],
      margin: "0 0.25rem",
      opacity: 1,

      "&.is-active": {
        background: theme.palette.primary.main,
      },
    },
  }),
  container: {
    alignItems: "center",
    gap: "1.5rem",
    padding: 0,
    paddingBottom: "1.5rem",
  },
  description: (theme) => ({
    [theme.breakpoints.up("md")]: {
      width: "34.5rem",
    },
  }),
  details: (theme) => ({
    alignItems: "center",
    gap: "0.5rem",
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      gap: "1rem",
    },
  }),
  header: {
    textAlign: "center",
  },
  image: (theme) => ({
    aspectRatio: 1,
    width: "11.5rem",

    [theme.breakpoints.up("md")]: {
      width: "12.5rem",
    },
  }),
  progressDescription: (theme) => ({
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      width: "24rem",
    },

    "& em": {
      color: Colors.superBlack,
      fontStyle: "normal",
    },
  }),
  progressItem: {
    alignItems: "center",
    gap: "0.5rem",
  },
  progressItemText: {
    color: (theme) => theme.palette.grey[500],

    ".completed &": {
      color: Colors.superBlack,
    },
  },
  progressItems: (theme) => ({
    gap: "0.5rem",
    margin: 0,
    padding: 0,

    [theme.breakpoints.up("md")]: {
      gap: "1rem",
    },
  }),
  progressStepIcon: (theme) => ({
    width: "1rem",

    [theme.breakpoints.up("md")]: {
      width: "1.5rem",
    },
  }),
  terms: (theme) => ({
    alignItems: "center",
    gap: "1rem",

    [theme.breakpoints.up("md")]: {
      width: "36rem",
    },
  }),
  termsToggle: {
    borderBottom: "1px solid transparent",
    color: (theme) => theme.palette.primary.main,

    "&:hover": {
      color: (theme) => theme.palette.primary.main,
    },

    "& .MuiButton-endIcon": {
      margin: 0,
    },

    "& .MuiButton-endIcon svg": {
      fontSize: "1.5rem !important",
    },
  },
  termsText: {
    textAlign: "center",
  },
} satisfies MuiStyles;

export default styles;
