export enum OrderType {
  delivery = "delivery",
  pickup = "pickup",
  curbside = "curbside",
  drivethru = "drivethru",
}

export const isOrderType = (object: unknown): object is OrderType => {
  return (
    object === OrderType.delivery ||
    object === OrderType.pickup ||
    object === OrderType.curbside ||
    object === OrderType.drivethru
  );
};

export type PickupType = OrderType.curbside | OrderType.drivethru | OrderType.pickup;

export const isPickupType = (object: unknown): object is PickupType => {
  return object === OrderType.pickup || object === OrderType.curbside || object === OrderType.drivethru;
};

export const displayNameForOrderType = (orderType: OrderType) => {
  switch (orderType) {
    case OrderType.curbside:
      return "Curbside Pickup";
    case OrderType.delivery:
      return "Delivery";
    case OrderType.drivethru:
      return "Drive-Thru Pickup";
    case OrderType.pickup:
      return "In-Store Pickup";
  }
};
