import useAnalytics from "hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import { useCart } from "stores/cart";
import { useOrder } from "stores/order";
import { CheckoutPagePath } from "ui/navigation/Pages";
import { logError } from "util/Logger";
import MParticle from "util/MParticle";
import { trackOptimizelyEvent } from "util/Optimizely";
import { OptimizelyEventName } from "util/Optimizely/OptimizelyTypes";

export type LockGroupOrderModalViewModelProps = {
  onClose: () => void;
};

const useLockGroupOrderModalViewModel = ({ onClose }: LockGroupOrderModalViewModelProps) => {
  const { analytics, getAnalyticsItemFromCartItems } = useAnalytics();
  const { hideCart } = useCart();
  const navigate = useNavigate();
  const { order } = useOrder();

  const checkout = async () => {
    try {
      trackOptimizelyEvent(OptimizelyEventName.CHECKOUT_CLICK);

      if (order?.items) {
        const items = await getAnalyticsItemFromCartItems(order.items);
        analytics.beginCheckout(items, order.store.name, order.orderType);
        MParticle.logEventCheckout({
          items,
          orderId: order.id,
          storeId: order.store.id,
          storeName: order.store.name,
        });
      }
    } catch (e) {
      logError(e);
    }

    hideCart();
    navigate(CheckoutPagePath.checkout);
    onClose();
  };

  return {
    onClose,
    checkout,
  };
};

export default useLockGroupOrderModalViewModel;
