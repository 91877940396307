import { computed, IReactionDisposer, makeObservable } from "mobx";
import { AppSizable, AppSize } from "ui/AppSize";
import { Size } from "ui/Size";

export const AriaLiveContentID = "ariaLiveContentRegion";

/**
 * The BaseResizableComponentView should be the base class of any components that need to respond to the global app
 * size but are not screens themselves
 */
export abstract class BaseResizableComponentViewModel {
  @computed get size(): Size {
    return this.sizer.size;
  }

  /** Reactions that should be disposed of in stop() */
  private reactions: IReactionDisposer[] = [];
  private readonly sizer: AppSizable;

  protected constructor(sizer?: AppSizable) {
    makeObservable(this);
    if (!sizer) {
      this.sizer = AppSize.start(window, document);
    } else {
      this.sizer = sizer;
    }
  }

  /**
   * Called in useEffect when the component is mounting
   * Override if more observables are needed (and use addReaction so they're automatically disposed)
   * This will happen automatically if using the BaseResizeableComponentView. If not, you'll need to call it yourself in useEffect
   */
  start(): void {
    // Implement if more observables are needed
  }

  /**
   * Called in useEffect so it will run when the component is unmounting.
   * Override if necessary. All reactions added via `addReaction` will be cleared automatically
   * This will happen automatically if using the BaseResizeableComponentView. If not, you'll need to call it yourself in useEffect
   */
  stop(): void {
    this.reactions.forEach((r) => r());
    this.reactions = [];
    // Implement if needed
  }

  /** Add a reaction to be automatically disposed of on unmount */
  readonly addReaction = (reaction: IReactionDisposer): void => {
    this.reactions.push(reaction);
  };

  readonly setAriaLiveText = (text: string): void => {
    setTimeout(() => {
      const ariaLiveDiv = document.getElementById(AriaLiveContentID) as HTMLDivElement;
      if (ariaLiveDiv) {
        ariaLiveDiv.textContent = text;
        setTimeout(() => {
          ariaLiveDiv.textContent = null;
        }, 3000);
      }
    }, 1000);
  };
}
