import { BannerInteraction, BannerInteractionKey, BannerKey } from "util/GoogleAnalytics";

/**
 * This function creates an analytics object with appropriate property names based on the kind of `BannerKey` passed in.
 * @param bannerKey the type of banner used for generating analytics property names, @see BannerKey
 * @param bannerInteraction which kind of interaction the user has, @see BannerInteraction
 * @param bannerName the name of the banner
 * @returns an object with property names mapped from `bannerKey`, e.g. `gift_card_interaction` or `banner_name`,
 * and property values assigned from the `bannerInteraction` and `bannerName` inputs.
 * For a `"gift_card"` `bannerKey` input, the following output could be produced:
 * @example
 * {
 *  gift_card_interaction: "CTA Click",
 *  gift_card_name: "Plastic Gift Cards"
 * }
 */
export const createBannerAnalytics = <Key extends BannerKey>(
  bannerKey: Key,
  bannerInteraction: BannerInteraction,
  bannerName: string
) => {
  return {
    [`${bannerKey}_interaction`]: bannerInteraction,
    [`${bannerKey}_name`]: bannerName,
  } as Key extends unknown
    ? {
        [PropertyName in `${Key}${"_interaction" | "_name"}`]: PropertyName extends BannerInteractionKey
          ? BannerInteraction
          : string;
      }
    : never;
};
