import { Colors, Layout } from "ui/theme/Types";

/** @deprecated */
const defaultColors: Colors = {
  /** Base Palette */
  primary100: "#1F2937",
  secondary100: "#4B5563",
  tertiary100: "#DC92A0",

  /** Extended Palette */
  primary150: "#1F2937",
  secondary150: "#4B5563",
  tertiary150: "#C76072",
  primary50: "#1F2937",
  secondary50: "#4B5563",
  tertiary50: "#F5DFE3",

  /** Semantic Colors */
  announcement100: "#5DA3DD",
  announcement50: "#E1F2FC",
  success100: "#0E836E",
  success50: "#DDF4E8",
  warning100: "#EFBA00",
  warning50: "#FEEFAF",
  error100: "#C63D3A",
  error50: "#FBE4E8",

  /** Neutrals/Greyscale */
  gray100: "#1F2937",
  gray80: "#4B5563",
  gray60: "#9CA3AF",
  gray40: "#D1D5DB",
  gray20: "#E5E7EB",
  gray10: "#F7F8F9",
  overlay: "#1F293755",
  white: "#FFFFFF",

  contrastPrimary: "#FFFFFF",
  contrastDisabled: "#FFFFFF50",
  contrastSecondary: "#FFFFFF80",
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

const layout: Layout = {
  borderRadius: "0.25rem", // 4px
  buttonWidth: "20.5rem", // Common button size or max size, not in Figma. Default 328px
  spacing: "1.5rem", // Default spacing between children, not in Figma. 24px
  margin: {
    // Used for margins or padding
    small: "1rem", // 16px
    medium: "2.5rem", // 40px
    large: "3rem", // 48px
  },
  screen: {
    // Max widths of the content of screens
    small: breakpoints.sm + "px",
    medium: breakpoints.md + "px",
    large: breakpoints.xxl + "px",
  },
};

export class Theme {
  /** @deprecated Use theme.palette or ui/theme/Colors.ts, instead. */
  static readonly colors: Colors = defaultColors;
  static readonly layout: Layout = layout;
  static readonly breakpoints = breakpoints;
}

// Helps keep z-indexes consistent so unrelated views aren't stepping on each other
export const ZIndex = {
  max: 100,
  high: 90,
  medium: 45,
  low: 10,
  default: "auto",
};
