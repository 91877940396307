import { DateTime, SystemZone } from "luxon";
import { displayNameForOrderType, OrderType } from "models/order/OrderType";
import OrderAheadDateTimes from "models/store/OrderAheadDateTimes";
import { OrderTimePickerOption } from "ui/components/OrderTimePicker/OrderTimePickerViewModel";

const createTimeSelectOptionLabel = (value: string) => {
  if (value.toUpperCase() === "ASAP") {
    return value;
  }

  const startTime = DateTime.fromISO(value, { setZone: true });

  if (!startTime.isValid) return value;

  const endTime = startTime.plus({ minutes: 15 });
  const formattedStartTime = startTime.toLocaleString(DateTime.TIME_SIMPLE);
  const formattedEndTime = endTime.toLocaleString(DateTime.TIME_SIMPLE);

  return `${formattedStartTime} - ${formattedEndTime}`;
};

const createTimeZoneHelperText = (timestamp: string) => {
  const startTime = DateTime.fromISO(timestamp);

  if (!startTime.isValid) return;

  const endTime = startTime.plus({ minutes: 15 });
  const formattedStartTime = startTime.toFormat("h:mm a");
  const formattedEndTime = endTime.toFormat("h:mm a ZZZZ");

  return `* ${formattedStartTime} - ${formattedEndTime}`;
};

const getBrowserTimeZoneOffset = () => SystemZone.instance.formatOffset(DateTime.now().toMillis(), "short");

const getDayForTimeValue = (dateTimes: OrderAheadDateTimes[], value: string) => {
  const dateTime = dateTimes.find((day) => {
    return day.timeSlots.find((timeSlot) => timeSlot.value === value);
  });

  return dateTime?.text;
};

const getOrderTimeUnvailableError = (orderType: OrderType) => {
  const orderTypeDisplayName = displayNameForOrderType(orderType).toLowerCase();
  return `Please note that your order time has been updated, as the previously selected time is not available for ${orderTypeDisplayName}.`;
};

const getTimeOptionsForDay = (day: string, orderAheadDateTimes: OrderAheadDateTimes[]) => {
  if (!day) return [];

  const timeSlotsForDay = orderAheadDateTimes.find((dateTimes) => dateTimes.text === day)?.timeSlots;

  if (!timeSlotsForDay) return [];

  const timeOptions: OrderTimePickerOption[] =
    timeSlotsForDay?.map(({ value }) => {
      return {
        label: createTimeSelectOptionLabel(value),
        value,
      };
    }) ?? [];

  return timeOptions;
};

export {
  createTimeZoneHelperText,
  getBrowserTimeZoneOffset,
  getDayForTimeValue,
  getOrderTimeUnvailableError,
  getTimeOptionsForDay,
};
