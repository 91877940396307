import { FilterOption } from "models/Filter";
import MetadataKey from "models/MetadataKey";
import { ProductItem } from "models/ProductItem";
import getMetadataValueForKey from "util/MetadataHelper";

export const getCategoryById = (categories: FilterOption[], categoryId: string) => {
  return categories?.find((category) => category.id === categoryId);
};

export const getCategoryByVanityMetadata = (categories: FilterOption[], categorySlug: string) => {
  return categories?.find((category) => {
    const categoryUrlSlug = getMetadataValueForKey(category, MetadataKey.UrlSlug);
    return categoryUrlSlug === categorySlug;
  });
};

export const getProductByVanityMetadata = (products: ProductItem[], categorySlug: string, productSlug: string) => {
  return products?.find(({ categories, dynamicData }) => {
    if (!categories[0]) return false;

    const category = categories[0];

    const categoryUrlSlug = getMetadataValueForKey(category, MetadataKey.UrlSlug);
    if (categoryUrlSlug !== categorySlug) return false;

    const productUrlSlug = dynamicData?.metaData.find(({ name }) => name === MetadataKey.UrlSlug)?.value;
    if (productUrlSlug !== productSlug) return false;

    return true;
  });
};
