import _ from "lodash";

type StoreDisclaimerImage = {
  groupName: string;
  url: string;
};

export type StoreDisclaimer = {
  code?: string;
  name?: string;
  disclaimer: string;
  images?: StoreDisclaimerImage[];
};

export type StoreDisclaimerResponse = {
  storeDisclaimers: StoreDisclaimer[];
};

export const isStoreDisclaimer = (object: unknown): object is StoreDisclaimer => {
  const storeDisclaimer = object as StoreDisclaimer;
  return (
    !!storeDisclaimer &&
    (_.isString(storeDisclaimer.code) || storeDisclaimer.code === undefined) &&
    (_.isString(storeDisclaimer.name) || storeDisclaimer.name === undefined) &&
    _.isString(storeDisclaimer.disclaimer) &&
    (storeDisclaimer.images === undefined ||
      (Array.isArray(storeDisclaimer.images) && storeDisclaimer.images.every(isStoreDisclaimerImage)))
  );
};

export const isStoreDisclaimersResponse = (object: unknown): object is StoreDisclaimerResponse => {
  const disclaimers = object as StoreDisclaimerResponse;
  return Array.isArray(disclaimers.storeDisclaimers) && disclaimers.storeDisclaimers.every(isStoreDisclaimer);
};

export const isStoreDisclaimerImage = (object: unknown): object is StoreDisclaimerImage => {
  const image = object as StoreDisclaimerImage;
  return !!image && _.isString(image.groupName) && _.isString(image.url);
};
