/** Possible component sizes */
export enum Size {
  small = 1,
  medium,
  large,
}

/** Interface to extend for props that require size */
export interface Sizable {
  size: Size;
}
