import cardValidator from "card-validator";
import { CreditCardDetails } from "models/payment/CreditCardPaymentRequest";
import { GiftCardDetails } from "models/payment/GiftCardPaymentRequest";
import { isValidMonthYear } from "util/DateHelpers";
import { isUndefined } from "util/JavascriptUtility";

export const isValidGiftCard = (giftCard: GiftCardDetails): boolean => {
  return !!giftCard.number;
};

export const isValidCardNumber = (number?: string): boolean => {
  if (!number) return true;
  return /^[0-9]+$/.test(number);
};

export const isValidExpiration = (value?: string): boolean => {
  if (!value) return false;
  return isValidMonthYear(value);
};

export const isValidCVV = (cvv?: string): boolean => {
  if (!cvv) return false;
  return cardValidator.cvv(cvv, 4).isPotentiallyValid;
};

export const isValidCreditCard = (creditCard: CreditCardDetails): boolean => {
  const validCard = isValidCardNumber(creditCard.number);
  const validCVV = isValidCVV(creditCard.securityCode);

  return validCard && validCVV && !!creditCard.billingAddress;
};

export class TipAmountValidations {
  static isNonNegative(tipAmount?: string): boolean {
    if (!tipAmount) return false;
    const tipAmountNumber = Number(tipAmount.replace(/[^0-9]/g, ""));
    return tipAmountNumber >= 0;
  }

  static isNotTooLarge(tipAmount?: string): boolean {
    if (!tipAmount) return false;
    const tipAmountNumber = Number(tipAmount.replace(/[^0-9]/g, ""));
    return tipAmountNumber < Number.MAX_SAFE_INTEGER;
  }

  static isValidTipAmount = (tipAmount?: string): boolean => {
    return TipAmountValidations.isNonNegative(tipAmount) && TipAmountValidations.isNotTooLarge(tipAmount);
  };
}

export const isValidDisplayName = (displayName?: string): boolean => {
  if (isUndefined(displayName)) return true;
  const regex = /^[a-zA-Z0-9-' ]{1,30}$/;
  return !!displayName && regex.test(displayName);
};

export class PaymentDisplayNameValidations {
  static meetsLengthRequirements(value?: string): boolean {
    if (!value) return true;
    return value.length <= 30;
  }

  static meetsCharacterRequirements(value?: string): boolean {
    if (!value) return true;
    return /^[a-zA-Z0-9-' ]+$/.test(value);
  }

  static isValidDisplayName(value?: string): boolean {
    return (
      PaymentDisplayNameValidations.meetsLengthRequirements(value) &&
      PaymentDisplayNameValidations.meetsCharacterRequirements(value)
    );
  }
}
