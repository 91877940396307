import { CardContent, SxProps, Theme } from "@mui/material";
import MuiCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { PropsWithChildren, ReactNode } from "react";
import styles from "ui/components/Card/Card.styles";

export type CardProps = PropsWithChildren & {
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
  sx?: SxProps<Theme>;
};

const Card = ({ children, primaryButton, secondaryButton, sx }: CardProps) => (
  <MuiCard sx={[styles.card, ...(Array.isArray(sx) ? sx : [sx])]}>
    <CardContent sx={styles.content}>{children}</CardContent>
    <CardActions sx={styles.buttons}>
      {primaryButton}
      {secondaryButton}
    </CardActions>
  </MuiCard>
);

export default Card;
