import { Box } from "@mui/material";
import styles from "ui/components/Spinner/Spinner.styles";
import Colors from "ui/theme/Colors";

/**
 * Components/Misc/04 Spinners
 * https://www.figma.com/file/Pfdl4rjZk6sNFdmmZf8IXb/Master-LCP-Commerce-Core-Library?node-id=1424%3A0
 */

export type SpinnerProps = {
  color?: keyof typeof Colors;
  wrap?: boolean;
};

const Spinner = ({ color, wrap }: SpinnerProps) => {
  return (
    <Box className="spinner" sx={[...(wrap ? [styles.wrap] : [])]}>
      <Box
        component="svg"
        sx={styles.container}
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <Box
          component="circle"
          sx={styles.circle}
          cx="50"
          cy="50"
          fill="none"
          stroke={color ? Colors[color] : Colors.freshTomato}
          strokeWidth="14"
          r="36"
          strokeDasharray="169.64600329384882 58.548667764616276"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Spinner;
