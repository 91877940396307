import { IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as AlertIcon } from "assets/icons/error-inverse.svg";
import { RuntimeAlert } from "models/Alert";
import { useEffect, useRef } from "react";
import styles from "ui/components/Alert/Alert.styles";
import Icon from "ui/components/Icon";

/**
 * Props for Alert component
 */

type AlertProps = RuntimeAlert;

/**
 * Alert component
 */
const Alert = ({ title, message, icon = true, actions, onClose }: AlertProps) => {
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    alertRef.current?.focus();
  }, []);

  return (
    <Stack sx={styles.alert} direction="column" tabIndex={-1} ref={alertRef}>
      {title && (
        <>
          <Stack direction="row" alignItems="center" justifyContent={{ md: "center" }} gap={1}>
            {icon && (
              <Stack alignItems="center">
                <AlertIcon aria-hidden="true" />
              </Stack>
            )}
            <Typography variant="formFieldLabel" color="primary.contrastText" sx={styles.title}>
              {title}
            </Typography>
          </Stack>
          <Typography variant="body2" color="primary.contrastText">
            {message}
          </Typography>
        </>
      )}

      {/* titles are opt-in, so if we _don't_ have one, just render the (required) message in place of the title */}
      {!title && (
        <Stack direction="row" alignItems="center" justifyContent={{ md: "center" }} gap={1}>
          {icon && (
            <Stack alignItems="center">
              <AlertIcon />
            </Stack>
          )}
          <Typography variant="formFieldLabel" color="primary.contrastText" sx={styles.title}>
            {message}
          </Typography>
        </Stack>
      )}

      {actions && (
        <Stack
          direction="row"
          justifyContent={{ xs: "space-evenly", md: "center" }}
          alignItems="center"
          gap={2}
          sx={styles.actions}
        >
          {actions}
        </Stack>
      )}

      {onClose && (
        <IconButton aria-label="close" sx={styles.closeBtn} onClick={() => onClose()}>
          <Icon icon="close" size="medium" />
        </IconButton>
      )}
    </Stack>
  );
};

export default Alert;
