import mParticle, { MPConfiguration } from "@mparticle/web-sdk";

enum CustomEvent {
  Login = "Login",
  Logout = "Logout",
  SignUp = "Completed enrollment form",
}

type IdentityFields = {
  userId: string;
  email: string;
  phone: string;
  loyaltyCardNumber?: string;
};

type ProductAttributes = {
  item_id?: string;
  item_name?: string;
  price?: string;
  quantity?: number;
};

class MParticleUtil {
  private isReady = false;

  constructor() {
    mParticle.ready(() => {
      this.isReady = true;
    });
  }

  init() {
    if (!Env.MParticleKey || !Env.MParticlePlanId || !Env.MParticlePlanVersion) return;

    const isDevelopment = process.env.NODE_ENV === "development";
    const mParticleConfig: MPConfiguration = {
      dataPlan: {
        planId: Env.MParticlePlanId,
        planVersion: +Env.MParticlePlanVersion,
      },
      isDevelopmentMode: isDevelopment,
      logLevel: isDevelopment ? "warning" : "none",
    };
    mParticle.init(Env.MParticleKey, mParticleConfig);
    mParticle.eCommerce.setCurrencyCode("USD");
  }

  // Identity

  clearIdentity() {
    if (!this.isReady) return;
    mParticle.Identity.logout({});
  }

  modifyIdentity(fields: { email?: string; phone?: string }) {
    if (!this.isReady) return;

    const { email, phone } = fields;

    mParticle.Identity.modify({
      userIdentities: {
        email,
        mobile_number: phone,
      },
    });
  }

  setIdentity(fields: IdentityFields) {
    if (!this.isReady) return;

    const { email, loyaltyCardNumber, phone, userId } = fields;

    mParticle.Identity.login({
      userIdentities: {
        customerid: userId,
        email,
        mobile_number: phone,
        other: loyaltyCardNumber,
      },
    });
  }

  // Commerce Events

  logEventAddToCart(data: {
    items: ProductAttributes[];
    orderId: string;
    storeId: string;
    storeName: string;
    isReorder?: boolean;
  }) {
    MParticle.logProductAction(mParticle.ProductActionType.AddToCart, data);
  }

  logEventCheckout(data: { items: ProductAttributes[]; orderId: string; storeId: string; storeName?: string }) {
    MParticle.logProductAction(mParticle.ProductActionType.Checkout, data);
  }

  logEventPurchase(data: {
    items: ProductAttributes[];
    orderId: string;
    storeId: string;
    storeName: string;
    total: string;
  }) {
    const revenue = +data.total.replace(/[^0-9.]/, "");
    this.logProductAction(mParticle.ProductActionType.Purchase, { ...data, revenue });
  }

  logEventViewDetail(data: { item: ProductAttributes; orderId?: string; storeId?: string; storeName?: string }) {
    const { item, ...rest } = data;
    this.logProductAction(mParticle.ProductActionType.ViewDetail, { ...rest, items: [item] });
  }

  // Custom Events

  logEventLogin() {
    if (!this.isReady) return;
    mParticle.logEvent(CustomEvent.Login, mParticle.EventType.Other);
  }

  logEventLogout() {
    if (!this.isReady) return;
    mParticle.logEvent(CustomEvent.Logout, mParticle.EventType.Other);
  }

  logEventSignUp() {
    if (!this.isReady) return;
    mParticle.logEvent(CustomEvent.SignUp, mParticle.EventType.Other);
  }

  private logProductAction(
    productActionType: mParticle.ProductActionType,
    data: {
      items: ProductAttributes[];
      orderId?: string;
      storeId?: string;
      storeName?: string;
      revenue?: number;
      isReorder?: boolean;
    }
  ) {
    if (!this.isReady) return;

    const {
      items,
      orderId: order_id,
      isReorder: reordering_flag,
      revenue,
      storeId: store_id,
      storeName: store_name,
    } = data;

    const products: mParticle.Product[] = items.map((item) => {
      const { item_id: sku, item_name: name, price: priceString, quantity } = item;
      const price = priceString ? Number(priceString.replace(/[^0-9.-]+/g, "")) : 0;
      // TODO: Check if item_id & item_name could be not undefined
      return mParticle.eCommerce.createProduct(name ?? "", sku ?? "", price, quantity ?? 1);
    });

    // TODO: Can we send undefined attributes?
    const customAttributes: mParticle.SDKEventAttrs = {
      reordering_flag: reordering_flag ?? null,
      store_id,
      store_name,
    };

    // TODO: Can we send undefined attributes?
    const transactionAttributes = mParticle.eCommerce.createTransactionAttributes(
      order_id ?? "",
      undefined,
      undefined,
      revenue
    );

    mParticle.eCommerce.logProductAction(
      productActionType,
      products,
      customAttributes,
      undefined,
      transactionAttributes
    );
  }
}

// MParticleUtil global/singleton
const MParticle = new MParticleUtil();

export default MParticle;
