import MuiStyles from "ui/util/Styles";

const styles = {
  container: {
    paddingTop: {
      xs: "1.5rem",
      md: "4rem",
    },
  },
} satisfies MuiStyles;

export default styles;
