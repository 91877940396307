import { FormControl, Stack, Typography } from "@mui/material";
import { GroupOrderPaymentType } from "models/groupOrder";
import CurrencyInput from "react-currency-input-field";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import RadioGroup, { RadioButton } from "ui/components/RadioGroup";
import { StartGroupOrderSchema } from "ui/components/StartGroupOrderModal/StartGroupOrderModal.data";
import styles from "ui/components/StartGroupOrderModal/StartGroupOrderModal.styles";
import textFieldStyles from "ui/components/TextField/TextField.styles";
import { FieldOptions } from "util/FieldOptions";

type WhosPayingProps = {
  fieldErrors: FieldErrors<StartGroupOrderSchema>;
  fieldOptions: FieldOptions<StartGroupOrderSchema>;
  groupOrderPaymentType: GroupOrderPaymentType;
  register: UseFormRegister<StartGroupOrderSchema>;
};

const WhosPaying = ({ fieldErrors, fieldOptions, groupOrderPaymentType, register }: WhosPayingProps) => (
  <Stack gap={3}>
    <Typography component="h2" variant="h4" sx={styles.sectionHeading}>
      Who&rsquo;s Paying?
    </Typography>

    <RadioGroup
      id="group-order-payment-type"
      error={!!fieldErrors.groupOrderPaymentType}
      errorText={fieldErrors.groupOrderPaymentType?.message}
      aria-label="who's paying?"
    >
      <RadioButton
        title="I&rsquo;m feeling big, I&rsquo;ve got the bill."
        description="Can set a price limit per person (optional)"
        value="host"
        required
        {...register("groupOrderPaymentType", fieldOptions.groupOrderPaymentType)}
      />
      <RadioButton
        title="Everyone is going Dutch."
        description="Each person enters their payment info"
        value="split"
        required
        {...register("groupOrderPaymentType", fieldOptions.groupOrderPaymentType)}
      />
    </RadioGroup>

    {groupOrderPaymentType === "host" && (
      <FormControl sx={styles.priceLimitField}>
        <Typography component="label" htmlFor="price-limit-per-person" variant="formFieldLabelCaps">
          Price Limit Per Person
          <Typography component="span" sx={textFieldStyles.optionalText} variant="formFieldText">
            {" "}
            (optional)
          </Typography>
        </Typography>
        <CurrencyInput
          decimalsLimit={2}
          id="price-limit-per-person"
          maxLength={16}
          placeholder="$"
          prefix="$"
          allowNegativeValue={false}
          {...register("maxParticipantSubtotal", fieldOptions.maxParticipantSubtotal)}
        />
      </FormControl>
    )}
  </Stack>
);

export default WhosPaying;
