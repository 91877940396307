import { BaseDataTransformer, isVoidResponse } from "dataTransformers/BaseDataTransformer";
import TokenManageable from "dataTransformers/TokenManageable";
import { UserDataTransformable } from "dataTransformers/UserDataTransformable";
import { LocalErrorCodes } from "errors/LocalErrorCodes";
import { ValidationErrors } from "errors/LocalErrors";
import { autorun } from "mobx";
import { isPartialCommunicationPreferences } from "models/CommunicationPreferences";
import { CreditCardDetails } from "models/payment/CreditCardPaymentRequest";
import { GiftCardDetails } from "models/payment/GiftCardPaymentRequest";
import { PaymentType } from "models/payment/PaymentType";
import { isRecentOrder, isRecentOrderArray, RecentOrder } from "models/RecentOrder";
import { isRewardResponse, RewardResponse } from "models/rewards/Reward";
import { EditUserRequestPayload, isUser, NewUserRequestPayload, User } from "models/User";
import { FavesRequestPayload, isFaveResponse } from "models/user/Fave";
import SavedDeliveryAddress, {
  isSavedDeliveryAddress,
  isSavedDeliveryAddressArray,
} from "models/user/SavedDeliveryAddress";
import SavedLocation, {
  isSavedLocationArray,
  isSimpleSavedLocation,
  SimpleSavedLocation,
} from "models/user/SavedLocation";
import { isSavedParticipantsArray } from "models/user/SavedParticipants";
import { isCreditCardSavedPayment, isSavedPaymentArray, SavedPayment } from "models/user/savedPayments/SavedPayment";
import { NetworkError, NetworkResponse } from "networking/NetworkPromise";
import { UpdateAchievementBadgesPayload } from "networking/requestPayloads/UpdateAchievementBadgesPayload";
import UserRewardableNetworking from "networking/RewardsNetworking";
import TokenNetworking from "networking/TokenNetworking";
import { isTokenResponse } from "networking/TokenResponse";
import { UserNetworking } from "networking/UserNetworking";
import { UserSavedPaymentsNetworking } from "networking/UserSavedPaymentsNetworking";
import LocalStorage from "util/LocalStorage";
import { updateOptimizelyUserAttributes } from "util/Optimizely";
import PasswordValidator from "util/validations/PasswordValidator";
import { isValidCreditCard, isValidDisplayName, isValidGiftCard } from "util/validations/PaymentValidator";
import {
  BirthdayValidations,
  EmailValidations,
  PhoneValidations,
  UserFirstLastNameValidations,
} from "util/validations/UserValidators";

export class UserDataTransformer extends BaseDataTransformer implements UserDataTransformable {
  private readonly userNetworker: UserNetworking & UserSavedPaymentsNetworking & UserRewardableNetworking;

  constructor(
    userNetworker: UserNetworking & UserSavedPaymentsNetworking & UserRewardableNetworking & TokenNetworking,
    tokenManager: TokenManageable,
    localStorage = new LocalStorage<string>("token")
  ) {
    super(userNetworker, tokenManager, localStorage);
    this.userNetworker = userNetworker;

    // keep Optimizely user authentication_status attribute in sync with user state
    autorun(() => {
      if (this.tokenManager.userState.object) {
        updateOptimizelyUserAttributes({ authentication_status: "logged-in" });
      } else {
        updateOptimizelyUserAttributes({ authentication_status: "guest" });
      }
    });
  }

  changeEmail(user: User, email: string, password: string): Promise<void> {
    if (!EmailValidations.isValidEmail(email)) return Promise.reject(ValidationErrors.InvalidEmail);

    return this.makeNetworkCall(() => this.userNetworker.changeUsername(user.id, email, password), isVoidResponse);
  }

  changePassword(user: User, oldPassword: string, newPassword: string): Promise<void> {
    if (!PasswordValidator.validPassword(newPassword)) return Promise.reject(ValidationErrors.InvalidPassword);

    return this.makeNetworkCall(
      () => this.userNetworker.changePassword(user.id, oldPassword, newPassword),
      isVoidResponse
    );
  }

  createAccount(newUser: NewUserRequestPayload): Promise<User> {
    const trimmedPhone = newUser.phoneNumber?.replace(/[^0-9]/g, "");

    if (!EmailValidations.isValidEmail(newUser.userName)) {
      return Promise.reject(ValidationErrors.InvalidEmail);
    } else if (!!newUser.phoneNumber && !PhoneValidations.isValidPhone(trimmedPhone)) {
      return Promise.reject(ValidationErrors.InvalidPhone);
    } else if (
      !UserFirstLastNameValidations.isValidName(newUser.firstName) ||
      !UserFirstLastNameValidations.isValidName(newUser.lastName)
    ) {
      return Promise.reject(ValidationErrors.InvalidName);
    } else if (!PasswordValidator.validPassword(newUser.password)) {
      return Promise.reject(ValidationErrors.InvalidPassword);
    } else if (!!newUser.birthday && !BirthdayValidations.isValidBirthday(newUser.birthday)) {
      return Promise.reject(ValidationErrors.InvalidBirthday);
    }

    return this.makeNetworkCall(
      () => this.userNetworker.createAccount({ ...newUser, phoneNumber: trimmedPhone }),
      isUser
    );
  }

  deleteAccount(userId: string): Promise<void> {
    return this.makeNetworkCall(() => this.userNetworker.deleteAccount(userId), isVoidResponse);
  }

  createSavedLocation(storeId: string, isDefault?: boolean | undefined): Promise<SimpleSavedLocation> {
    return this.makeNetworkCall(
      () => this.userNetworker.createSavedLocation(storeId, isDefault),
      isSimpleSavedLocation
    );
  }

  deleteSavedPayment(user: User, savedPayment: SavedPayment): Promise<SavedPayment[]> {
    return this.makeNetworkCall(
      () => this.userNetworker.deleteSavedPayment(user.id, savedPayment.id),
      isSavedPaymentArray
    );
  }

  editAccount(user: User, updatedFields: EditUserRequestPayload): Promise<User> {
    const trimmedPhone = updatedFields.phoneNumber.replace(/[^0-9]/g, "");

    if (
      !UserFirstLastNameValidations.isValidName(updatedFields.firstName) ||
      !UserFirstLastNameValidations.isValidName(updatedFields.lastName)
    ) {
      return Promise.reject(ValidationErrors.InvalidName);
    }

    if (!EmailValidations.isValidEmail(updatedFields.email)) {
      return Promise.reject(ValidationErrors.InvalidEmail);
    }

    if (!PhoneValidations.isValidPhone(trimmedPhone)) {
      return Promise.reject(ValidationErrors.InvalidPhone);
    }

    // only attempt to update/validate birthday if it is not already set
    if (updatedFields.birthday && !BirthdayValidations.isValidBirthday(updatedFields.birthday)) {
      return Promise.reject(ValidationErrors.InvalidBirthday);
    }

    if (!isPartialCommunicationPreferences(updatedFields.communicationPreferences)) {
      return Promise.reject(ValidationErrors.InvalidCommunicationsPreferences);
    }

    return this.makeNetworkCall(
      () =>
        this.userNetworker.editAccount(user.id, {
          ...updatedFields,
          phoneNumber: trimmedPhone,
        }),
      isUser
    );
  }

  editPayment(
    user: User,
    savedPayment: SavedPayment,
    displayName?: string,
    setToDefault?: boolean
  ): Promise<SavedPayment[]> {
    if (savedPayment.displayName === displayName) {
      return Promise.reject(ValidationErrors.InvalidSavedPaymentEdit);
    }

    if (!isCreditCardSavedPayment(savedPayment) || savedPayment.default === setToDefault) {
      return Promise.reject(ValidationErrors.InvalidSavedPaymentEdit);
    }

    return this.makeNetworkCall(
      () => this.userNetworker.editSavedPayment(user.id, savedPayment.id, displayName, setToDefault),
      isSavedPaymentArray
    );
  }

  getCurrentUser(): Promise<User> {
    return this.makeNetworkCall(() => this.userNetworker.getCurrentUser(), isUser);
  }

  getDeliveryAddresses(): Promise<SavedDeliveryAddress[]> {
    return this.makeNetworkCall(() => this.userNetworker.getDeliveryAddresses(), isSavedDeliveryAddressArray);
  }

  getRecentOrders(): Promise<RecentOrder[]> {
    return this.makeNetworkCall(() => this.userNetworker.getRecentOrders(), isRecentOrderArray);
  }

  getRecentOrderDetails(recentOrderId: string): Promise<RecentOrder> {
    return this.makeNetworkCall(() => this.userNetworker.getRecentOrderDetails(recentOrderId), isRecentOrder);
  }

  getSavedGroupOrderParticipants(): Promise<string[]> {
    return this.makeNetworkCall(() => this.userNetworker.getSavedGroupOrderParticipants(), isSavedParticipantsArray);
  }

  getSavedLocations(): Promise<SavedLocation[]> {
    return this.makeNetworkCall(() => this.userNetworker.getSavedLocations(), isSavedLocationArray);
  }

  getRewards(): Promise<RewardResponse> {
    return this.makeNetworkCall(() => this.userNetworker.getUserRewards(), isRewardResponse);
  }

  updateAchievementBadges(payload: UpdateAchievementBadgesPayload): Promise<RewardResponse> {
    return this.makeNetworkCall(() => this.userNetworker.updateAchievementBadges(payload), isRewardResponse);
  }

  getSavedPayments(user: User): Promise<SavedPayment[]> {
    return this.makeNetworkCall(() => this.userNetworker.getSavedPayments(user.id), isSavedPaymentArray);
  }

  getAllowedSavedPayments(): Promise<SavedPayment[]> {
    return this.makeNetworkCall(() => this.userNetworker.getAllowedSavedPayments(), isSavedPaymentArray);
  }

  getFaves() {
    return this.makeNetworkCall(() => this.userNetworker.getFaves(), isFaveResponse);
  }

  createFave(payload: FavesRequestPayload) {
    return this.makeNetworkCall(() => this.userNetworker.createFave(payload), isFaveResponse);
  }

  deleteFave(faveId: string) {
    return this.makeNetworkCall(() => this.userNetworker.deleteFave(faveId), isVoidResponse);
  }

  async login(username: string, password: string) {
    if (!EmailValidations.isValidEmail(username)) return Promise.reject(ValidationErrors.InvalidEmail);

    const { token } = await this.makeNetworkCall(() => this.userNetworker.login(username, password), isTokenResponse);
    this.localStorage.set(token);
    return this.getCurrentUser();
  }

  removeDeliveryAddress(addressId: string): Promise<void> {
    return this.makeNetworkCall(() => this.userNetworker.deleteDeliveryAddress(addressId), isVoidResponse);
  }

  removeSavedLocation(storeId: string): Promise<void> {
    return this.makeNetworkCall(() => this.userNetworker.deleteSavedLocation(storeId), isVoidResponse);
  }

  saveCreditCard(
    user: User,
    creditCard: CreditCardDetails,
    displayName?: string,
    defaultPayment?: boolean
  ): Promise<SavedPayment[]> {
    const formattedCreditCard = { ...creditCard, number: creditCard.number.replace(/\s+/g, "") };

    if (!isValidCreditCard(formattedCreditCard)) return Promise.reject(ValidationErrors.InvalidCreditCard);
    if (!isValidDisplayName(displayName)) return Promise.reject(ValidationErrors.InvalidDisplayName);

    const payment = {
      details: formattedCreditCard,
      type: PaymentType.credit,
      default: defaultPayment ?? false,
      displayName: displayName,
    };

    return this.makeNetworkCall(() => this.userNetworker.savePayment(user.id, payment), isSavedPaymentArray);
  }

  saveGiftCard(
    user: User,
    giftCard: GiftCardDetails,
    displayName?: string,
    defaultPayment?: boolean
  ): Promise<SavedPayment[]> {
    const formattedGiftCard = { ...giftCard, number: giftCard.number.replace(/\s+/g, "") };

    if (!isValidGiftCard(formattedGiftCard)) return Promise.reject(ValidationErrors.InvalidGiftCard);
    if (!isValidDisplayName(displayName)) return Promise.reject(ValidationErrors.InvalidDisplayName);

    const payment = {
      details: formattedGiftCard,
      type: PaymentType.giftCard,
      default: defaultPayment ?? false,
      displayName: displayName,
    };

    return this.makeNetworkCall(() => this.userNetworker.savePayment(user.id, payment), isSavedPaymentArray);
  }

  sendForgotPasswordEmail(email: string): Promise<void> {
    if (!EmailValidations.isValidEmail(email)) {
      return Promise.reject(ValidationErrors.InvalidEmail);
    }

    return this.makeNetworkCall(() => this.userNetworker.sendForgotPasswordEmail(email), isVoidResponse);
  }

  updateDefaultDeliveryAddress(addressId: string): Promise<SavedDeliveryAddress> {
    return this.makeNetworkCall(
      () => this.userNetworker.updateDefaultDeliveryAddress(addressId),
      isSavedDeliveryAddress
    );
  }

  handleError(error: NetworkResponse | NetworkError | Error | unknown): Promise<never> {
    return super.handleError(error).catch((error) => {
      if (error.httpStatusCode === 401) {
        return Promise.reject({ code: LocalErrorCodes.NotAuthenticated, message: error.message });
      }
      return Promise.reject(error);
    });
  }
}
