import _ from "lodash";

type SavedDeliveryAddress = {
  city: string;
  id: number;
  isDefault: boolean;
  streetAddress: string;
  zipcode: string;
  specialInstructions?: string;
};

export const isSavedDeliveryAddress = (object: unknown): object is SavedDeliveryAddress => {
  const deliveryAddress = object as SavedDeliveryAddress;
  return (
    !!deliveryAddress &&
    _.isString(deliveryAddress.city) &&
    _.isNumber(deliveryAddress.id) &&
    _.isBoolean(deliveryAddress.isDefault) &&
    _.isString(deliveryAddress.streetAddress) &&
    _.isString(deliveryAddress.zipcode)
  );
};

export const isSavedDeliveryAddressArray = (object: unknown): object is SavedDeliveryAddress[] => {
  const deliveryAddresses = object as SavedDeliveryAddress[];
  return (
    Array.isArray(deliveryAddresses) &&
    deliveryAddresses.length === deliveryAddresses.filter((address) => isSavedDeliveryAddress(address)).length
  );
};

export default SavedDeliveryAddress;
