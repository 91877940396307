import axios, { AxiosInstance, AxiosRequestHeaders, CustomParamsSerializer, InternalAxiosRequestConfig } from "axios";
import md5 from "md5";
import { ContactInfo } from "models/ContactInfo";
import { DynamicData } from "models/DynamicData";
import Feedback from "models/Feedback";
import { Filter } from "models/Filter";
import GiftCardBalanceResponse from "models/GiftCardBalanceResponse";
import {
  AcceptGroupOrderInviteResponse,
  GroupOrder,
  GroupOrderDetails,
  GroupOrderParticipant,
} from "models/groupOrder";
import MenuType from "models/MenuType";
import { ModifierCategory } from "models/ModifierCategory";
import { ModifierOptionId } from "models/modifiers/ModifierOption";
import { AddItem } from "models/order/AddItem";
import { DeliveryInfo } from "models/order/DeliveryInfo";
import { FaveOrderResponse } from "models/order/FaveOrderResponse";
import { Order } from "models/order/Order";
import { OrderType } from "models/order/OrderType";
import { OrderUpsellResponse } from "models/order/OrderUpsell";
import Tip from "models/order/Tip";
import UpdateAndSubmitOrderPayload from "models/order/UpdateAndSubmitOrderPayload";
import { OrderConfirmation } from "models/OrderConfirmation";
import { Products } from "models/Products";
import { RecentOrder } from "models/RecentOrder";
import { RewardResponse } from "models/rewards/Reward";
import { CanDeliverAddress, CanDeliverResponse } from "models/store/CanDeliver";
import OrderAheadDateTimes from "models/store/OrderAheadDateTimes";
import { Store, StoreFilter } from "models/store/Store";
import { StoreDisclaimerResponse } from "models/store/StoreDisclaimer";
import { EditUserRequestPayload, NewUserRequestPayload, User } from "models/User";
import { FavesRequestPayload, FavesResponse } from "models/user/Fave";
import SavedDeliveryAddress from "models/user/SavedDeliveryAddress";
import SavedLocation, { SimpleSavedLocation } from "models/user/SavedLocation";
import { SavedPayment } from "models/user/savedPayments/SavedPayment";
import { ContactNetworking } from "networking/ContactNetworking";
import { NetworkPromise } from "networking/NetworkPromise";
import { OrderNetworking } from "networking/OrderNetworking";
import { ProductsNetworking } from "networking/ProductsNetworking";
import CreateGroupOrderPayload from "networking/requestPayloads/CreateGroupOrderPayload";
import SendGroupOrderInvitesPayload from "networking/requestPayloads/SendGroupOrderInvitesPayload";
import { UpdateAchievementBadgesPayload } from "networking/requestPayloads/UpdateAchievementBadgesPayload";
import UpdateGroupOrderPayload from "networking/requestPayloads/UpdateGroupOrderPayload";
import UserRewardableNetworking from "networking/RewardsNetworking";
import { StoreNetworking } from "networking/StoreNetworking";
import TokenNetworking from "networking/TokenNetworking";
import { TokenResponse } from "networking/TokenResponse";
import { UserNetworking } from "networking/UserNetworking";
import { SavablePayment, UserSavedPaymentsNetworking } from "networking/UserSavedPaymentsNetworking";
import qs from "qs";
import LocalStorage from "util/LocalStorage";
import { getSessionId } from "util/SessionStorage";

export type AllNetworking = OrderNetworking &
  ProductsNetworking &
  StoreNetworking &
  UserNetworking &
  UserSavedPaymentsNetworking &
  ContactNetworking &
  UserRewardableNetworking &
  TokenNetworking;

export const defaultAxiosInstance = (baseURL = Env.BackendUrl, apiKey = Env.BackendApiKey): AxiosInstance => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      APIKey: apiKey,
      "X-Session-Id": getSessionId(),
    },
    withCredentials: true,
    paramsSerializer: { serialize: paramsSerializer },
  });
};

export const paramsSerializer: CustomParamsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });

/** @public */
export class Networker implements AllNetworking {
  private readonly axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance = defaultAxiosInstance()) {
    this.axiosInstance = axiosInstance;

    axiosInstance.interceptors.request.use((config) => Networker.preFlight(config));
  }

  private static preFlight(config: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> {
    const localStorage = new LocalStorage<string>("token");
    const token = localStorage.get();

    // Ensure config.headers is defined and set its type explicitly
    config.headers = config.headers || ({} as AxiosRequestHeaders);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  }

  applyTip(payload: Tip): NetworkPromise<Order> {
    return this.axiosInstance.post("/order/tips", payload);
  }

  // OrderNetworking
  addToOrder(
    productId: string,
    quantity: number,
    options: string[],
    specialInstructions?: string,
    recipient?: string,
    isUpsell?: boolean
  ): NetworkPromise<Order> {
    const data = {
      productId: productId,
      quantity: quantity,
      options: options,
      specialInstructions: specialInstructions,
      recipient: recipient,
      isUpsell: isUpsell,
    };
    return this.axiosInstance.post("/order/items", data);
  }

  addMultipleItemsToOrder(items: AddItem[]): NetworkPromise<Order> {
    //strip price attribute backend doesn't want/need it
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newItemsArray = items.map(({ price, ...keepAttrs }) => keepAttrs);
    const data = { products: newItemsArray };
    return this.axiosInstance.post("/order/batchItems", data);
  }

  repeatOrder(orderId: string): NetworkPromise<Order> {
    return this.axiosInstance.post("/order/repeat", { id: orderId, ignoreUnavailableProducts: false });
  }

  applyCoupon(coupon: string): NetworkPromise<Order> {
    return this.axiosInstance.post("/order/coupons", { code: coupon });
  }

  cancelOrder(): NetworkPromise<void> {
    return this.axiosInstance.delete("/order");
  }

  checkinOrder(orderId: string): NetworkPromise<void> {
    return this.axiosInstance.patch(`/order/${orderId}/arrival`);
  }

  createOrder(storeId: string): NetworkPromise<Order> {
    return this.axiosInstance.post("/order", { storeId: storeId });
  }

  deleteFromOrder(orderItemId: string): NetworkPromise<Order> {
    return this.axiosInstance.delete(`/order/items/${orderItemId}`);
  }

  editOrderItem(
    orderItemId: string,
    quantity: number,
    options: string[],
    specialInstructions?: string,
    recipient?: string
  ): NetworkPromise<Order> {
    return this.axiosInstance.put(`/order/items/${orderItemId}`, {
      quantity: quantity,
      options: options,
      specialInstructions: specialInstructions,
      recipient: recipient,
    });
  }

  editOrderItemQuantity(orderItemId: string, quantity: number): NetworkPromise<Order> {
    return this.axiosInstance.patch(`/order/items/${orderItemId}`, {
      quantity,
    });
  }

  getOrder(): NetworkPromise<Order> {
    return this.axiosInstance.get("/order");
  }

  getOrderUpsell(): NetworkPromise<OrderUpsellResponse> {
    return this.axiosInstance.get("/order/upsell");
  }

  redeemReward(reference: string): NetworkPromise<Order> {
    return this.axiosInstance.post("/order/rewards", { id: reference });
  }

  removeCoupon(): NetworkPromise<Order> {
    return this.axiosInstance.delete("/order/coupons");
  }

  removeReward(rewardId: string): NetworkPromise<Order> {
    return this.axiosInstance.delete(`/order/rewards/${rewardId}`);
  }

  removeTip(): NetworkPromise<Order> {
    return this.axiosInstance.delete("/order/tips");
  }

  setOrderTimeWanted(timeWanted: string): NetworkPromise<Order> {
    return this.axiosInstance.patch("/order", { timeWanted });
  }

  setOrderType(orderType: OrderType, deliveryInfo?: DeliveryInfo, specialInstructions?: string): NetworkPromise<Order> {
    const data = {
      orderType,
      deliveryInfo,
      specialInstructions,
    };
    return this.axiosInstance.patch("/order", data);
  }

  updateContactInfo(contactInfo: ContactInfo): NetworkPromise<Order> {
    return this.axiosInstance.patch("/order", { contactInfo });
  }

  validateOrder(): NetworkPromise<Order> {
    return this.axiosInstance.get(`/order/validation`);
  }

  submitOrder(): NetworkPromise<OrderConfirmation> {
    return this.axiosInstance.post(`/order/submit`);
  }

  updateAndSubmitOrder(payload: UpdateAndSubmitOrderPayload): NetworkPromise<OrderConfirmation> {
    return this.axiosInstance.post(`/order/updateAndSubmit`, payload);
  }

  // ProductsNetworking
  fetchDynamicData(productId: string, storeId: string, options?: ModifierOptionId[]): NetworkPromise<DynamicData> {
    const md5Hash = md5(storeId + productId + options?.join(","));
    return this.axiosInstance.post(
      `/products/${productId}/dynamicData`,
      {
        storeId: storeId,
        modifierOptions: options?.join(",") ?? "",
      },
      { params: { requestHash: md5Hash } }
    );
  }

  getFilters(storeId?: string, filterKeys?: string, menuType?: MenuType): NetworkPromise<Filter[]> {
    return this.axiosInstance.get("/products/filters", {
      params: {
        storeId,
        filterKeys,
        menuType,
      },
    });
  }

  getModifiers(productId: string, storeId: string, orderType?: OrderType): NetworkPromise<ModifierCategory[]> {
    return this.axiosInstance.get(`/products/${productId}/modifiers`, {
      params: {
        orderType,
        storeId,
      },
    });
  }

  getProducts(
    storeId?: string,
    categoryId?: string,
    menuType?: MenuType,
    orderType?: OrderType
  ): NetworkPromise<Products> {
    return this.axiosInstance.get("/products", {
      params: {
        storeId,
        categoryId,
        menuType,
        orderType,
      },
    });
  }

  // Store Networking
  getOrderAheadTimes(storeId: string, orderType: string): NetworkPromise<OrderAheadDateTimes[]> {
    return this.axiosInstance.get(`/stores/${storeId}/orderAheadTimes`, {
      params: {
        orderType: orderType,
      },
    });
  }

  getStores(
    addressSearch?: string,
    latitude?: number,
    longitude?: number,
    radius?: number,
    filters?: StoreFilter[],
    canDeliver?: boolean,
    deliveryStreet?: string,
    deliveryCity?: string,
    deliveryZip?: string,
    numberOfResults?: number
  ): NetworkPromise<Store[]> {
    return this.axiosInstance.get("/stores", {
      params: {
        addressSearch,
        latitude,
        longitude,
        radius,
        filter: filters?.join(","),
        canDeliver,
        deliveryStreet,
        deliveryCity,
        deliveryZip,
        numberOfResults,
      },
    });
  }

  getStore(storeId: string): NetworkPromise<Store> {
    return this.axiosInstance.get(`/stores/${storeId}`);
  }

  getStoreDisclaimers(storeId: string): NetworkPromise<StoreDisclaimerResponse> {
    return this.axiosInstance.get(`/stores/${storeId}/disclaimers`);
  }

  checkCanDeliver(storeId: string, address: CanDeliverAddress): NetworkPromise<CanDeliverResponse> {
    return this.axiosInstance.post(`/stores/${storeId}/canDeliver`, {
      ...address,
      handOffMode: "delivery",
      timeWantedMode: "asap",
    });
  }

  // UserNetworking
  changePassword(userId: string, oldPassword: string, newPassword: string): NetworkPromise<void> {
    return this.axiosInstance.put(`/users/${userId}/credentials`, {
      newPassword: newPassword,
      password: oldPassword,
    });
  }

  changeUsername(userId: string, userName: string, password: string): NetworkPromise<void> {
    return this.axiosInstance.put(`/users/${userId}/credentials`, {
      userName: userName,
      password: password,
    });
  }

  createAccount(newUser: NewUserRequestPayload): NetworkPromise<User> {
    return this.axiosInstance.post("/users", newUser);
  }

  deleteAccount(userId: string): NetworkPromise<void> {
    return this.axiosInstance.delete(`/users/${userId}`);
  }

  createSavedLocation(storeId: string, isDefault?: boolean): NetworkPromise<SimpleSavedLocation> {
    return this.axiosInstance.post(`/users/current/savedLocations/${storeId}`, undefined, { params: { isDefault } });
  }

  deleteDeliveryAddress(addressId: string): NetworkPromise<void> {
    return this.axiosInstance.delete(`/users/current/deliveryAddress/${addressId}`);
  }

  deleteSavedLocation(storeId: string): NetworkPromise<void> {
    return this.axiosInstance.delete(`/users/current/savedLocations/${storeId}`);
  }

  editAccount(userId: string, payload: EditUserRequestPayload): NetworkPromise<User> {
    return this.axiosInstance.put(`/users/${userId}`, payload);
  }

  getCurrentUser(): NetworkPromise<User> {
    return this.axiosInstance.get(`/users/current`);
  }

  getDeliveryAddresses(): NetworkPromise<SavedDeliveryAddress[]> {
    return this.axiosInstance.get(`/users/current/deliveryAddresses`);
  }

  getRecentOrders(): NetworkPromise<RecentOrder[]> {
    return this.axiosInstance.get(`/users/current/recentOrders`);
  }

  getRecentOrderDetails(recentOrderId: string): NetworkPromise<RecentOrder> {
    return this.axiosInstance.get(`/users/current/recentOrders/${recentOrderId}`);
  }

  getSavedLocations(): NetworkPromise<SavedLocation[]> {
    return this.axiosInstance.get(`/users/current/savedLocations`);
  }

  // User My Faves
  getFaves(): NetworkPromise<FavesResponse> {
    return this.axiosInstance.get(`/users/current/faves`);
  }

  createFave(payload: FavesRequestPayload): NetworkPromise<FavesResponse> {
    return this.axiosInstance.post(`/users/current/faves`, payload);
  }

  deleteFave(faveId: string): NetworkPromise<void> {
    return this.axiosInstance.delete(`/users/current/faves/${faveId}`);
  }

  addFaveToOrder(faveId: string): NetworkPromise<FaveOrderResponse> {
    const data = {
      faveId,
    };
    return this.axiosInstance.post("/order/fave", data);
  }

  login(username: string, password: string): NetworkPromise<TokenResponse> {
    return this.axiosInstance.post("/login", undefined, {
      auth: {
        username,
        password,
      },
    });
  }

  logout(): NetworkPromise<void> {
    return this.axiosInstance.post("/logout");
  }

  sendForgotPasswordEmail(email: string): NetworkPromise<void> {
    return this.axiosInstance.post(`/users/sendForgotPassword`, {
      emailaddress: email,
    });
  }

  updateDefaultDeliveryAddress(addressId: string): NetworkPromise<SavedDeliveryAddress> {
    return this.axiosInstance.put("/users/current/deliveryAddress/default", {
      addressId,
    });
  }

  getSavedGroupOrderParticipants(): NetworkPromise<string[]> {
    return this.axiosInstance.get("/users/current/savedGroupOrderParticipants");
  }

  // User Saved Payments Networking
  deleteSavedPayment(userId: string, savedPaymentId: string): NetworkPromise<SavedPayment[]> {
    return this.axiosInstance.delete(`/users/${userId}/savedPayments/${savedPaymentId}`);
  }

  editSavedPayment(
    userId: string,
    savedPaymentId: string,
    displayName?: string,
    setToDefault?: boolean
  ): NetworkPromise<SavedPayment[]> {
    return this.axiosInstance.patch(`/users/${userId}/savedPayments/${savedPaymentId}`, {
      default: setToDefault,
      displayName: displayName,
    });
  }

  getSavedPayments(userId: string): NetworkPromise<SavedPayment[]> {
    return this.axiosInstance.get(`/users/${userId}/savedPayments`);
  }

  getAllowedSavedPayments(): NetworkPromise<SavedPayment[]> {
    return this.axiosInstance.get(`/order/allowedSavedPayments`);
  }

  savePayment(userId: string, payment: SavablePayment): NetworkPromise<SavedPayment[]> {
    return this.axiosInstance.post(`/users/${userId}/savedPayments`, payment);
  }

  // ContactUs
  contactUs(firstName: string, lastName: string, email: string, message: string): NetworkPromise<void> {
    return this.axiosInstance.post("/contactUs", {
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
    });
  }

  // User Rewards
  getUserRewards(): NetworkPromise<RewardResponse> {
    return this.axiosInstance.get(`/users/current/rewards`);
  }

  updateAchievementBadges(payload: UpdateAchievementBadgesPayload): NetworkPromise<RewardResponse> {
    return this.axiosInstance.patch(`/users/current/rewards/badgeStatus`, payload);
  }

  // Token Networking
  getWebToken(): NetworkPromise<TokenResponse> {
    return this.axiosInstance.post("/webToken");
  }

  updateToken(token: string | undefined): void {
    delete this.axiosInstance.defaults.headers.common["X-CSRF-TOKEN"];
    if (token) this.axiosInstance.defaults.headers.common["X-CSRF-TOKEN"] = token;
  }

  // Root Networking
  sendFeedback(feedback: Feedback): NetworkPromise<void> {
    return this.axiosInstance.post("/sendFeedback", feedback);
  }

  createApplePaySession(gateway: string, validationUrl: string): NetworkPromise<object> {
    return this.axiosInstance.post("/applePayPaymentSession", { gateway, validationUrl });
  }

  signMapUrl(url: string): NetworkPromise<string> {
    return this.axiosInstance.get("/maps/signUrl", {
      params: {
        unsignedUrl: url,
      },
    });
  }

  // Group Order Networking

  acceptGroupOrderInvite(
    groupOrderId: string,
    participantId: string,
    participantName: string
  ): NetworkPromise<AcceptGroupOrderInviteResponse> {
    return this.axiosInstance.post(`/groupOrders/${groupOrderId}/acceptInvite`, {
      participantId,
      participantName,
    });
  }

  cancelGroupOrder(groupOrderId: string): NetworkPromise<void> {
    return this.axiosInstance.delete(`/groupOrders/${groupOrderId}`);
  }

  createGroupOrder(payload: CreateGroupOrderPayload): NetworkPromise<GroupOrderDetails> {
    return this.axiosInstance.post("/groupOrders", payload);
  }

  getCurrentGroupOrderParticipants(groupOrderId: string): NetworkPromise<GroupOrderParticipant[]> {
    return this.axiosInstance.get(`/groupOrders/${groupOrderId}/participants`);
  }

  getGroupOrder(groupOrderId: string): NetworkPromise<GroupOrderDetails> {
    return this.axiosInstance.get(`/groupOrders/${groupOrderId}`);
  }

  joinGroupOrder(groupOrderId: string, orderId?: string): NetworkPromise<GroupOrder> {
    return this.axiosInstance.post(`/groupOrders/${groupOrderId}/join`, null, { params: { orderId } });
  }

  sendGroupOrderInvites(groupOrderId: string, payload: SendGroupOrderInvitesPayload): NetworkPromise<void> {
    return this.axiosInstance.post(`/groupOrders/${groupOrderId}/sendInvites`, payload);
  }

  submitGroupOrder(groupOrderId: string): NetworkPromise<GroupOrderDetails> {
    return this.axiosInstance.post(`/groupOrders/${groupOrderId}/submit`);
  }

  updateGroupOrder(groupOrderId: string, payload: UpdateGroupOrderPayload): NetworkPromise<GroupOrderDetails> {
    return this.axiosInstance.put(`/groupOrders/${groupOrderId}`, payload);
  }

  getGiftCardBalance(cardNumber: string, pin: string): NetworkPromise<GiftCardBalanceResponse> {
    return this.axiosInstance.get(`/giftCards/${cardNumber}/balance`, { params: { pin } });
  }
}
