import backgroundSrc from "assets/auth-background.jpg";
import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  header: (theme) => ({
    display: "none",

    [theme.breakpoints.up("md")]: {
      backgroundColor: Colors.superBlack,
      backgroundImage: `radial-gradient(50% 2000% at center, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.4) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundSrc})`,
      backgroundPositionY: "21rem",
      backgroundSize: "cover",
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      padding: "2rem 3rem",
      position: "relative",
    },

    [theme.breakpoints.up("lg")]: {
      padding: "4rem 3rem",
    },
  }),
  headerText: (theme) => ({
    color: theme.palette.secondary.contrastText,
    maxWidth: `calc(${theme.pxToRem(theme.breakpoints.values["xxl"])} - 6rem)`,
    width: "100%",
  }),
  content: (theme) => ({
    margin: "0 auto",
    maxWidth: theme.pxToRem(theme.breakpoints.values.xxl),

    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },

    [theme.breakpoints.up("xl")]: {
      gap: "3rem",
    },
  }),
  nav: (theme) => ({
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      alignItems: "start",
      borderBottom: "none",
      flexDirection: "column",
      gap: "2rem",
      justifyContent: "flex-start",
      marginBottom: 0,
      maxWidth: "20rem",
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
  navControl: (theme) => ({
    ...theme.typography.formFieldLabelCaps,

    appearance: "none",
    background: "transparent",
    border: "none",
    color: theme.palette.grey[500],
    cursor: "pointer",
    flexGrow: 1,
    minWidth: 0,
    padding: "0.75rem 0.25rem",
    textAlign: "center",
    textDecoration: "none",

    [theme.breakpoints.up("md")]: {
      ...theme.typography.formFieldLabel,

      color: theme.palette.grey[500],
      flexGrow: 0,
      padding: "1rem 0.25rem 0.5rem",
      textTransform: "none",
    },

    "&:hover": {
      borderBottom: `0.125rem solid ${theme.palette.grey[300]}`,
      color: theme.palette.secondary.main,
      paddingBottom: "0.375rem",
    },
    "&.Mui-focusVisible": {
      outline: `0.25rem solid ${theme.palette.primary.light}`,
      outlineOffset: "-0.25rem",
    },
    "&.active": {
      borderBottom: `0.25rem solid ${theme.palette.primary.main}`,
      color: theme.palette.secondary.main,
      paddingBottom: "0.25rem",
    },
  }),
  logOut: (theme) => ({
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  }),
  outlet: (theme) => ({
    padding: "0 1rem",
    width: "100%",

    [theme.breakpoints.up("xl")]: {
      maxWidth: "45rem",
      padding: 0,
      width: "auto",
    },
  }),
} as const satisfies MuiStyles;

export default styles;
