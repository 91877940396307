import { PaletteOptions } from "@mui/material";
import Colors from "ui/theme/Colors";

const palette: PaletteOptions = {
  primary: {
    light: Colors.lightTomato,
    main: Colors.freshTomato,
    dark: Colors.darkTomato,
    contrastText: Colors.brightWhite,
  },
  secondary: {
    main: Colors.superBlack,
    light: Colors.gray600,
    contrastText: Colors.brightWhite,
  },
  grey: {
    100: Colors.gray100,
    200: Colors.gray200,
    300: Colors.gray300,
    400: Colors.gray400,
    500: Colors.gray500,
    600: Colors.gray600,
  },
  text: {
    primary: Colors.gray500,
    secondary: Colors.gray300,
  },
};

export default palette;
