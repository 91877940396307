import useBadgeManager from "hooks/useBadgeManager";
import usePromoModal from "hooks/usePromoModal";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useGlobalModal } from "stores/globalModal";
import AchievementBadgeModal from "ui/components/AchievementBadgeModal";
import PromoModal from "ui/components/PromoModal";

const GlobalModalPresenter = observer(() => {
  const { closeModal, modal, presentModal } = useGlobalModal();
  const { markBadgesAsSeen, unseenBadges } = useBadgeManager();
  const { markPromoModalAsSeen, promoModal } = usePromoModal();
  const [hasModalBeenDisplayed, setHasModalBeenDisplayed] = useState(false);

  useEffect(() => {
    // return if a modal has already been displayed
    // return if the promo modal fetch has not yet completed
    if (hasModalBeenDisplayed || promoModal === undefined) return;

    if (promoModal) {
      const { cta, description, image, name, termsAndConditions, title } = promoModal;

      presentModal(
        <PromoModal
          cta={cta}
          description={description}
          image={image}
          name={name}
          open={true}
          onClose={closeModal}
          termsAndConditions={termsAndConditions}
          title={title}
        />
      );
      setHasModalBeenDisplayed(true);
      markPromoModalAsSeen(promoModal.id);
      return;
    }

    if (unseenBadges?.length) {
      presentModal(<AchievementBadgeModal open={true} onClose={closeModal} badges={unseenBadges} />);
      setHasModalBeenDisplayed(true);
      markBadgesAsSeen(unseenBadges);
      return;
    }
  }, [
    closeModal,
    promoModal,
    hasModalBeenDisplayed,
    markBadgesAsSeen,
    presentModal,
    unseenBadges,
    markPromoModalAsSeen,
  ]);

  return <>{modal}</>;
});

export default GlobalModalPresenter;
