import { Component, ErrorInfo, ReactNode } from "react";
import SomethingWentWrong from "ui/components/ErrorBoundary/SomethingWentWrong";
import { logError } from "util/Logger";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
