import { createContext, PropsWithChildren, ReactNode, useCallback, useState } from "react";

type GlobalModalContextType = {
  closeModal: () => void;
  modal: ReactNode;
  presentModal: (modal: ReactNode) => void;
};

export const GlobalModalContext = createContext<GlobalModalContextType | null>(null);

export const GlobalModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<ReactNode>(null);

  const closeModal = useCallback(() => setModal(null), []);
  const presentModal = useCallback((modal: ReactNode) => setModal(modal), []);

  return (
    <GlobalModalContext.Provider value={{ closeModal, modal, presentModal }}>{children}</GlobalModalContext.Provider>
  );
};

export default GlobalModalProvider;
