import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Footer from "ui/app/Footer";
import GlobalNav from "ui/app/GlobalNav";
import useViewModel from "ui/app/Layout/useLayoutViewModel";
import StickyAlertsView from "ui/app/StickyAlertsView";
import { AriaLiveContentID } from "ui/commerceUI/BaseResizableComponentViewModel";
import ErrorBoundary from "ui/components/ErrorBoundary";
import Spinner from "ui/components/Spinner";
import muiTheme from "ui/theme/muiTheme";

type LayoutProps = {
  fullWidth?: boolean;
};

const Layout = observer(({ fullWidth = false }: LayoutProps) => {
  const { isLoading, isMediumOrLarger } = useViewModel();

  return (
    <S.Container>
      <S.Header id="top">
        <S.SkipToContent href="#main">Skip to main content</S.SkipToContent>
        <GlobalNav />
        <StickyAlertsView />
      </S.Header>
      <S.Main id="main" tabIndex={-1} fullWidth={fullWidth} isMediumOrLarger={isMediumOrLarger}>
        <ErrorBoundary>
          <S.AriaLiveDiv id={AriaLiveContentID} role="region" aria-live="polite" />
          {isLoading ? (
            <Loading />
          ) : (
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          )}
        </ErrorBoundary>
      </S.Main>
      <Footer />
    </S.Container>
  );
});

const Loading = () => {
  return (
    <S.SpinnerContainer>
      <Spinner />
    </S.SpinnerContainer>
  );
};

const S = {
  Header: styled.header`
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: ${muiTheme.zIndex.appBar};
    &:focus-visible {
      outline: none;
    }
  `,
  Main: styled.main<{ fullWidth: boolean; isMediumOrLarger?: boolean }>`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: ${(props) => (props.isMediumOrLarger ? 1 : "auto")};
  `,
  AriaLiveDiv: styled.div`
    height: 0;
    width: 0;
    overflow: hidden;
  `,
  Container: styled.div`
    background: ${muiTheme.palette.common.white};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  `,
  SpinnerContainer: styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SkipToContent: styled.a`
    display: inline-block;
    position: absolute;
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    border: 1px solid black;
    z-index: 10001;
    transform: translateY(-200%);
    transition: transform 0.1s;

    &:focus {
      transform: translateY(0);
    }
  }
  `,
};

export default Layout;
