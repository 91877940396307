import { Components, Theme } from "@mui/material";
import { CheckboxIcon } from "ui/components/Checkbox/Checkbox";
import Colors from "ui/theme/Colors";

const createComponents = (theme: Theme): Components => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: Colors.overlay,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
      variant: "contained",
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        overflow: "hidden",
        padding: 0,
        transition: "none",
        whiteSpace: "normal",
        textAlign: "center",

        "&:focus-visible, &.Mui-focusVisible": {
          outline: `0.25rem solid ${theme.palette.primary.light}`,
        },
      },
      contained: ({ ownerState }) => ({
        transition: "background-color 200ms ease, border-color 200ms ease, color 200ms ease",

        ...(ownerState.color === "primary" && {
          backgroundColor: theme.palette.primary.main,
          border: `0.25rem solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.contrastText,
        }),
        ...(ownerState.color === "secondary" && {
          backgroundColor: theme.palette.secondary.main,
          border: `0.25rem solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.contrastText,
        }),
        "&:hover": {
          ...(ownerState.color === "primary" && {
            backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
          }),
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.light,
          }),
        },
        "&:active, &.Mui-active": {
          ...(ownerState.color === "primary" && {
            backgroundColor: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }),
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.secondary.contrastText,
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
          }),
        },
        "&.Mui-disabled": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
        },
      }),
      outlined: ({ ownerState }) => ({
        transition: "background-color 200ms ease, border-color 200ms ease, color 200ms ease",

        ...(ownerState.color === "primary" && {
          backgroundColor: theme.palette.primary.contrastText,
          border: `0.125rem solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        }),
        ...(ownerState.color === "secondary" && {
          backgroundColor: theme.palette.secondary.contrastText,
          border: `0.125rem solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
        }),
        "&:hover": {
          ...(ownerState.color === "primary" && {
            backgroundColor: theme.palette.primary.contrastText,
            border: `0.125rem solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.dark,
          }),
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.secondary.contrastText,
            border: `0.125rem solid ${theme.palette.secondary.light}`,
            color: theme.palette.secondary.light,
          }),
        },
        "&:active, &.Mui-active": {
          ...(ownerState.color === "primary" && {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }),
          ...(ownerState.color === "secondary" && {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }),
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.common.white,
          border: `0.125rem solid ${theme.palette.grey[300]}`,
          color: theme.palette.grey[300],
        },
      }),
      text: {
        ...theme.typography.body2,
        borderBottom: `0.0625rem solid ${theme.palette.grey[400]}`,
        color: Colors.superBlack,
        minWidth: "auto",
        padding: "0.0625rem !important",
        textTransform: "none",

        "&:hover": {
          background: "transparent",
          color: Colors.superBlack,

          borderBottom: `0.0625rem solid ${theme.palette.primary.main}`,
        },

        "&:active, &.Mui-active": {
          color: theme.palette.primary.main,

          borderBottom: `0.125rem solid ${theme.palette.primary.main}`,
          paddingBottom: "0 !important",
        },

        "&:focus-visible, &.Mui-focusVisible": {
          border: `0.0625rem solid ${theme.palette.primary.main}`,
          outline: "none",
          padding: "0 0 0.0625rem 0 !important",
        },
      },
      sizeSmall: {
        fontSize: theme.typography.pxToRem(16),
        padding: "0.25rem 0.75rem",

        "&.MuiButton-outlined": {
          padding: "0.375rem 0.875rem",
        },
      },
      sizeMedium: {
        padding: "0.625rem 1.25rem",

        "&.MuiButton-outlined": {
          padding: "0.75rem 1.375rem",
        },
      },
      sizeLarge: {
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: 0.5,
        padding: "0.75rem 1.25rem",

        "&.MuiButton-outlined": {
          padding: "0.875rem 1.375rem",
        },
      },
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableRipple: true,
      variant: "outlined",
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxIcon />,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        padding: 0,

        "> .checkbox-icon": {
          alignItems: "center",
          display: "flex",
          flexShrink: 0,
          height: "1.5rem",
          justifyContent: "center",
          width: "1.5rem",

          background: theme.palette.common.white,
          border: `0.125rem solid ${theme.palette.grey[400]}`,

          "> img": {
            display: "none",
            height: "0.9375rem",
            width: "0.9375rem",
          },
        },

        "&.Mui-disabled > .checkbox-icon": {
          background: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },

        "&.Mui-checked": {
          "> .checkbox-icon": {
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,

            "> img": {
              display: "block",
            },
          },

          "&.Mui-disabled > .checkbox-icon": {
            background: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
          },
        },

        "&:focus-visible, &.Mui-focusVisible": {
          outline: `0.25rem solid ${theme.palette.primary.light}`,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        alignSelf: "start",
        gap: "0.25rem",
        padding: theme.spacing(1, 1.5),
      },
      colorError: {
        backgroundColor: "rgba(228, 0, 43, 0.1);",
        color: theme.palette.primary.main,
      },
      colorSuccess: {
        backgroundColor: "rgba(7, 151, 73, 0.1)",
        color: "rgba(7, 151, 73, 1)",
      },
      label: {
        color: theme.palette.grey[600],
        fontSize: "0.875rem",
        fontWeight: 325,
        lineHeight: "1rem",
        padding: 0,
        textTransform: "uppercase",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 0,
        width: "100%",

        [theme.breakpoints.up("md")]: {
          maxHeight: "calc(100% - 4rem)",
          maxWidth: "50rem",
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...theme.typography.h4,
        alignItems: "center",
        display: "flex",
        fontFamily: theme.typography.fontFamily,
        justifyContent: "flex-end",
        margin: theme.spacing(1.5, 2, 0.5),
        padding: 0,

        "&.has-title": {
          borderBottom: `0.0625rem solid ${theme.palette.grey[200]}`,
          justifyContent: "space-between",
          paddingBottom: "0.25rem",
        },

        "&+.MuiDialogContent-root": {
          paddingTop: "0 !important",
        },

        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(5, 6, 2),
          padding: 0,

          "&.has-title": {
            paddingBottom: "0.25rem",
          },
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "0 1rem",

        [theme.breakpoints.up("md")]: {
          padding: "0 3rem",
        },
      },
    },
  },
  MuiDialogActions: {
    defaultProps: {
      disableSpacing: true,
    },
    styleOverrides: {
      root: {
        borderTop: `0.0625rem solid ${theme.palette.grey[200]}`,
        flexDirection: "column",
        margin: "0 1rem 1.5rem",
        padding: "1.5rem 0 0",

        [theme.breakpoints.up("md")]: {
          margin: "0 3rem 3rem",
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderBottom: `0.0625rem solid ${theme.palette.grey[200]}`,
      },
      vertical: {
        borderBottom: "none",
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    defaultProps: {
      variant: "body1",
    },
    styleOverrides: {
      root: {
        color: Colors.superBlack,
        textDecoration: "none !important",

        "&:visited": {
          color: theme.palette.grey[500],
        },

        "&:hover": {
          color: Colors.superBlack,
        },

        "&:active, &.Mui-active": {
          color: theme.palette.primary.main,
        },

        "&:focus-visible, &.Mui-focusVisible": {
          border: `0.0625rem solid ${theme.palette.primary.main}`,
          outline: "none",
          padding: "0 0 0.0625rem 0",
        },
      },
      underlineNone: {
        padding: "0.0625rem 0.0625rem 0.125rem",
      },
      underlineHover: {
        borderBottom: `0.0625rem solid transparent`,
        padding: "0.0625rem",

        "&:visited": {
          borderBottom: `0.125rem solid ${theme.palette.grey[500]}`,
          paddingBottom: 0,
        },

        "&:hover": {
          borderBottom: `0.0625rem solid ${theme.palette.primary.main}`,
        },

        "&:active, &.Mui-active": {
          borderBottom: `0.125rem solid ${theme.palette.primary.main}`,
          paddingBottom: 0,
        },

        "&:focus-visible, &.Mui-focusVisible": {
          border: `0.0625rem solid ${theme.palette.primary.main}`,
        },
      },
      underlineAlways: {
        borderBottom: `0.0625rem solid ${theme.palette.grey[400]}`,
        padding: "0.0625rem",

        "&:visited": {
          borderBottom: `0.125rem solid ${theme.palette.grey[500]}`,
          paddingBottom: 0,
        },

        "&:hover": {
          borderBottom: `0.0625rem solid ${theme.palette.primary.main}`,
        },

        "&:active, &.Mui-active": {
          borderBottom: `0.125rem solid ${theme.palette.primary.main}`,
          paddingBottom: 0,
        },

        "&:focus-visible, &.Mui-focusVisible": {
          border: `0.0625rem solid ${theme.palette.primary.main}`,
        },
      },
      button: {},
    },
  },
  // @ts-expect-error: MuiLoadingButton does not exist in Component type
  MuiLoadingButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
      variant: "contained",
    },
  },
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiTabs: {
    defaultProps: {
      textColor: "secondary",
    },
    styleOverrides: {
      root: {
        overflow: "visible", // make focus indicator visible
      },
      indicator: {
        height: "0.25rem",
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        ...theme.typography.formFieldLabel,
        textTransform: "none",
        color: theme.palette.grey[500],

        marginRight: theme.spacing(3),
        padding: theme.spacing(2, 0.5, 1, 0.5),
        minWidth: 0,

        "&:hover": {
          padding: theme.spacing(2, 0.5, 0.75, 0.5),
          color: theme.palette.secondary.main,
          borderWidth: "0.125rem",
          borderBottomStyle: "solid",
          borderColor: theme.palette.grey[300],
        },
        "&.Mui-focusVisible": {
          outline: `0.25rem solid ${theme.palette.primary.light}`,
          outlineOffset: "-0.25rem",
        },
      },
      fullWidth: {
        ...theme.typography.formFieldLabelCaps,
        padding: theme.spacing(1.5, 3),
        marginRight: theme.spacing(0),

        "&:hover": {
          padding: theme.spacing(1.5, 3, 1.25, 3),
          background: theme.palette.grey[100],
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        "::placeholder": {
          color: theme.palette.grey[500],
          opacity: 1,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "standard",
    },
    styleOverrides: {
      root: {
        ".MuiInputBase-root": {
          display: "flex",
          position: "relative",
          flexDirection: "row",
          alignItems: "center",
          background: theme.palette.primary.contrastText,
          border: `0.0625rem solid ${theme.palette.grey[400]}`,
          boxShadow: `0 0 0.25rem rgba(0, 0, 0, 0.25)`,
          borderRadius: "initial",
          padding: "0.0625rem",
          "&:hover": {
            background: theme.palette.grey[100],
            border: `0.0625rem solid ${theme.palette.grey[400]}`,
            boxShadow: `0 0 0.25rem rgba(0, 0, 0, 0.25)`,
          },

          "&.Mui-disabled": {
            background: theme.palette.grey[200],
            border: `0.0625rem solid ${theme.palette.grey[200]}`,
            boxShadow: "none",
            "&:hover": {
              background: theme.palette.grey[200],
              borderColor: theme.palette.grey[200],
            },
          },

          "&.Mui-focused": {
            border: `0.125rem solid ${theme.palette.secondary.main}`,
            padding: 0,
          },
          "&.Mui-error": {
            border: `0.125rem solid ${theme.palette.primary.main}`,
            padding: 0,
          },
          ".MuiInput-input": {
            ...theme.typography.formFieldText,

            height: "1.5rem",
            padding: "0.875rem",
          },
          ":before": {
            display: "none",
          },
          ":after": {
            display: "none",
          },
          // adornments
          ".MuiInputAdornment-root": {
            width: "1.5rem",
            height: "1.5rem",
          },
          ".MuiInputAdornment-positionStart": {
            marginRight: theme.pxToRem(12),
            marginLeft: theme.spacing(2),
          },
          ".MuiInputAdornment-positionEnd": {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
          },
          "&.MuiInputBase-adornedStart .MuiInputBase-input": {
            paddingLeft: 0,
          },
          "&.MuiInputBase-adornedEnd .MuiInputBase-input": {
            paddingRight: 0,
          },

          "&.MuiInputBase-multiline": {
            "& .MuiInputBase-inputMultiline": {
              minHeight: "5rem",
            },
          },
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        body1Bold: "p",
        body2Bold: "p",
        bodySmall: "p",
        bodySmallBold: "p",
        modifierGroupHeading: "p",
      },
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      autoHideDuration: 6000,
    },
    styleOverrides: {
      root: {
        "& .MuiPaper-root": { boxShadow: "none" },
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1.5, 2),
        typography: "body2",
        color: theme.palette.primary.contrastText,
        background: theme.palette.secondary.main,
      },
    },
  },
});

export default createComponents;
