import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    border: `0.0625rem solid ${theme.palette.grey[200]}`,
    boxShadow: "0 0 0.25rem 0 rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
  }),
  main: {
    display: "flex",
    padding: "1rem",
    gap: "0.75rem",
  },
  image: {
    flexShrink: 0,
    height: "5rem",
    objectFit: "contain",
    width: "5rem",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  name: {
    color: Colors.superBlack,
  },
  price: {},
  metadata: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  more: (theme) => ({
    typography: theme.typography.body2,
    color: Colors.superBlack,
  }),
  controls: (theme) => ({
    borderTop: `0.0625rem solid ${theme.palette.grey[200]}`,
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  }),
  control: {
    alignItems: "center",
    display: "flex",
    flexBasis: "6rem",
    justifyContent: "center",
  },
  controlLeft: (theme) => ({
    borderRight: `0.0625rem solid ${theme.palette.grey[200]}`,
  }),
  controlRight: (theme) => ({
    borderLeft: `0.0625rem solid ${theme.palette.grey[200]}`,
  }),
  controlButton: (theme) => ({
    typography: theme.typography.bodySmall,
    color: Colors.superBlack,

    [theme.breakpoints.up("md")]: {
      typography: theme.typography.bodySmall,
      color: Colors.superBlack,
    },
  }),
  controlLink: {
    "&:visited": {
      color: Colors.superBlack,
    },
  },
  stepper: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  recipient: (theme) => ({
    color: theme.palette.grey[500],
    fontSize: theme.pxToRem(15),
    fontWeight: 325,
    lineHeight: theme.pxToRem(20),
    textTransform: "uppercase",
  }),
} satisfies MuiStyles;

export default styles;
