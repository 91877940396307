export enum PaymentType {
  cash = "cash",
  credit = "debit-credit",
  digitalWallet = "digital-wallet",
  giftCard = "gift-card",
  savedPayment = "saved-payment",
}

export const isPaymentType = (object: unknown): object is PaymentType => {
  return (
    object === PaymentType.cash ||
    object === PaymentType.credit ||
    object === PaymentType.digitalWallet ||
    object === PaymentType.giftCard ||
    object === PaymentType.savedPayment
  );
};
