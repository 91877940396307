import useAnalytics from "hooks/useAnalytics";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "stores/cart";
import { useOrder } from "stores/order";
import {
  SortedGroupOrderParticipants,
  sortGroupOrderParticipants,
} from "ui/components/GroupOrderParticipantStatus/GroupOrderParticipantStatus.helpers";
import { CheckoutPagePath } from "ui/navigation/Pages";
import { logError } from "util/Logger";

const title = "Participant Status";

export type ParticipantStatusModalViewModelProps = {
  onClose: () => void;
  sortedGroupOrderParticipants?: SortedGroupOrderParticipants;
};

const useParticipantStatusViewModel = ({
  onClose,
  sortedGroupOrderParticipants,
}: ParticipantStatusModalViewModelProps) => {
  const { analytics } = useAnalytics();
  const { getCurrentGroupOrderParticipants } = useOrder();
  const { hideCart } = useCart();
  const navigate = useNavigate();

  const [orderNotSubmittedParticipants, setOrderNotSubmittedParticipants] = useState<string[] | undefined>(
    sortedGroupOrderParticipants?.orderNotSubmittedParticipants
  );
  const [orderSubmittedParticipants, setOrderSubmittedParticipants] = useState<string[] | undefined>(
    sortedGroupOrderParticipants?.orderSubmittedParticipants
  );
  const [isLoadingParticipants, setIsLoadingParticipants] = useState(false);

  const closeModal = () => {
    onClose();
    analytics.modalCTA("Cancel", title);
  };

  useEffect(() => {
    const loadGroupOrderParticipants = async () => {
      if (!orderNotSubmittedParticipants && !orderSubmittedParticipants) {
        try {
          setIsLoadingParticipants(true);
          const groupOrderParticipants = await getCurrentGroupOrderParticipants();
          const { orderNotSubmittedParticipants, orderSubmittedParticipants } =
            sortGroupOrderParticipants(groupOrderParticipants);
          setOrderNotSubmittedParticipants(orderNotSubmittedParticipants);
          setOrderSubmittedParticipants(orderSubmittedParticipants);
        } catch (e) {
          logError(e);
          setOrderNotSubmittedParticipants([]);
          setOrderSubmittedParticipants([]);
        } finally {
          setIsLoadingParticipants(false);
        }
      }
    };

    loadGroupOrderParticipants();
  }, [getCurrentGroupOrderParticipants, orderNotSubmittedParticipants, orderSubmittedParticipants]);

  const onClickCheckout = () => {
    hideCart();
    navigate(CheckoutPagePath.checkout);
    closeModal();
  };

  return {
    analytics,
    closeModal,
    isLoadingParticipants,
    onClickCheckout,
    orderNotSubmittedParticipants,
    orderSubmittedParticipants,
    title,
  };
};

export default useParticipantStatusViewModel;
