import { fontFamilyBlankenhorn } from "ui/theme/theme.typography";
import MuiStyles from "ui/util/Styles";

const styles = {
  divider: {
    borderColor: (theme) => theme.palette.grey[600],
    marginRight: "4rem",
  },
  drawer: (theme) => ({
    "& .MuiDrawer-paper": {
      bgcolor: theme.palette.common.black,
      paddingLeft: "2rem",
      width: "100%",
      overflow: "hidden",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  }),
  drawerCloseButton: (theme) => ({
    marginBottom: "2rem",
    marginLeft: "-.5rem",
    marginTop: "2rem",
    padding: 0,
    color: theme.palette.common.white,
  }),
  container: {
    overflow: "hidden",
    position: "relative",
  },
  logo: {
    minWidth: "24.5rem",
    right: "-81px",
    bottom: "-64px",
    opacity: 0.08,
    position: "absolute",
    zIndex: "-1",
  },
  menuItem: (theme) => ({
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.white,
  }),
  greeting: {
    display: "block",
    marginTop: "2.5rem",
    marginBottom: "2rem",
    color: (theme) => theme.palette.common.white,
  },
  greetingItem: {
    marginLeft: "-.25rem",
    marginBottom: "1.75rem",
  },
  text: {
    textTransform: "initial",
    color: (theme) => theme.palette.common.white,
  },
  logoutItem: {
    paddingLeft: ".5rem",
    color: (theme) => theme.palette.common.white,
  },
  cursive: (theme) => ({
    fontFamily: fontFamilyBlankenhorn,
    fontWeight: 400,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24),
  }),
  primaryNav: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "2.5rem",

    "&.logged-out": {
      flexGrow: 1,
    },
  },
  loggedInNavUser: {
    width: "100%",
    color: "white",
    "& .MuiButton-text": {
      textTransform: "none",
    },
  },
  loggedOutNavUser: {
    color: "white",
    marginBottom: "2rem",
    alignItems: "center",
  },
} as const satisfies MuiStyles;

export default styles;
