import { isGroupOrder } from "models/groupOrder";
import { useOrder } from "stores/order";
import { useUser } from "stores/user";
import { getStoredGroupOrderParticipantName } from "util/GroupOrderParticipantNameUtils";

const useGroupOrderRecipient = () => {
  const { order } = useOrder();
  const { user } = useUser();

  const getGroupOrderRecipientName = () => {
    if (!isGroupOrder(order)) {
      return;
    }

    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }

    return getStoredGroupOrderParticipantName();
  };

  return { getGroupOrderRecipientName };
};

export default useGroupOrderRecipient;
