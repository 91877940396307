import { ModifierType } from "models/modifiers/ModifierType";
import { OptionModifier } from "models/modifiers/OptionModifier";

export type MultiSelectModifier = OptionModifier & {
  minSelections?: number;
  maxSelections?: number;
};

export const isMultiSelectModifier = (object: unknown): object is MultiSelectModifier => {
  const modifier = object as MultiSelectModifier;
  return modifier.type === ModifierType.MultiSelect && Array.isArray(modifier.options);
};
