import MuiStyles from "ui/util/Styles";

const styles = {
  card: {
    width: "100%",
    height: "100%",
    borderRadius: 0,
    boxShadow: "0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1rem",
    padding: "1rem",
  },
  content: {
    padding: 0,
  },
  buttons: {
    borderTop: "1px solid #DEDEDE",
    justifyContent: "space-between",
    padding: "1rem 0 0",
    width: "100%",
  },
} satisfies MuiStyles;

export default styles;
