import { RuntimeAlert } from "models/Alert";
import { useAlert } from "stores/alert";

const useStickyAlertsViewModel = () => {
  const alertStore = useAlert();

  const alerts = alertStore.alerts.map<RuntimeAlert>((alert) => ({
    type: alert.type,
    title: alert.title,
    message: alert.message,
    actions: alert.actions,
    onClose: () => {
      alertStore.removeAlert(alert);
    },
  }));

  return { alerts };
};

export default useStickyAlertsViewModel;
