import _ from "lodash";
import { Address } from "models/Address";
import { CardIssuer, isCardIssuer } from "models/payment/CardIssuer";
import { PaymentType } from "models/payment/PaymentType";

/**
 * Information returned in the payment of an Order object + also the response
 * in the user's saved payments if the payment is a credit card
 */
type CreditCardInfoResponse = {
  type: PaymentType.credit;
  default: boolean;
  details: {
    lastFour: string;
    issuer: CardIssuer;
    expirationMonth: number;
    expirationYear: number;
    billingAddress?: Address;
  };
};

export const isCreditCardInfoResponse = (object: unknown): object is CreditCardInfoResponse => {
  const creditCard = object as CreditCardInfoResponse;
  return (
    !!creditCard &&
    !!creditCard.type &&
    creditCard.type === PaymentType.credit &&
    _.isBoolean(creditCard.default) &&
    !!creditCard.details &&
    _.isString(creditCard.details.lastFour) &&
    isCardIssuer(creditCard.details.issuer) &&
    _.isNumber(creditCard.details.expirationMonth) &&
    _.isNumber(creditCard.details.expirationYear)
  );
};

export default CreditCardInfoResponse;
