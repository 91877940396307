import { lazy } from "react";
import { Route } from "react-router-dom";
import AccountLayout from "ui/app/AccountLayout";
import { AccountPagePath } from "ui/navigation/Pages";
import ProtectedRoute from "ui/navigation/routes/ProtectedRoute";

const MyFavesScreen = lazy(() => import("ui/screens/account/MyFaves"));
const RecentOrderDetailsScreen = lazy(() => import("ui/screens/account/RecentOrderDetailsScreen"));
const ProfileScreen = lazy(() => import("ui/screens/account/ProfileScreen"));
const RecentOrdersScreen = lazy(() => import("ui/screens/account/RecentOrdersScreen"));

const AccountRoutes = (
  <Route element={<ProtectedRoute />}>
    <Route path={AccountPagePath.base} element={<AccountLayout />}>
      <Route index element={<ProfileScreen />} />
      <Route path={AccountPagePath.recentOrders} element={<RecentOrdersScreen />} />
      <Route path={AccountPagePath.recentOrderDetails} element={<RecentOrderDetailsScreen />} />
    </Route>
  </Route>
);

export const MyFavesRoutes = (
  <Route element={<ProtectedRoute />}>
    <Route element={<AccountLayout />}>
      <Route path={AccountPagePath.myFaves} element={<MyFavesScreen />} />
    </Route>
  </Route>
);

export default AccountRoutes;
