import { Address } from "models/Address";
import CalorieInfo from "models/CalorieInfo";
import SavedDeliveryAddress from "models/user/SavedDeliveryAddress";

type FormatCurrencyOptions = {
  /**
   * Sets the maximum allowed number of digits for a curency input
   * If not set, limits on digits fall back to NumberFormat: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumsignificantdigits
   * Only the first digits up to maxDigits will be used for the formatted amount
   */
  maxInputDigits?: number;

  /** Groups numbers by commas if true, e.g., 1000000 -> 1,000,000 */
  useGrouping?: boolean;
};
export class Formatter {
  // Formats a 10 digit phone number so it's in the format (555) 555-5555
  static formatPhone(phoneNumber?: string): string {
    if (!phoneNumber) return "";
    const trimmedPhone = phoneNumber.replace(/[^0-9]/g, "");
    if (trimmedPhone.length === 10) {
      const areaCode = trimmedPhone.slice(0, 3);
      const part1 = trimmedPhone.slice(3, 6);
      const part2 = trimmedPhone.slice(6);
      return `(${areaCode}) ${part1}-${part2}`;
    }

    if (trimmedPhone.length === 11) {
      const countryCode = trimmedPhone.slice(0, 1);
      const areaCode = trimmedPhone.slice(1, 4);
      const part1 = trimmedPhone.slice(4, 7);
      const part2 = trimmedPhone.slice(7);
      return `${countryCode} (${areaCode}) ${part1}-${part2}`;
    }

    return trimmedPhone;
  }

  // Formats a 10 or 11 digit phone number so it's in the format 5555555555
  static formatPhoneRaw(phoneNumber?: string) {
    return phoneNumber?.replace(/[^0-9]/g, "").slice(-10) ?? "";
  }

  static formatDate(date?: string): string {
    if (!date) return "";
    const trimmedDate = date.replace(/[^0-9]/g, "");
    if (trimmedDate.length < 2) return trimmedDate;
    if (trimmedDate.length < 4) return `${trimmedDate.slice(0, 2)}/${trimmedDate.slice(2)}`;
    return `${trimmedDate.slice(0, 2)}/${trimmedDate.slice(2, 4)}/${trimmedDate.slice(4, 8)}`;
  }

  static formatAddress(address: Address): string {
    const addressStrings: string[] = [];
    addressStrings.push(address.addressLineOne);
    if (address.addressLineTwo) addressStrings.push(address.addressLineTwo);
    addressStrings.push(`${address.city} ${address.zipcode}`);
    return addressStrings.join(", ");
  }

  static formatAddressForScreenReader(address: string): string {
    return address.replace(/([0-9]{2,})/g, (match) => match.split("").join(" "));
  }

  static formatDeliveryAddress(address: SavedDeliveryAddress): string {
    const addressStrings: string[] = [];
    addressStrings.push(address.streetAddress);
    addressStrings.push(`${address.city} ${address.zipcode}`);
    return addressStrings.join(", ");
  }

  static formatShortAddress(address: Address): string {
    const addressStrings: string[] = [];
    addressStrings.push(address.addressLineOne);
    if (address.addressLineTwo) addressStrings.push(address.addressLineTwo);
    return addressStrings.join(" ");
  }

  static formatStoreInfoUrl(address: Address | undefined, storeName: string | undefined) {
    let url = "https://locations.jimmyjohns.com/";

    if (address && storeName) {
      const state = address.state?.toLowerCase();
      const city = address.city
        ?.replace(/\./g, "-") // replace periods with dashes
        ?.replace(/-+/g, "-") // replace multiple dashes with single dash
        ?.replace(/[^\w-]/g, "") // remove characters that are not a letter, number or dash
        ?.toLowerCase();
      const storeNameId = storeName.match(/JJ([\d]+)/i)?.pop();

      if (state && city && storeNameId) {
        url = `${url}${state}/${city}/sandwiches-${storeNameId}.html`;
      }
    }

    return url;
  }

  static formatCurrency(amount?: number | string, options?: FormatCurrencyOptions): string {
    if (!amount) return "$0.00";

    // Maximum currency significant digits allowed https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumsignificantdigits
    let maxInputDigits = 21;
    let useGrouping = false;
    if (options) {
      if (options.maxInputDigits) maxInputDigits = options.maxInputDigits;
      if (options.useGrouping) useGrouping = options.useGrouping;
    }

    const formatter = Intl.NumberFormat("en-US", {
      currency: "USD",
      maximumFractionDigits: 2,
      style: "currency",
      useGrouping: !!useGrouping,
    });

    if (typeof amount === "number") {
      return formatter.format(amount);
    } else {
      const numericAmount = amount.replace(/[^0-9]/g, "");
      const trimmedNumericAmount =
        numericAmount.length > maxInputDigits ? numericAmount.substring(0, maxInputDigits) : numericAmount;
      return formatter.format(Number(trimmedNumericAmount) / 100);
    }
  }

  static formatMilitaryTime(time: string): string | undefined {
    const [h, m] = time.split(":");
    const hours = parseInt(h, 10);

    if (isNaN(hours) || hours < 0 || hours > 24) return undefined;
    const minutes = m === "00" ? `` : `:${m}`;

    if (hours === 12) return `12${minutes} PM`;
    if (hours === 24 || hours === 0) return `12${minutes} AM`;
    if (hours < 12) return `${hours}${minutes} AM`;
    if (hours > 12) return `${hours - 12}${minutes} PM`;
  }

  static formatCalories = (calorieInfo?: CalorieInfo, calorieMetaData?: string) => {
    if (!calorieInfo?.baseCalories) return "0 cal";
    let caloriesString = `${calorieInfo.baseCalories}`;

    if (calorieInfo.caloriesSeparator && calorieInfo.maxCalories) {
      caloriesString = `${caloriesString}${calorieInfo.caloriesSeparator}${calorieInfo.maxCalories}`;
    }
    if (calorieMetaData) {
      caloriesString = `${caloriesString} | ${calorieMetaData}`;
    }
    return `${caloriesString} cal`;
  };
}
