import TokenManager from "dataTransformers/TokenManager";
import { Networker } from "networking/Networker";
import GoogleAnalytics from "util/GoogleAnalytics";

class RootStore {
  static readonly analytics = new GoogleAnalytics();
  static readonly networker = new Networker();
  static readonly tokenManager = new TokenManager();
}

export default RootStore;
