import {
  Box,
  Checkbox as MuiCheckbox,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx";
import IconCheck from "assets/checkbox/check.svg";
import React, { Ref } from "react";
import { ReactNode } from "react";
import styles from "ui/components/Checkbox/Checkbox.styles";
import Icon from "ui/components/Icon/Icon";
import multiStyles from "ui/components/MultiSelectCheckbox/MultiSelectCheckbox.styles";

export const CheckboxIcon = () => (
  <Box className="checkbox-icon">
    <img src={IconCheck} aria-hidden="true" />
  </Box>
);

export type CheckboxProps = Omit<FormControlLabelProps, "control" | "label"> & {
  label: string | ReactNode;
  capitalizeText?: boolean;
  containerSx?: SxProps<Theme>;
  id?: string;
  image?: string;
  multiSelect?: boolean;
  secondaryLabel?: string;
  tertiaryLabel?: string;
  tooltipText?: string;
  variant?: "basic" | "outlined";
};

export const Checkbox = React.forwardRef(function Checkbox(
  {
    label,
    capitalizeText = true,
    containerSx,
    id,
    image,
    multiSelect,
    secondaryLabel,
    tertiaryLabel,
    sx,
    tooltipText,
    variant = "outlined",
    ...formControlLabelProps
  }: CheckboxProps,
  ref?: Ref<unknown>
) {
  const isSelected = !!formControlLabelProps.checked;

  return (
    <Box sx={[styles.container, ...(containerSx ? [containerSx as SystemStyleObject<Theme>] : [])]}>
      <FormControlLabel
        control={<MuiCheckbox id={id} />}
        inputRef={ref}
        label={
          <Box sx={styles.root}>
            <Box sx={styles.content}>
              {image && <Box component="img" src={image} sx={styles.image} alt="" />}
              <Box sx={styles.labels}>
                <Typography
                  component="span"
                  sx={[styles.checkboxTitle, capitalizeText ? styles.capitalizeTitle : null]}
                  variant={variant === "outlined" ? "formFieldLabel" : "formFieldText"}
                >
                  {label}
                </Typography>

                <Box component="span" sx={styles.secondaryLabels}>
                  {secondaryLabel ? (
                    <Typography
                      component="span"
                      variant={variant === "outlined" ? "bodySmallBold" : "formFieldText"}
                      sx={styles.secondaryLabel}
                    >
                      {secondaryLabel}
                    </Typography>
                  ) : null}

                  {secondaryLabel && tertiaryLabel ? (
                    <>
                      <Divider sx={styles.divider} orientation="vertical" />
                    </>
                  ) : null}

                  {tertiaryLabel ? (
                    <Typography
                      component="span"
                      variant={variant === "outlined" ? "bodySmall" : "formFieldText"}
                      sx={styles.tertiaryLabel}
                    >
                      {tertiaryLabel}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        }
        sx={[
          styles.formControlLabel,
          tooltipText ? styles.maxWidth : null,
          variant === "outlined" ? styles.outlined : styles.basic,
          multiSelect ? multiStyles.multiselect : null,
          ...(sx ? [sx as SystemStyleObject<Theme>] : []),
        ]}
        className={`checkbox-${variant}${isSelected ? " selected" : ""}`}
        {...formControlLabelProps}
      />
      {tooltipText ? (
        <Tooltip
          PopperProps={{ sx: styles.tooltip }}
          arrow
          placement="top"
          enterTouchDelay={200}
          title={
            <Typography sx={styles.tooltipText} variant="bodySmall">
              {tooltipText}
            </Typography>
          }
        >
          <Box sx={styles.icon} tabIndex={0} aria-label="more info">
            <Icon icon={"info"} />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
});

export default Checkbox;
