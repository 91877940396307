import { isBoolean, isString } from "lodash";
import {
  GroupOrderParticipant,
  GroupOrderPaymentType,
  GroupOrderStatus,
  isGroupOrderPaymentType,
  isGroupOrderStatus,
} from "models/groupOrder";

/**
 * @see {@link https://jj-swagger.detroitlabs.com/#/GroupOrder/getGroupOrder API Docs}
 */

type GroupOrderDetails = {
  id: string;
  groupOrderPaymentType: GroupOrderPaymentType;
  hostName: string;
  isOpen: boolean;
  invitationText?: string;
  participants: GroupOrderParticipant[];
  status?: GroupOrderStatus;
};

export const isGroupOrderDetails = (object: unknown): object is GroupOrderDetails => {
  const details = object as GroupOrderDetails;
  return (
    details !== undefined &&
    isString(details.id) &&
    isGroupOrderPaymentType(details.groupOrderPaymentType) &&
    isString(details.hostName) &&
    isBoolean(details.isOpen) &&
    (details.invitationText === undefined || isString(details.invitationText)) &&
    Array.isArray(details.participants) &&
    (details.status === undefined || isGroupOrderStatus(details.status))
  );
};

export default GroupOrderDetails;
