import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  desktopPaper: (theme) => ({
    background: Colors.brightWhite,
    filter: "drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.25))",
    marginTop: "1rem",
    overflow: "visible",

    [theme.breakpoints.up("xl")]: {
      marginTop: "2.5rem",
    },
  }),
  desktopArrow: {
    border: "0.5rem solid transparent",
    borderBottomColor: Colors.brightWhite,
    height: 0,
    left: "50%",
    marginLeft: "-0.5rem",
    overflow: "hidden",
    position: "absolute",
    top: "-1rem",
    width: 0,
  },
  mobilePaper: {
    background: Colors.brightWhite,
    maxHeight: "100%",
    maxWidth: "100%",
    width: "100%",
  },
} as const satisfies MuiStyles;

export default styles;
