import { useContext } from "react";
import { UserContext } from "stores/user/UserProvider";

const useUser = () => {
  const store = useContext(UserContext);
  if (!store) {
    throw new Error("useUser must be used within a UserProvider.");
  }
  return store;
};

export default useUser;
