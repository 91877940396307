import { createTheme } from "@mui/material/styles";
import { Theme } from "ui/Theme";
import createComponents from "ui/theme/theme.components";
import palette from "ui/theme/theme.palette";
import createTypography from "ui/theme/theme.typography";

const muiTheme = createTheme({
  breakpoints: {
    values: Theme.breakpoints,
  },
  palette,
  spacing: (factor: number | string) => (typeof factor === "number" ? `${0.5 * factor}rem` : factor), // responsive grid spacing based on 0.5rem (aka 8px)
});

// lifting this util method reference up to the top-level of the theme object, for convenience.
muiTheme.pxToRem = muiTheme.typography.pxToRem;

muiTheme.typography = createTypography(muiTheme);
muiTheme.components = createComponents(muiTheme);

export default muiTheme;
