import FullScreenError from "ui/components/Error/FullScreenError";

const SomethingWentWrong = () => (
  <FullScreenError
    title="Uh-Oh"
    subtitle="Something Went Wrong Here"
    description="Something went wrong with your request. Please try again in a few minutes."
  />
);

export default SomethingWentWrong;
