import { isNumber, isString } from "lodash";
import { isStringUnion } from "util/JavascriptUtility";

type AchievementBadge = {
  id: string;
  imageUrl: string;
  name: string;
  perkCode: number;
  status: AchievementBadgeStatus;
  alertHeadline?: string;
  description?: string;
  progress?: AchievementBadgeProgress;
  statusEndDescription?: string;
  termsAndConditions?: string;
};

export type AchievementBadgeProgress = {
  current: number;
  threshold?: number;
  rawDescription?: string;
  nestedProgressItems?: AchievementBadgeProgressItem[];
};

type AchievementBadgeProgressItem = {
  current?: number;
  name?: string;
  threshold?: number;
};

const achievementBadgeStatuses = ["ACHIEVED", "ACHIEVED_AND_SEEN", "AVAILABLE", "AVAILABLE_AND_SEEN"] as const;
export type AchievementBadgeStatus = (typeof achievementBadgeStatuses)[number];

const isAchievementBadge = (object: unknown): object is AchievementBadge => {
  const badge = object as AchievementBadge;
  return (
    !!badge &&
    isString(badge.id) &&
    isString(badge.imageUrl) &&
    isString(badge.name) &&
    isNumber(badge.perkCode) &&
    isStringUnion(badge.status, achievementBadgeStatuses) &&
    (badge.alertHeadline === undefined || isString(badge.alertHeadline)) &&
    (badge.description === undefined || isString(badge.description)) &&
    (badge.statusEndDescription === undefined || isString(badge.statusEndDescription)) &&
    (badge.termsAndConditions === undefined || isString(badge.termsAndConditions))
  );
};

export const isAchievementBadgeArray = (array: unknown): array is AchievementBadge[] => {
  const badgeArray = array as AchievementBadge[];
  return !!badgeArray && badgeArray.every(isAchievementBadge);
};

export default AchievementBadge;
