import _ from "lodash";
import OrderTimeSlot from "models/store/OrderTimeSlot";

type OrderAheadDateTimes = {
  text: string; // Day of week
  timeSlots: OrderTimeSlot[];
};

const isOrderAheadDateTime = (object: unknown): object is OrderAheadDateTimes => {
  const dateTime = object as OrderAheadDateTimes;
  return (
    !!dateTime &&
    _.isString(dateTime.text) &&
    Array.isArray(dateTime.timeSlots) &&
    dateTime.timeSlots.length === dateTime.timeSlots.filter((ts) => _.isString(ts.text) && _.isString(ts.value)).length
  );
};

export const isOrderAheadDateTimesArray = (object: unknown): object is OrderAheadDateTimes[] => {
  const dateTimes = object as OrderAheadDateTimes[];
  return Array.isArray(dateTimes) && dateTimes.length === dateTimes.filter((dt) => isOrderAheadDateTime(dt)).length;
};

export default OrderAheadDateTimes;
