import { OctocartError } from "errors/OctocartError";

export interface OctocartNetworkError extends OctocartError {
  httpStatusCode: number;
}

export const isOctocartNetworkError = (object: unknown): object is OctocartNetworkError => {
  const error = object as OctocartNetworkError;
  return !!error && !!error.httpStatusCode;
};
