import ValidationErrorMessages from "errors/ValidationErrorMessages";
import { FieldOptions } from "util/FieldOptions";

export type AddParticipantsSchema = {
  participantEmails: string[];
  saveParticipantEmails?: boolean;
};

export const fieldOptions: FieldOptions<AddParticipantsSchema> = {
  participantEmails: {
    required: ValidationErrorMessages.Default.FieldIsRequired,
  },
};
