import { GroupOrderDetails, isGroupOrderDetails } from "models/groupOrder";
import { isOrder, Order } from "models/order/Order";

type GroupOrder = Order & {
  groupOrderDetails: GroupOrderDetails;
};

export const isGroupOrder = (object: unknown): object is GroupOrder => {
  const order = object as GroupOrder;
  return isOrder(order) && !!order.groupOrderDetails && isGroupOrderDetails(order.groupOrderDetails);
};

export const isHostPayGroupOrder = (object: unknown): object is GroupOrder => {
  const order = object as GroupOrder;
  return isGroupOrder(order) && order.groupOrderDetails.groupOrderPaymentType === "host";
};

export const isSplitPayGroupOrder = (object: unknown): object is GroupOrder => {
  const order = object as GroupOrder;
  return isGroupOrder(order) && order.groupOrderDetails.groupOrderPaymentType === "split";
};

export default GroupOrder;
