import { BaseModifier, isBaseModifier } from "models/modifiers/BaseModifier";
import { Modifier } from "models/modifiers/Modifier";
import { TypeChecker } from "util/JavascriptUtility";

export const isModifierArray = (object: unknown): object is BaseModifier[] => {
  const modifiers = object as Array<BaseModifier>;
  return (
    !!modifiers && Array.isArray(modifiers) && modifiers.filter((m) => isBaseModifier(m)).length === modifiers.length
  );
};

/** BaseModifier Category */
export type ModifierCategory = {
  id: string;
  text: string;
  description?: string;
  image?: string;
  modifiers: Modifier[];
};

export const isModifierCategory = (object: unknown): object is ModifierCategory => {
  const category = object as ModifierCategory;
  return (
    !!category &&
    TypeChecker.isString(category.id) &&
    TypeChecker.isString(category.text) &&
    TypeChecker.isOptional(category.description, TypeChecker.isString) &&
    TypeChecker.isOptional(category.image, TypeChecker.isString) &&
    isModifierArray(category.modifiers)
  );
};

export const isModifierCategoryArray = (object: unknown): object is ModifierCategory[] => {
  const category = object as ModifierCategory[];
  return !!category && Array.isArray(category) && category.length === category.filter(isModifierCategory).length;
};
