import { useContext } from "react";
import { StoreManagerContext } from "stores/StoreManagerProvider";

const useStoreManager = () => {
  const store = useContext(StoreManagerContext);
  if (!store) {
    throw new Error("useStoreManager must be used within a StoreManagerProvider.");
  }
  return store;
};

export default useStoreManager;
