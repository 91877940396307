import { GroupOrderParticipant } from "models/groupOrder";

export type SortedGroupOrderParticipants = {
  orderNotSubmittedParticipants: string[];
  orderSubmittedParticipants: string[];
};

export const sortGroupOrderParticipants = (
  groupOrderParticipants: GroupOrderParticipant[]
): SortedGroupOrderParticipants => {
  const currentParticipantsExcludingHost = groupOrderParticipants.filter((participant) => participant.id !== "host");

  const orderNotSubmittedParticipants = currentParticipantsExcludingHost
    .filter((participant) => participant.orders?.length === 0)
    .filter((participant) => participant.email !== undefined)
    .map((participant) => {
      return participant.name ? `${participant.name} (${participant.email})` : participant.email;
    }) as string[];

  const orderSubmittedParticipants = currentParticipantsExcludingHost
    .filter((participant) => participant.orders && participant.orders?.length > 0)
    .filter((participant) => participant.email !== undefined)
    .map((participant) => {
      return participant.name ? `${participant.name} (${participant.email})` : participant.email;
    }) as string[];

  return { orderNotSubmittedParticipants, orderSubmittedParticipants };
};
