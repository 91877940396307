import { useContext } from "react";
import { CartContext } from "stores/cart/CartProvider";

const useCart = () => {
  const store = useContext(CartContext);
  if (!store) {
    throw new Error("useCart must be used within a CartProvider.");
  }
  return store;
};

export default useCart;
