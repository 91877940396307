import { Button, Fade, ModalProps, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import useAnalytics from "hooks/useAnalytics";
import { CmsButtonData } from "models/cms";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentfulImage from "ui/components/ContentfulImage";
import Modal from "ui/components/Modal";
import styles from "ui/components/PromoModal/PromoModal.styles";

export type PromoModalProps = Omit<ModalProps, "children" | "title" | "onClose"> & {
  cta?: CmsButtonData;
  description?: string;
  image?: string;
  name: string;
  onClose: () => void;
  termsAndConditions?: string;
  title?: string;
};

const PromoModal = ({
  cta,
  description,
  image,
  name,
  onClose,
  title,
  termsAndConditions,
  ...modalProps
}: PromoModalProps) => {
  const { analytics } = useAnalytics();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [shouldShowTerms, setShouldShowTerms] = useState(false);

  const handleOnClose = () => {
    analytics.modalCTA("Close", name);
    onClose();
  };

  const onClickCta = () => {
    if (!cta) return;

    analytics.modalCTA(cta.labelText, name);
    navigate(cta.href);
    onClose();
  };

  const onClickTermsToggle = () => {
    setShouldShowTerms((current) => !current);
  };

  useEffect(() => {
    if (!modalProps.open) return;
    analytics.modalImpression("Promotion", name);
  }, [analytics, modalProps.open, name]);

  return (
    <Modal {...modalProps} onClose={handleOnClose}>
      <Stack sx={styles.container}>
        {title && (
          <Typography variant="h1" sx={styles.title}>
            {title}
          </Typography>
        )}
        {image && <ContentfulImage url={image} sx={styles.image} />}
        {description && <Typography sx={styles.description}>{description}</Typography>}
        {cta && (
          <Button onClick={onClickCta} role="link" size={isMdUp ? "large" : "medium"} sx={styles.cta}>
            {cta.labelText}
          </Button>
        )}
        {termsAndConditions && (
          <>
            <Button onClick={onClickTermsToggle} sx={styles.termsButton} variant="text">
              Terms and Conditions
            </Button>
            {shouldShowTerms && (
              <Fade in={shouldShowTerms}>
                <Typography sx={styles.termsText}>{termsAndConditions}</Typography>
              </Fade>
            )}
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default PromoModal;
