import MetadataKey from "models/MetadataKey";

type ObjectWithMetadata = {
  metadata?: { key: MetadataKey; value: string }[];
};

const getMetadataValueForKey = (obj: ObjectWithMetadata, metadataKey: MetadataKey) => {
  return obj.metadata?.find(({ key }) => key === metadataKey)?.value;
};

export default getMetadataValueForKey;
