import { isString } from "lodash";

type GroupOrderParticipantOrder = {
  id?: string;
  status?: string;
};

export const isGroupOrderParticipantOrder = (object: unknown): object is GroupOrderParticipantOrder => {
  const order = object as GroupOrderParticipantOrder;
  return (
    order !== undefined &&
    (order.id === undefined || isString(order.id)) &&
    (order.status === undefined || isString(order.status))
  );
};

export default GroupOrderParticipantOrder;
