import useAnalytics from "hooks/useAnalytics";
import { useOrder } from "stores/order";

const useOrderNavViewModel = () => {
  const { analytics } = useAnalytics();
  const { order } = useOrder();

  const onClickOrderNow = () => {
    analytics.linkClick("Order Now", "Global Header");
  };

  return {
    isOrderStarted: !!order,
    onClickOrderNow,
  };
};

export default useOrderNavViewModel;
