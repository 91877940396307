import { Box, Divider, Stack, Typography } from "@mui/material";
import { SortedGroupOrderParticipants } from "ui/components/GroupOrderParticipantStatus/GroupOrderParticipantStatus.helpers";
import styles from "ui/components/GroupOrderParticipantStatus/GroupOrderParticipantStatus.styles";
import Icon from "ui/components/Icon";

const GroupOrderParticipantStatus = ({
  orderNotSubmittedParticipants,
  orderSubmittedParticipants,
}: SortedGroupOrderParticipants) => {
  return (
    <Box>
      {orderNotSubmittedParticipants.length > 0 && (
        <Stack sx={styles.pariticipantList}>
          <Typography variant="formFieldLabelCaps">Order Not Submitted</Typography>
          <ul>
            {orderNotSubmittedParticipants.map((participant) => (
              <li key={participant}>
                <Stack direction="row" sx={styles.participantRow}>
                  <Icon icon="stillWaiting" />
                  <Typography variant="body2">{participant}</Typography>
                </Stack>
              </li>
            ))}
          </ul>
        </Stack>
      )}
      {orderNotSubmittedParticipants.length > 0 && orderSubmittedParticipants.length > 0 && (
        <Divider sx={styles.divider} />
      )}
      {orderSubmittedParticipants.length > 0 && (
        <Stack sx={styles.pariticipantList}>
          <Typography variant="formFieldLabelCaps">Order Submitted</Typography>
          <ul>
            {orderSubmittedParticipants.map((participant) => (
              <li key={participant}>
                <Stack direction="row" sx={styles.participantRow}>
                  <Icon icon="greenCheckMark" />
                  <Typography variant="body2">{participant}</Typography>
                </Stack>
              </li>
            ))}
          </ul>
        </Stack>
      )}
    </Box>
  );
};

export default GroupOrderParticipantStatus;
