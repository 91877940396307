export type CommunicationPreferences = {
  emailReceipts: boolean;
  followUps: boolean;
  marketingSms: boolean;
  promotionalEmails: boolean;
  upSell: boolean;
};

export const isCommunicationPreferences = (object: unknown): object is CommunicationPreferences => {
  const prefs = object as CommunicationPreferences;
  return (
    typeof prefs.emailReceipts === "boolean" &&
    typeof prefs.followUps === "boolean" &&
    typeof prefs.marketingSms === "boolean" &&
    typeof prefs.promotionalEmails === "boolean" &&
    typeof prefs.upSell === "boolean"
  );
};

export const isPartialCommunicationPreferences = (
  object: unknown
): object is Partial<CommunicationPreferences> | undefined => {
  const prefs = object as Partial<CommunicationPreferences> | undefined;
  return (
    typeof prefs === "undefined" ||
    (Object.keys(prefs).every((key) =>
      ["emailReceipts", "followUps", "marketingSms", "promotionalEmails", "upSell"].includes(key)
    ) &&
      ["undefined", "boolean"].includes(typeof prefs.emailReceipts) &&
      ["undefined", "boolean"].includes(typeof prefs.followUps) &&
      ["undefined", "boolean"].includes(typeof prefs.marketingSms) &&
      ["undefined", "boolean"].includes(typeof prefs.promotionalEmails) &&
      ["undefined", "boolean"].includes(typeof prefs.upSell))
  );
};
