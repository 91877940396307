import { Box, FormHelperText, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { ForwardedRef, ReactNode } from "react";
import styles from "ui/components/OrderTimePicker/OrderTimePicker.styles";
import useViewModel, {
  OrderTimePickerOption,
  OrderTimePickerViewModelProps,
} from "ui/components/OrderTimePicker/OrderTimePickerViewModel";
import Select from "ui/components/Select";

export type OrderTimePickerProps = OrderTimePickerViewModelProps & {
  label: string;
  disabled?: boolean;
};

const OrderTimePicker = React.forwardRef(function OrderTimePicker(
  { disabled, label, ...viewModelProps }: OrderTimePickerProps,
  ref?: ForwardedRef<unknown>
) {
  const {
    dateOptions,
    error,
    onDateChange,
    onTimeChange,
    selectedDate,
    selectedTime,
    timeOptions,
    timeZoneHelperText,
  } = useViewModel(viewModelProps);

  return (
    <div>
      <Typography variant="formFieldLabelCaps" component="label" id="order-time-label">
        {label}
      </Typography>
      <Box sx={styles.dateTimeContainer}>
        <DateTimePicker
          disabled={disabled}
          onChange={onDateChange}
          options={dateOptions}
          pickerType="date"
          value={selectedDate}
        />
        <DateTimePicker
          disabled={disabled}
          errorText={timeZoneHelperText}
          onChange={onTimeChange}
          options={timeOptions}
          pickerType="time"
          ref={ref}
          value={selectedTime}
        />
      </Box>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
});

type DateTimePickerProps = {
  onChange: (event: SelectChangeEvent<unknown>) => void;
  options: OrderTimePickerOption[];
  pickerType: "date" | "time";
  disabled?: boolean;
  errorText?: string;
  value?: string;
};

const DateTimePicker = React.forwardRef(function DateTimePicker(
  { disabled, errorText, onChange, options, pickerType, value }: DateTimePickerProps,
  ref?: ForwardedRef<unknown>
) {
  return (
    <Box sx={styles.dateTimePicker}>
      <Select
        aria-label={`select an order ${pickerType}`}
        disabled={disabled}
        id={`order-${pickerType}`}
        labelId={`order-${pickerType}-label`}
        onChange={onChange}
        ref={ref}
        value={value}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Box>
  );
});

type ErrorTextProps = {
  children: ReactNode;
};

const ErrorText = ({ children }: ErrorTextProps) => (
  <Box sx={styles.errorBox}>
    <FormHelperText sx={styles.errorText}>{children}</FormHelperText>
  </Box>
);

export default OrderTimePicker;
