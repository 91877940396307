import { StoreDataTransformer } from "dataTransformers/StoreDataTransformer";
import { observer } from "mobx-react-lite";
import { createContext, ReactNode } from "react";
import RootStore from "stores/RootStore";
import { StoreStorable } from "stores/store/StoreStorable";
import StoreStore from "stores/store/StoreStore";

export const StoreContext = createContext<StoreStorable | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

const StoreProvider = observer(({ children }: StoreProviderProps) => {
  const store: StoreStorable = new StoreStore(new StoreDataTransformer(RootStore.networker, RootStore.tokenManager));

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
});

export default StoreProvider;
