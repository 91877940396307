import { TypeChecker } from "util/JavascriptUtility";

type CalorieInfo = {
  baseCalories?: number;
  caloriesSeparator?: string;
  maxCalories?: number;
};

export const isCalorieInfo = (object: unknown): object is CalorieInfo => {
  const info = object as CalorieInfo;
  return (
    !!info &&
    TypeChecker.isOptional(info.baseCalories, TypeChecker.isNumber) &&
    TypeChecker.isOptional(info.caloriesSeparator, TypeChecker.isString) &&
    TypeChecker.isOptional(info.maxCalories, TypeChecker.isNumber)
  );
};

export default CalorieInfo;
