import CreditCardInfoResponse, { isCreditCardInfoResponse } from "models/payment/CreditCardInfoResponse";
import GiftCardInfoResponse, { isGiftCardInfoResponse } from "models/payment/GiftCardInfoResponse";
import { PaymentType } from "models/payment/PaymentType";

/** A user's saved payment. This is different from the SavedPaymentInfoResponse that is part of the Order */
export type SavedPaymentId = string;

export type BaseSavedPayment = {
  id: SavedPaymentId;
  displayName: string;
  type: PaymentType;
};
export type CreditCardSavedPayment = BaseSavedPayment & CreditCardInfoResponse;
export type GiftCardSavedPayment = BaseSavedPayment & GiftCardInfoResponse;
export type SavedPayment = CreditCardSavedPayment | GiftCardSavedPayment;

export const isCreditCardSavedPayment = (object: unknown): object is CreditCardSavedPayment => {
  const payment = object as CreditCardSavedPayment;
  return isBaseSavedPayment(payment) && isCreditCardInfoResponse(payment);
};

export const isGiftCardSavedPayment = (object: unknown): object is GiftCardSavedPayment => {
  const payment = object as GiftCardSavedPayment;
  return isBaseSavedPayment(payment) && isGiftCardInfoResponse(payment);
};

export const isBaseSavedPayment = (object: unknown): object is BaseSavedPayment => {
  const payment = object as BaseSavedPayment;
  return !!payment && !!payment.id && !!payment.displayName && !!payment.type;
};

export const isBaseSavedPaymentArray = (object: unknown): object is BaseSavedPayment[] => {
  const payments = object as BaseSavedPayment[];
  return (
    !!payments && Array.isArray(payments) && payments.length === payments.filter((p) => isBaseSavedPayment(p)).length
  );
};

export const isSavedPayment = (object: unknown): object is SavedPayment => {
  return isCreditCardSavedPayment(object) || isGiftCardSavedPayment(object);
};

export const isSavedPaymentArray = (object: unknown): object is SavedPayment[] => {
  const payments = object as SavedPayment[];
  return (
    !!payments &&
    Array.isArray(payments) &&
    payments.length === payments.filter((p) => isCreditCardSavedPayment(p) || isGiftCardSavedPayment(p)).length
  );
};
