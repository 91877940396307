import { Box, Stack, Typography } from "@mui/material";
import { SplideSlide } from "@splidejs/react-splide";
import AchievementBadge from "models/rewards/AchievementBadge";
import styles from "ui/components/AchievementBadgeModal/AchievementBadgeModal.styles";
import BadgeProgress from "ui/components/AchievementBadgeModal/BadgeProgress";
import BadgeTerms from "ui/components/AchievementBadgeModal/BadgeTerms";

type BadgeProps = {
  badge: AchievementBadge;
};

const Badge = ({ badge }: BadgeProps) => {
  const { alertHeadline, description, imageUrl, name, progress, status, statusEndDescription, termsAndConditions } =
    badge;
  const isAchieved = status === "ACHIEVED" || status === "ACHIEVED_AND_SEEN";

  return (
    <Stack component={SplideSlide} sx={styles.container} aria-label={name}>
      <Stack sx={styles.header}>
        {alertHeadline && <Box sx={styles.alertHeadline}>{alertHeadline}</Box>}
        <Typography component="h2" variant="h3">
          {name}
        </Typography>
      </Stack>

      <Box component="img" src={imageUrl} alt="" role="presentation" sx={styles.image} data-testid="badge-image" />

      <Stack sx={styles.details}>
        <Typography sx={styles.description}>{description}</Typography>
        {!isAchieved && statusEndDescription && <Typography variant="h5">{statusEndDescription}</Typography>}
        {progress && <BadgeProgress isAchieved={isAchieved} progress={progress} />}
      </Stack>

      {!isAchieved && termsAndConditions && <BadgeTerms>{termsAndConditions}</BadgeTerms>}
    </Stack>
  );
};

export default Badge;
