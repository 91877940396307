import { Stack, Typography } from "@mui/material";
import { ReactComponent as StepCompleteIcon } from "assets/icons/step-complete.svg";
import { ReactComponent as StepIncompleteIcon } from "assets/icons/step-incomplete.svg";
import { AchievementBadgeProgress } from "models/rewards/AchievementBadge";
import styles from "ui/components/AchievementBadgeModal/AchievementBadgeModal.styles";

type BadgeProgressProps = {
  isAchieved: boolean;
  progress: AchievementBadgeProgress;
};

const BadgeProgress = ({ isAchieved, progress }: BadgeProgressProps) => {
  const { current, nestedProgressItems, rawDescription, threshold } = progress;

  const interpolateProgressDescriptionMarkup = (rawDescription: string, current: number, threshold?: number) => ({
    __html: rawDescription.replace("[progress]", `<em>${current}</em>`).replace("[threshold]", `<em>${threshold}</em>`),
  });

  const progressDescriptionMarkup = rawDescription
    ? interpolateProgressDescriptionMarkup(rawDescription, current, threshold)
    : null;

  return (
    <>
      {progressDescriptionMarkup && (
        <Typography variant="h4" sx={styles.progressDescription} data-testid="progress-description">
          <div dangerouslySetInnerHTML={progressDescriptionMarkup} />
        </Typography>
      )}
      {!isAchieved && nestedProgressItems && (
        <Stack component="ul" sx={styles.progressItems}>
          {nestedProgressItems?.map(({ current, name, threshold }) => {
            const isStepCompleted = current === threshold;

            return (
              <Stack
                className={isStepCompleted ? "completed" : undefined}
                component="li"
                direction="row"
                key={name}
                sx={styles.progressItem}
              >
                {isStepCompleted ? <StepCompleteIcon /> : <StepIncompleteIcon />}
                <Typography
                  variant={isStepCompleted ? "body1Bold" : "body1"}
                  sx={styles.progressItemText}
                >{`${name} (${current}/${threshold})`}</Typography>
              </Stack>
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default BadgeProgress;
