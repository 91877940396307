import MuiStyles from "ui/util/Styles";

const styles = {
  multiSelectformControlLabel: {
    position: "relative",

    ".MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  multiselect: (theme) => {
    const borderSize = 0.0625;

    return {
      minHeight: theme.typography.pxToRem(78),
      border: `${borderSize}rem solid ${theme.palette.grey[200]}`,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "1rem",
      paddingRight: "1rem",

      "&.MuiFormControlLabel-root": {
        marginTop: 0,
      },

      [theme.breakpoints.up("lg")]: {
        // 2 checkboxes, 1 gaps, one half of space minus one half of the sum of the gaps
        flexBasis: "calc(100% / 2 - 1rem / 2)",
        flexGrow: 0,
      },
      "&.selected": () => {
        const selectedBorderSize = 0.125;
        const borderOffset = selectedBorderSize - borderSize;

        return {
          border: `${selectedBorderSize}rem solid ${theme.palette.secondary.main}`,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: `calc(1rem - ${borderOffset}rem)`,
          paddingRight: `calc(1rem - ${borderOffset}rem)`,

          "&:active, &.Mui-active": {
            border: `${selectedBorderSize}rem solid ${theme.palette.secondary.main}`,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: `calc(1rem - ${borderOffset}rem)`,
            paddingRight: `calc(1rem - ${borderOffset}rem)`,
          },

          "&.Mui-disabled": {
            background: theme.palette.common.white,
            border: `${selectedBorderSize}rem solid ${theme.palette.secondary.main}`,
            boxShadow: "none",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: `calc(1rem - ${borderOffset}rem)`,
            paddingRight: `calc(1rem - ${borderOffset}rem)`,
          },
        };
      },
    };
  },
  inner: {
    display: "flex",
    gap: "0.75rem",
    paddingRight: "5.125rem",
  },
  image: {
    width: "4rem",
    height: "4rem",
    marginTop: "0.375rem",
    marginBottom: "0.375rem",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    "&:not(.image-fill) img": {
      objectFit: "fill",
    },
  },
  divider: (theme) => ({
    height: "calc(100% - 2rem)",
    width: "1px",
    background: theme.palette.grey[200],
  }),
  select: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "5.125rem",

    "> .MuiInputBase-root": {
      height: "100%",
      width: "100%",
    },

    "& .MuiSelect-select": (theme) => ({
      ...theme.typography.bodySmall,
      border: 0,
      color: "secondary.main",
      textTransform: "uppercase",
      borderRadius: 0,
      height: "100% !important",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",

      "&:hover, &[aria-expanded='true']": {
        backgroundColor: theme.palette.grey[200],
      },
    }),
    "& .MuiOutlinedInput-notchedOutline": { border: 0 },
    "& .Mui-focused": {
      borderRadius: 0,
      outline: `0.25rem solid rgba(228, 0, 43, 0.3)`, // light tomato
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "0 !important",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 0.25rem)",
      right: "0.875rem",
      width: "0.75rem",
      height: "0.5rem",
    },
  },
  menu: (theme) => ({
    "& .MuiMenu-paper": {
      borderRadius: 0,
      boxShadow: "0px 4px 8px rgba(31, 41, 55, 0.25)",
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiMenu-paper": {
        transform: "translateX(-50%) !important",
      },
    },
  }),
  menuList: {
    color: "secondary.main",

    "& .MuiMenuItem-root:hover, & .MuiMenuItem-root.Mui-selected": (theme) => ({
      backgroundColor: theme.palette.grey[100],
    }),
    "& .MuiMenuItem-root.Mui-focusVisible": (theme) => ({
      backgroundColor: theme.palette.grey[200],
    }),
    "& .MuiMenuItem-root.Mui-selected.Mui-focusVisible, & .MuiMenuItem-root.Mui-selected.Mui-focusVisible:hover": (
      theme
    ) => ({
      backgroundColor: theme.palette.grey[200],
    }),
    "& .MuiMenuItem-root": {
      color: "secondary.main",
    },
  },
  labelText: {
    fontWeight: 370,
  },
  labelMetaData: (theme) => ({
    color: theme.palette.grey[500],
  }),
} satisfies MuiStyles;

export default styles;
