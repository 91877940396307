import { LoadingButton } from "@mui/lab";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import GroupOrderParticipantStatus from "ui/components/GroupOrderParticipantStatus/GroupOrderParticipantStatus";
import Modal from "ui/components/Modal";
import styles from "ui/components/ParticipantStatusModal/ParticipantStatusModal.styles";
import useViewModel, {
  ParticipantStatusModalViewModelProps as ViewModelProps,
} from "ui/components/ParticipantStatusModal/ParticipantStatusViewModel";

export type ParticipantStatusModalProps = ViewModelProps & {
  open: boolean;
  isSubmittingOrder: boolean;
};

const ParticipantStatusModal = observer(
  ({ open, isSubmittingOrder, onClose, sortedGroupOrderParticipants }: ParticipantStatusModalProps) => {
    const {
      closeModal,
      isLoadingParticipants,
      onClickCheckout,
      orderNotSubmittedParticipants,
      orderSubmittedParticipants,
      title,
    } = useViewModel({
      onClose,
      sortedGroupOrderParticipants,
    });

    const cancelButton = (
      <Button variant="text" onClick={closeModal} key="cancel">
        Cancel
      </Button>
    );

    const checkoutButton = (
      <LoadingButton onClick={onClickCheckout} key="checkout">
        Checkout
      </LoadingButton>
    );

    const actions = isSubmittingOrder ? [cancelButton, checkoutButton] : null;

    return (
      <Modal actions={actions} onClose={onClose} open={open} title={title} sx={styles.modal}>
        <Stack sx={styles.modalContent}>
          {isSubmittingOrder && orderNotSubmittedParticipants && orderNotSubmittedParticipants?.length > 0 && (
            <div>
              <Typography>
                Some folks haven&rsquo;t submitted their order yet. Do you want to submit the Group Order anyway?
              </Typography>
            </div>
          )}
          <div>
            {isLoadingParticipants ? (
              <LoadingSkeletonText />
            ) : (
              <>
                {orderNotSubmittedParticipants && orderSubmittedParticipants && (
                  <GroupOrderParticipantStatus
                    orderNotSubmittedParticipants={orderNotSubmittedParticipants}
                    orderSubmittedParticipants={orderSubmittedParticipants}
                  />
                )}
              </>
            )}
          </div>
        </Stack>
      </Modal>
    );
  }
);

const LoadingSkeletonText = () => (
  <>
    <Skeleton height="2.5rem" />
    <Skeleton height="1.5rem" />
    <Skeleton height="1.5rem" />
    <Skeleton height="2.5rem" />
    <Skeleton height="1.5rem" />
    <Skeleton height="1.5rem" />
  </>
);

export default ParticipantStatusModal;
