import { MouseEventHandler } from "react";
import { IconType } from "ui/commerceUI/foundations/icons/Icon";
import { MainPagePath } from "ui/navigation/Pages";

export enum WindowType {
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export type Link = {
  url: string;
  label: string;
  type?: WindowType;
  icon?: IconType;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type LinkColumn = {
  title: string;
  links: Link[];
};

export type LinkColumns = LinkColumn[];

const ourMenuColumn: LinkColumn = {
  title: "Our Menu",
  links: [
    {
      url: "/documents/print-menu",
      label: "Print Menu (PDF)",
      type: WindowType.External,
    },
    {
      url: "/documents/print-catering-menu",
      label: "Print Catering Menu (PDF)",
      type: WindowType.External,
    },
    {
      url: "/documents/allergen-info",
      label: "Allergen Info (PDF)",
      type: WindowType.External,
    },
    {
      url: "/documents/nutrition-info",
      label: "Nutrition Guide (PDF)",
      type: WindowType.External,
    },
  ],
};

const aboutUsColumn: LinkColumn = {
  title: "About Us",
  links: [
    {
      url: MainPagePath.faq,
      label: "FAQs",
    },
    {
      url: "https://www.jimmyjohnsfranchising.com/",
      label: "Franchising",
      type: WindowType.External,
    },
    {
      url: "https://careers.jimmyjohns.com/",
      label: "Careers",
      type: WindowType.External,
    },
    {
      url: MainPagePath.foundation,
      label: "Foundation",
    },
    {
      url: MainPagePath.feedback,
      label: "Feedback",
    },
  ],
};
const moreJJsColumn: LinkColumn = {
  title: "More JJ's",
  links: [
    {
      url: MainPagePath.promos,
      label: "Promos",
    },
    {
      url: MainPagePath.giftCards,
      label: "Gift Cards",
    },
    {
      url: "https://store.jimmyjohns.com/",
      label: "JJ Swag",
      type: WindowType.External,
    },
  ],
};

const LinkColumns: LinkColumns = [ourMenuColumn, aboutUsColumn, moreJJsColumn];

export default LinkColumns;
