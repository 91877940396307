import _ from "lodash";
import MetadataKey from "models/MetadataKey";
import { ModifierOptionId } from "models/modifiers/ModifierOption";
import { isModifierType, ModifierType } from "models/modifiers/ModifierType";
import { TypeChecker } from "util/JavascriptUtility";

export type ModifierId = string;

/**
 * Basics of all modifiers
 */
export type BaseModifier = {
  id: ModifierId;
  required: boolean;
  type: ModifierType;
  text: string;
  subtext?: string;
  description?: string;
  image?: string;
  defaultOptions?: ModifierOptionId[];
  metadata?: { key: MetadataKey; value: string }[];
};

const isDefaultOptionsArray = (object: unknown): object is ModifierOptionId[] => {
  if (_.isUndefined(object)) return true;
  const modifierOptionIds = object as ModifierOptionId[];
  return (
    Array.isArray(modifierOptionIds) &&
    modifierOptionIds.filter((s) => _.isString(s)).length === modifierOptionIds.length
  );
};

export const isBaseModifier = (object: unknown): object is BaseModifier => {
  const modifier = object as BaseModifier;
  return (
    !!modifier &&
    TypeChecker.isString(modifier.id) &&
    TypeChecker.isBoolean(modifier.required) &&
    isModifierType(modifier.type) &&
    TypeChecker.isString(modifier.text) &&
    TypeChecker.isOptional(modifier.subtext, TypeChecker.isString) &&
    TypeChecker.isOptional(modifier.description, TypeChecker.isString) &&
    TypeChecker.isOptional(modifier.image, TypeChecker.isString) &&
    isDefaultOptionsArray(modifier.defaultOptions)
  );
};
