import _ from "lodash";
import AchievementBadge, { isAchievementBadgeArray } from "models/rewards/AchievementBadge";

export type RewardResponse = {
  id: string;
  membershipNumber: string;
  description: string;
  balance: BalanceObject;
  rewards: RewardItem[];
  badges?: AchievementBadge[];
};

export type BalanceObject = {
  label: string;
  quantity: number;
  rewardThreshold: number;
  unit: string;
};

export type RewardItem = {
  applied: boolean;
  availableOffline: boolean;
  categories?: number[];
  description: string;
  expirationDate: string;
  externalReference?: string;
  label: string;
  link?: string;
  imageUrl: string;
  membershipId: number;
  quantityApplied: number;
  quantityAvailable: number;
  rewardId?: number;
  reference: string;
};

export const isRewardResponse = (object: unknown): object is RewardResponse => {
  const reward = object as RewardResponse;
  return (
    !!reward &&
    !!reward.id &&
    !!reward.membershipNumber &&
    !!reward.description &&
    isRewards(reward.rewards) &&
    isRewardBalance(reward.balance) &&
    (reward.badges === undefined || isAchievementBadgeArray(reward.badges))
  );
};

const isRewardBalance = (object: unknown): object is BalanceObject => {
  const balance = object as BalanceObject;
  return (
    !!balance &&
    !!balance.label &&
    _.isNumber(balance.quantity) &&
    _.isNumber(balance.rewardThreshold) &&
    !!balance.unit
  );
};

const isRewards = (object: unknown): object is RewardResponse => {
  const rewards = object as RewardItem[];
  const filter = rewards.filter((r) => isRewardsItem(r)).length;
  return Array.isArray(rewards) && rewards.length === filter;
};

export const isRewardsItem = (object: unknown): object is RewardItem => {
  const reward = object as RewardItem;
  return (
    !!reward &&
    _.isBoolean(reward.applied) &&
    _.isBoolean(reward.availableOffline) &&
    !!reward.expirationDate &&
    !!reward.label &&
    _.isNumber(reward.membershipId) &&
    _.isNumber(reward.quantityApplied) &&
    _.isNumber(reward.quantityAvailable) &&
    !!reward.reference
  );
};

export default RewardResponse;
