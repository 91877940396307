import { BaseModifier } from "models/modifiers/BaseModifier";
import { ModifierOption } from "models/modifiers/ModifierOption";

export type OptionModifier = BaseModifier & {
  options: ModifierOption[];
};

export const isOptionModifier = (object: unknown): object is OptionModifier => {
  const modifier = object as OptionModifier;
  return Array.isArray(modifier.options);
};
