import { isProductItem, ProductItem } from "models/ProductItem";

export type Products = {
  id: string;
  products: ProductItem[];
};

// This matches on the required products properties defined by the octocart-backend
// https://github.com/detroit-labs/octocart-backend/blob/master/docs/open-api/models/products.yaml
export const isProducts = (object: unknown): object is Products => {
  const products = object as Products;
  return (
    products != null &&
    products.id != null &&
    products.products != null &&
    products.products.filter((productItem) => isProductItem(productItem)).length === products.products.length
  );
};
