import { PaymentType } from "models/payment/PaymentType";

/**
 * Information returned in the payment of an Order object + also the response
 * in the user's saved payments if the payment is a gift card
 */
type GiftCardInfoResponse = {
  type: PaymentType.giftCard;
  details: {
    lastFour: string;
    balance?: string;
  };
};

export const isGiftCardInfoResponse = (object: unknown): object is GiftCardInfoResponse => {
  const giftCard = object as GiftCardInfoResponse;
  return !!giftCard && !!giftCard.type && giftCard.type === PaymentType.giftCard;
};

export default GiftCardInfoResponse;
