import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import styles from "ui/app/MenuLayout/MenuLayout.styles";

const MenuLayout = () => {
  return (
    <>
      <Stack sx={styles.container}>
        <Outlet />
      </Stack>
    </>
  );
};

export default MenuLayout;
