import { Button, Typography } from "@mui/material";
import useAnalytics from "hooks/useAnalytics";
import { observer } from "mobx-react-lite";
import useConfirmationModal from "ui/app/AccountLayout/ConfirmationModal/useConfirmationModal";
import Modal from "ui/components/Modal";

const ConfirmationModal = observer(() => {
  const { analytics } = useAnalytics();
  const {
    confirmationModalState: { confirmButtonLabel, message, onClose, onConfirm, open, title },
  } = useConfirmationModal();

  const cancelButton = (
    <Button
      variant="text"
      onClick={() => {
        onClose();
        analytics.modalCTA("Cancel", title);
      }}
      key="cancel"
    >
      Cancel
    </Button>
  );

  const confirmButton = (
    <Button onClick={onConfirm} key="confirm">
      {confirmButtonLabel}
    </Button>
  );

  return (
    <Modal actions={[cancelButton, confirmButton]} onClose={onClose} open={open} title={title}>
      <Typography sx={{ marginBottom: "3rem" }}>{message}</Typography>
    </Modal>
  );
});

export default ConfirmationModal;
