import { Box, Divider, Drawer, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import Branding from "assets/Branding.svg";
import useAnalytics from "hooks/useAnalytics";
import useRedirectToPreviousLocation from "hooks/useRedirectToPreviousLocation";
import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "stores/user";
import styles from "ui/app/GlobalNav/NavDrawer/NavDrawer.styles";
import GlobalNavLink from "ui/components/GlobalNavLink";
import Icon from "ui/components/Icon/Icon";
import { AccountPagePath, MainPagePath } from "ui/navigation/Pages";
import Colors from "ui/theme/Colors";

export type NavDrawerProps = {
  hideDrawer: () => void;
  shouldShowDrawer: boolean;
};

const NavDrawer = ({ hideDrawer, shouldShowDrawer }: NavDrawerProps) => {
  const navigate = useNavigate();

  const userStore = useUser();
  const { isLoggedIn, user } = userStore;

  const handleLogOut = async () => {
    await userStore.logout();
    navigate(MainPagePath.home);
  };

  const { analytics } = useAnalytics();
  const onClickNavigation = (linkText: string) => analytics.linkClick(linkText, "Global Header");

  return (
    <Drawer
      open={shouldShowDrawer}
      hideBackdrop={true}
      elevation={0}
      sx={styles.drawer}
      role="navigation"
      aria-label="Main"
    >
      <Box>
        <IconButton
          onClick={hideDrawer}
          size="large"
          sx={styles.drawerCloseButton}
          aria-label="Close the navigation menu"
        >
          <Icon icon="close" />
        </IconButton>
      </Box>
      <Box>
        <List disablePadding onClick={hideDrawer} sx={styles.primaryNav} className={!isLoggedIn ? "logged-out" : ""}>
          <ListItem disablePadding>
            <Icon icon="star" />
            <GlobalNavLink to={MainPagePath.menu} selectOnMatch onClick={() => onClickNavigation("Menu")}>
              <Typography variant="globalNav" sx={styles.menuItem}>
                Menu
              </Typography>
            </GlobalNavLink>
          </ListItem>
          <ListItem disablePadding>
            <Icon icon="star" />
            <GlobalNavLink to={MainPagePath.catering} selectOnMatch onClick={() => onClickNavigation("Catering")}>
              <Typography variant="globalNav" sx={styles.menuItem}>
                Catering
              </Typography>
            </GlobalNavLink>
          </ListItem>
          <ListItem disablePadding>
            <Icon icon="star" />
            <GlobalNavLink to={MainPagePath.locations} selectOnMatch onClick={() => onClickNavigation("Locations")}>
              <Typography variant="globalNav" sx={styles.menuItem}>
                Locations
              </Typography>
            </GlobalNavLink>
          </ListItem>
          <ListItem disablePadding>
            <Icon icon="star" />
            <GlobalNavLink to={MainPagePath.rewards} selectOnMatch onClick={() => onClickNavigation("Rewards")}>
              <Typography variant="globalNav" sx={styles.menuItem}>
                Rewards
              </Typography>
            </GlobalNavLink>
          </ListItem>
        </List>
        {userStore.isLoggedIn ? (
          <LoggedInNavUser firstName={user?.firstName} onClick={hideDrawer} onLogOut={handleLogOut} />
        ) : (
          <LoggedOutNavUser onClick={hideDrawer} />
        )}
      </Box>
      <Box component="img" alt={"jimmy johns logo"} sx={styles.logo} src={Branding} aria-hidden="true" />
    </Drawer>
  );
};

type LoggedInNavUserProps = {
  firstName?: string;
  onClick: MouseEventHandler;
  onLogOut: () => void;
};

const LoggedInNavUser = ({ firstName, onClick, onLogOut }: LoggedInNavUserProps) => (
  <Box sx={styles.loggedInNavUser}>
    <Divider sx={styles.divider} aria-hidden="true" />
    <List onClick={onClick} disablePadding>
      <ListItem disablePadding>
        {firstName ? (
          <Typography variant="body1Bold" sx={styles.greeting}>
            Hi there, {firstName}
          </Typography>
        ) : null}
      </ListItem>
      <ListItem disablePadding sx={styles.greetingItem}>
        <GlobalNavLink to={AccountPagePath.profile} selectOnMatch>
          <Typography variant="body2" sx={styles.text}>
            Profile
          </Typography>
        </GlobalNavLink>
      </ListItem>
      <ListItem disablePadding sx={styles.greetingItem}>
        <GlobalNavLink to={AccountPagePath.recentOrders} selectOnMatch>
          <Typography variant="body2" sx={styles.text}>
            Orders
          </Typography>
        </GlobalNavLink>
      </ListItem>
      <ListItem disablePadding>
        <GlobalNavLink onClick={onLogOut}>
          <Typography variant="body2" sx={styles.text}>
            Log Out
          </Typography>
        </GlobalNavLink>
      </ListItem>
    </List>
  </Box>
);

type LoggedOutNavUserProps = {
  onClick: MouseEventHandler;
};

const LoggedOutNavUser = ({ onClick }: LoggedOutNavUserProps) => {
  const { redirectState } = useRedirectToPreviousLocation();
  const { analytics } = useAnalytics();

  const signInAnalytics = () => analytics.signIn("Log In CTA");
  const accountCreationAnalytics = () => analytics.createAccount("Begin Creation");

  return (
    <Stack direction="row" onClick={onClick} sx={styles.loggedOutNavUser}>
      <GlobalNavLink
        onClick={signInAnalytics}
        to={AccountPagePath.signIn}
        Icon={<Icon icon="account" />}
        state={redirectState}
        selectOnMatch
      >
        <Typography variant="globalNav" color={Colors.brightWhite}>
          Log In
        </Typography>
      </GlobalNavLink>
      <Typography sx={styles.cursive}>or</Typography>
      <GlobalNavLink onClick={accountCreationAnalytics} to={AccountPagePath.createAccount} selectOnMatch>
        <Typography variant="globalNav" color={Colors.brightWhite}>
          Create Account
        </Typography>
      </GlobalNavLink>
    </Stack>
  );
};

export default NavDrawer;
