import useGroupOrderRecipient from "hooks/useGroupOrderRecipient";
import { isGroupOrder } from "models/groupOrder/GroupOrder";
import { OrderUpsellGroupTitle, OrderUpsellResponse } from "models/order/OrderUpsell";
import { useEffect, useState } from "react";
import { useOrder } from "stores/order";

const useOrderUpsell = () => {
  const [orderUpsellResponse, setOrderUpsellResponse] = useState<OrderUpsellResponse>();

  const { addToOrder, getOrderUpsell, order } = useOrder();
  const { getGroupOrderRecipientName } = useGroupOrderRecipient();

  const smartUpsellItems = orderUpsellResponse?.groups.find(
    ({ title }) => title === OrderUpsellGroupTitle.Smart
  )?.items;

  const staticUpsellItems = orderUpsellResponse?.groups.find(
    ({ title }) => title === OrderUpsellGroupTitle.Static
  )?.items;

  const addUpsellToOrder = async (itemId: string, quantity: number) => {
    let recipient: string | undefined;

    if (isGroupOrder(order)) {
      recipient = getGroupOrderRecipientName();
    }

    await addToOrder({ itemId, isUpsell: true, quantity, recipient });
  };

  useEffect(() => {
    (async () => {
      if (!order?.items.length) return;

      try {
        const response = await getOrderUpsell();
        setOrderUpsellResponse(response);
      } catch {
        setOrderUpsellResponse(undefined);
      }
    })();

    // order?.items must be in the deps array even though it is unused in the effect
    // so that we reload upsells when the order.items change
  }, [order?.items, getOrderUpsell]);

  return {
    addUpsellToOrder,
    order,
    smartUpsellItems,
    staticUpsellItems,
  };
};

export default useOrderUpsell;
