import AchievementBadge from "models/rewards/AchievementBadge";
import LocalStorage from "util/LocalStorage";

/**
 * Helpers for managing local "seen" state of achievement badges;
 * protects against network issues causing infinite modal display loops
 **/

const locallySeenAvailableBadgeStorage = new LocalStorage<string[]>("seen_available_badges");
const locallySeenAchievedBadgeStorage = new LocalStorage<string[]>("seen_achieved_badges");

export const setBadgesAsLocallySeen = (badges: AchievementBadge[]) => {
  // Set "ACHIEVED" badges as locally seen
  const newLocallySeenAchievedBadgeIds = (() => {
    const locallySeenAchievedBadgeIds = locallySeenAchievedBadgeStorage.get() ?? [];

    return badges
      .filter(({ status }) => status === "ACHIEVED")
      .filter(({ id }) => !locallySeenAchievedBadgeIds.includes(id))
      .map(({ id }) => id)
      .concat(locallySeenAchievedBadgeIds);
  })();

  locallySeenAchievedBadgeStorage.set(newLocallySeenAchievedBadgeIds);

  // Set "AVAILABLE" badges as locally seen
  const newLocallySeenAvailableBadgeIds = (() => {
    const locallySeenAvailableBadgeIds = locallySeenAvailableBadgeStorage.get() ?? [];

    return badges
      .filter(({ status }) => status === "AVAILABLE")
      .filter(({ id }) => !locallySeenAvailableBadgeIds.includes(id))
      .map(({ id }) => id)
      .concat(locallySeenAvailableBadgeIds);
  })();

  locallySeenAvailableBadgeStorage.set(newLocallySeenAvailableBadgeIds);
};

export const getLocallySeenAvailableBadges = () => locallySeenAvailableBadgeStorage.get() ?? [];
export const getLocallySeenAchievedBadges = () => locallySeenAchievedBadgeStorage.get() ?? [];
