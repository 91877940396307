import { Stack, Typography } from "@mui/material";
import { OrderType } from "models/order/OrderType";
import OrderAheadDateTimes from "models/store/OrderAheadDateTimes";
import { Store } from "models/store/Store";
import { Control, Controller } from "react-hook-form";
import OrderTimePicker from "ui/components/OrderTimePicker";
import PickupTypeSelector from "ui/components/PickupTypeSelector";
import { StartGroupOrderSchema } from "ui/components/StartGroupOrderModal/StartGroupOrderModal.data";
import styles from "ui/components/StartGroupOrderModal/StartGroupOrderModal.styles";

type WhenDoYouWantItProps = {
  control: Control<StartGroupOrderSchema>;
  isPickup: boolean;
  orderAheadDateTimes: OrderAheadDateTimes[];
  shouldDisableOrderTimePicker: boolean;
  timeWanted: string;
  orderType?: OrderType;
  store?: Store;
};

const WhenDoYouWantIt = ({
  control,
  isPickup,
  orderAheadDateTimes,
  orderType,
  shouldDisableOrderTimePicker,
  store,
  timeWanted,
}: WhenDoYouWantItProps) => {
  if (!orderType || !store) return null;

  const orderTypeLabel = isPickup ? "Pickup Time" : "Delivery Time";

  return (
    <Stack gap={2}>
      <Typography variant="h4" component="h2" sx={styles.sectionHeading}>
        When Do You Want It?
      </Typography>

      {isPickup && (
        <>
          <Typography variant="formFieldLabelCaps">Select Pickup Method</Typography>
          <Controller
            control={control}
            name="orderType"
            render={({ field }) => (
              <PickupTypeSelector {...field} id="pickup-type" store={store} timeWanted={timeWanted} />
            )}
          />
        </>
      )}

      <div>
        <Controller
          control={control}
          name="timeWanted"
          render={({ field }) => (
            <OrderTimePicker
              {...field}
              disabled={shouldDisableOrderTimePicker}
              label={orderTypeLabel}
              orderAheadDateTimes={orderAheadDateTimes}
              orderType={orderType}
              storeTimeZoneOffset={store.timeZoneOffset}
            />
          )}
        />
        <Typography sx={styles.orderTimeInfoText} variant="body2">
          For a freaky fast experience, we kindly ask that all invitees submit their orders{" "}
          <Typography component="span" variant="body2Bold">
            30 minutes
          </Typography>{" "}
          prior to scheduled {orderTypeLabel}.
        </Typography>
      </div>
    </Stack>
  );
};

export default WhenDoYouWantIt;
