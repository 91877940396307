import { useContext } from "react";
import { GlobalModalContext } from "stores/globalModal/GlobalModalProvider";

const useGlobalModal = () => {
  const context = useContext(GlobalModalContext);
  if (!context) {
    throw new Error("useGlobalModal must be used within a GlobalModalProvider.");
  }
  return context;
};

export default useGlobalModal;
