import { Box, Fade, Popover, PopoverProps, useMediaQuery, useTheme } from "@mui/material";
import styles from "ui/components/NavPopover/NavPopover.styles";

interface CustomSlotProps {
  backdrop?: {
    invisible?: boolean;
    sx?: React.CSSProperties;
  };
}

export type NavPopoverProps = PopoverProps & {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  slotProps?: CustomSlotProps;
};

const NavPopover = (props: NavPopoverProps) => {
  const theme = useTheme();
  const isSmallOrGreater = useMediaQuery(theme.breakpoints.up("sm"));

  return isSmallOrGreater ? <NavPopoverDesktop {...props} /> : <NavPopoverMobile {...props} />;
};

const NavPopoverDesktop = ({ anchorEl, open, onClose, children, slotProps }: NavPopoverProps) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      elevation={0}
      PaperProps={{ square: true, sx: styles.desktopPaper }}
      TransitionComponent={Fade}
      {...slotProps}
    >
      <Box sx={styles.desktopArrow}></Box>
      <Box>{children}</Box>
    </Popover>
  );
};

const NavPopoverMobile = ({ anchorEl, open, onClose, children, slotProps }: NavPopoverProps) => {
  if (!anchorEl) {
    return null;
  }

  const backdropOffset = anchorEl.offsetTop + anchorEl.offsetHeight;

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      elevation={0}
      marginThreshold={0}
      slotProps={{
        backdrop: {
          invisible: false,
          sx: {
            background: `linear-gradient(180deg, transparent ${backdropOffset}px, rgba(0, 0, 0, 0.35) ${backdropOffset}px)`,
          },
        },
        ...slotProps,
      }}
      PaperProps={{ square: true, sx: styles.mobilePaper }}
      TransitionComponent={Fade}
    >
      <Box>{children}</Box>
    </Popover>
  );
};

export default NavPopover;
