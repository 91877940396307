import { lazy } from "react";
import { Route } from "react-router-dom";
import { MainPagePath } from "ui/navigation/Pages";

const AccessibilityScreen = lazy(() => import("ui/screens/policies/AccessibilityScreen"));
const CaliforniaAct = lazy(() => import("ui/screens/policies/CaliforniaAct"));
const CookiePolicyScreen = lazy(() => import("ui/screens/policies/CookiePolicyScreen"));
const FAQScreen = lazy(() => import("ui/screens/FAQScreen"));
const FeedbackScreen = lazy(() => import("ui/screens/FeedbackScreen"));
const FoundationScreen = lazy(() => import("ui/screens/FoundationScreen"));
const GiftCardScreen = lazy(() => import("ui/screens/GiftCardScreen"));
const PromoScreen = lazy(() => import("ui/screens/PromoScreen"));
const PrivacyPolicyScreen = lazy(() => import("ui/screens/policies/PrivacyPolicyScreen"));
const RewardsScreen = lazy(() => import("ui/screens/RewardsScreen"));
const TermsAndConditionsScreen = lazy(() => import("ui/screens/policies/TermsAndConditionsScreen"));

const InfoRoutes = (
  <>
    <Route path={MainPagePath.accessibility} element={<AccessibilityScreen />} />
    <Route path={MainPagePath.californiaSupplyChain} element={<CaliforniaAct />} />
    <Route path={MainPagePath.adsCookiePolicy} element={<CookiePolicyScreen />} />
    <Route path={MainPagePath.faq} element={<FAQScreen />} />
    <Route path={MainPagePath.feedback} element={<FeedbackScreen />} />
    <Route path={MainPagePath.foundation} element={<FoundationScreen />} />
    <Route path={MainPagePath.giftCards} element={<GiftCardScreen />} />
    <Route path={MainPagePath.promos} element={<PromoScreen />} />
    <Route path={MainPagePath.privacyPolicy} element={<PrivacyPolicyScreen />} />
    <Route path={MainPagePath.rewards} element={<RewardsScreen />} />
    <Route path={MainPagePath.termsAndConditions} element={<TermsAndConditionsScreen />} />
  </>
);

export default InfoRoutes;
