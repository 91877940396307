export interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

/**
 * Save, retrieve, and clear a serializable value/object with localStorage
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */
export default class LocalStorage<T> {
  private readonly storage: IStorage;
  private readonly key: string;

  public constructor(key: string, getStorage = (): IStorage => window.localStorage) {
    this.key = key;
    this.storage = getStorage();
  }

  public get() {
    try {
      const item = this.storage.getItem(this.key);
      return item ? (parseJSON(item) as T) : undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key "${this.key}":`, error);
      return undefined;
    }
  }

  public set(value: T): void {
    try {
      this.storage.setItem(this.key, JSON.stringify(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error setting localStorage key "${this.key}":`, error);
    }
  }

  public clear(): void {
    this.storage.removeItem(this.key);
  }
}

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    return undefined;
  }
}
