import { UnknownError } from "errors/Errors";
import { reaction } from "mobx";
import { isHostPayGroupOrder } from "models/groupOrder/GroupOrder";
import { Order } from "models/order/Order";
import { Store } from "models/store/Store";
import { AlertStorable } from "stores/alert/AlertStorable";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import { ProductStorable } from "stores/menu/ProductStorable";
import OctocartStoreManageable from "stores/OctocartStoreManageable";
import { OrderStorable } from "stores/order/OrderStorable";
import { StoreStorable } from "stores/store/StoreStorable";
import { UserStorable } from "stores/user/UserStorable";
import GoogleAnalytics from "util/GoogleAnalytics";
import { errorHandler } from "util/Logger";

/**
 * Handles startup calls on the stores
 * Handles interactions between stores
 */
export class StoreManager implements OctocartStoreManageable {
  private readonly alertStore: AlertStorable;
  private readonly storeStore: StoreStorable;
  private readonly orderStore: OrderStorable;
  private readonly productStore: ProductStorable;
  private readonly userStore: UserStorable;

  constructor(
    alertStore: AlertStorable,
    orderStore: OrderStorable,
    storeStore: StoreStorable,
    productStore: ProductStorable,
    userStore: UserStorable
  ) {
    this.alertStore = alertStore;
    this.storeStore = storeStore;
    this.orderStore = orderStore;
    this.productStore = productStore;
    this.userStore = userStore;
  }

  /**
   * Should be run on app start
   *
   *  Loads the current order, and current user, and does not resolve until those 3 calls are finished
   *  Also loads the user's saved store, but those do not block the promise
   *  Sets up observables on the order (to refresh products and filters when an order starts/ends),
   *  Sets up an observable for refreshing the order when authentication changes
   *  Sets up an observable for refreshing user rewards when an order has been placed
   */
  async start() {
    this.handleUnlockGroupOrder();
    reaction(
      () => this.orderStore.orderConfirmation,
      () => this.handleOrderFinished()
    );

    // reaction(
    //   () => this.orderStore.orderState.error,
    //   () => {}
    //   (error) => this.processOrderError(error) // see processOrderError comments below.
    // );

    // when the order changes, update saved payments
    reaction(
      () => this.orderStore.order,
      () => {
        this.updateSavedPayments();
      }
    );

    // when the selected store for the order changes, update the selected store in the product store
    reaction(
      () => this.orderStore.order?.store,
      (newValue, oldValue) => {
        this.updateStore(oldValue, newValue);
      }
    );

    // set the user ID (if logged-in) for remote error-reporting
    reaction(
      () => this.userStore.user,
      (user) => {
        errorHandler.setUser(user?.id);
      }
    );

    // set the user ID/state for the analytics session
    const analytics = new GoogleAnalytics();
    analytics.setUser(this.userStore.user?.id);
    reaction(
      () => this.userStore.user,
      (user) => {
        analytics.setUser(user?.id);
      }
    );

    const getInitialOrderPromise = this.orderStore.getOrder().finally(() => {
      if (!this.productStore.products && this.productStore.productState.status !== LoadableObservableStatus.Loading) {
        return this.productStore.refreshProductsAndFiltersForStore();
      }
    });

    const getInitialUserPromise = this.userStore.refreshUser().then(() => {
      reaction(
        () => this.userStore.isLoggedIn,
        (isLoggedIn, wasLoggedIn) => {
          return this.handleAuthenticationChange(wasLoggedIn, isLoggedIn);
        }
      );
    });

    await Promise.allSettled([getInitialUserPromise, getInitialOrderPromise]);
  }

  private async handleUnlockGroupOrder() {
    const currentPath = () => window.location.pathname;

    reaction(
      () => [this.orderStore.order, this.userStore.user],
      ([order, user]) => {
        if (order && user && currentPath() !== "/checkout") {
          const isHostAndHostPay =
            isHostPayGroupOrder(order) && this.userStore.isGroupOrderHost(order.groupOrderDetails);
          const isLocked = isHostAndHostPay && order.groupOrderDetails.status === "locked";
          if (isLocked) {
            this.unlockGroupOrder();
          }
        }
      }
    );
  }

  private async unlockGroupOrder() {
    try {
      await this.orderStore.unlockGroupOrder();
    } catch (error) {
      this.alertStore.addErrorAlert(UnknownError.Unknown, error);
    }
  }

  /**
   * Updates the current store in the products/menu store when the order store changes
   */
  private async updateStore(oldStore?: Store, newStore?: Store) {
    if (!newStore) {
      // There was a previous store, now there is no longer a store, reset new store
      this.productStore.clearStore();
      try {
        await this.storeStore.getDisclaimers(Env.NationalStoreId ?? "");
      } catch (error) {
        this.alertStore.addErrorAlert(UnknownError.Unknown, error);
      }
    } else if (newStore.id !== oldStore?.id) {
      // There is a new store or no previous store, set store as new store
      this.productStore.currentStore = newStore;
      try {
        await this.storeStore.getDisclaimers(newStore.id);
      } catch (error) {
        this.alertStore.addErrorAlert(UnknownError.Unknown, error);
      }
    }
  }

  /**
   * Refreshes the saved payments if a new saved payment has been created via the order
   */
  private updateSavedPayments(oldOrder?: Order, newOrder?: Order): void {
    if (!newOrder || !oldOrder) return;
    this.userStore.refreshSavedPayments().catch(() => {
      // Nothing to do currently with this error
    });
  }

  /**
   * When an order has been placed, refresh the user's rewards
   */
  private handleOrderFinished(): void {
    this.userStore.refreshRewards().catch(() => {
      // Nothing to do currently with this error
    });
  }

  /**
   * When authentication changes, the order is refreshed if there was an order started
   * Errors with refreshing are displayed as global alerts
   */
  private async handleAuthenticationChange(wasLoggedIn: boolean, isNowLoggedIn: boolean) {
    if (wasLoggedIn === isNowLoggedIn) return;

    this.userStore.refreshUser(false);

    try {
      if (this.orderStore.order) {
        await this.orderStore.getOrder();
      }
    } catch {
      // silence error, ok for this to fail if no order exists
    }
  }

  /**
   * Handles any app wide changes related to errors on the app's order object
   * Currently this handles the 404 error by taking the user to the location's page and letting them know the order has expired
   * Note: React Router v6 upgrade made navigating from this class no longer possible. Left this implementation here (commented out)
   * in case we need to bring it back, in a later refactor.
   */
  // private processOrderError(error?: OctocartError): void {
  //   if (!error) return;
  //   if (error.code === LocalErrorCodes.OrderNotFound) {
  //     // If we don't have an order and the page we're on requires one, take them to the locations page.
  //     const currentPageRequiresOrder = OrderRequiredPages.some((page) => this.navigator.currentLocation.includes(page));

  //     if (currentPageRequiresOrder) {
  //       this.navigator.navigateToPage(MainPage.locations);
  //       this.alertStore.addAlert({ message: "Your order has expired.", type: AlertType.Alert });
  //     }
  //   }
  // }
}
