import ValidationErrorMessages from "errors/ValidationErrorMessages";
import { isString } from "lodash";
import { GroupOrderPaymentType } from "models/groupOrder";
import { OrderType } from "models/order/OrderType";
import { FieldOptions } from "util/FieldOptions";

export type StartGroupOrderSchema = {
  groupOrderPaymentType: GroupOrderPaymentType;
  invitationText?: string;
  maxParticipantSubtotal?: number;
  orderType: OrderType;
  participantEmails: string[];
  saveParticipantEmails?: boolean;
  timeWanted: string;
};

export const fieldOptions: FieldOptions<StartGroupOrderSchema> = {
  groupOrderPaymentType: {
    required: ValidationErrorMessages.Default.FieldIsRequired,
  },
  maxParticipantSubtotal: {
    setValueAs: (value) => {
      if (!isString(value)) return;
      return +value.replace(/[^0-9.]/, "");
    },
  },
  participantEmails: {
    required: ValidationErrorMessages.Default.FieldIsRequired,
  },
};
