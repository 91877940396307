class PasswordValidator {
  /**
   * Matches a minimum of 8 characters, maximum 64. Must contain at least one uppercase, one lowercase,
   * and one digit.
   */
  static validPassword = (password?: string): boolean => {
    return PasswordValidator.isAtLeastMinimumCharacters(password);
  };

  static hasUppercase = (password?: string): boolean => {
    return !!password && /[A-Z]/.test(password.trim());
  };

  static hasLowercase = (password?: string): boolean => {
    return !!password && /[a-z]/.test(password.trim());
  };

  static hasDigit = (password?: string): boolean => {
    return !!password && /[0-9]/.test(password.trim());
  };

  static isAtLeastMinimumCharacters = (password?: string): boolean => {
    return !!password && password.trim().length >= 8;
  };

  static isAtMostMaximumCharacters = (password?: string): boolean => {
    return !!password && password.trim().length <= 64;
  };
}

export default PasswordValidator;
