import useAnalytics from "hooks/useAnalytics";
import MetadataKey, { isMenuCategoryType, MenuCategoryType } from "models/MetadataKey";
import { CartItem } from "models/order/CartItem";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCart } from "stores/cart";
import { MainPage } from "ui/navigation/Pages";
import getMetadataValueForKey from "util/MetadataHelper";

const COLLAPSED_OPTION_COUNT = 4;

const useCartItemViewModel = (
  cartItem: CartItem,
  onQuantityChange?: (item: CartItem, quantity: number) => Promise<void>,
  onRemove?: (item: CartItem) => Promise<void>
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { hideCart } = useCart();
  const { analytics } = useAnalytics();

  const options = useMemo(() => {
    const options = cartItem.options
      .filter((option) => {
        const displayInBasket = getMetadataValueForKey(option, MetadataKey.DisplayInBasket);
        // don't display if "display-in-basket" is "false"
        return displayInBasket !== "false";
      })
      .map(({ id, text }) => ({ key: id, value: text }));

    if (cartItem.specialInstructions) {
      options.push({
        key: `special-instructions-${cartItem.id}`,
        value: "Special instructions: " + cartItem.specialInstructions,
      });
    }

    return options;
  }, [cartItem.id, cartItem.options, cartItem.specialInstructions]);

  const [shouldShowAllOptions, setShouldShowAllOptions] = useState(false);
  const displayedOptions = shouldShowAllOptions ? options : options.slice(0, COLLAPSED_OPTION_COUNT);
  const hiddenOptionCount = shouldShowAllOptions ? 0 : Math.max(options.length - COLLAPSED_OPTION_COUNT, 0);
  const onClickMoreOptions = () => setShouldShowAllOptions(true);

  const [isDisabled, setIsDisabled] = useState(false);

  const isModifiable = useMemo(() => {
    const category = cartItem.product.categories[0];
    if (!category) return true;
    const metadataValue = getMetadataValueForKey(category, MetadataKey.MenuCategoryType);
    if (!isMenuCategoryType(metadataValue)) return true;
    return ![MenuCategoryType.Drinks, MenuCategoryType.Sides].includes(metadataValue);
  }, [cartItem.product.categories]);
  const onClickModify = () => {
    analytics.linkClick("Modify", "Cart");
    hideCart();
    navigate(MainPage.product(cartItem.product, cartItem).path);
  };

  const quantityStepperViewModel = onQuantityChange
    ? {
        value: cartItem.quantity,
        onQuantityChange: async (quantity: number) => {
          try {
            setIsDisabled(true);
            await onQuantityChange(cartItem, quantity);
          } finally {
            setIsDisabled(false);
          }
        },
      }
    : undefined;

  const onClickRemove = onRemove
    ? async () => {
        try {
          setIsDisabled(true);
          await onRemove(cartItem);

          const cartItemId = searchParams.get("cartItemId");
          // updates path to not include the cartItemId if the item is removed from the cart
          if (cartItemId === cartItem.id) {
            navigate(MainPage.product(cartItem.product).path);
          }
        } finally {
          setIsDisabled(false);
        }
      }
    : undefined;

  const images = cartItem.product.images;

  return {
    name: cartItem.product.name,
    images,
    price: cartItem.price,
    recipient: cartItem.recipient,
    options: displayedOptions,
    hiddenOptionCount,
    onClickMoreOptions,
    isDisabled,
    isModifiable,
    onClickModify,
    quantityStepperViewModel,
    canRemove: !!onClickRemove,
    onClickRemove,
  };
};

export default useCartItemViewModel;
