type AcceptGroupOrderInviteResponse = {
  orderId: string;
};

export const isAcceptGroupOrderInviteResponse = (obj: unknown): obj is AcceptGroupOrderInviteResponse => {
  const response = obj as AcceptGroupOrderInviteResponse;
  return typeof response.orderId === "string";
};

export default AcceptGroupOrderInviteResponse;
