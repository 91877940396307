import MuiStyles from "ui/util/Styles";

const styles: MuiStyles = {
  container: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",

    "&.icon-inline": {
      display: "inline-flex",
    },

    "&.icon-size-xxsmall": {
      height: "0.5rem",
      width: "0.5rem",
    },

    "&.icon-size-xsmall": {
      height: "0.75rem",
      width: "0.75rem",
    },

    "&.icon-size-small": {
      height: "1rem",
      width: "1rem",
    },

    "&.icon-size-medium": {
      height: "1.5rem",
      width: "1.5rem",
    },

    "&.icon-size-large": {
      height: "2rem",
      width: "2rem",
    },

    "&.icon-size-xlarge": {
      height: "4rem",
      width: "4rem",

      "&.icon-responsive": {
        [theme.breakpoints.down("lg")]: {
          height: "3.5rem",
          width: "3.5rem",
        },
      },
    },

    "&.icon-size-xxlarge": {
      height: "5rem",
      width: "5rem",

      "&.icon-responsive": {
        [theme.breakpoints.down("lg")]: {
          height: "4rem",
          width: "4rem",
        },
      },
    },

    "&.icon-size-xxxlarge": {
      height: "6rem",
      width: "6rem",

      "&.icon-responsive": {
        [theme.breakpoints.down("lg")]: {
          height: "5rem",
          width: "5rem",
        },
      },
    },

    "> img": {
      height: "100%",
      width: "100%",
    },
  }),
};

export default styles;
