/** Returns true if the date is later than today */
export const inFuture = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
};

/** Validates that the input string is a valid date formatted as "MM/DD/YYYY" */
export const isValidDate = (dateString?: string): boolean => {
  if (!dateString) return false;
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  if (month < 1 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

/** Validates that the input string is a valid date formatted as "MM/DD" */
export const isValidMonthDay = (dateString?: string) => {
  if (!dateString) return false;
  if (!/^\d{2}\/\d{2}$/.test(dateString)) return false;

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const month = parseInt(parts[0], 10);

  if (month < 1 || month > 12) return false;

  const maxMonthLengths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const maxDays = maxMonthLengths[month - 1];
  const day = parseInt(parts[1], 10);

  if (day < 1 || day > maxDays) return false;

  return true;
};

/** Validates that the input string is a valid date formatted as "MM/YYYY" */
export const isValidMonthYear = (dateString?: string): boolean => {
  if (!dateString) return false;
  if (!/^\d{2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10);

  if (month < 1 || month > 12) return false;
  const currentYear = new Date().getFullYear();
  return year >= currentYear;
};

export const howManyYearsOld = (dateString: string): number => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }

  return age;
};

export const getDayMonthYearString = (date: string) => {
  return new Date(date).toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const getDayShortMonthString = (date: string) => {
  return new Date(date).toLocaleString("default", {
    day: "numeric",
    month: "short",
  });
};
