import CalorieInfo, { isCalorieInfo } from "models/CalorieInfo";
import DocumentInformation from "models/DocumentInformation";
import { DynamicData, isDynamicData } from "models/DynamicData";
import { FilterOption, ImagesType } from "models/Filter";
import { MenuItemLabel } from "models/MenuItemLabel";
import { isEmpty, isString, isUndefined, TypeChecker } from "util/JavascriptUtility";

export type BaseProductItem = {
  id: string;
  name: string;
  images?: ImagesType[];
  description?: string;
  documents?: DocumentInformation[];
  categories: FilterOption[];
  calorieInfo?: CalorieInfo;
  menuItemLabels?: MenuItemLabel[];
  chainId?: string;
  shortDescription?: string;
  features: string[];
};

export type ProductItem = BaseProductItem & {
  dynamicData: DynamicData;
};

export const isProductItem = (object: unknown): object is ProductItem => {
  const productItem = object as ProductItem;
  return (
    !!productItem &&
    !!productItem.id &&
    !!productItem.name &&
    !!productItem.dynamicData &&
    isDynamicData(productItem.dynamicData) &&
    (isUndefined(productItem.description) || isString(productItem.description)) &&
    TypeChecker.isOptional(productItem.calorieInfo, isCalorieInfo) &&
    (isEmpty(productItem.features) || TypeChecker.isArray(productItem.features, isString))
  );
};
