import { LoadingButton } from "@mui/lab";
import { Button, Divider, Stack, Typography } from "@mui/material";
import Modal from "ui/components/Modal";
import styles from "ui/components/StartGroupOrderModal/StartGroupOrderModal.styles";
import useViewModel, {
  StartGroupOrderModalViewModelProps as ViewModelProps,
} from "ui/components/StartGroupOrderModal/StartGroupOrderModalViewModel";
import WhenDoYouWantIt from "ui/components/StartGroupOrderModal/WhenDoYouWantIt";
import WhosInvited from "ui/components/StartGroupOrderModal/WhosInvited";
import WhosPaying from "ui/components/StartGroupOrderModal/WhosPaying";

export type StartGroupOrderModalProps = ViewModelProps & {
  open: boolean;
};

const StartGroupOrderModal = ({ open, ...viewModelProps }: StartGroupOrderModalProps) => {
  const { isSubmitting, onClose, onSubmit, ...viewModel } = useViewModel({ ...viewModelProps, open });

  const modalActions = [
    <Button variant="text" onClick={onClose} key="cancel">
      Cancel
    </Button>,
    <LoadingButton onClick={onSubmit} loading={isSubmitting} key="save">
      Send Invitations
    </LoadingButton>,
  ];

  return (
    <Modal actions={modalActions} onClose={onClose} open={open} title="Start a Group Order">
      <Stack gap={4} sx={styles.container}>
        <Typography>
          Fill out this page to invite your buds, and we&rsquo;ll send each person a link to create their custom order.
          As host, you&rsquo;ll be able to see everyone&rsquo;s selections and send in the whole order.
        </Typography>
        <WhenDoYouWantIt {...viewModel} />
        <Divider />
        <WhosPaying {...viewModel} />
        <Divider />
        <WhosInvited {...viewModel} />
      </Stack>
    </Modal>
  );
};

export default StartGroupOrderModal;
