import MuiStyles from "ui/util/Styles";

const styles = {
  container: (theme) => ({
    display: "inline-block",
    marginTop: "calc(1rem + (4rem / 2))",

    [theme.breakpoints.up("md")]: {
      marginTop: "calc(2rem + (7.5rem / 2))",
    },
  }),
  img: (theme) => ({
    display: "block",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    height: theme.spacing(8),

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(15),
    },

    "> svg": {
      height: "100%",
    },
  }),
  content: (theme) => ({
    background: theme.palette.primary.contrastText,
    maxWidth: "40rem",
    padding: theme.spacing(5, 3, 7, 3),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(11.5, 6, 8, 6),
    },
  }),
} satisfies MuiStyles;

export default styles;
