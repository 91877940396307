import Colors from "ui/theme/Colors";
import MuiStyles from "ui/util/Styles";

const styles = {
  cartContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "scroll",
  },
  cartDrawerTitle: (theme) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  closeIcon: (theme) => ({
    color: Colors.superBlack,
    marginRight: theme.spacing(-1.5),
    padding: theme.spacing(1.5),
  }),
  cta: {
    flexGrow: 1,
    width: "100%",
  },
  ctas: (theme) => ({
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: "0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "row",
    gap: 2,
    justifyContent: "space-between",
    p: 2,
  }),
  ctasEmpty: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    justifyContent: "space-around",
    paddingX: "1rem",
    width: "100%",

    "& .emptyCta, .MuiButton-outlined.emptyCta": {
      paddingX: "revert",
    },
  },
  divider: (theme) => ({
    borderColor: theme.palette.grey[200],
  }),
  drawer: (theme) => ({
    "& .MuiDrawer-paper": {
      height: "100dvh",
      width: "100%",

      [theme.breakpoints.up("md")]: {
        width: theme.spacing(53),
      },
    },
  }),
  empty: (theme) => ({
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "1.5rem",
    justifyContent: "center",
    margin: theme.spacing(3, 2, 6),
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      marginX: "auto",
      maxWidth: theme.spacing(49),
    },
  }),
  emptyBagText: {
    display: "flex",
    flexDirection: "column",
    gap: 0.5,
    paddingX: "1rem",
  },
  header: (theme) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0, 2),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1.5),
  }),
  groupOrderButton: {
    alignItems: "center",
    flexDirection: "row",
    gap: "0.5rem",
  },
  participantsBanner: (theme) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: theme.pxToRem(50),
    background: theme.palette.grey[100],
    padding: theme.spacing(2),
  }),
  addParticipantsButton: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
  },
  groupOrderButtonWrapper: {
    margin: "1rem 1rem 0.5rem",
  },
} as const satisfies MuiStyles;

export default styles;
