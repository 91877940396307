export type AuthenticationStatus = "guest" | "logged-in";
export type LocationId = string;
export type HandoffType = "delivery" | "pickup" | "curbside" | "drivethru";
export type PaymentMethod = "debit-credit" | "digital-wallet" | "gift-card" | "cash" | "saved-payment";

export type UserAttributes = {
  authentication_status?: AuthenticationStatus;
  handoff_type?: HandoffType;
  payment_method?: PaymentMethod;
  store_number?: LocationId;
};

export enum PlacementOptions {
  BAG = "bag",
  PDP = "pdp",
}

export enum OptimizelyEventName {
  ATB_CLICK = "atb_click",
  BANNER_CLICK = "banner_click",
  CHECKOUT_CLICK = "checkout_click",
  CROSS_SELL_CLICK = "cross_sell_click",
  CUSTOMIZE_SANDWICH_CLICK = "customize_sandwich_click",
  HOMEPAGE_CARD_CLICK = "homepage_card_click",
  MAKE_IT_A_COMBO_CLICK = "make_it_a_combo_click",
  PDP_CLICK = "pdp_click",
  PDP_VIEW = "pdp_view",
  PRODUCT_MODIFY_CLICK = "product_modify_click",
  PURCHASE_EVENT = "purchase_event",
  REDEEM_REWARD_CLICK = "redeem_reward_click",
  SELECT_LOCATION_CLICK = "select_location_click",
  SIGN_IN_CLICK = "sign_in_click",
  SIGN_UP_CLICK = "sign_up_click",
}

export enum OptimizelyTagName {
  BANNER_NAME = "banner_name",
  HANDOFF_TYPE = "handoff_type",
  ITEM_LIST_NAME = "item_list_name",
  ITEM_NAME = "item_name",
  LINK_TEXT = "link_text",
  LOCATION_SELECTION = "location_selection",
  PAYMENT_METHOD = "payment_method",
  PLACEMENT = "placement",
  REVENUE = "revenue",
  REWARD_NAME = "reward_name",
  REWARD_PLACEMENT = "reward_placement",
}

export type OptimizelyTags = Partial<Record<OptimizelyTagName, string>>;
