import { FilterOption } from "models/Filter";
import MetadataKey from "models/MetadataKey";
import { CartItem } from "models/order/CartItem";
import { BaseProductItem, ProductItem } from "models/ProductItem";
import { RecentOrder } from "models/RecentOrder";
import { Pageable, PagePath } from "ui/navigation/Pageable";
import getMetadataValueForKey from "util/MetadataHelper";

/**
 * *PagePath classes are the static string paths and are broken out separately so that they can be used in routers
 * *Page classes have static properties or functions that return a Pageable type which is used in navigation
 */

/**
 * Main Pages
 * All main paths, no nested levels
 * @public
 */
export class MainPagePath {
  static readonly aboutUs: PagePath = "/about-us";
  static readonly accessibility: PagePath = "/accessibility";
  static readonly adsCookiePolicy: PagePath = "/ad-cookie-policy";
  static readonly californiaSupplyChain: PagePath = "/california-transparency-in-supply-chain-act";
  static readonly category: PagePath = "/menu/category/:categoryId";
  static readonly categoryVanityRegularMenu: PagePath = "/menu/:categorySlug";
  static readonly categoryVanityCateringMenu: PagePath = "/catering/:categorySlug";
  static readonly catering: PagePath = "/catering";
  static readonly documents: PagePath = "/documents/:identifier";
  static readonly error: PagePath = "/error";
  static readonly faq: PagePath = "/faq";
  static readonly feedback: PagePath = "/contact-us/feedback";
  static readonly foundation: PagePath = "/jimmyjohnsfoundation";
  static readonly giftCards: PagePath = "/gift-cards";
  static readonly history: PagePath = "/about-us/history";
  static readonly home: PagePath = "/";
  static readonly locations: PagePath = "/locations";
  static readonly menu: PagePath = "/menu";
  static readonly notFound: PagePath = "/404";
  static readonly openSourceAttributions: PagePath = "/open-source-attributions";
  static readonly privacyPolicy: PagePath = "/privacy-policy";
  static readonly product: PagePath = "/product"; // A single product page
  static readonly productVanityRegularMenu: PagePath = "/menu/:categorySlug/:productSlug";
  static readonly productVanityCateringMenu: PagePath = "/catering/:categorySlug/:productSlug";
  static readonly promos: PagePath = "/promos";
  static readonly rewards: PagePath = "/rewards";
  static readonly sandwichTypes: PagePath = "/sandwich-types";
  static readonly termsAndConditions: PagePath = "/terms-and-conditions";
  static readonly unwichInfo: PagePath = "/unwich-info";
}

/** @public */
export class MainPage {
  static readonly aboutUs: Pageable = { path: MainPagePath.aboutUs, title: "About" };
  static category(category: FilterOption): Pageable {
    const menuView = getMetadataValueForKey(category, MetadataKey.MenuView);
    const categorySlug = getMetadataValueForKey(category, MetadataKey.UrlSlug);

    if (menuView && categorySlug) {
      // build a vanity URL
      const menuType = menuView === "catering" ? "catering" : "menu";

      return {
        path: `/${menuType}/${categorySlug}`,
        title: category.name,
      };
    }

    // fallback to id URL
    return {
      path: `/menu/category/${category.id}`,
      title: category.name,
    };
  }
  static readonly catering: Pageable = { path: MainPagePath.catering, title: "Catering" };
  static readonly faq: Pageable = { path: MainPagePath.faq, title: "FAQs" };
  static readonly home: Pageable = { path: MainPagePath.home, title: "Home" };
  static readonly locations: Pageable = { path: MainPagePath.locations, title: "Stores" };
  static readonly openSourceAttributions: Pageable = {
    path: MainPagePath.openSourceAttributions,
    title: "Open Source Attributions",
  };
  static product(product: ProductItem, cartItem?: CartItem): Pageable {
    if (product.categories[0]) {
      const productCategory = product.categories[0];
      const menuView = getMetadataValueForKey(productCategory, MetadataKey.MenuView);
      const categorySlug = getMetadataValueForKey(productCategory, MetadataKey.UrlSlug);
      const productSlug = product.dynamicData.metaData?.find(({ name }) => name === MetadataKey.UrlSlug)?.value;

      if (menuView && categorySlug && productSlug) {
        // build a vanity URL
        const menuType = menuView === "catering" ? "catering" : "menu";

        return {
          path: `/${menuType}/${categorySlug}/${productSlug}`,
          title: product.name,
        };
      }
    }

    // fallback to id URL
    const cartParam = cartItem ? `&cartItemId=${cartItem.id}` : "";
    return {
      path: `${MainPagePath.product}?id=${product.id}${cartParam}`,
      title: product.name,
    };
  }
  static getByChainId(products: BaseProductItem[], chainId: string): Pageable {
    const product = products.filter((product) => product.chainId === chainId)[0];
    return { path: `${MainPagePath.product}?id=${product.id}`, title: product.name };
  }
  static readonly menu: Pageable = { path: MainPagePath.menu, title: "Menu" };
  static readonly termsAndConditions: Pageable = {
    path: MainPagePath.termsAndConditions,
    title: "Terms and Conditions",
  };
}

/**
 * Cart Pages
 * All Paths that start with /cart
 * @public
 */

export class CartPagePath {
  static readonly base = "/cart";
}

/**
 * Checkout Pages
 * All paths that start with /checkout
 * @public
 */
export class CheckoutPagePath {
  static readonly base = "/checkout";
  static readonly checkout: PagePath = CheckoutPagePath.base;
  static readonly orderConfirmation: PagePath = `${CheckoutPagePath.base}/confirmation`;
  static readonly hostPayParticipantOrderConfirmation: PagePath = `${CheckoutPagePath.base}/participant-confirmation`;
  static readonly signIn: PagePath = `${CheckoutPagePath.base}/sign-in`;
}

/** @public */
export class CheckoutPage {
  static readonly checkout: Pageable = { path: CheckoutPagePath.checkout, title: "Checkout" };
  static readonly orderConfirmation: Pageable = {
    path: CheckoutPagePath.orderConfirmation,
    title: "Thanks for shopping!",
  };
  static readonly signIn: Pageable = { path: CheckoutPagePath.signIn, title: "Checkout Authentication" };
}

/**
 * Account Pages
 * All paths that start with /account
 * @public
 */
export class AccountPagePath {
  static readonly base = "/my-account";
  static readonly createAccount: PagePath = `${AccountPagePath.base}/create-an-account`;
  static readonly forgotPassword: PagePath = `${AccountPagePath.base}/forgot-password`;
  static readonly myFaves: PagePath = `/favorites`;
  static readonly recentOrders: PagePath = `${AccountPagePath.base}/recent-orders`;
  static readonly recentOrderDetails: PagePath = `${AccountPagePath.base}/order/:orderId`;
  static readonly profile: PagePath = AccountPagePath.base;
  static readonly paymentMethods: PagePath = `${AccountPagePath.base}/payment-methods`;
  static readonly personalInformation: PagePath = `${AccountPagePath.base}/personal-information`;
  static readonly signIn: PagePath = `${AccountPagePath.base}/sign-in`;
  static readonly updateEmail: PagePath = `${AccountPagePath.base}/update-email`;
}

/** @public */
export class AccountPage {
  static readonly createAccount: Pageable = { path: AccountPagePath.createAccount, title: "Create an Account" };
  static readonly forgotPassword: Pageable = { path: AccountPagePath.forgotPassword, title: "Forgot Password" };
  static readonly profile: Pageable = { path: AccountPagePath.profile, title: "Profile" };
  static readonly paymentMethods: Pageable = { path: AccountPagePath.paymentMethods, title: "Payment Methods" };
  static readonly personalInformation: Pageable = {
    path: AccountPagePath.personalInformation,
    title: "Personal Information",
  };
  static readonly signIn: Pageable = { path: AccountPagePath.signIn, title: "Sign In" };
  static readonly updateEmail: Pageable = { path: AccountPagePath.updateEmail, title: "Email Address" };
  static readonly recentOrders: Pageable = { path: AccountPagePath.recentOrders, title: "Orders" };
  static recentOrderDetail(order: RecentOrder): Pageable {
    return { path: `${AccountPagePath.base}/order/${order.id}`, title: "Order Details" };
  }
}
export class GroupOrderPagePath {
  static readonly base = "/group-order";
  static readonly resume = `${GroupOrderPagePath.base}/:groupOrderId`;
  static readonly join = `${GroupOrderPagePath.base}/:groupOrderId/join`;
  static readonly splitPayNoHostOrderSubmitted = `${GroupOrderPagePath.base}/submitted`;
  static readonly submissionError = `${GroupOrderPagePath.base}/:groupOrderId/submission-error`;
}

// Pages that require an order to be present
export const OrderRequiredPages = [
  MainPagePath.menu,
  MainPagePath.product,
  MainPagePath.category,
  CartPagePath.base,
  CheckoutPagePath.base,
];
