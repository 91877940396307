import MuiStyles from "ui/util/Styles";

const styles = {
  alert: (theme) => ({
    background: theme.palette.primary.dark,
    padding: theme.spacing(2),
    display: "flex",
    boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.15)",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 6),
      textAlign: "center",
    },
  }),
  title: (theme) => ({
    textTransform: "uppercase",
    paddingRight: "2rem",
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
    },
  }),
  closeBtn: {
    width: "1.5rem",
    height: "1.5rem",
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
  actions: {
    marginTop: "1rem",
    "& .MuiButton-text": {
      color: "primary.contrastText",
      borderColor: "primary.contrastText",

      "&:visited, &:hover": {
        color: "primary.contrastText",
        borderColor: "primary.contrastText",
      },
    },

    "& .MuiButton-contained": {
      padding: "0.5rem 0.5rem",
    },
  },
} satisfies MuiStyles;

export default styles;
