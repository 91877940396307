import { Address } from "models/Address";
import MetadataKey from "models/MetadataKey";
import { OrderType } from "models/order/OrderType";
import SavedDeliveryAddress from "models/user/SavedDeliveryAddress";

export type Product = {
  id: string;
  name: string;
  quantity: number;
  totalCost: number;
  specialInstructions: string;
  choices: Choice[];
  recipient?: string;
};

export type Choice = {
  id: string;
  name: string;
  quantity: number;
  metadata?: { key: MetadataKey; value: string }[];
};

export enum RecentOrderStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  InProgress = "In Progress",
  Pending = "Pending Manual Fire",
  Scheduled = "Scheduled",
  Transmitting = "Transmitting",
}

export enum TrackingStatus {
  Received = "Received",
  OutForDelivery = "Out For Delivery",
  ReadyForPickup = "Ready For Pickup",
  Delivered = "Delivered",
}

const PAST_ORDER_STATUSES = [RecentOrderStatus.Canceled, RecentOrderStatus.Completed];
export const isPastOrderStatus = (status: RecentOrderStatus) => PAST_ORDER_STATUSES.includes(status);

export type RecentOrder = {
  id: string;
  oloId: string;
  status: RecentOrderStatus;
  trackingEventHistory?: OrderTrackingEvent[];
  total: number;
  deliveryAddress?: SavedDeliveryAddress;
  timePlacedFormatted: string;
  readyTimeFormatted: string;
  deliveryMode: OrderType;
  products: Product[];
  customerHandOffCharge: number;
  vendorAddress?: Address;
  vendorPhone: string;
  subTotal: number;
  timeMode: string;
  tip: number;
  salesTax: number;
  fees: Fee[];
  vendorExtRef: string;
};

export type OrderTrackingEvent = { status: TrackingStatus; timeStamp: string };

type Fee = {
  amount: number;
  description: string;
};

export const isRecentOrder = (object: unknown): object is RecentOrder => {
  const order = object as RecentOrder;
  return (
    order != null &&
    order.id != null &&
    typeof order.id === "string" &&
    order.status != null &&
    typeof order.status === "string" &&
    order.total != null &&
    typeof order.total === "number" &&
    order.timePlacedFormatted != null &&
    typeof order.timePlacedFormatted === "string" &&
    order.deliveryMode != null &&
    typeof order.deliveryMode === "string" &&
    order.products != null &&
    order.timeMode != null &&
    typeof order.timeMode === "string" &&
    Array.isArray(order.products)
  );
};

export const isRecentOrderArray = (object: unknown): object is RecentOrder[] => {
  const recentOrders = object as RecentOrder[];
  return !!recentOrders && Array.isArray(recentOrders) && recentOrders.every(isRecentOrder);
};
