import { useContext } from "react";
import { AlertContext } from "stores/alert/AlertProvider";

const useAlert = () => {
  const store = useContext(AlertContext);
  if (!store) {
    throw new Error("useAlert must be used within an AlertProvider.");
  }
  return store;
};

export default useAlert;
