import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import { ImagesType } from "models/Filter";
import { ImageUnavailable } from "ui/commerceUI/components/placeholders/ImageUnavailable";
import { Size } from "ui/Size";
import { TypeChecker } from "util/JavascriptUtility";

type ImageProps = {
  images: ImagesType[];
  groupNameInfo: groupNameType | string;
  imageUnavailableProps?: { size?: Size; className?: string };
  sx?: SxProps<Theme>;
  productName: string;
};

type groupNameType = {
  mobile: string;
  desktop: string;
};

const Image = ({ images, groupNameInfo, imageUnavailableProps, sx, productName }: ImageProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let imageGroup = TypeChecker.isString(groupNameInfo) ? groupNameInfo : groupNameInfo.desktop;
  if (!TypeChecker.isString(groupNameInfo) && isMobile) {
    imageGroup = groupNameInfo.mobile;
  }
  const image = images?.filter((image) => image.groupName === imageGroup)[0];
  return (
    <>
      {image ? (
        <Box component="img" src={image.url} alt={productName} sx={sx} />
      ) : (
        <Box sx={sx}>
          <ImageUnavailable {...imageUnavailableProps} />
        </Box>
      )}
    </>
  );
};

export default Image;
