enum LoadableObservableStatus {
  /** Currently being modified by an asynchronous call */
  Loading = "loading",

  /** Last asynchronous call ended in an error */
  Error = "error",

  /** Nothing is currently happening */
  Idle = "idle",
}

export default LoadableObservableStatus;
