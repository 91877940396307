import { useLocalObservable } from "mobx-react-lite";
import { createContext, ReactNode } from "react";

type CartStore = {
  shouldShowCart: boolean;
  hideCart: () => void;
  showCart: () => void;
  toggleCart: () => void;
};

export const CartContext = createContext<CartStore | null>(null);

type StoreProviderProps = {
  children: ReactNode;
};

const CartProvider = ({ children }: StoreProviderProps) => {
  const store = useLocalObservable(() => ({
    shouldShowCart: false,
    hideCart: function (this: CartStore) {
      this.shouldShowCart = false;
    },
    showCart: function (this: CartStore) {
      this.shouldShowCart = true;
    },
    toggleCart: function (this: CartStore) {
      this.shouldShowCart = !this.shouldShowCart;
    },
  }));

  return <CartContext.Provider value={store}>{children}</CartContext.Provider>;
};

export default CartProvider;
