import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Divider, Fade, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "ui/app/GlobalNav/OrderNav/OrderInfoButton/OrderInfoButton.styles";
import useViewModel from "ui/app/GlobalNav/OrderNav/OrderInfoButton/OrderInfoButtonViewModel";
import Icon from "ui/components/Icon/Icon";
import NavPopover from "ui/components/NavPopover";

const OrderInfoButton = () => {
  const {
    deliveryAddress,
    isDelivery,
    onClickInfoButton,
    onClickStartOver,
    onClickStoreInfo,
    onClosePopover,
    orderTypeText,
    popoverAnchorRef,
    shouldShowChangeLocationText,
    shouldShowPopover,
    startOverLabel,
    storeAddress,
    storeInfoUrl,
    storePhone,
  } = useViewModel();

  const theme = useTheme();
  const isSmallOrGreater = useMediaQuery(theme.breakpoints.up("sm"));
  const direction = isSmallOrGreater ? "column" : "row";
  const alignment = isSmallOrGreater ? "flex-start" : "flex-end";
  const iconSize = isSmallOrGreater ? "medium" : "small";

  return (
    <>
      <Fade in={true} timeout={200}>
        <Box sx={styles.container} ref={popoverAnchorRef}>
          <Link component="button" onClick={onClickInfoButton} sx={styles.button} underline="none">
            <Stack direction="row" alignItems="center" gap="0.5rem">
              <Icon icon="location" size={direction === "row" ? "medium" : undefined} />
              <Stack direction="row" alignItems="center">
                <Stack direction={direction} alignItems={alignment} gap={direction === "row" ? "0.25rem" : undefined}>
                  <Typography variant="body2" color="grey.300" noWrap component="span">
                    {orderTypeText}
                  </Typography>
                  <Typography
                    variant="body2Bold"
                    color="secondary.contrastText"
                    component="span"
                    textAlign="left"
                    noWrap
                  >
                    {isDelivery ? deliveryAddress?.streetAddress : storeAddress?.streetAddress}
                    {shouldShowPopover ? (
                      <KeyboardArrowUpIcon sx={styles.chevron} fontSize={iconSize} />
                    ) : (
                      <KeyboardArrowDownIcon sx={styles.chevron} fontSize={iconSize} />
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Link>
        </Box>
      </Fade>

      <NavPopover anchorEl={popoverAnchorRef.current} open={shouldShowPopover} onClose={onClosePopover}>
        <Box sx={styles.popoverContainer}>
          <Stack sx={styles.addressContainer}>
            {isDelivery ? (
              <>
                <Typography component="p" variant="h4" sx={styles.streetAddress}>
                  {deliveryAddress?.streetAddress}
                </Typography>
                <Divider sx={styles.divider} flexItem />
                <Typography sx={styles.cityStateZipLine}>Store address: {storeAddress?.streetAddress}</Typography>
              </>
            ) : (
              <Typography component="p" variant="h4" sx={styles.streetAddress}>
                {storeAddress?.streetAddress}
              </Typography>
            )}
            <Typography sx={styles.crossStreet}>{storeAddress?.crossStreet}</Typography>
            <Typography sx={styles.cityStateZipLine}>{storeAddress?.cityStateZip}</Typography>
            <Typography sx={styles.cityStateZipLine}>{storePhone}</Typography>
            <Link target="_blank" href={storeInfoUrl} sx={styles.storeInfoButton} onClick={onClickStoreInfo}>
              Store Info
            </Link>
          </Stack>

          <Stack sx={styles.popoverFooter}>
            {shouldShowChangeLocationText && (
              <Typography sx={styles.changeLocationText}>Need to change locations?</Typography>
            )}
            <Button role="link" onClick={onClickStartOver} variant="text" sx={styles.startOverButton}>
              {startOverLabel}
            </Button>
          </Stack>
        </Box>
      </NavPopover>
    </>
  );
};

export default OrderInfoButton;
