import MuiStyles from "ui/util/Styles";

const styles = {
  modal: (theme) => ({
    "& .MuiDialog-paper": {
      [theme.breakpoints.up("md")]: {
        width: "33rem",
      },
    },
  }),
  modalContent: (theme) => ({
    gap: "1rem",
    paddingBottom: "1rem",
    paddingTop: "0.75rem",

    [theme.breakpoints.up("md")]: {
      paddingBottom: "3rem",
      paddingTop: "0.5rem",
    },
  }),
} as const satisfies MuiStyles;

export default styles;
