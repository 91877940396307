import StackdriverErrorReporter from "stackdriver-errors-js";

export const errorHandler = new StackdriverErrorReporter();

if (process.env.NODE_ENV === "production" && Env.GoogleApiKey && Env.GoogleProjectId) {
  errorHandler.start({
    key: Env.GoogleApiKey,
    projectId: Env.GoogleProjectId,
    service: Env.GoogleServiceName,
  });
} else {
  errorHandler.setUser = () => {};
}

export const logError = (e: unknown) => {
  if (process.env.NODE_ENV === "production") {
    if (e instanceof Error || typeof e === "string") {
      errorHandler.report(e);
    }
  } else if (process.env.NODE_ENV === "development") {
    console.error(e);
    return;
  }
};

export const log = (object: string | Error): void => {
  if (process.env.NODE_ENV === "development") {
    console.log(object);
  }
};
