import TokenManageable from "dataTransformers/TokenManageable";
import { LocalErrorCodes } from "errors/LocalErrorCodes";
import { CSRFTimeOut, MalformedData } from "errors/LocalErrors";
import { isOctocartError, OctocartError } from "errors/OctocartError";
import _ from "lodash";
import { when } from "mobx";
import { isNetworkError, NetworkError, NetworkPromise, NetworkResponse } from "networking/NetworkPromise";
import { OctocartNetworkError } from "networking/OctocartNetworkError";
import OctocartNetworkErrorCodes from "networking/OctocartNetworkErrorCodes";
import TokenNetworking from "networking/TokenNetworking";
import { isTokenResponse } from "networking/TokenResponse";
import LoadableObservableStatus from "stores/LoadableObservableStatus";
import LocalStorage from "util/LocalStorage";
import { logError } from "util/Logger";

export const UnknownError: OctocartError = {
  name: "Unknown Error",
  message: "An unknown error has occurred",
  code: LocalErrorCodes.UnknownError,
};

export const isVoidResponse = (object: unknown): object is void => {
  return _.isUndefined(object) || _.isEmpty(object);
};

export interface BaseDataTransformable {
  readonly tokenManager: TokenManageable;
}

/**
 * Base class that has methods for all data transformers.
 */
export abstract class BaseDataTransformer {
  protected readonly tokenNetworker: TokenNetworking;
  public readonly tokenManager: TokenManageable;

  protected readonly localStorage: LocalStorage<string>;

  constructor(
    tokenNetworker: TokenNetworking,
    tokenManager: TokenManageable,
    localStorage = new LocalStorage<string>("token")
  ) {
    this.tokenNetworker = tokenNetworker;
    this.tokenManager = tokenManager;

    this.localStorage = localStorage;
  }

  /**
   * Verifies data is properly formed, rejects with a MalformedData error.
   * @param response network response
   * @param typeGuard type guard for expected object
   */
  processResponse<T>(response: NetworkResponse, typeGuard: (object: unknown) => object is T): Promise<T> {
    if (response && typeGuard(response.data)) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(MalformedData);
    }
  }

  /**
   * Returns consistent OFErrors when error handling.
   * Sets the code to the HTTP status code
   */
  handleError(error: NetworkResponse | NetworkError | Error | unknown): Promise<never> {
    let octocartError: OctocartError | OctocartNetworkError = UnknownError;
    if (isNetworkError(error) && !!error.response && !!error.response.data && isOctocartError(error.response.data)) {
      // Networking error and server returned a message in the response
      octocartError = { ...error.response.data, httpStatusCode: error.response.status };
    } else if (isOctocartError(error)) {
      // This error has already been handled by the data transformer, so just pass it on.
      // This will happen if the .then() throws a rejection, for example if the type check fails
      octocartError = error;
    } else if (isNetworkError(error)) {
      // A networking error with a response but there's no message in the response
      octocartError = { ...UnknownError, httpStatusCode: error.response.status };
    }

    if (isNetworkError(error) && [400, 500, 502, 503, 504].includes(error.response.status)) {
      logError({ ...error, ...octocartError, stack: error.stack });
    }

    return Promise.reject(octocartError);
  }

  /**
   * Wrapper for all network calls. Handles type and error processing/unpacking, and web token refreshing
   * @param call: Network call to be made
   * @param typeGuard: Type guard for the expected return type
   */
  makeNetworkCall<T>(call: () => NetworkPromise<T | void>, typeGuard: (object: unknown) => object is T): Promise<T> {
    const processedCall: () => Promise<T> = () =>
      call()
        .then((response) => this.processResponse(response, typeGuard))
        .catch((error) => this.handleError(error));

    // Already fetching a web token, so wait for it
    if (this.isFetchingWebToken()) return this.waitForWebToken(processedCall);

    return processedCall().catch((error) => {
      // Not an Octocart error, pass on the rejection
      if (!isOctocartError(error)) {
        return Promise.reject(error);
      }

      if (error.code === LocalErrorCodes.NotAuthenticated) {
        if (this.tokenManager.userState.object) {
          this.logout();
        }

        return Promise.reject(error);
      }

      // Not the CSRF error, pass on the rejection
      if (error.code !== OctocartNetworkErrorCodes.InvalidCSRFToken) {
        return Promise.reject(error);
      }

      // Already fetching a web token, set up a Promise to wait
      if (this.isFetchingWebToken()) return this.waitForWebToken(processedCall);

      // Refresh the web token and retry the call
      return this.loadToken(processedCall);
    });
  }

  /**
   * Attempts to load the web token, and if successful, makes the myCall()
   * If the web token is not successful, it returns the error
   * @param call: Should be the regular network call with type processing, and without a token retry
   * @private
   */
  private loadToken<T>(call: () => Promise<T>): Promise<T> {
    this.tokenManager.setToken(LoadableObservableStatus.Loading, undefined);
    return this.tokenNetworker
      .getWebToken()
      .then((response) => this.processResponse(response, isTokenResponse))
      .then((response) => {
        this.tokenManager.setToken(LoadableObservableStatus.Idle, response.token);
        this.tokenNetworker.updateToken(response.token);
        return call();
      })
      .catch((error) => {
        this.tokenManager.setToken(LoadableObservableStatus.Error, undefined);
        return this.handleError(error);
      });
  }

  /**
   * Sets up a promise that waits for the webToken's status to no longer be loading, then retries the call.
   * Times out at 10 seconds. If it times out, it rejects with the CSRFTimeOut error
   * @param call: Should be the regular network call with type processing, and without a token retry
   */
  waitForWebToken<T>(call: () => Promise<T>): Promise<T> {
    return when(() => this.tokenManager.webToken.status !== LoadableObservableStatus.Loading, {
      timeout: 10000,
    })
      .catch(() => Promise.reject(CSRFTimeOut))
      .then(() => call());
  }

  private isFetchingWebToken(): boolean {
    return this.tokenManager.webToken.status === LoadableObservableStatus.Loading;
  }

  async logout() {
    try {
      await this.makeNetworkCall(() => this.tokenNetworker.logout(), isVoidResponse);
    } catch (e) {
      logError(e);
    } finally {
      this.tokenManager.clearUserState();
    }
  }
}
